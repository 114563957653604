import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Grid,
  Backdrop,
  CircularProgress,
  Divider,
} from "@material-ui/core";
//Notificacion errores https://iamhosseindhv.com/notistack/demos#variants
import { useSnackbar } from "notistack";
//componentes del sistema
import { getRUCEmpresa } from "redux/actions/userActions";
import { API_POST_SAVE_SECUENCIAL } from "../apis";
import { palette, useStylesGlobal } from "configurations/styles/theme";
import { useSelector } from "react-redux";
import clientBackend from "configurations/axios";
import Alert from "widgets/alert";
import useForm from "hooks/useFrom";
import validations from "../validations";
import { useStylesApp } from "hooks/useStyles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import InputMask from "react-input-mask";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(0),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  divForm: {
    marginBottom: "1%",
  },
  divInput: {
    marginBottom: "1%",
    paddingRight: "1%",
    paddingLeft: "0.5%",
  },
  divButton: {
    paddingLeft: "3%",
    paddingRight: "3%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
}));

const AgregarSecuencial = ({ updateView }) => {
  const classes = useStyles();
  const classesApp = useStylesApp();
  const classesGlobal = useStylesGlobal();
  const [openLoader, setOpenLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const userToken = useSelector((state) => state.user.tk);
  const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
  const { enqueueSnackbar } = useSnackbar();
  const [secuencial, setSecuencial] = useState({
    // codigoEstablecimiento:'',
    // puntoEmision:'',
  });

  const existError = () => {};

  const handleSave = async () => {
    setOpenLoader(true);
    secuencial.codigoEstablecimiento = validations
      .handleNumSecuencial(secuencial.codigoEstablecimiento)
      .toString();
    secuencial.puntoEmision = validations
      .handleNumSecuencial(secuencial.puntoEmision)
      .toString();
    secuencial.idEmpresa = userToken ? getRUCEmpresa(userToken) : "0";

    await clientBackend
      .post(API_POST_SAVE_SECUENCIAL, secuencial)
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Punto de Emisión registrado.", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 10000,
          });
          updateView();
          setOpenLoader(false);
          setOpen(false);
        }
      })
      .catch((exception) => {
        if (exception.response) {
          if (exception.response.status === 400) {
            setMsg({ show: true, msg: "No se pudo almacenar el secuencial." });
          } else if (exception.response.status === 404) {
            setMsg({
              show: true,
              msg: "No se encontró la empresa enviada para relacionarla con el secuencial.",
            });
          } else if (exception.response.status === 409) {
            setMsg({ show: true, msg: "El secuencial ya existe." });
          } else {
            setMsg({
              show: true,
              msg: "Error al registrar el secuencial. Contáctese con el administrador del Sistema.",
            });
          }
        } else {
          setMsg({
            show: true,
            msg: "Existen problemas en la red. Contáctese con el administrador del Sistema.",
          });
        }
        setOpenLoader(false);
      });
  };

  const { errors, handleSubmit, getObject } = useForm(
    handleSave,
    validations.addUser,
    existError
  );

  function closeAlert() {
    setMsg({ show: false });
  }

  const handleClickOpen = () => {
    setOpen(true);
    errors.codigoEstablecimiento = null;
    errors.puntoEmision = null;
    errors.direccion = null;
    setSecuencial({});
    setMsg({ show: false, msg: null, type: null });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setSecuencial({
      ...secuencial,
      [event.target.name]: event.target.value,
    });
    getObject({
      ...secuencial,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Fragment>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        <AddCircleIcon
          style={{ color: palette.primary.contrastText, paddingRight: "3%" }}
          fontSize="small"
        />{" "}
        Agregar Punto de Emisión
      </Button>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="max-width-dialog-title">
          Nuevo Punto de Emisión
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <label>
                  Los campos marcados con ({" "}
                  <font color={palette.error.main}> *</font> ) son obligatorios:
                </label>
              </Grid>
              {msgError.show ? (
                <Alert
                  tipo={msgError.type}
                  mensaje={msgError.msg}
                  mesajeSubrayado={null}
                  titulo={null}
                  mostrar={msgError.show}
                  cerrarAlerta={closeAlert}
                />
              ) : null}
              <Grid item xs={12} md={4}>
                Establecimiento: <font color={palette.error.main}> *</font>
              </Grid>
              <Grid item xs={12} md={8}>
                <InputMask
                  className={
                    errors.codigoEstablecimiento
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Ej. 001"
                  mask="999"
                  name="codigoEstablecimiento"
                  value={validations.handleNumSecuencial(
                    secuencial.codigoEstablecimiento
                  )}
                  onChange={handleChange}
                  autoComplete="off"
                  step="1"
                  min="1"
                  max="999"
                />
                {errors.codigoEstablecimiento ? (
                  <p className={classesApp.errorLetters}>
                    {errors.codigoEstablecimiento}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} md={4}>
                Punto de Emisión: <font color={palette.error.main}> *</font>
              </Grid>
              <Grid item xs={12} md={8}>
                <InputMask
                  className={
                    errors.puntoEmision
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Ej. 001"
                  mask="999"
                  name="puntoEmision"
                  value={validations.handleNumSecuencial(
                    secuencial.puntoEmision
                  )}
                  onChange={handleChange}
                  autoComplete="off"
                  step="1"
                  min="1"
                  max="999"
                />
                {errors.puntoEmision ? (
                  <p className={classesApp.errorLetters}>
                    {errors.puntoEmision}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} md={4}>
                Dirección:<font color={palette.error.main}> *</font>
              </Grid>
              <Grid item xs={12} md={8}>
                <input
                  type="text"
                  className={
                    errors.direccion
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Ej. Solanda"
                  name="direccion"
                  value={secuencial.direccion}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {errors.direccion ? (
                  <p className={classesApp.errorLetters}>{errors.direccion}</p>
                ) : null}
              </Grid>
              <Grid container spacing={3} justify="center">
                <Grid item md={12} xs={12}>
                  <Divider></Divider>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classesGlobal.marginButton}
                >
                  Agregar
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  className={classesGlobal.marginButton}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
};

export default AgregarSecuencial;
