import { makeStyles, Paper } from '@material-ui/core';
import { useStylesGlobal } from 'configurations/styles/theme';
import useTitle from 'hooks/useTitle';
import React, { useState } from 'react';
import ClientData1 from 'views/facturacion/Vouchers/perchaseSettlement/Forms/ClientData1';
import PerchaseSettlementData from '../perchaseSettlement/Forms/PerchaseSettlementData';
import UploadPhysicalInvoiceData from './Forms/UploadPhysicalInvoiceData';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: '3px',
        flexGrow: 1,
        paddingLeft: '3%',
        paddingRight: '3%',
        marginBottom: "1%",
    },
}));

export function UploadPhysicalInvoices({data}) {

    const classesView = useStyles();
    const classesGlobales = useStylesGlobal();
    const [idAsgCliente, setIdAsgCliente] = useState({});
    const [foundClient, setFoundClient] = useState({});
    const [options, setOptions] = React.useState([]);
    //useTitle({ title: "Factura" });
    return (
        <div className={classesView.root}>
            <Paper className={classesGlobales.paperHeader}>
                <h5 className={classesGlobales.titleHeader}>Subir Factura Física</h5>
            </Paper>

            <ClientData1
                setIdAsgCliente={setIdAsgCliente}
                setFoundClient={setFoundClient}
                foundClient={foundClient}
                setOptions={setOptions}
                options={options}
            />

            <UploadPhysicalInvoiceData
                setIdAsgCliente={setIdAsgCliente}
                idAsgCliente={idAsgCliente}
                setFoundClient={setFoundClient}
                setOptions={setOptions}
                idempresacontratante={data}
            />

        </div>
    );

}