import React, { useState, useCallback, useEffect, Fragment } from "react";
import { makeStyles, TextField, Grid } from "@material-ui/core";
import { palette, useStylesGlobal } from "configurations/styles/theme";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { debounce } from "throttle-debounce";
import service from "./services";
import TextError from "componets/TextError";
//redux
import { getRUCEmpresa } from "redux/actions/userActions";
import { useSelector } from "react-redux";
import AddCostumer from 'views/administración/Clients/Buttons/AddCostumer'
const useStyles = makeStyles({
  form: {
    width: "100%",
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  divForm: {
    marginBottom: "0%",
    textAlign: "justify",
  },
  divInput: {
    marginBottom: "0%",
    paddingRight: "0%",
    paddingLeft: "0%",
    textAlign: "justify",
  },
});

const costumerNull = {
  nombres: "",
  telefono: "",
  correo: "",
  direccion: "",
};
const DataClient = ({
  stateRedux = {},
  action,
  ErrorRedux = null,
  saveCache,
}) => {
  const classes = useStyles();
  const classesGlobales = useStylesGlobal();
  const [data, setData] = useState(costumerNull);
  const [inputValue, setInputValue] = useState("");
  const [existClient, setExistClient] = useState(false);
  const [isSelectedClient, setIsSelectedClient] = useState(false);
  const tk = useSelector((state) => state.user.tk);
  const [optionsCostumers, setOptionsCostumers] = useState([
    {
      label: "Ingrese por lo menos 3 caracteres para iniciar la busqueda.",
      isDisabled: true,
    },
  ]);

  const updateView = () => {

  }

  const getCostumers = useCallback(
    debounce(500, false, (param, ruc = "0") => {
      service
        .searchCostumer(param, ruc)
        .then((response) => {
          if (response.status === 200) {
            setExistClient(false)
            const newConstumers = response.data;

            if (newConstumers.length > 0) {
              for (let i = 0; i < newConstumers.length; i++) {
                newConstumers[i].label =
                  newConstumers[i].cliente.idCliente +
                  " - " +
                  newConstumers[i].nombres +
                  " - " +
                  newConstumers[i].correo;
              }
              setOptionsCostumers(newConstumers);
              setExistClient(false)
            } else {
              setOptionsCostumers([
                {
                  label: `No existen clientes con la identificación: ${param}`,
                },
              ]);

              setExistClient(!existClient)
            }
          } else {
            setExistClient(true)
          }
        })
        .catch(() => {
          setOptionsCostumers([
            {
              label: `No existen clientes con la identificación: ${param}`,
            },
          ]);
          setExistClient(true)
        });
    }),
    []
  );

  useEffect(() => {
    if (stateRedux.nombresCliente && stateRedux.correoCliente) {
      setData({
        nombres: stateRedux.nombresCliente,
        telefono: stateRedux.telefonoCliente,
        correo: stateRedux.correoCliente,
        direccion: stateRedux.direccionCliente,
      });
      setInputValue(
        stateRedux.nombresCliente + " - " + stateRedux.correoCliente
      );
      setExistClient(false)
    }
  }, [stateRedux]);

  const handleAutocompleteChange = (event, newInputValue) => {
    setInputValue(newInputValue);

    if (newInputValue.length > 0 && newInputValue.length < 100 && tk) {
      let token = tk ? tk : "";
      getCostumers(newInputValue, getRUCEmpresa(token));
    } else if (newInputValue.length === 0) {
      setOptionsCostumers([
        {
          label: "Ingrese por lo menos 3 caracteres para iniciar la busqueda.",
          isDisabled: true,
        },
      ]);
    }
  };

  const handleStyle = (nameInput) => {
    if (data) {
      switch (nameInput) {
        case "nombres":
          if (data.nombres) {
            return "form-control  is-valid";
          }
          return "form-control ";
        case "telefono":
          if (data.telefono) {
            return "form-control  is-valid";
          }
          return "form-control ";
        case "correo":
          if (data.correo) {
            return "form-control  is-valid";
          }
          return "form-control ";
        case "direccion":
          if (data.direccion) {
            return "form-control  is-valid";
          }
          return "form-control ";
        default:
          break;
      }
    } else {
      return "form-control ";
    }
  };

  const handleFlagLoadingAutoComplete = () => {
    const aviableOptions = optionsCostumers.filter(
      (item) =>
        item.label !==
        "Ingrese por lo menos 3 caracteres para iniciar la busqueda."
    );
    return aviableOptions.length === 0;
  };

  return (
    <Fragment>
      <div className={classesGlobales.containerRoot}>
        <Grid container spacing={1} className={classesGlobales.containerForm}>
          <Grid item xs={12} md={12}>
            <label>
              Los campos marcados con ({" "}
              <font color={palette.error.main}> *</font> ) son obligatorios:
            </label>
          </Grid>
          <Grid item xs={12} md={2}>
            <label>
              {" "}
              CI/ RUC/ Pasaporte:<font color={palette.error.main}> *</font>{" "}
            </label>
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              onChange={(event, newValue) => {
                if (newValue) {
                  if (newValue.idAsignacionEmpresaCliente) {
                    action(newValue.idAsignacionEmpresaCliente.toString());
                  }
                  setData(newValue);
                  setIsSelectedClient(true)
                  if (typeof saveCache === "function") {
                    saveCache(newValue);
                  }
                } else {
                  action(null);
                  setIsSelectedClient(false)
                  setData(costumerNull);
                  if (typeof saveCache === "function") {
                    saveCache(costumerNull);
                  }
                }
              }}
              getOptionDisabled={(option) =>
                option.label.includes(
                  "Ingrese por lo menos 3 caracteres para iniciar la busqueda."
                ) ||
                option.label.includes(
                  "No existen clientes con la identificación:"
                )
              }
              noOptionsText="No  existen clientes con la identificación ingresada..."
              inputValue={inputValue}
              onInputChange={handleAutocompleteChange}
              options={optionsCostumers}
              loading={handleFlagLoadingAutoComplete()}
              loadingText="Buscando..."
              getOptionLabel={(option) => option.label}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  className="form-control"
                  {...params}
                  variant="outlined"
                  fullWidth
                  error={ErrorRedux ? true : false}
                />
              )}
            />
            <TextError text={ErrorRedux} />
          </Grid>

          {existClient && !isSelectedClient ?
            <Grid item xs={12} md={6}  >
              <AddCostumer updateView={updateView}></AddCostumer>
            </Grid> :
            <Grid item xs={12} md={6}  ></Grid>

          }

          <Grid item xs={12} md={2} className={classes.divForm}>
            <label> Razón Social / Nombres:</label>
          </Grid>
          <Grid item xs={12} md={4} className={classes.divInput}>
            <input
              className={handleStyle("nombres")}
              value={data.nombres}
              disabled
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <label> Teléfono:</label>
          </Grid>
          <Grid item xs={12} md={4}>
            <input
              className={handleStyle("telefono")}
              value={data.telefono ? data.telefono : ''}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <label> Correo Principal:</label>
          </Grid>
          <Grid item xs={12} md={4}>
            <input
              className={handleStyle("correo")}
              value={data.correo}
              disabled
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <label> Dirección:</label>
          </Grid>
          <Grid item xs={12} md={4}>
            <input
              className={handleStyle("direccion")}
              value={data.direccion ? data.direccion : ''}
              disabled
            />
          </Grid>

        </Grid>
      </div>
    </Fragment>
  );
};

export default DataClient;
