import React, { useEffect, useState } from 'react';
import {
    Button, Dialog, DialogContent,
    DialogContentText, DialogTitle,
    Backdrop, CircularProgress, makeStyles, Grid
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
//estilos
import { useStylesApp } from '../../hooks/useStyles';
import { palette } from "configurations/styles/theme";
import validations from "./validations";
import { useDispatch } from "react-redux";
import { authUserSuccess, parseJwt } from "redux/actions/userActions";
import { getTkTmp, removeTKTmp } from "redux/sessionstorage";
import { sessionTimeOut } from "security/services";
import middleware from "configurations/axios";
import Alert from 'widgets/alert';
const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
    tituloClave: {
        textAlign: 'center',
        fontSize: '16px',
    }
}));

export default function DialogChangePass({ openDialog, closeDialog, onClose }) {
    const classes = useStylesApp();
    const [open, setOpen] = React.useState(openDialog);
    const classesView = useStyles();
    const history = useHistory();
    const [currentPass, setCurrentPass] = useState('');
    const [pass, setPass] = useState('');
    const [passConfirm, setPassConfirm] = useState('');
    const [errores, setErrores] = useState({});
    const [openLoader, setOpenLoader] = useState(false);
    const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
    const dispatch = useDispatch();
    const authSuccess = (data) => dispatch(authUserSuccess(data));
    useEffect(() => {
        setOpen(openDialog);
        setMsg({ show: false, msg: null, type: null });
        setCurrentPass('');
        setPass('');
        setPassConfirm('');
        return () => setOpen(false);
    }, [openDialog]);

    function closeAlert() { setMsg({ show: false }); }
    const handleClose = () => {
        setOpen(false);
        closeDialog(false);
        onClose(false);
    };

    const handleChange = event => {
        if (event.target.name === 'pass') {
            setPass(event.target.value);
        } else if (event.target.name === 'passConfirm') {
            setPassConfirm(event.target.value);
        } else if (event.target.name === 'currentPass') {
            setCurrentPass(event.target.value);
        }
    }

    const updatePass = async () => {
        const errors = validations.updatePassword({
            currentPass: currentPass,
            newPass: pass,
            confirmPass: passConfirm
        });
        setErrores(errors)
        if (Object.keys(errors).length === 0) {
            const objAuth = getTkTmp();
            const tk = objAuth.accessToken;
            const timeout = sessionTimeOut(parseJwt(tk).exp);
            authSuccess(objAuth);
            if (timeout >= 360000) {
                setOpenLoader(true);
                await middleware.put("/cuenta/usuario/contrasenia", {
                    claveActual: currentPass,
                    idAsignacionEmpresaUsuario: parseJwt(tk).sub,
                    idEmpresa: parseJwt(tk).ruc,
                    nuevaClave: pass
                }).then(
                    response => {

                        if (response.status >= 200 && response.status <= 300) {

                            removeTKTmp();
                            history.push("/facturacion");

                        }
                        setOpenLoader(false);
                        onClose(false);
                    }
                ).catch(
                    exception => {
                        if (exception.response) {
                            if (exception.response.status === 400) {
                                setMsg({ show: true, msg: 'No se pudo actualizar la contraseña.', type: "error" });
                            } else if (exception.response.status === 401) {
                                setMsg({ show: true, msg: 'Error de autenticación.', type: "error" });
                            } else if (exception.response.status === 405) {
                                setMsg({ show: true, msg: 'La contraseña actual enviada no coincide con los registros.', type: "error" });
                            } else {
                                setMsg({ show: true, msg: 'No se pudo actualizar la contraseña.', type: "error" });
                            }
                        } else {
                            setMsg({ show: true, msg: 'Existen problemas en la red. Contáctese con el administrador del Sistema.', type: "error" });
                        }
                        setOpenLoader(false);
                    }
                );
            } else {
                setMsg({ show: true, msg: 'La sesión expiro, vuela a la pantalla de Login.', type: "error" });
            }
        }

    }


    const handleStyle = (error, value) => {
        if (value) {
            if (error) {
                return "form-control is-invalid";
            } else {
                return "form-control is-valid";
            }
        } else {
            return "form-control";
        }
    }


    return (
        <Dialog
            maxWidth="md"
            fullWidth
            open={open}
            onClose={handleClose}
            aria-labelledby="primer logeo"  >
            <DialogTitle id="responsive-dialog-title"> <h6> Cambie su contraseña </h6></DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 alert alert-primary" role="alert">
                                <Grid container justify='center' className={classesView.tituloClave} >
                                    <Grid item xs={12}>
                                        <strong>Bienvenido al Sistema de Facturación Electrónica, Facturar EC </strong> <br />
                                    </Grid>
                                </Grid>
                                <strong>Al ser su primer incio de sesión o recuperar su contraseña, debe actualizar la misma. </strong><br />
                                <strong>Recuerde:</strong>
                                <ul>
                                    <li>La nueva contraseña debe tener al menos 8 caracteres</li>
                                    <li>Las contraseñas seguras tienen al menos una letra minúscula, una letra mayúscula y un carácter especial (por ejemplo: @, #, %, entre otros).</li>
                                </ul>
                            </div>
                            {msgError.show ?
                                <Alert
                                    tipo={msgError.type}
                                    mensaje={msgError.msg}
                                    mesajeSubrayado={null}
                                    titulo={null}
                                    mostrar={msgError.show}
                                    cerrarAlerta={closeAlert}
                                /> : null
                            }
                            <div className="col-sm-4 mb-1">
                                <label>Contraseña actual: <font color={palette.error.main}>*</font></label>
                            </div>
                            <div className="col-sm-6 mb-1">
                                <input type="password"
                                    className={handleStyle(errores.currentPass, currentPass)}
                                    name="currentPass"
                                    value={currentPass}
                                    onChange={handleChange}
                                />
                                {
                                    errores.currentPass ? <label className={classes.errorLetters} >{errores.currentPass}</label> : null
                                }
                            </div>
                            <div className="col-sm-4 mb-1">
                                <label>Nueva contraseña: <font color={palette.error.main}>*</font></label>
                            </div>
                            <div className="col-sm-6 mb-1">
                                <input type="password" className={handleStyle(errores.newPass, pass)}
                                    name="pass" value={pass}
                                    onChange={handleChange}
                                />
                                {
                                    errores.newPass ? <label className={classes.errorLetters} >{errores.newPass}</label> : null
                                }
                            </div>
                            <div className="col-sm-4 mb-1">
                                <label>Confirme la contraseña: <font color={palette.error.main}>*</font></label>
                            </div>
                            <div className="col-sm-6 mb-1">
                                <input type="password" className={handleStyle(errores.confirmPass, passConfirm)}
                                    name="passConfirm" value={passConfirm}
                                    onChange={handleChange}
                                />
                                {
                                    errores.confirmPass ? <label className={classes.errorLetters} >{errores.confirmPass}</label> : null
                                }
                            </div>
                            <div className="col-sm-6 pr-2">

                                <Button onClick={updatePass} color="primary" variant="contained" fullWidth>
                                    Cambiar Contraseña
                                </Button>
                            </div>
                            <div className="col-sm-6 pr-2">
                                <Button onClick={handleClose} color="secondary" variant="outlined" fullWidth>
                                    Cancelar
                                </Button>
                            </div>
                        </div>
                    </div>
                </DialogContentText>
            </DialogContent>
            <Backdrop className={classesView.backdrop} open={openLoader} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    );
}