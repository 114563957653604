import { Backdrop, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import { palette, useStylesGlobal } from 'configurations/styles/theme';
import { useSelector } from 'react-redux';
import { clientPay } from 'configurations/axios';
import { getRUCEmpresa } from 'redux/actions/userActions';
import PinchZoomPan from "react-responsive-pinch-zoom-pan";

const useStyles = makeStyles(theme => ({
    IconButton: {
        marginTop: "0%",
        marginBottom: "0%",
        padding: "0px",
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    img: {
        minWidth: "auto",
        maxWidth: "90%",
        minHeight: "250px",
        maxHeight: "280px",
        border: "1px solid  #aaaaaa",
        borderRadius: "8px",
        display: "block"
    },
    divForm: {
        marginBottom: "1%",
    },
    divInput: {
        marginBottom: "1%",
        paddingRight: "1%",
        paddingLeft: "0.5%",
    },
    containerEnlarge: {
        display: 'inline-block',
        position: 'relative',
        margin: '15px',
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
}))

const ReviewPaymentBtn = ({ data }) => {
    const classes = useStyles();
    const userToken = useSelector((state) => state.user.tk);
    const [openLoader, setOpenLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const classesGlobal = useStylesGlobal();
    const [vouchers, setVouchers] = useState([]);


    const handleClickOpen = () => {
        getVoucher()
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getVoucher = async () => {
        setOpenLoader(true)
        await clientPay
            .get(
                `/pago/voucher/${data.idAsignacionPaqueteEmpresa}`
            )
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setOpenLoader(false)
                    setVouchers(response.data);
                    return response.data;
                }
            })
            .catch((exception) => {
                console.log({ exception });
                return exception;
            });
    };


    return (
        <div>

            <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleClickOpen}
                className={classes.IconButton}
            >
                <RateReviewOutlinedIcon style={{ color: palette.primary.main }} className={classes.small} />
            </IconButton>

            <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose} >
                <DialogTitle id="max-width-dialog-title">Comprobantes de Pago: {data.empresaContratante.razonSocialempresa}</DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} md={2} className={classes.divForm}>
                            <label>Paquete: </label>
                        </Grid>
                        <Grid item xs={12} md={4} className={classes.divInput}>
                            <input value={data.paquete.nombre} className={"form-control"} disabled></input>
                        </Grid>
                        <Grid item xs={12} md={2} className={classes.divForm}>
                            <label>Total: </label>
                        </Grid>
                        <Grid item xs={12} md={4} className={classes.divInput}>
                            <input value={`$. ${data.valorTotalPaquete.toFixed(2)}`} className={"form-control"} disabled></input>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Divider></Divider>
                            <br />
                        </Grid>

                        {vouchers ?
                            vouchers.map(voucher =>
                                <Grid item xs={12} md={4} style={{ width: '300px', height: '300px' }}>
                                    <PinchZoomPan >
                                        <img src={"data:image/png;base64," + voucher.voucher} alt="Comprobante" />
                                    </PinchZoomPan>
                                </Grid>

                            ) :
                            null
                        }

                    </Grid>

                    <Grid container spacing={3} justify="center">
                        <Grid item md={12} xs={12}>
                            <br />
                            <Divider></Divider>
                        </Grid>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleClose}
                            className={classesGlobal.marginButton}
                        >
                            Cerrar
                                </Button>
                    </Grid>


                </DialogContent>
            </Dialog>
            <Backdrop className={classes.backdrop} open={openLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </div>
    );
}

export default ReviewPaymentBtn;