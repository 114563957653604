import { useState } from 'react';
import { makeStyles, withStyles, InputBase, } from '@material-ui/core';
import { palette, useStylesGlobal } from 'configurations/styles/theme';
import { useStylesApp } from 'hooks/useStyles';
import bigDecimal from 'js-big-decimal';
//Notificacion errores https://iamhosseindhv.com/notistack/demos#variants
import { useSnackbar } from 'notistack';
import uuid from 'react-uuid';
import { notNull } from "utils/checkInputs";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        margin: theme.spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '1%',
        borderStyle: 'solid',
        borderBottomWidth: '5px',
        borderRadius: '4px',
    },
    formControl: {
        marginTop: theme.spacing(0),
        minWidth: 120,

    },
    radio: {
        display: '-webkit-box',
        marginTop: '0px'
    },
    radioLable: {
        color: palette.primary.main,
        display: 'flex'
    },
    radioButton: {
        marginTop: '0px',
        paddingTop: '0px'
    },
    divForm: {
        marginBottom: '1%',
    },
    divInput: {
        marginBottom: '1%',
        paddingRight: '1%',
        paddingLeft: '0.5%',

    },
    divSelect: {
        width: '100%',
    },
    divButton: {
        paddingLeft: '3%',
        paddingRight: '3%',
        marginBottom: '1%'
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
    haedClient: {
        backgroundColor: palette.primary.light,
        width: '10%',
        marginBottom: '2%',
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '1%',
        borderStyle: 'solid',
        borderBottomWidth: '5px',
        borderRadius: '4px',
    }
}));



export default function useMultiplePayments(pagos, setPagos, viewPago, setViewPago, totals) {

    const classes = useStyles();
    const classesApp = useStylesApp();
    const classesGlobal = useStylesGlobal();
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [pago, setPago] = useState({
        id: uuid(),
        formaPago: "",
        plazo: "",
        total: "",
        unidadTiempo: ""
    });

    const [empty, setEmty] = useState({
        pago: true,
        plazo: true,
        total: true,
        tiempo: true
    })

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        cleanPago();
        setOpen(false);
    };



    const hadleAddPago = () => {
        const errors = validationInputs();
        setEmty(errors)
        let isValid = Object.values(errors).filter(item => item === false).length === 0
        isValid = isValid && (errors.total === null)
        if (isValid) {
            if (pagos.length > 0) {
                setPagos([...pagos, pago]);
            } else {
                setPagos([pago]);
            }
            if (viewPago === 1) {
                setViewPago(2)
            } else {
                setViewPago(1)
            }
            handleClose();
            enqueueSnackbar(`Forma de pago agregado`, {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                autoHideDuration: 4000,
            });
        }
    }

    const handleChange = event => {
        event.preventDefault();
        const re = /^[0-9\b]{1,14}$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setPago({
                ...pago,
                [event.target.name]: event.target.value
            })
        }
    }

    const handleChangeTotal = event => {
        event.preventDefault();
        const re = /^[0-9\b.]{1,14}$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            if (Number(event.target.value.trim()) >= 0) {
                setPago({
                    ...pago,
                    [event.target.name]: event.target.value
                })
            }
        }
    }

    const validateDecimal = (numberValor) => {
        let splitList = numberValor.split('.');
        if (splitList.length > 2) {
            return `El dato ingresado es erroneo utilice punto (.) para separar decimales`;
        } else if (splitList.length > 1) {
            if (splitList[1].length > 2) {
                return `El dato ingresado debe tener solo 2 decimales Ej, 12.43`;
            } else {
                return null;
            }
        } else {
            return null;
        }

    }



    const validateMultiplePayment = () => {

        let sum = pagos.reduce(function (acc, pago) {
            return Number(acc) + Number(pago.total);
        }, 0);

        return sum;
    }

    const cleanPago = () => {
        setPago({
            id: uuid(),
            formaPago: "",
            plazo: "",
            total: "",
            unidadTiempo: ""

        });
        setEmty({
            pago: true,
            plazo: true,
            total: null,
            tiempo: true
        })
    }


    const validationInputs = () => {
        let errors = {};
        errors.pago = notNull(pago.formaPago)
        errors.tiempo = notNull(pago.unidadTiempo)
        errors.plazo = notNull(pago.plazo)
        errors.total = notNull(pago.total) ? null : "Ingrese el total."
        if (errors.total === null) {
            errors.total = validateDecimal(pago.total.toString())
        }
        if (Number(pago.total) === 0) {
            errors.total = 'No puede ser un valor menor o igual a cero'
        }

        if (!(Number(bigDecimal.add(validateMultiplePayment(), pago.total)) <= Number(bigDecimal.add(totals.totalFactura, totals.totalPropina)))) {
            errors.total = `El valor es: ${Number(bigDecimal.add(validateMultiplePayment(), pago.total))}, y excede al valor total`
        }

        return errors;
    }

    const summation = () => {
        let sumTotal = bigDecimal.round(bigDecimal.subtract(bigDecimal.add(totals.totalFactura, totals.totalPropina), validateMultiplePayment()), 2, bigDecimal.RoundingModes.HALF_UP);
        return (sumTotal);
    }


    return {
        setPago,
        palette,
        classes,
        classesApp,
        classesGlobal,
        open,
        pago,
        empty,
        handleClickOpen,
        handleClose,
        hadleAddPago,
        handleChange,
        handleChangeTotal,
        validateDecimal,
        validateMultiplePayment,
        cleanPago,
        validationInputs,
        summation
    }

}

export const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(0),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 15,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

