import React, { Fragment, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import usePermissons from "hooks/usePermissons";
import UseTitle from "hooks/useTitle";

import Users from "../Users";
import Header from "widgets/Header";
import AuthorizedIssuedVouchers from "../../facturacion/AuthorizedIssuedVouchers";
import NoAuthorizedIssuedVouchers from "../../facturacion/NoAuthorizedIssuedVouchers";
import PendingVouchers from "../../facturacion/PendingVouchers";
import HomeContent from "./Content";
import Costumers from "../Clients";
import ProductsService from "../productsServices";
import VoucherCancel from "../../facturacion/CancelVouchers";
import Providers from "../providers";
import Secuencial from "../secuencial";
import AsignacionSecuencial from "../asignacionSecuencial";
import Invoice from "../../facturacion/Vouchers/invoices";
import CompanyData from "../UserAccount/CompanyData";
import ChangePass from "../UserAccount/Forms/ChangePass";
import CreditNote from "../../facturacion/Vouchers/CreditNote";
import Assitence from "../Assistance";
import Informacion from "../Information";
import AlertTimeOut from "./AlertTimeOut";
import PerchaseSettlement from "views/facturacion/Vouchers/perchaseSettlement";
import Retention from "views/facturacion/Vouchers/Retention";
import DebitNote from "../../facturacion/Vouchers/DebitNote";
import ReferralGhide from "views/facturacion/Vouchers/referralGhide";
import NoPrerequisities from "views/facturacion/NoPrerequisites";
import VoucherReportIssued from "views/facturacion/Reports/VoucherReportIssued";
import VoucherReportReceived from "views/facturacion/Reports/VoucherReportReceived";
import UploadVoucher from "views/facturacion/Vouchers/Add/UploadVoucher";
import VoucherReceived from "views/facturacion/AuthorizedReceived/index";
import BuyPackage from "../buyPackage";
import UploadVoucherPay from "../buyPackage/components/UploadVoucherPay";
import Company from "../company";
import Payments from "../payments";

const Home = () => {
  const actualContent = useSelector(
    (state) => state.resources.selectedResource
  );
  const dispatch = useDispatch();
  const { updatePermissons } = usePermissons(useSelector, dispatch);

  const updateContent = () => {
    if (typeof actualContent === "undefined" || actualContent === null) {
      UseTitle({ title: "Inicio" });
      return <HomeContent />;
    }
    UseTitle({ title: actualContent.titleTab });

    switch (actualContent.id) {
      case "INI":
        return <HomeContent />;
      case "INI":
        return <HomeContent />;
      case "CLI":
        return <Costumers />;
      case "ANU":
        return <VoucherCancel />;
      case "CSE":
        return <Secuencial />;
      case "PRO":
        return <ProductsService />;
      case "USU":
        return <Users />;
      case "AUT":
        return <AuthorizedIssuedVouchers />;
      case "NAU":
        return <NoAuthorizedIssuedVouchers />;
      case "CRA":
        return <VoucherReceived />;
      case "PEN":
        return <PendingVouchers />;
      case "PRV":
        return <Providers />;
      case "ASE":
        return <AsignacionSecuencial />;
      case "FAC":
        return <Invoice />;
      case "GUI":
        return <ReferralGhide />;
      case "CAC":
        return <UploadVoucher />;
      case "CRE":
        return <CreditNote />;
      case "DEB":
        return <DebitNote />;
      case "RET":
        return <Retention />;
      case "LIQ":
        return <PerchaseSettlement />;
      case "RCE":
        return <VoucherReportIssued />;
      case "RCR":
        return <VoucherReportReceived />;
      case "AEM":
        return <Company />;
      case "APQ":
        return <Payments />;
      case "IEM":
        return <CompanyData />;
      case "CCO":
        return <ChangePass />;
      case -1:
        return <Assitence />;
      case -2:
        return <Informacion />;
      case -4:
        return <NoPrerequisities />;
      case -5:
        return <BuyPackage />;
      case -6:
        return <UploadVoucherPay />;
      default:
        return <HomeContent />;
    }
  };

  const buildTitle = () => {
    if (typeof actualContent === "undefined" || actualContent === null) {
      return "Inicio";
    } else {
      return actualContent.titlePage;
    }
  };

  useEffect(() => {
    updatePermissons();
  }, []);

  return (
    <Fragment>
      <Header title={buildTitle()} />
      <main>
        <AlertTimeOut percentageWarnigTimeOut={25} />
        {updateContent()}
      </main>
    </Fragment>
  );
};

export default Home;
