import React, { Fragment, useState, useEffect } from 'react';
import { Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, makeStyles, Radio, NativeSelect, withStyles, InputBase, CardHeader } from '@material-ui/core';
import { palette, useStylesGlobal } from 'configurations/styles/theme';
import { formasPago, unidadTiempo } from 'views/facturacion/Forms/typeVoucher'
import MultiplePayments from '../Buttons/MultiplePayments';
import tableStyle from 'configurations/styles/table'
import DeletePaymentBtn from '../Buttons/DeletePaymentBtn';
import bigDecimal from 'js-big-decimal';
import AssignmentIcon from '@material-ui/icons/Assignment';
import TableDataSimplePag from 'widgets/Tables/TableDataSimplePag';
import TableDataSimple from 'widgets/Tables/TableDataSimple';
import { useStylesApp } from 'hooks/useStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        margin: theme.spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '1%',
        borderStyle: 'solid',
        boxShadow: "5px 3px 3px #aaaaaa",
        borderRadius: '8px',
        borderWidth: '2px'
    },
    formControl: {
        marginTop: theme.spacing(0),
        minWidth: 120,

    },
    radio: {
        display: '-webkit-box',
        marginTop: '0px'
    },
    radioLable: {
        color: palette.primary.main,
        display: 'flex'
    },
    radioButton: {
        marginTop: '0px',
        paddingTop: '0px'
    },
    divForm: {
        marginBottom: '0%',
        textAlign: 'justify',
        color: theme.palette.text.primary,
    },
    divInput: {
        marginBottom: '1%',
        paddingRight: '1%',
        //paddingLeft: '0.5%',
        textAlign: 'justify',

    },
    divSelect: {
        width: '100%',
    },
    divButton: {
        paddingLeft: '3%',
        paddingRight: '3%'
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
    haedClient: {
        backgroundColor: palette.primary.main,
        width: '10%',
        marginBottom: '2%',
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '1%',
        borderStyle: 'solid',
        borderBottomWidth: '5px',
        borderRadius: '8px',
    }
}));

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(0),
        },
    },
    input: {
        borderRadius: 4,
        //position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const PaymentMethods = ({ totals, pagos, setPagos, productsDetalle }) => {


    const classes = useStyles();
    const classesGlobales = useStylesGlobal();
    const [isOne, setIsOne] = useState('U');
    const [emptyPlazo, setEmptyPlazo] = useState(true);
    const [pago, setPago] = useState({
        formaPago: "20",
        plazo: "1",
        total: totals.totalFactura,
        unidadTiempo: "Días"
    });
    const [viewPago, setViewPago] = useState(1);
    const columns = [
        { title: 'Forma pago', field: `formaPago`, cellStyle: tableStyle.cellTable, width: '10%' },
        { title: 'Unidad de tiempo', field: 'unidadTiempo', cellStyle: tableStyle.cellTable, width: '30%' },
        { title: 'Plazo', field: 'plazo', cellStyle: tableStyle.cellTable, width: '10%' },
        { title: 'Valor', field: 'total', cellStyle: tableStyle.cellTable, width: '30%' },
        {
            title: 'Eliminar', cellStyle: tableStyle.cellTable, width: '10%', render: rowData => <DeletePaymentBtn
                data={rowData}
                pagos={pagos}
                setPagos={setPagos}
                viewPago={viewPago}
                setViewPago={setViewPago} />
        }
    ];
    const [update, setUpdate] = useState(1);
    const classesApp = useStylesApp();


    const handleChangeCheck = event => {
        let totalFactura = Number(bigDecimal.add(totals.totalFactura, totals.totalPropina));
        if (totalFactura > 0) {
            if (event.target.value) {
                setIsOne(event.target.value);
                setPago({ ...pago, total: totalFactura })
            } else {
                setIsOne(event.target.value);
            }

        } else {
            alert('No tiene detalle/s ingresado/s')
        }

    }


    const hadleChangePago = event => {
        setPago(
            {
                ...pago,
                [event.target.name]: event.target.value
            }
        )
        setPagos([{ ...pago, [event.target.name]: event.target.value }])
    }

    const handleChange = event => {
        event.preventDefault();
        const re = /^[0-9\b]{1,14}$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setPago(
                {
                    ...pago,
                    [event.target.name]: event.target.value
                })
            setPagos([{ ...pago, [event.target.name]: event.target.value }])
            if (event.target.name === 'plazo' && event.target.value.toString() !== '') {
                setEmptyPlazo(true)
            } else if (event.target.name === 'plazo' && event.target.value.toString() === '') {
                setEmptyPlazo(false)
            }


        }
    }



    useEffect(() => {

        if (isOne === 'U') {
            //setPago({...pago,['total']:bigDecimal.round(bigDecimal.add(totals.totalFactura,totals.totalPropina),2,bigDecimal.RoundingModes.HALF_UP)})          
            setPago({ ...pago, ['total']: bigDecimal.add(totals.totalFactura, totals.totalPropina) })
            //setPagos([]);
            //setPagos([pago])
            setPagos([{ ...pago, ['total']: bigDecimal.add(totals.totalFactura, totals.totalPropina) }])

        } else if (pagos.length === 1) {
            setPagos([]);
        }
        if (productsDetalle.length > 1) {
            //setPago({...pago,['total']:bigDecimal.round(bigDecimal.add(totals.totalFactura,totals.totalPropina),2,bigDecimal.RoundingModes.HALF_UP)})
            setPago({ ...pago, ['total']: bigDecimal.add(totals.totalFactura, totals.totalPropina) })
        }
    }, //[isOne,totals.totalFactura])
        [isOne, productsDetalle, totals.totalFactura, totals.totalPropina])


    return (
        <Fragment>
            <div className={classesGlobales.itemContainer} >
                <Grid container xs={12} md={12}
                    justify='center'        >
                    <CardHeader
                        className={classesGlobales.cabecera}
                        title={'Forma de Pago'}
                        avatar={<AssignmentIcon></AssignmentIcon>}
                    />
                </Grid>
                <Grid container spacing={1} className={classesGlobales.containerForm}>
                    <Grid container justify='center' >
                        <FormControl component="fieldset" className={classes.formControl} >
                            <FormLabel component="legend" className={classesGlobales.titleCard} > <strong> Cantidad de pagos de la factura</strong> </FormLabel>
                            <RadioGroup aria-label="datosCliente"
                                name="isOne"
                                value={isOne}
                                onChange={handleChangeCheck}
                                className={classes.radio} required
                            >
                                <FormControlLabel
                                    value='U'
                                    control={<Radio color="primary" className={classes.radioButton} />}
                                    label="Uno"
                                    labelPlacement="start"
                                    className={classes.radioLable}
                                />
                                <FormControlLabel
                                    value='V'
                                    control={<Radio color="primary" className={classes.radioButton} />}
                                    label="Varios"
                                    labelPlacement="start"
                                    className={classes.radioLable}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {isOne === 'U' ?
                        <Fragment>
                            <Grid container spacing={1} className={classesGlobales.containerForm}>
                                <Grid item xs={12} md={2} className={classes.divForm}>
                                    <label>Forma: <font color={palette.error.main}>*</font></label>
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <FormControl className={classes.divSelect}>
                                        <NativeSelect
                                            name='formaPago'
                                            value={pago.formaPago}
                                            onChange={hadleChangePago}
                                            input={<BootstrapInput />}
                                        >
                                            <option aria-label="None" disabled >--Seleccione--</option>
                                            {
                                                formasPago.map(pago => (
                                                    <option
                                                        key={pago.CODIGO}
                                                        value={pago.CODIGO}>
                                                        {pago.CODIGO} - {pago.NOMBRE}
                                                    </option>
                                                ))
                                            }

                                        </NativeSelect>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={2} className={classes.divForm}>
                                    <label>Tiempo: <font color={palette.error.main}>*</font></label>
                                </Grid>
                                <Grid item xs={12} md={4} c>
                                    <FormControl className={classes.divSelect}>
                                        <NativeSelect
                                            name='unidadTiempo'
                                            value={pago.unidadTiempo}
                                            onChange={hadleChangePago}
                                            input={<BootstrapInput />}
                                        >
                                            <option aria-label="None" disabled >--Seleccione--</option>
                                            {
                                                unidadTiempo.map(unidad => (
                                                    <option
                                                        key={unidad.CODIGO}
                                                        value={unidad.NOMBRE}>
                                                        {unidad.NOMBRE}
                                                    </option>
                                                ))
                                            }

                                        </NativeSelect>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={2} className={classes.divForm}>
                                    <label>Plazo: <font color={palette.error.main}>*</font></label>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <input type="text"
                                        maxLength={3}
                                        placeholder="Ej. 3"
                                        name="plazo"
                                        value={pago.plazo}
                                        onChange={handleChange}
                                        autoComplete="off"
                                        className={pago ? pago.plazo ? 'form-control is-valid' : 'form-control' : 'form-control'} />
                                    {
                                        emptyPlazo === true ?
                                            null
                                            :
                                            <p className={classesApp.errorLetters}>Ingrese un valor</p>
                                    }


                                </Grid>

                                <Grid item xs={12} md={2} className={classes.divForm}>
                                    <label>Total: <font color={palette.error.main}>*</font></label>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <input type="text"
                                        placeholder="Ej. 300"
                                        disabled
                                        name="total_"
                                        value={pago.total}
                                        //onChange={event=> setPago({...pago,[event.target.name]:event.target.value})}
                                        autoComplete="off"
                                        className={pago ? pago.total ? 'form-control is-valid' : 'form-control' : 'form-control'} />

                                </Grid>
                            </Grid>
                        </Fragment>

                        :
                        <Grid item xs={12} md={12}>
                            <MultiplePayments
                                pagos={pagos}
                                setPagos={setPagos}
                                viewPago={viewPago}
                                setViewPago={setViewPago}
                                totals={totals}
                            />
                            {pagos.length > 0 ?
                                <Grid item xs={12} md={12}>
                                    {viewPago === 1 ?

                                        <TableDataSimplePag
                                            title=""
                                            columns={columns}
                                            data={pagos}
                                            buscar={false}
                                        />
                                        :
                                        <TableDataSimple
                                            title=""
                                            columns={columns}
                                            data={pagos}
                                            buscar={false}
                                        />
                                    }
                                </Grid>
                                :
                                null
                            }
                        </Grid>
                    }
                </Grid>
            </div>

        </Fragment>
    );
}

export default PaymentMethods;