import React, { useState, useEffect } from "react";
import { Grid, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import { getRUCEmpresa } from "redux/actions/userActions";
//componentes del sistema
import TablePaginated from "widgets/Tables/TablePaginated";
import TableSimple from "widgets/Tables/TableSimple";
import { API_GET_ALL_USER, API_GET_SEARCH_USER } from "./apis";
import clientBackend from "configurations/axios";
import tableStyle from "configurations/styles/table";
import AddUserBtn from "./Buttons/AddUser";
import SearchUsers from "./Froms/SearchUsers";
import InfoUserBtn from "./Buttons/InfoUser";
import EditUserBtn from "./Buttons/EditUser";
import EditProfileBtn from "./Buttons/EditProfile";
import { CardGeneric } from "../components/CardGeneric";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "35px",
    flexGrow: 1,
    paddingLeft: "3%",
    paddingRight: "3%",
    marginBottom: "50px",
  },
  headerBottons: {
    padding: "0.5%",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    height: "300px",
  },
}));

const Home = () => {
  const tokenUser = useSelector((state) => state.user.tk);

  const classesView = useStyles();
  const [update, setUpdate] = useState(0);
  const [foundUsers, setFoundUsers] = useState([]);
  const [paramSearch, setParamSearch] = useState("");
  const ProfileRender = (data) => {
    switch (data.data.asignacionPerfilUsuarios[0].perfil.nombre) {
      case "INT":
      case "Interno":
      case "interno":
        return "Interno";
      case "ADM":
      case "Administrador":
      case "administrador":
        return "Administrador";
      default:
        return "Indefinido";
    }
  };

  const getParam = (data) => {
    if (data.length > 0) {
      searchUsers(data);
    } else {
      reload();
    }
  };

  const ParseStateClient = (data) => {
    console.log(data);
    // if (data.data.estado) {
    //   return "Activo";
    // } else {
    //   return "Inactivo";
    // }
  };

  const columns = [
    {
      title: "Identificación",
      field: "usuario.identificacionUsuario",
      cellStyle: tableStyle.cellLeft,
      width: "7%",
    },
    {
      title: "Usuario",
      field: "nombreUsuarioAcceso",
      cellStyle: tableStyle.cellLeft,
      width: "15%",
    },
    {
      title: "Nombre",
      field: "nombreUsuario",
      cellStyle: tableStyle.cellLeft,
      width: "25%",
    },
    {
      title: "Perfil",
      cellStyle: tableStyle.cellTable,
      width: "7%",
      render: (rowData) => <ProfileRender data={rowData} />,
    },
    {
      title: "Ver Información",
      cellStyle: tableStyle.cellTable,
      width: "7%",
      render: (rowData) => <InfoUserBtn user={rowData} />,
    },
    {
      title: "Estado",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => {
        console.log(rowData);
        return rowData.estadoUsuario;
      },
    },
    {
      title: "Editar Perfil",
      cellStyle: tableStyle.cellTable,
      width: "7%",
      render: (rowData) => (
        <EditProfileBtn user={rowData} updateView={reload} />
      ),
    },
    {
      title: "Editar Información",
      cellStyle: tableStyle.cellTable,
      width: "7%",
      render: (rowData) => <EditUserBtn user={rowData} updateView={reload} />,
    },
  ];

  const requestConfig = {
    uri: API_GET_ALL_USER + (tokenUser ? getRUCEmpresa(tokenUser) : "0"),
    metodo: "get",
    body: null,
    page: 0,
    elementos: 15,
    sort: "idAsignacionEmpresaUsuario,desc",
  };

  const reload = () => {
    setParamSearch("");
    setUpdate(1);
  };

  useEffect(() => {
    if (update === 1) {
      setUpdate(0);
    }
  }, [update]);

  const searchUsers = async (param) => {
    setUpdate(3);
    await clientBackend
      .get(
        API_GET_SEARCH_USER +
          (tokenUser ? getRUCEmpresa(tokenUser) : "0") +
          "?parametroBusqueda=" +
          param
      )
      .then((response) => {
        if (response.status === 200) {
          setFoundUsers(response.data);
          setUpdate(2);
        }
      })
      .catch((exception) => {
        console.log({ exception });
      });
  };

  const renderTable = () => {
    switch (update) {
      case 0:
        return (
          <TablePaginated
            title=""
            columns={columns}
            requestConfig={requestConfig}
          />
        );

      case 1:
      case 3:
        return (
          <div className={classesView.loader}>
            <CircularProgress />
          </div>
        );
      case 2:
        return (
          <TableSimple
            columns={columns}
            data={foundUsers}
            title=""
            numberRowsPerPage={15}
          />
        );
      default:
        break;
    }
  };
  return (
    <div className={classesView.root}>
      <CardGeneric>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <AddUserBtn updateView={reload} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={9}>
            <SearchUsers backupParam={paramSearch} sendData={getParam} />
          </Grid>
        </Grid>
      </CardGeneric>
      {renderTable()}
    </div>
  );
};

export default Home;
