import React, { useState, useEffect } from "react";
import { Button, Grid, Divider, Input } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import { getRUCEmpresa } from "redux/actions/userActions";
//componentes del sistema
import TablePaginated from "widgets/Tables/TablePaginated";
import TableSimple from "widgets/Tables/TableSimple";
import { API_GET_ALL_PROVIDER, API_GET_SEARCH_PROVIDERS } from "./apis";
import clientBackend from "configurations/axios";
import tableStyle from "configurations/styles/table";
import AddProviderBtn from "./Buttons/AddProvider";
import SearchProvider from "./Froms/SearchProvider";
import EditCostumerBtn from "./Buttons/EditProvider";
import { CardGeneric } from "../components/CardGeneric";

import { clientBackendXLSX } from "configurations/axios";
import QueueIcon from "@material-ui/icons/Queue";
import { palette } from "configurations/styles/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "35px",
    flexGrow: 1,
    paddingLeft: "3%",
    paddingRight: "3%",
    marginBottom: "50px",
  },
  headerBottons: {
    padding: "0.5%",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    height: "300px",
  },
  uploadFile: {
    marginLeft: "9px",
    padding: "5px",
    color: "blue",
  },
}));

const Home = () => {
  const tokenUser = useSelector((state) => state.user.tk);

  const classesView = useStyles();
  const [update, setUpdate] = useState(0);
  const [foundCostumers, setFoundCostumers] = useState([]);
  const [paramSearch, setParamSearch] = useState("");
  const [fileXLSX, setFileXLSX] = useState();

  const getParam = (data) => {
    setParamSearch(data);
    if (data.length > 2) {
      searchProvider(data);
    } else {
      reload();
    }
  };

  const ParseStateProvider = (data) => {
    if (data.data.estado) {
      return "Activo";
    } else {
      return "Inactivo";
    }
  };

  const columns = [
    {
      title: "Identificación",
      field: "cliente.idCliente",
      cellStyle: tableStyle.cellLeft,
      width: "7%",
    },
    {
      title: "Razón Social/Nombres",
      field: "nombres",
      cellStyle: tableStyle.cellLeft,
      width: "25%",
    },
    {
      title: "Teléfono",
      field: "telefono",
      cellStyle: tableStyle.cellTable,
      width: "7%",
    },
    {
      title: "Dirección",
      field: "direccion",
      cellStyle: tableStyle.cellLeft,
      width: "20%",
    },
    {
      title: "Correo",
      field: "correo",
      cellStyle: tableStyle.cellLeft,
      width: "17%",
    },
    {
      title: "Estado",
      cellStyle: tableStyle.cellTable,
      width: "10%",
      render: (rowData) => <ParseStateProvider data={rowData} />,
    },
    {
      title: "Editar",
      cellStyle: tableStyle.cellTable,
      width: "3%",
      render: (rowData) => (
        <EditCostumerBtn costumer={rowData} updateView={reload} />
      ),
    },
  ];

  const requestConfig = {
    uri: API_GET_ALL_PROVIDER + (tokenUser ? getRUCEmpresa(tokenUser) : "0"),
    metodo: "get",
    body: null,
    page: 0,
    elementos: 15,
    // sort: "idAsignacionEmpresaCliente,desc",
    // sort: "idAsignacionEmpresaCliente,desc",
    sort: "estado,idAsignacionEmpresaCliente,desc",
  };

  const reload = () => {
    setUpdate(1);
  };

  useEffect(() => {
    if (update === 1) {
      setUpdate(0);
    }
  }, [update]);

  const searchProvider = async (param) => {
    setUpdate(3);
    await clientBackend
      .get(
        API_GET_SEARCH_PROVIDERS +
          (tokenUser ? getRUCEmpresa(tokenUser) : "0") +
          "?parametroBusqueda=" +
          param
      )
      .then((response) => {
        if (response.status === 200) {
          setFoundCostumers(response.data);
          setUpdate(2);
        }
      })
      .catch((exception) => {
        console.log({ exception });
      });
  };

  const renderTable = () => {
    switch (update) {
      case 0:
        return (
          <TablePaginated
            title=""
            columns={columns}
            requestConfig={requestConfig}
          />
        );

      case 1:
      case 3:
        return (
          <div className={classesView.loader}>
            <CircularProgress />
          </div>
        );
      case 2:
        return (
          <TableSimple
            columns={columns}
            data={foundCostumers}
            title=""
            numberRowsPerPage={15}
          />
        );
      default:
        break;
    }
  };

  const uploadFileXLSX = async () => {
    console.log(fileXLSX);

    let formData = new FormData();
    formData.append("file", fileXLSX);

    const response = await clientBackendXLSX.post(
      "/carga/masiva/proveedores/archivo",
      formData,
      {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "cache-control": "no-cache",
        },
      }
    );

    console.log(response);
  };

  const handleFileChange = async (event) => {
    console.log(event.target.files);
    let files = event.target.files;
    if (files.length === 1) {
      if (
        files[0].type === "application/vnd.ms-excel" ||
        files[0].type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        if (files[0].size < "1048576") {
          setFileXLSX(files[0]);
        } else if (files[0].size > "1048576") {
          console.log("El peso máximo del archivo es de 1 Mb.");
          setFileXLSX(null);
        }
      } else {
        console.log("Solo se admiten archivos en formato xls o xlsx.");
      }
    }
  };

  return (
    <div className={classesView.root}>
      <CardGeneric>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <AddProviderBtn updateView={reload} />
            {/* <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "9px" }}
              onClick={uploadFileXLSX}
              disabled={fileXLSX == null ? true : false}
            >
              <QueueIcon
                style={{
                  color: palette.primary.contrastText,
                  paddingRight: "3%",
                }}
                fontSize="small"
              />
              Carga Masiva EXCEL
            </Button>
            <Input
              className={classesView.uploadFile}
              variant="contained"
              color="primary"
              type="file"
              placeholder="Carga Masiva EXCEL"
              onChange={handleFileChange}
            /> */}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={8} className={classesView.headerBottons}>
            <SearchProvider backupParam={paramSearch} sendData={getParam} />
          </Grid>
        </Grid>
      </CardGeneric>
      {renderTable()}
    </div>
  );
};

export default Home;
