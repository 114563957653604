import React, { useState, useEffect, Fragment } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import DateProvaider from "providers/DateProvaider";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { palette, useStylesGlobal } from "configurations/styles/theme";
import TextError from "componets/TextError";
import { validarNumDocumentoSRI } from "utils/checkDocument";
import InputMask from "react-input-mask";
import { styleInput } from "configurations/styles/theme";
const getDateOneMonthAgo = () => {
  let date = new Date();
  const month = date.getMonth();
  date.setMonth(date.getMonth() - 1);

  if (date.getMonth() === month) {
    date.setDate(0);
  }

  date.setHours(0, 0, 0);
  date.setMilliseconds(0);
  return date;
};

const useStyles = makeStyles({
  dataPicker: {
    backgroundColor: "#FFF",
  },
});

const ReferenceDocument = ({
  type,
  State,
  onChange,
  isRecoveryInvoice = false,
  errorsRedux = {},
}) => {
  const [emisionDate, setDateChange] = useState(State.fechaEmision);
  const [authorizationDate, setAuthDateChange] = useState(
    State.fechaDocSustento
  );
  const [errors, setErrors] = useState(null);
  const [numDoc, setNumDoc] = useState(State.numAuxDocSustento);
  const classesGlobales = useStylesGlobal();
  const classes = useStyles();
  useEffect(() => {
    if (State.fechaEmision) {
      setDateChange(State.fechaEmision);
    } else {
      setDateChange(null);
    }

    if (State.fechaDocSustento) {
      setAuthDateChange(State.fechaDocSustento);
    } else {
      setAuthDateChange(null);
    }
    setNumDoc(State.numAuxDocSustento);
    setErrors(null);
  }, [State.fechaEmision, State.fechaDocSustento, State.numAuxDocSustento]);

  const handleChangeDateEmision = (value) => {
    const date = value;
    const event = { target: { name: "fechaEmision", value: date } };
    onChange(event);
  };

  const handleChangeDateAuth = (value) => {
    const date = value;
    const event = { target: { name: "fechaAutorizacion", value: date } };
    onChange(event);
  };

  const handleChangeNumDoc = (event) => {
    const value = event.target.value;
    setNumDoc(value);
    if (!validarNumDocumentoSRI(value)) {
      setErrors("Ingrese el número completo. Ejemplo 001-001-000000001");
      const event = { target: { name: "numDoc", value: null } };
      onChange(event);
    } else {
      setErrors(null);
      onChange(event);
    }
  };

  const handleStyleNumDoc = () => {
    if (State.numAuxDocSustento || errorsRedux.numeroDocSustento) {
      if (errors || errorsRedux.numeroDocSustento) {
        return "form-control  is-invalid mr-5";
      } else {
        return "form-control  is-valid mr-5";
      }
    } else {
      return "form-control ";
    }
  };

  return (
    <Fragment>
      <Grid container spacing={1} className={classesGlobales.containerForm}>
        <Grid item xs={12} md={12}>
          <label>
            Los campos marcados con ( <font color={palette.error.main}> *</font>{" "}
            ) son obligatorios:
          </label>
        </Grid>

        <Grid item xs={12} md={2}>
          <label>
            Fecha Emisión: <font color={palette.error.main}> *</font>
          </label>
        </Grid>
        <Grid item xs={12} md={4}>
          <DateProvaider>
            <KeyboardDatePicker
              value={emisionDate}
              onChange={handleChangeDateEmision}
              minDateMessage="Solo puede ingresar documentos emitos desde el anterior mes (28 diás calendario) hasta el día de actual."
              format="dd/MM/yyyy"
              minDate={getDateOneMonthAgo()}
              fullWidth
              autoOk
              variant="dialog"
              inputVariant="outlined"
              InputAdornmentProps={{ position: "start" }}
              disableFuture
              cancelLabel="Cancelar"
              okLabel="Seleccionar"
              todayLabel="Hoy"
              invalidDateMessage="Formato inválido."
              className={classes.dataPicker}
              error={errorsRedux.fechaEmision}
            />
            <TextError text={errorsRedux.fechaEmision} />
          </DateProvaider>
        </Grid>

        <Grid item xs={12} md={2}>
          <label>Tipo Doc. Sustento:</label>
        </Grid>

        <Grid item xs={12} md={4}>
          <input
            className="form-control  is-valid  mb-1 mr-2"
            value="01 - FACTURA"
            disabled
          />
        </Grid>
        {type === "NC" ? (
          <>
            <Grid item xs={12} md={2}>
              <label>
                Motivo: <font color={palette.error.main}> *</font>
              </label>
            </Grid>
            <Grid item xs={12} md={4}>
              <input
                className={`${styleInput(
                  errorsRedux.motivo,
                  State.motivo
                )} mb-1 mr-2`}
                name="motivo"
                value={State.motivo}
                onChange={onChange}
                autoComplete="off"
              />
              <TextError text={errorsRedux.motivo} />
            </Grid>
          </>
        ) : null}
        <Grid item xs={12} md={2}>
          <label>
            Fecha Emisión Doc. Sustento:{" "}
            <font color={palette.error.main}> *</font>
          </label>
        </Grid>
        <Grid item xs={12} md={4}>
          <DateProvaider>
            <KeyboardDatePicker
              value={authorizationDate}
              onChange={handleChangeDateAuth}
              //minDateMessage="Solo puede ingresar documentos emitos desde el anterior mes (28 diás calendario) hasta el día de actual."
              format="dd/MM/yyyy"
              //minDate={getDateOneMonthAgo()}
              fullWidth
              autoOk
              variant="dialog"
              inputVariant="outlined"
              InputAdornmentProps={{ position: "start" }}
              disableFuture
              cancelLabel="Cancelar"
              okLabel="Seleccionar"
              todayLabel="Hoy"
              invalidDateMessage="Formato inválido."
              disabled={isRecoveryInvoice}
              className={classes.dataPicker}
              error={errorsRedux.fechaDocSustento}
            />
          </DateProvaider>
          <TextError text={errorsRedux.fechaDocSustento} />
        </Grid>
        <Grid item xs={12} md={2}>
          <label>Rise:</label>
        </Grid>
        <Grid item xs={12} md={4}>
          <input
            className={`${styleInput(errorsRedux.rise, State.rise)} mb-1 mr-2`}
            name="rise"
            value={State.rise}
            onChange={onChange}
          />
          <TextError text={errorsRedux.rise} />
        </Grid>
        <Grid item xs={12} md={2}>
          <label>
            Número Doc. Sustento: <font color={palette.error.main}> *</font>
          </label>
        </Grid>
        <Grid item xs={12} md={4}>
          <InputMask
            className={handleStyleNumDoc()}
            value={numDoc}
            name="numDoc"
            onChange={handleChangeNumDoc}
            mask="999-999-999999999"
            disabled={isRecoveryInvoice}
          />
          <TextError text={errors} />
          <TextError text={errorsRedux.numeroDocSustento} />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ReferenceDocument;
