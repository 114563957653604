import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  makeStyles,
  Backdrop,
  CircularProgress,
  FormControl,
  FormControlLabel,
  NativeSelect,
  withStyles,
  InputBase,
  TextField,
  Checkbox,
  Divider,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import {
  palette,
  useStylesGlobal,
  styleInput,
} from "configurations/styles/theme";
import { Alert, Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useStylesApp } from "hooks/useStyles";
import { valorIva } from "../Forms/typeVoucher";
import { valorICE } from "../Forms/typeVoucher";
import EditTable from "widgets/Tables/EditTable";
import { getRUCEmpresa } from "redux/actions/userActions";
import clientBackend from "configurations/axios";
import bigDecimal from "js-big-decimal";
import uuid from "react-uuid";
import {
  validationVoucher,
  calculateDiscount,
} from "../Vouchers/invoices/validations";
import AddProductsBtn from "views/administración/productsServices/Buttons/AddProduct";
import checkInputs from "utils/checkInputs";
import { numDecimalesDescuento, numDecimales } from "configurations/constant";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(3),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  divForm: {
    marginBottom: "1%",
  },
  divInput: {
    marginBottom: "1%",
    paddingRight: "1%",
    paddingLeft: "0.5%",
  },
  divButton: {
    paddingLeft: "3%",
    paddingRight: "3%",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    marginTop: "1%",
    marginBottom: "0.5%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
}));
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(0),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const AddDetailReimbursement = ({
  productsDetalle,
  setProductsDetalle,
  viewDetalleReimbursement,
  setViewDetalleReimbursement,
  detalles,
  setDetalles,
  reimbursementCounter,
  totalPayment,
}) => {
  const classes = useStyles();
  const classesGlobales = useStylesGlobal();
  const classesApp = useStylesApp();
  const [dataAditional, setDataAditional] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const userToken = useSelector((state) => state.user.tk);
  const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
  const { enqueueSnackbar } = useSnackbar();
  const [product, setProduct] = useState({
    descuentoProducto: "",
    cantidadProducto: "1",
    // cantidadProducto: reimbursementCounter.toString(),
    isDescuentoPorcentaje: "false",
    valorDescuento: "",
  });
  const [iva, setIva] = useState("6");
  const [ice, setIce] = useState();
  const [options, setOptions] = useState([]);
  const [foundProduct, setFoundProduct] = useState({
    codigoAuxiliar: "",
    codigoPrincipal: "",
    descripcion: "",
    estado: "",
    idProducto: "",
    precioUnitario: totalPayment,
  });
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const loading = openAutocomplete && options.length === 0;
  const [empty, setEmty] = useState(true);
  const [emptyCodigo, setEmtyCodigo] = useState(true);
  const [emptyPrecio, setEmtyPrecio] = useState(true);
  const [isAditional, setIsAdditional] = useState(false);
  const [errors, setErrors] = useState({});
  const [existProduct, setExistProduct] = useState(false);
  const [isDescuentoPorcentaje, setIsDescuentoPorcentaje] = useState("false");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    clearData();
    setOpen(false);
  };

  const validateProductData = (data) => {
    let newErros = validationVoucher.onSubmit({
      codigoPrincipal: data.codigoPrincipal,
      cantidadProducto: data.cantidadProducto,
      precioUnitario: data.precioUnitario,
      descripcion: data.descripcion,
    });
    if (Object.entries(newErros).length > 0) {
      setErrors(newErros);
      return false;
    } else {
      return true;
    }
  };

  const handleAddProduct = () => {
    if (
      validateProductData({
        codigoPrincipal: foundProduct.codigoPrincipal,
        cantidadProducto: product.cantidadProducto,
        precioUnitario: foundProduct.precioUnitario,
        descripcion: foundProduct.descripcion,
      })
    ) {
      if (
        foundProduct &&
        typeof foundProduct !== "undefined" &&
        typeof foundProduct !== null &&
        foundProduct.codigoPrincipal.trim() !== ""
      ) {
        if (Object.entries(foundProduct).length > 0) {
          setEmtyCodigo(true);
          if (Number(product.cantidadProducto) > 0) {
            setEmty(true);
            if (
              foundProduct.precioUnitario.toString() !== "" &&
              Number(foundProduct.precioUnitario) >= 0
            ) {
              let valorDiscount = calculateDiscount({
                isDescuentoPorcentaje: product.isDescuentoPorcentaje,
                cantidadProducto: product.cantidadProducto,
                precioUnitario: foundProduct.precioUnitario,
                descuentoProducto: product.descuentoProducto,
              });
              valorDiscount = bigDecimal.round(
                valorDiscount,
                2,
                bigDecimal.RoundingModes.HALF_UP
              );
              if (validateDiscount(valorDiscount)) {
                setEmtyPrecio(true);
                let uuId = uuid();

                let totProducto = new bigDecimal(
                  product.cantidadProducto.toString()
                );
                let priceUnit = new bigDecimal(
                  foundProduct.precioUnitario.toString()
                );
                let valueDiscount = new bigDecimal(valorDiscount.toString());
                totProducto = totProducto
                  .multiply(priceUnit)
                  .subtract(valueDiscount);

                let iceProducto = valorICE.reduce(function (iceProducto, vIce) {
                  if (vIce.CODIGO === ice) {
                    return (
                      Number(iceProducto) +
                      (Number(totProducto.getValue()) * Number(vIce.VALOR)) /
                        100
                    );
                  } else {
                    return Number(iceProducto);
                  }
                }, 0);

                setProductsDetalle([
                  ...productsDetalle,
                  {
                    ...foundProduct,
                    id: uuId,
                    descuentoProducto: product.descuentoProducto
                      ? Number(
                          bigDecimal.round(
                            product.descuentoProducto,
                            2,
                            bigDecimal.RoundingModes.HALF_UP
                          )
                        )
                      : 0,
                    cantidadProducto: product.cantidadProducto
                      ? Number(
                          bigDecimal.round(
                            product.cantidadProducto,
                            checkInputs.countDecimals(
                              product.cantidadProducto.toString()
                            ) <= 2
                              ? 2
                              : checkInputs.countDecimals(
                                  product.cantidadProducto.toString()
                                ),
                            bigDecimal.RoundingModes.HALF_UP
                          )
                        )
                      : 0,
                    impuestoIva: valorIva.filter((aiva) => aiva.CODIGO === iva),
                    impuestoIce: valorICE.filter((vice) => vice.CODIGO === ice),
                    totalIceProducto: Number(
                      bigDecimal.round(
                        iceProducto,
                        2,
                        bigDecimal.RoundingModes.HALF_UP
                      )
                    ),
                    total:
                      //  totalPayment,
                      product.cantidadProducto ? totProducto.getValue() : 0,
                    precioUnitario:
                      // totalPayment,
                      Number(
                        bigDecimal.round(
                          foundProduct.precioUnitario,
                          checkInputs.countDecimals(
                            foundProduct.precioUnitario.toString()
                          ) > 2
                            ? checkInputs.countDecimals(
                                foundProduct.precioUnitario.toString()
                              )
                            : 2,
                          bigDecimal.RoundingModes.HALF_UP
                        )
                      ),
                    informacionAdicional:
                      dataAditional.length > 0 ? dataAditional : [],
                    isDescuentoPorcentaje: product.isDescuentoPorcentaje,
                  },
                ]);

                //agrego los detalles de la factura
                setDetalles([
                  ...detalles,
                  {
                    id: uuId,
                    cantidad: product.cantidadProducto
                      ? Number(
                          bigDecimal.round(
                            product.cantidadProducto,
                            checkInputs.countDecimals(
                              product.cantidadProducto.toString()
                            ),
                            bigDecimal.RoundingModes.HALF_UP
                          )
                        )
                      : 0,
                    codigoPorcentajeICE: ice ? ice : "",
                    codigoPorcentajeIVA: iva,
                    descuento: product.descuentoProducto
                      ? Number(
                          bigDecimal.round(
                            product.descuentoProducto,
                            2,
                            bigDecimal.RoundingModes.HALF_UP
                          )
                        )
                      : 0,
                    idProducto: foundProduct.idProducto,
                    informacionAdicional:
                      dataAditional.length > 0 ? dataAditional : [],
                    precioProducto: Number(
                      bigDecimal.round(
                        foundProduct.precioUnitario,
                        checkInputs.countDecimals(
                          foundProduct.precioUnitario.toString()
                        ),
                        bigDecimal.RoundingModes.HALF_UP
                      )
                    ),
                    isDescuentoPorcentaje: product.isDescuentoPorcentaje,
                  },
                ]);

                if (viewDetalleReimbursement === 1) {
                  setViewDetalleReimbursement(2);
                } else if (viewDetalleReimbursement === 2) {
                  setViewDetalleReimbursement(1);
                }
                setProduct({
                  descuentoProducto: "",
                  cantidadProducto: "1",
                  isDescuentoPorcentaje: "false",
                });

                setIce("");
                //setIva("2");
                setFoundProduct({});
                setDataAditional([]);
                handleClose();
                enqueueSnackbar(`Detalle agregado al comprobante`, {
                  variant: "success",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  autoHideDuration: 4000,
                });
              }
            } else {
              setEmtyPrecio(false);
            }
          } else {
            setEmty(false);
          }
        } else {
          setEmtyCodigo(false);
        }
      } else {
        setEmtyCodigo(false);
      }
    }
  };

  const handleChangeCheckDesc = (event) => {
    setIsDescuentoPorcentaje(event.target.value);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { error, value, keyWordError } = validationVoucher.onChange(
      event.target.name,
      event.target.value
    );

    switch (event.target.name) {
      case "isDescuentoPorcentaje":
        setProduct({
          ...product,
          [event.target.name]: event.target.value,
        });
        break;
      case "descuentoProducto":
        if (
          keyWordError !== "isNotDecimal" &&
          keyWordError !== "isLong" &&
          checkInputs.countDecimals(event.target.value.toString().trim()) <=
            numDecimalesDescuento
        ) {
          setProduct({
            ...product,
            [event.target.name]: event.target.value,
          });
        }
        setErrors({ ...errors, [event.target.name]: error });
      default:
        break;
    }
  };

  const handleChangeCheck = (event) => {
    setIsAdditional(event.target.checked);
  };

  function closeAlert() {
    setMsg({ show: false });
  }

  //método para la búsqueda
  const searchProducts = async (params) => {
    await clientBackend
      .get(
        `/admin/productos/buscar/activos/${
          userToken ? getRUCEmpresa(userToken) : ""
        }?parametroBusqueda=${params}`
      )
      .then((response) => {
        if (response.status === 200) {
          if (Array.isArray(response.data)) {
            if (response.data.length > 0) {
              setOptions(response.data);

              setExistProduct(false);
            } else {
              setOptions([
                {
                  codigoPrincipal: "",
                  descripcion:
                    "No se encontró datos con el argumento ingresado",
                },
              ]);
              setExistProduct(true);
            }
          }
          setOpen(true);
        }
      })
      .catch((exception) => {
        if (exception.response) {
          setOptions([
            {
              codigoPrincipal: "",
              descripcion: "No se encontró datos con el argumento ingresado",
            },
          ]);
          setExistProduct(true);
        } else {
          setOptions([
            {
              codigoPrincipal: "",
              descripcion: "Error en la red al consultar el producto.",
            },
          ]);
        }
      });
  };

  const validateDiscount = (valorDiscount) => {
    if (product.descuentoProducto.trim() !== "" && Number(valorDiscount) > 0) {
      if (validateDecimal(product.descuentoProducto.toString().trim())) {
        let totalPrice =
          Number(foundProduct.precioUnitario) *
          Number(product.cantidadProducto);
        totalPrice = bigDecimal.round(
          totalPrice,
          2,
          bigDecimal.RoundingModes.HALF_UP
        );
        if (Number(valorDiscount) <= Number(totalPrice)) {
          return true;
        } else {
          enqueueSnackbar(`El descuento debe ser menor a ${totalPrice}`, {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 4000,
          });
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const validateDecimal = (numberValor) => {
    let splitList = numberValor.split(".");
    if (splitList.length > 2) {
      enqueueSnackbar(
        `El dato ingresado es erróneo utilice punto (.) para separar decimales`,
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 4000,
        }
      );
      return false;
    } else if (splitList.length > 1) {
      if (splitList[1].length > 2) {
        enqueueSnackbar(
          `Los datos numéricos debe tener máximo 2 decimales Ej, 12.43`,
          {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 4000,
          }
        );
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const clearData = () => {
    setEmtyCodigo(true);
    setEmty(true);
    setEmtyPrecio(true);
    setProduct({
      descuentoProducto: "",
      cantidadProducto: "1",
      isDescuentoPorcentaje: "false",
    });
    setIce("");
    setFoundProduct({
      codigoAuxiliar: "",
      codigoPrincipal: "",
      descripcion: "",
      estado: "",
      idProducto: "",
      precioUnitario: "",
    });
    setDataAditional([]);
    setOptions([]);
    setIsAdditional(false);
    setErrors({});
  };

  const updateView = () => {};

  useEffect(() => {
    if (open) {
      // setProduct({
      //   ...product,
      //   cantidadProducto: reimbursementCounter.toString(),
      // });
      setFoundProduct({
        ...foundProduct,
        precioUnitario: totalPayment,
      });
    }
  }, [open]);

  const handleDebug = () => {
    console.log("Product");
    console.log(product);
    console.log("Found Product");
    console.log(foundProduct);
    console.log("Original Detail Product");
    console.log(productsDetalle);
  };

  return (
    <Fragment>
      <Grid container justifyContent="center" className={classes.divButton}>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Agregar Detalle
        </Button>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="max-width-dialog-title">Nuevo detalle</DialogTitle>
        <DialogContent>
          {/* <Fragment>
            <Button onClick={handleDebug}>ANTI BUGS</Button>
          </Fragment> */}
          <form>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={12} md={12}>
                    <label>
                      Los campos marcados con ({" "}
                      <font color={palette.error.main}> *</font> ) son
                      obligatorios:
                    </label>
                  </Grid>
                  {msgError.show ? (
                    <Alert
                      tipo={msgError.type}
                      mensaje={msgError.msg}
                      mesajeSubrayado={null}
                      titulo={null}
                      mostrar={msgError.show}
                      cerrarAlerta={closeAlert}
                    />
                  ) : null}
                  {existProduct ? (
                    <Fragment>
                      <Grid item xs={12} md={12} className={classes.divForm}>
                        <Divider />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        align="center"
                        className={classes.divForm}
                      >
                        <AddProductsBtn updateView={updateView} />
                      </Grid>
                      <Grid item xs={12} md={12} className={classes.divForm}>
                        <Divider />
                      </Grid>
                    </Fragment>
                  ) : null}

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Código Principal:{" "}
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>

                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <Autocomplete
                      id="codPrincipalProducto"
                      onChange={(e, value) => {
                        if (value !== null) {
                          // console.log(value);
                          // setFoundProduct(value);
                          setFoundProduct({
                            codigoAuxiliar: value.codigoAuxiliar,
                            codigoPrincipal: value.codigoPrincipal,
                            descripcion: value.descripcion,
                            // estado: true,
                            // idProducto: 133,
                            estado: value.estado,
                            idProducto: value.idProducto,
                            precioUnitario: totalPayment,
                          });
                          setErrors({});
                        } else {
                          setFoundProduct({
                            codigoAuxiliar: "",
                            codigoPrincipal: "",
                            descripcion: "",
                            estado: "",
                            idProducto: "",
                            precioUnitario: "",
                          });
                        }
                        setEmtyCodigo(true);
                      }}
                      open={openAutocomplete}
                      onOpen={() => {
                        setOpenAutocomplete(true);
                      }}
                      onClose={() => {
                        setOpenAutocomplete(false);
                      }}
                      getOptionDisabled={(option) =>
                        option.descripcion.includes(
                          "No se encontró datos con el argumento ingresado"
                        ) ||
                        option.descripcion.includes(
                          "Error en la red al consultar el producto."
                        )
                      }
                      getOptionSelected={(option, value) =>
                        option.codigoPrincipal.trim() ===
                        value.codigoPrincipal.trim()
                      }
                      getOptionLabel={(option) =>
                        `${option.codigoPrincipal}-${option.descripcion}`
                      }
                      options={options}
                      noOptionsText={
                        "No se encontró datos con el argumento ingresado"
                      }
                      loading={options.length === 0}
                      loadingText="Ingrese al menos 1 caracter"
                      renderInput={(params) => (
                        <TextField
                          className={styleInput(
                            errors.codigoPrincipal,
                            foundProduct.codigoPrincipal
                          )}
                          onChange={(event) => {
                            if (event.target.value.trim().length >= 1) {
                              searchProducts(event.target.value.trim());
                            }
                          }}
                          {...params}
                          label=""
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                    <small style={{ color: "#818181" }}>
                      También puede buscar por la descripción del producto.
                    </small>
                    {errors.codigoPrincipal ? (
                      <p className={classesApp.errorLetters}>
                        {errors.codigoPrincipal}
                      </p>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Código Auxiliar: <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <input
                      type="text"
                      placeholder="Ej. 0002"
                      disabled
                      name="codigoAuxiliarProducto"
                      value={foundProduct ? foundProduct.codigoAuxiliar : ""}
                      autoComplete="off"
                      className={
                        foundProduct
                          ? foundProduct.codigoAuxiliar
                            ? "form-control  is-valid"
                            : "form-control "
                          : "form-control "
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Cantidad de comprobantes reembolso:{" "}
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <input
                      className={styleInput(
                        errors.cantidadProducto,
                        product.cantidadProducto
                      )}
                      type="text"
                      name="cantidadProducto"
                      disabled
                      value={reimbursementCounter}
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Descripción: <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <input
                      className={
                        foundProduct
                          ? foundProduct.descripcion
                            ? "form-control  is-valid"
                            : "form-control "
                          : "form-control "
                      }
                      type="text"
                      disabled
                      placeholder="Ej. Ordenador"
                      name="descripcionProducto"
                      value={foundProduct ? foundProduct.descripcion : ""}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Sub Total: <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <input
                      className={styleInput(
                        errors.precioUnitario,
                        foundProduct.precioUnitario
                      )}
                      type="text"
                      disabled
                      placeholder="Ej. 12.00"
                      name="precioUnitario"
                      value={totalPayment}
                      autoComplete="off"
                    />
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>Descuento: </label>
                  </Grid>
                  <Grid
                    fullWidth
                    item
                    xs={6}
                    md={4}
                    className={classes.divInput}
                  >
                    <input
                      className={styleInput(
                        errors.descuentoProducto,
                        product.descuentoProducto
                      )}
                      type="text"
                      placeholder="Ej. 1"
                      name="descuentoProducto"
                      value={product.descuentoProducto}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    {errors.descuentoProducto ? (
                      <p className={classesApp.errorLetters}>
                        {errors.descuentoProducto}
                      </p>
                    ) : null}
                  </Grid>

                  {
                    <Grid xs={6} md={4}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="isPorcentaje"
                          name="isDescuentoPorcentaje"
                          value={product.isDescuentoPorcentaje}
                          className={classes.radio}
                          required
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value={"false"}
                            control={
                              <Radio
                                color="primary"
                                className={classes.radioButton}
                              />
                            }
                            label="Valor"
                            labelPlacement="start"
                            className={classes.radioLable}
                          />
                          <FormControlLabel
                            value={"true"}
                            control={
                              <Radio
                                color="primary"
                                className={classes.radioButton}
                              />
                            }
                            label="Porcentaje"
                            labelPlacement="start"
                            className={classes.radioLable}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  }

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      IVA: <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <FormControl className={classesGlobales.select}>
                      <NativeSelect
                        value={iva}
                        onChange={(event) => setIva(event.target.value)}
                        input={<BootstrapInput />}
                      >
                        <option aria-label="None" disabled>
                          --Seleccione--
                        </option>
                        {valorIva.map((iva) => (
                          <option key={iva.CODIGO} value={iva.CODIGO}>
                            {iva.NOMBRE}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>ICE:</label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <FormControl className={classes.margin}>
                      <NativeSelect
                        value={ice}
                        onChange={(event) => setIce(event.target.value)}
                        input={<BootstrapInput />}
                      >
                        <option aria-label="None" value="">
                          --Ninguno--
                        </option>
                        {valorICE.map((ice) => (
                          <option key={ice.CODIGO} value={ice.CODIGO}>
                            {`${ice.CODIGO} - ${ice.NOMBRE}`}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  item
                  xs={12}
                  md={12}
                  justifyContent="center"
                  alignItems="center"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isAditional}
                        onChange={handleChangeCheck}
                        name="isAditional"
                        color="primary"
                      />
                    }
                    label="Desea agregar información adicional al detalle?"
                  />
                </Grid>
                {isAditional ? (
                  <Grid
                    container
                    xs={12}
                    md={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <EditTable
                      dataAditional={dataAditional}
                      setDataAditional={setDataAditional}
                    />
                  </Grid>
                ) : null}
              </Grid>

              <Grid item xs={12} md={12}>
                <Divider />
              </Grid>

              <Grid item xs={12} md={12} className={classes.divButton}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddProduct}
                  className={classesGlobales.button}
                >
                  Agregar
                </Button>

                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  className={classesGlobales.button}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
};

export default AddDetailReimbursement;
