import React, { useEffect, useState } from "react";
import { makeStyles, Grid } from '@material-ui/core';
import {reportTotalsDebitNote} from "utils/checkTaxes"
import {useSelector} from "react-redux";
import {roundTwoDecimal } from "utils/parseInput";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        marginTop: "1%"
    },
    container: {
        padding: "2%",
        borderRadius: "8px",
        borderWidth: "1px",
        borderStyle: "solid"
    },
    totalLabel: {
        padding: "2%",
        borderRadius: "8px",
        borderWidth: "1px",
        borderStyle: "solid",
        marginBottom: "1%"
    }
});

const Totals = ({ listOfReasons = [], update }) => {
    const classes = useStyles();
    const codeIva = useSelector(state => state.debitNote.codigoImpuestoIva);
    const codeIce = useSelector(state => state.debitNote.codigoImpuestoIce);
    const [values, setValues] = useState({
        subtotal12: 0,
        subtotal15: 0,
        subtotal5:0,
        subtotal0: 0,
        subtotalNoIva: 0,
        SubtotalSinImpuestos: 0,
        IVA12: 0,
        IVA5: 0,
        IVA15: 0,
        ICE: 0,
        total: 0
    });

    useEffect(() => {
        if (!update) {
            setValues(reportTotalsDebitNote(listOfReasons, codeIva, codeIce));
        }
    }, [update, listOfReasons, codeIva, codeIce]);

    return (  
          <Grid spacing={2} className={classes.root} container direction="row" justify="center"
            alignItems="flex-start">
            <Grid item xs={5}>
                <Grid container className={classes.container} spacing={1}>
                    <Grid item xs={3}>
                        <label>Subtotal 12%:</label>
                    </Grid>
                    <Grid item xs={7}>
                        <input className={(values.subtotal12 > 0) ? "form-control  is-valid  text-right" : "form-control  text-right"}
                            value={roundTwoDecimal(values.subtotal12)}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <label>Subtotal 15%:</label>
                    </Grid>
                    <Grid item xs={7}>
                        <input className={(values.subtotal15 > 0) ? "form-control  is-valid  text-right" : "form-control  text-right"}
                            value={roundTwoDecimal(values.subtotal15)} disabled
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <label>Subtotal 0%:</label>
                    </Grid>
                    <Grid item xs={7}>
                        <input className={(values.subtotal0 > 0) ? "form-control  is-valid  text-right" : "form-control  text-right"}
                            value={roundTwoDecimal(values.subtotal0)} disabled
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <label>Subtotal No IVA:</label>
                    </Grid>
                    <Grid item xs={7}>
                        <input className={(values.subtotalNoIva > 0) ? "form-control  is-valid  text-right" : "form-control  text-right"}
                            value={roundTwoDecimal(values.subtotalNoIva)} disabled
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={5}>
                <Grid container className={classes.container} spacing={1}>
                    <Grid item xs={4}>
                        <label>Subtotal Sin Impuesto:</label>
                    </Grid>
                    <Grid item xs={7}>
                        <input className={(values.SubtotalSinImpuestos > 0) ? "form-control  is-valid  text-right" : "form-control  text-right"}
                            value={roundTwoDecimal(values.SubtotalSinImpuestos)} disabled
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <label>ICE</label>
                    </Grid>
                    <Grid item xs={7}>
                        <input className={(values.ICE > 0) ? "form-control  is-valid  text-right" : "form-control  text-right"}
                            value={roundTwoDecimal(values.ICE)} disabled
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <label>IVA (5%):</label>
                    </Grid>
                    <Grid item xs={7} align="right">
                        <input className={(values.IVA5 > 0) ? "form-control  is-valid text-right" : "form-control  text-right"}
                            value={roundTwoDecimal(values.IVA5)} disabled
                            
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <label>IVA (12%):</label>
                    </Grid>
                    <Grid item xs={7}>
                        <input className={(values.IVA12 > 0) ? "form-control  is-valid  text-right" : "form-control  text-right"}
                            value={roundTwoDecimal(values.IVA12)} disabled
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <label>IVA (15%):</label>
                    </Grid>
                    <Grid item xs={7} align="right">
                        <input className={(values.IVA15 > 0) ? "form-control  is-valid text-right" : "form-control  text-right"}
                            value={roundTwoDecimal(values.IVA15)} disabled
                            
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={10} className={classes.totalLabel} align="center">
                <strong>Importe Total: {roundTwoDecimal(values.total)}</strong>
            </Grid>
        </Grid>
    );
}
 
export default Totals;