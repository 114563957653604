import { CircularProgress, Divider, Grid, makeStyles } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRUCEmpresa } from 'redux/actions/userActions';
import TablePaginatedPay from 'widgets/Tables/TablePaginatedPay';
import TableSimple from 'widgets/Tables/TableSimple';
import { CardGeneric } from '../components/CardGeneric';
import { API_GET_ALL_COMPANY, API_GET_SEARCH_COMPANY } from './apis';
import AddCompanyBtn from './Buttons/AddCompanyBtn';
import tableStyle from "configurations/styles/table";
import GenerateReportsBtn from './Buttons/GenerateReportsBtn';
import GenerateReportIssuedBtn from './Buttons/GenerateReportIssuedBtn';
import SearchCompany from './Forms/SearchCompany';
import clientBackend, { clientPay } from 'configurations/axios';
import EditCompanyBtn from './Buttons/EditCompanyBtn';
import UpVouchersIssuedBtn from './Buttons/UpVouchersIssuedBtn';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: "35px",
        paddingBottom: "7%",
        flexGrow: 1,
        paddingLeft: "3%",
        paddingRight: "3%",
    },
    headerBottons: {
        padding: "0.5%",
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        height: "300px"
    }
}));


const Company = () => {

    const tokenUser = useSelector((state) => state.user.tk);
    const classesView = useStyles();
    const [update, setUpdate] = useState(0);
    const [paramSearch, setParamSearch] = useState("");
    const [foundCompany, setFoundCompany] = useState([]);

    const columns = [
        { title: "RUC", field: "idempresacontratante", cellStyle: tableStyle.cellTable, width: "8%", },
        { title: "Razón Social/Nombres", field: "razonSocialempresa", cellStyle: tableStyle.cellLeft, },
        { title: "Correo", field: "correo", cellStyle: tableStyle.cellLeft, },
        { title: "Fecha de Registro", field: "fechaRegistro", cellStyle: tableStyle.cellTable,  render: rowData => rowData.fechaRegistro.substring(0, 10) },
        { title: "Rep. Comp. Emitidos", cellStyle: tableStyle.cellTable, render: rowData => <GenerateReportIssuedBtn data={rowData} /> },
        { title: "Rep. Comp. Recibidos", cellStyle: tableStyle.cellTable, render: rowData => <GenerateReportsBtn data={rowData} /> },
        { title: "Subir Comp. Recibidos", cellStyle: tableStyle.cellTable, render: rowData => <UpVouchersIssuedBtn data={rowData} /> },
        { title: "Editar", cellStyle: tableStyle.cellTable, render: rowData => <EditCompanyBtn data={rowData} reload={reload} /> },
    ];

    const requestConfig = {
        uri: API_GET_ALL_COMPANY + (tokenUser ? getRUCEmpresa(tokenUser) : "0"),
        metodo: "get",
        body: null,
        page: 0,
        elementos: 15,
        sort: "fechaRegistro,desc",
    };

    const reload = () => {
        setUpdate(1);
    };

    const getParam = (data) => {
        setParamSearch(data);
        if (data.length > 0) {
            searchCompany(data);
        } else {
            reload();
        }
    };

    const searchCompany = async (param) => {
        setUpdate(3);
        await clientPay
            .get(
                API_GET_SEARCH_COMPANY +
                getRUCEmpresa(tokenUser) +
                "?parametroBusqueda=" +
                param
            )
            .then((response) => {
                if (response.status === 200) {
                    setFoundCompany(response.data);
                    setUpdate(2);
                }
            })
            .catch((exception) => {
                console.error({ exception });
            });
    };

    useEffect(() => {
        if (update === 1) {
            setUpdate(0);
        }
    }, [update]);

    const renderTable = () => {
        switch (update) {
            case 0:
                return (
                    <TablePaginatedPay
                        title=""
                        columns={columns}
                        requestConfig={requestConfig}
                        seleccion={false}
                    />
                );

            case 1:
            case 3:
                return (
                    <div className={classesView.loader}>
                        <CircularProgress />
                    </div>
                );
            case 2:
                return (
                    <TableSimple
                        columns={columns}
                        data={foundCompany}
                        title=""
                        numberRowsPerPage={15}
                    />
                );
            default:
                break;
        }
    };

    return (
        <div className={classesView.root}>
            <CardGeneric>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        {<AddCompanyBtn updateView={reload}
                        />}
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={9} className={classesView.headerBottons}>
                        {<SearchCompany backupParam={paramSearch} sendData={getParam} />}
                    </Grid>
                </Grid>
            </CardGeneric>
            {renderTable()}
        </div>
    );
}

export default Company;