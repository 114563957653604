import React, { Fragment, useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, Grid, makeStyles, Backdrop, CircularProgress, FormControl, NativeSelect, withStyles, InputBase, TextField, Divider, FormControlLabel, Checkbox, RadioGroup, Radio } from '@material-ui/core';
import { palette, useStylesGlobal, styleInput } from 'configurations/styles/theme';
import { Alert, Autocomplete } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useStylesApp } from 'hooks/useStyles';
import EditTable from 'widgets/Tables/EditTable';
import { getRUCEmpresa } from 'redux/actions/userActions';
import clientBackend from 'configurations/axios';
import bigDecimal from 'js-big-decimal';
import { valorICE, valorIva } from 'views/facturacion/Forms/typeVoucher';
import uuid from 'react-uuid';
import { validationVoucher, calculateDiscount } from '../../invoices/validations';
import AddProductsBtn from 'views/administración/productsServices/Buttons/AddProduct'
import checkInputs from 'utils/checkInputs';
import { numDecimales, numDecimalesDescuento } from 'configurations/constant';


const useStyles = makeStyles((theme) => ({
    radio: {
        display: '-webkit-box',
        marginTop: '0px'
    },
    radioLable: {
        color: palette.primary.main,
        display: 'flex'
    },
    radioButton: {
        marginTop: '0px',
        paddingTop: '0px'
    },
    divForm: {
        marginBottom: '1%',
    },
    divInput: {
        marginBottom: '1%',
        paddingRight: '1%',
        paddingLeft: '0.5%'
    },
    divButton: {
        paddingLeft: '3%',
        paddingRight: '3%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '1%',
        marginBottom: '0.5%',
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
}));

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(0),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const AddDetail = ({ productsDetalle, setProductsDetalle, viewDetalle, setViewDetalle, detalles, setDetalles }) => {
    const classes = useStyles();
    const classesApp = useStylesApp();
    const classesGlobales = useStylesGlobal();
    const [dataAditional, setDataAditional] = useState([]);
    const [openLoader, setOpenLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const userToken = useSelector(state => state.user.tk);
    const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
    const { enqueueSnackbar } = useSnackbar();
    const [product, setProduct] = useState(
        {
            descuentoProducto: '',
            cantidadProducto: '1',
            isDescuentoPorcentaje: 'false',
        }
    );
    const [facturaComercialCheck, setFacturaComercialCheck] = useState("C");
    const [iva, setIva] = useState("2");
    const [ice, setIce] = useState();
    //state para actualizar la vista
    const [update, setUpdate] = useState(1);
    const [options, setOptions] = React.useState([]);
    const [foundProduct, setFoundProduct] = useState({
        codigoAuxiliar: '',
        codigoPrincipal: '',
        descripcion: '',
        estado: '',
        idProducto: '',
        precioUnitario: '',
    });
    const loaded = React.useRef(false);
    const [foundProducts, setFoundProducts] = useState([]);
    const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
    const loading = openAutocomplete && options.length === 0;
    const [empty, setEmty] = useState(true);
    const [emptyCodigo, setEmtyCodigo] = useState(true);
    const [emptyPrecio, setEmtyPrecio] = useState(true);
    const [isAditional, setIsAdditional] = useState(false);
    const [errors, setErrors] = useState({});
    const [existProduct, setExistProduct] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        //setUser({ idEmpresa: getRUCEmpresa(userToken) });
    };

    const handleClose = () => {
        setOpen(false);
        clearData();
    };

    const validateProductData = (data) => {
        let newErros = validationVoucher.onSubmit(
            {
                codigoPrincipal: data.codigoPrincipal,
                cantidadProducto: data.cantidadProducto,
                precioUnitario: data.precioUnitario,
                descripcion: data.descripcion,
            }
        );
        if (Object.entries(newErros).length > 0) {
            setErrors(newErros)
            return false;
        } else {
            return true;
        }
    }

    const handleAddProduct = () => {

        if (validateProductData({
            codigoPrincipal: foundProduct.codigoPrincipal,
            cantidadProducto: product.cantidadProducto,
            precioUnitario: foundProduct.precioUnitario,
            descripcion: foundProduct.descripcion,
        })) {
            if (foundProduct && typeof foundProduct !== 'undefined' && typeof foundProduct !== null) {
                if (Object.entries(foundProduct).length > 0) {
                    setEmtyCodigo(true);
                    if (Number(product.cantidadProducto) > 0) {
                        setEmty(true);
                        if (foundProduct.precioUnitario.toString() !== '' && Number(foundProduct.precioUnitario) >= 0) {
                            //if (validateDecimal(foundProduct.precioUnitario.toString())) {
                            let valorDiscount = calculateDiscount({
                                isDescuentoPorcentaje: product.isDescuentoPorcentaje,
                                cantidadProducto: product.cantidadProducto,
                                precioUnitario: foundProduct.precioUnitario,
                                descuentoProducto: product.descuentoProducto,
                            });
                            valorDiscount = bigDecimal.round(valorDiscount, 2, bigDecimal.RoundingModes.HALF_UP)
                            if (validateDiscount(valorDiscount)) {
                                setEmtyPrecio(true);
                                let uuId = uuid();
                                let totProducto = Number(bigDecimal.multiply(product.cantidadProducto.toString(), foundProduct.precioUnitario.toString()) - calculateDiscount({
                                    isDescuentoPorcentaje: product.isDescuentoPorcentaje,
                                    cantidadProducto: product.cantidadProducto,
                                    precioUnitario: foundProduct.precioUnitario,
                                    descuentoProducto: product.descuentoProducto,
                                }));

                                let iceProducto = valorICE.reduce(function (iceProducto, vIce) {
                                    if (vIce.CODIGO === ice) {
                                        return Number(iceProducto) + ((Number(totProducto) * Number(vIce.VALOR)) / 100);
                                    } else {
                                        return Number(iceProducto);
                                    }
                                }, 0);
                                setProductsDetalle([...productsDetalle, {
                                    ...foundProduct,
                                    id: uuId,
                                    descuentoProducto: product.descuentoProducto ? Number(bigDecimal.round(product.descuentoProducto, 2, bigDecimal.RoundingModes.HALF_UP)) : 0.00,
                                    cantidadProducto: product.cantidadProducto ? Number(bigDecimal.round(product.cantidadProducto, checkInputs.countDecimals(product.cantidadProducto.toString()) <= 2 ? 2 : checkInputs.countDecimals(product.cantidadProducto.toString()), bigDecimal.RoundingModes.HALF_UP)) : 0.00,
                                    impuestoIva: valorIva.filter(aiva => aiva.CODIGO === iva),
                                    impuestoIce: valorICE.filter(vice => vice.CODIGO === ice),
                                    totalIceProducto: Number(bigDecimal.round(iceProducto, checkInputs.countDecimals(iceProducto.toString()), bigDecimal.RoundingModes.HALF_UP)),
                                    /*total: product.cantidadProducto ? Number(bigDecimal.round(totProducto, checkInputs.countDecimals(totProducto.toString()) <= 2 ? 2 : checkInputs.countDecimals(totProducto.toString()), bigDecimal.RoundingModes.HALF_UP)) : 0.00,
                                    */
                                    total: product.cantidadProducto ? Number(totProducto) : 0.00,
                                    precioUnitario: Number(
                                        bigDecimal.round(
                                            foundProduct.precioUnitario,
                                            checkInputs.countDecimals(foundProduct.precioUnitario.toString()) > 2 ? checkInputs.countDecimals(foundProduct.precioUnitario.toString()) : 2,
                                            bigDecimal.RoundingModes.HALF_UP
                                        )
                                    ),

                                    informacionAdicional: dataAditional.length > 0 ? dataAditional : [],
                                    isDescuentoPorcentaje: product.isDescuentoPorcentaje,
                                }]);

                                //agrego los detalles de la factura
                                setDetalles([...detalles, {
                                    id: uuId,
                                    cantidad: product.cantidadProducto ? Number(bigDecimal.round(product.cantidadProducto, checkInputs.countDecimals(product.cantidadProducto.toString()), bigDecimal.RoundingModes.HALF_UP)) : 0.00,
                                    codigoPorcentajeICE: ice ? ice : '',
                                    codigoPorcentajeIVA: iva,
                                    descuento: product.descuentoProducto ? Number(bigDecimal.round(product.descuentoProducto, 2, bigDecimal.RoundingModes.HALF_UP)) : 0.00,
                                    idProducto: foundProduct.idProducto,
                                    informacionAdicional: dataAditional.length > 0 ? dataAditional : [],
                                    precioProducto: foundProduct.precioUnitario,
                                    isDescuentoPorcentaje: product.isDescuentoPorcentaje,
                                }]);

                                if (viewDetalle === 1) {
                                    setViewDetalle(2)
                                } else if (viewDetalle === 2) {
                                    setViewDetalle(1)
                                }
                                setProduct({
                                    descuentoProducto: '',
                                    cantidadProducto: '1',
                                    isDescuentoPorcentaje: 'false',
                                });
                                setIce('');
                                setFoundProduct({});
                                setDataAditional([]);
                                handleClose();
                                enqueueSnackbar(`Detalle agregado al comprobante`, {
                                    variant: 'success',
                                    anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'right',
                                    },
                                    autoHideDuration: 4000,
                                });
                            }
                            //}
                        } else {
                            setEmtyPrecio(false);
                        }
                    } else {
                        setEmty(false);
                    }
                } else {
                    setEmtyCodigo(false);
                }
            } else {
                setEmtyCodigo(false);
            }
        }
    };

    const validateDiscount = (valorDiscount) => {
        if (product.descuentoProducto.trim() !== '' && Number(product.descuentoProducto) > 0) {
            if (validateDecimal(product.descuentoProducto)) {
                let totalPrice = Number(foundProduct.precioUnitario) * Number(product.cantidadProducto)
                totalPrice = bigDecimal.round(totalPrice, 2, bigDecimal.RoundingModes.HALF_UP);
                if (Number(valorDiscount) <= Number(totalPrice)) {
                    return true;
                } else {
                    enqueueSnackbar(`El descuento debe ser menor a ${totalPrice}`, {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        autoHideDuration: 4000,
                    });
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    const validateDecimal = (numberValor) => {
        let splitList = numberValor.split('.');
        if (splitList.length > 2) {
            enqueueSnackbar(`El dato ingresado es erroneo utilice punto (.) para separar decimales`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                autoHideDuration: 4000,
            });
            return false;
        } else if (splitList.length > 1) {
            if (splitList[1].length > 2) {
                enqueueSnackbar(`Los datos numéricos debe tener máximo 2 decimales Ej, 12.43`, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    autoHideDuration: 4000,
                });
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }

    }

    const handleChangeDescuento = event => {
        event.preventDefault();
        const re = /^[0-9\b.]{1,14}$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            if (Number(event.target.value.trim()) >= 0) {
                setProduct({
                    ...product,
                    [event.target.name]: event.target.value
                });
            }
        }
    }

    const handleChangePrecio = event => {
        event.preventDefault();
        const re = /^[0-9\b.]{1,14}$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            if (Number(event.target.value.trim()) >= 0) {
                setFoundProduct({
                    ...foundProduct,
                    [event.target.name]: event.target.value
                });
                setEmtyPrecio(true);
            }
        }
    }
    const handleChange = event => {
        event.preventDefault();
        /*const re = /^[0-9\b]{1,14}$/;
        if (event.target.value === '' || re.test(event.target.value)) {

            setProduct({
                ...product,
                [event.target.name]: event.target.value
            });
            setEmty(true);

        }*/

        const { error, value, keyWordError } = validationVoucher.onChange(
            event.target.name,
            event.target.value
        );

        switch (event.target.name) {
            case 'cantidadProducto':
                if (keyWordError !== "isNotNumeric" && keyWordError !== "isLong" && checkInputs.countDecimals(event.target.value.toString().trim()) <= numDecimales) {
                    setProduct({
                        ...product,
                        [event.target.name]: event.target.value,
                    });

                }
                setErrors({ ...errors, [event.target.name]: error });
                break;
            case 'precioUnitario':
                if (keyWordError !== "isNotDecimal" && keyWordError !== "isLong" && checkInputs.countDecimals(event.target.value.toString().trim()) <= numDecimales) {
                    setFoundProduct({
                        ...foundProduct,
                        [event.target.name]: event.target.value,
                    });
                }
                setErrors({ ...errors, [event.target.name]: error });
                break;
            case 'descuentoProducto':
                if (keyWordError !== "isNotDecimal" && keyWordError !== "isLong" && checkInputs.countDecimals(event.target.value.toString().trim()) <= numDecimalesDescuento) {
                    setProduct({
                        ...product,
                        [event.target.name]: event.target.value,
                    });
                }
                setErrors({ ...errors, [event.target.name]: error });
                break;
            case 'isDescuentoPorcentaje':
                setProduct({
                    ...product,
                    [event.target.name]: event.target.value,
                });

            default:
                break;
        }

    }


    const handleChangeCheck = event => {
        setIsAdditional(event.target.checked);

    }

    function closeAlert() { setMsg({ show: false }); }

    //método para la busqueda
    const searchProducts = async (params) => {
        await clientBackend.get(`/admin/productos/buscar/activos/${getRUCEmpresa(userToken)}?parametroBusqueda=${params}`).then(
            response => {
                if (response.status === 200) {
                    if (Array.isArray(response.data)) {
                        if (response.data.length > 0) {
                            setOptions(response.data);
                            setExistProduct(false)
                        } else {
                            setOptions([
                                {
                                    codigoPrincipal: "",
                                    descripcion: `No se encontró datos con el argumento ingresado`,
                                },
                            ]);
                            setExistProduct(true)
                        }
                    }
                    setOpen(true);
                }
            }
        ).catch(exception => {
            if (exception.response) {
                setOptions([
                    {
                        codigoPrincipal: "",
                        descripcion: `No se encontró datos con el argumento ingresado`,
                    },
                ]);
                setExistProduct(true)
            } else {
                setOptions([
                    {
                        codigoPrincipal: "",
                        descripcion: `Error en la red al consultar el producto.`,
                    },
                ]);
            }
        }
        );
    }

    useEffect(() => {

    }, [])

    const clearData = () => {
        setEmtyCodigo(true);
        setEmty(true);
        setEmtyPrecio(true);
        setProduct({
            descuentoProducto: '',
            cantidadProducto: '1',
            isDescuentoPorcentaje: 'false',
        });
        setIce('');
        setFoundProduct({
            codigoAuxiliar: '',
            codigoPrincipal: '',
            descripcion: '',
            estado: '',
            idProducto: '',
            precioUnitario: '',
        });
        setDataAditional([]);
        setOptions([]);
        setIsAdditional(false);
        setErrors({})
    }
    const updateView = () => {

    }

    return (
        <Fragment>
            <Grid container justify='center' className={classes.divButton}>
                <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                    Agregar Detalle
                </Button>
            </Grid>
            <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="max-width-dialog-title">Nuevo Detalle</DialogTitle>
                <DialogContent>
                    <form >
                        <Grid container >
                            <Grid item xs={12} md={6}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" >
                                    <Grid item xs={12} md={12}>
                                        <label>Los campos marcados con (  <font color={palette.error.main}> *</font> ) son obligatorios:</label>
                                    </Grid>
                                    {msgError.show ?
                                        <Alert
                                            tipo={msgError.type}
                                            mensaje={msgError.msg}
                                            mesajeSubrayado={null}
                                            titulo={null}
                                            mostrar={msgError.show}
                                            cerrarAlerta={closeAlert}
                                        /> : null
                                    }
                                    {existProduct ?
                                        <Fragment>
                                            <Grid item xs={12} md={12} className={classes.divForm}>
                                                <Divider />
                                            </Grid>
                                            <Grid item xs={12} md={12} align='center' className={classes.divForm}>
                                                <AddProductsBtn updateView={updateView} />
                                            </Grid>
                                            <Grid item xs={12} md={12} className={classes.divForm}>
                                                <Divider />
                                            </Grid>
                                        </Fragment> :
                                        null
                                    }
                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>Código principal: <font color={palette.error.main}>*</font></label>
                                    </Grid>

                                    <Grid item xs={12} md={8} className={classes.divInput} >
                                        <Autocomplete
                                            id="codPrincipalProducto"
                                            onChange={(e, value) => {
                                                if (value !== null) {
                                                    setFoundProduct(value);
                                                    setErrors({});
                                                } else {
                                                    setFoundProduct({
                                                        codigoAuxiliar: '',
                                                        codigoPrincipal: '',
                                                        descripcion: '',
                                                        estado: '',
                                                        idProducto: '',
                                                        precioUnitario: '',

                                                    });
                                                }

                                                setEmtyCodigo(true)
                                            }}
                                            open={openAutocomplete}
                                            onOpen={() => {
                                                setOpenAutocomplete(true);
                                            }}
                                            onClose={() => {
                                                setOpenAutocomplete(false);
                                            }}
                                            loadingText="Ingrese al menos 1 caracter"
                                            noOptionsText={
                                                "No se encontró datos con el argumento ingresado"
                                            }
                                            getOptionDisabled={(option) =>
                                                option.descripcion.includes(
                                                    "No se encontró datos con el argumento ingresado"
                                                ) ||
                                                option.descripcion.includes(
                                                    "Error en la red al consultar el producto."
                                                )
                                            }
                                            getOptionSelected={(option, value) => option.codigoPrincipal === value.codigoPrincipal}
                                            getOptionLabel={(option) => `${option.codigoPrincipal}-${option.descripcion}`}
                                            options={options}
                                            loading={options.length === 0}
                                            renderInput={(params) => (
                                                <TextField
                                                    className={styleInput(
                                                        errors.codigoPrincipal,
                                                        foundProduct.codigoPrincipal
                                                    )} onChange={(event) => {
                                                        if (event.target.value.trim().length >= 1) {
                                                            searchProducts(event.target.value.trim())
                                                        }
                                                    }
                                                    }
                                                    {...params}
                                                    label=""
                                                    variant="outlined"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.codigoPrincipal ? (
                                            <p className={classesApp.errorLetters}>
                                                {errors.codigoPrincipal}
                                            </p>
                                        ) :
                                            null}
                                    </Grid>



                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>Código auxiliar: <font color={palette.error.main}>*</font></label>
                                    </Grid>
                                    <Grid item xs={12} md={8} className={classes.divInput} >
                                        <input type="text"
                                            placeholder="Ej. 0002"
                                            disabled
                                            name="codigoAuxiliarProducto"
                                            value={foundProduct ? foundProduct.codigoAuxiliar : ''}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            className={foundProduct ? foundProduct.codigoAuxiliar ? "form-control  is-valid" : "form-control " : "form-control "} />

                                    </Grid>
                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>Cantidad: <font color={palette.error.main}>*</font></label>
                                    </Grid>
                                    <Grid item xs={12} md={8} className={classes.divInput} >
                                        <input
                                            className={styleInput(
                                                errors.cantidadProducto,
                                                product.cantidadProducto
                                            )}
                                            type="text"
                                            name="cantidadProducto"
                                            value={product.cantidadProducto}
                                            onChange={handleChange}
                                            autoComplete="off"
                                        />
                                        {errors.cantidadProducto ? (
                                            <p className={classesApp.errorLetters}>
                                                {errors.cantidadProducto}
                                            </p>
                                        ) : null}

                                    </Grid>
                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>Descripción: <font color={palette.error.main}>*</font></label>
                                    </Grid>
                                    <Grid item xs={12} md={8} className={classes.divInput} >
                                        <input className={foundProduct ? foundProduct.descripcion ? "form-control  is-valid" : "form-control " : "form-control "}
                                            type="text"
                                            disabled
                                            placeholder="Ej. Ordenador"
                                            name="descripcionProducto"
                                            value={foundProduct ? foundProduct.descripcion : ''}
                                            onChange={handleChange}
                                            autoComplete="off"
                                        />

                                    </Grid>
                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>Precio Unitario: <font color={palette.error.main}>*</font></label>
                                    </Grid>
                                    <Grid item xs={12} md={8} className={classes.divInput} >
                                        <input
                                            className={styleInput(
                                                errors.precioUnitario,
                                                foundProduct.precioUnitario
                                            )}
                                            type="text"
                                            placeholder="Ej. 12"
                                            name="precioUnitario"
                                            value={foundProduct.precioUnitario}
                                            onChange={handleChange}
                                            autoComplete="off"
                                        />
                                        {errors.precioUnitario ? (
                                            <p className={classesApp.errorLetters}>
                                                {errors.precioUnitario}
                                            </p>
                                        ) :
                                            null}
                                    </Grid>




                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>Descuento</label>
                                    </Grid>
                                    <Grid fullWidth item xs={6} md={4} className={classes.divInput} >
                                        <input
                                            className={styleInput(
                                                errors.descuentoProducto,
                                                product.descuentoProducto
                                            )}
                                            type="text"
                                            placeholder="Ej. 3"
                                            name="descuentoProducto"
                                            value={product.descuentoProducto}
                                            onChange={handleChange}
                                            autoComplete="off"
                                        />
                                        {errors.descuentoProducto ? (
                                            <p className={classesApp.errorLetters}>
                                                {errors.descuentoProducto}
                                            </p>
                                        ) :
                                            null}
                                    </Grid>

                                    {<Grid xs={6} md={4}>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                aria-label="isPorcentaje"
                                                name="isDescuentoPorcentaje"
                                                value={product.isDescuentoPorcentaje}
                                                className={classes.radio}
                                                required
                                                onChange={handleChange}
                                            >
                                                <FormControlLabel
                                                    value={'false'}
                                                    control={
                                                        <Radio color="primary" className={classes.radioButton} />
                                                    }
                                                    label="Valor"
                                                    labelPlacement="start"
                                                    className={classes.radioLable}
                                                />
                                                <FormControlLabel
                                                    value={'true'}
                                                    control={
                                                        <Radio color="primary" className={classes.radioButton} />
                                                    }
                                                    label="Porcentaje"
                                                    labelPlacement="start"
                                                    className={classes.radioLable}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>}



                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>IVA: <font color={palette.error.main}>*</font></label>
                                    </Grid>
                                    <Grid item xs={12} md={8} className={classes.divInput}>
                                        <FormControl className={classesGlobales.select} >
                                            <NativeSelect
                                                value={iva}
                                                onChange={e => setIva(e.target.value)}
                                                input={<BootstrapInput />}
                                            >
                                                <option aria-label="None" value="" >--Seleccione--</option>
                                                {
                                                    valorIva.map(iva => (
                                                        <option
                                                            key={iva.CODIGO}
                                                            value={iva.CODIGO}>
                                                            {iva.NOMBRE}
                                                        </option>
                                                    ))
                                                }

                                            </NativeSelect>
                                        </FormControl>
                                    </Grid>

                                    {/*<Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>ICE:</label>
                                    </Grid>
                                    <Grid item xs={12} md={8} className={classes.divInput}>
                                        <FormControl className={classesGlobales.select}>
                                            <NativeSelect
                                                value={ice}
                                                onChange={e => setIce(e.target.value)}
                                                input={<BootstrapInput />}
                                            >
                                                <option aria-label="None" value=''>--Seleccione--</option>
                                                {
                                                    valorICE.map(ice => (
                                                        <option
                                                            key={ice.CODIGO}
                                                            value={ice.CODIGO}>
                                                            {`${ice.CODIGO} - ${ice.NOMBRE}`}
                                                        </option>
                                                    ))
                                                }
                                            </NativeSelect>
                                        </FormControl>
                                            </Grid>*/}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    md={12}
                                    justify="center"
                                    alignItems="center"
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isAditional}
                                                onChange={handleChangeCheck}
                                                name="isAditional"
                                                color="primary"
                                            />
                                        }
                                        label="Desea agregar información adicional al detalle?"
                                    />
                                </Grid>
                                {isAditional ? (
                                    <Grid
                                        container
                                        xs={12}
                                        md={12}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <EditTable
                                            dataAditional={dataAditional}
                                            setDataAditional={setDataAditional}
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} md={12} className={classes.divButton}>
                                <Button variant="contained" color="primary" onClick={handleAddProduct} className={classesGlobales.button} >
                                    Agregar
                                </Button>
                                <Button variant="outlined" color="secondary" onClick={handleClose} className={classesGlobales.button} >
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>

                    </form>
                </DialogContent>
            </Dialog>
            <Backdrop className={classes.backdrop} open={openLoader} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Fragment>
    );
}

export default AddDetail;