import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

//importo los actions
import { Grid, Button, makeStyles, Card, CardContent } from '@material-ui/core';

import { useStylesApp } from '../../../../hooks/useStyles';
import { msmSwalExito, msmSwalInformacion, msmSwalError } from 'configurations/styles/theme';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { palette, useStylesGlobal } from 'configurations/styles/theme';
import { httpCore } from 'configurations/axios';
import { getRUCEmpresa } from 'redux/actions/userActions';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: '1%',
        marginTop: '1%',
        borderStyle: 'solid',
        padding: '1%',
        borderLeftWidth: '0px',
        borderTopWidth: '0px',
        borderBottomWidth: '5px',
        borderRadius: '4px',
        borderColor: palette.primary.light
    },
    card: {
        width: '100%',
    }
}));


const CancelVoucher = ({ update, setUpdate }) => {

    const classes = useStyles();
    const classesApp = useStylesApp();
    const classesGlobal = useStylesGlobal();
    //Creamos el state del componente
    const [idComprobante, setIdComprobante] = useState('');
    const dispath = useDispatch();
    const [empty, setEmty] = useState(true);
    //const error = useSelector(state => state.cancelVoucher.error);
    const userToken = useSelector((state) => state.user.tk);
    //const cancelVoucher = (idComprobante) => (dispath(cancelVoucherAction(idComprobante)));


    const handleChange = event => {
        event.preventDefault();
        const re = /^[0-9\b]{1,49}$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setIdComprobante(event.target.value);

        }
    }

    //cuando el usuario haga submit (click)
    const submitCancelVoucher = (event) => {
        event.preventDefault();
        //Validar el input clave de acceso
        if (idComprobante.trim() === "" || idComprobante.length < 49) {
            setEmty(false);
        } else {
            setEmty(true);
            Swal.fire({
                title: 'Anular comprobante',
                text: "¿Está seguro de guardar el comprobante como anulado?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.value) {
                    cancelVoucherL(idComprobante);
                    //limpiar busqueda
                    //setIdComprobante('');
                }
            })
        }
    }
    const cancelVoucherL = async query => {
        await httpCore.put(`/comprobantes/anulado/anular/comprobante/${query}/${userToken ? getRUCEmpresa(userToken) : ""}`)
            .then(response => {
                if (response.status === 200) {
                    msmSwalExito('Proceso Exitoso', 'El comprobante se ha guardado!');
                    setIdComprobante('')
                    if (update === 5) {
                        setUpdate(2);
                    } else if (update === 2) {
                        setUpdate(5);
                    } else {
                        setUpdate(2);
                    }
                }
            })
            .catch(exception => {
                if (exception.response) {
                    msmSwalError(exception.response.data.apierror ? exception.response.data.apierror.mensaje ? exception.response.data.apierror.mensaje : 'Error, comprobante no anulado' : 'Error, comprobante no anulado')
                }
                else {
                    msmSwalError('Error en la red')
                }
            }
            )

    }




    /* useEffect(() => {
     }, [cancel])
 */


    return (
        <div >
            <form style={{ width: '100%' }}

            // onSubmit={submitCancelVoucher}
            >
                <Grid container justify='center'>
                    <Card className={classes.card} >

                        <CardContent >
                            <Grid container spacing={2} >
                                <Grid item md={2} xs={12}  >
                                    <label className={classesGlobal.titleCard}> Clave de acceso:</label>
                                </Grid>
                                <Grid item md={4} xs={12}>

                                    <input
                                        type="text"
                                        className="form-control "
                                        value={idComprobante}
                                        onChange={handleChange} />
                                    {empty === true ?
                                        null
                                        :
                                        <p className={classesApp.errorLetters}>El campo debe tener 49 dígitos</p>
                                    }
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Button
                                        type='submit'
                                        color='primary'
                                        variant="contained"
                                        align='center'
                                        onClick={submitCancelVoucher}
                                        startIcon={<SaveOutlinedIcon />}
                                    >Guardar Comprobante Anulado</Button>
                                </Grid>
                            </Grid>
                        </CardContent>

                    </Card>

                </Grid>
            </form>
        </div >
    );
}

export default CancelVoucher;