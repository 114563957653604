import React, { useReducer } from "react";

const initialContext = {
    page: {
        size: 0,
        content: [],
        numberOfElements: 0,
    },
    totalPages: 0,
    totalElements: 0,
    currentPage: 0,
    loading: false,
    numeroFilas: "15",
    atributoAOrdenar: 'idAsignacionComprobanteElectronico,desc',
    error: {
        show: false, msg: "", type: "error"
    },
    paramSearch: "",
    selected: []
}

const context = React.createContext(initialContext);

export const ACTIONS_TYPES = {
    SET_ROWS_TABLES: 1,
    SET_ERROR_LOAD_TABLE: 2,
    SET_LOADING_PAGE: 3,
    HANDLE_CHANGE_PAGE_TABLE: 4,
    HANDLE_CHANGE_FILTERS_TABLE: 5,
    ADD_SELECTION_ITEMS: 6,
}

function reducer(state, action) {
    switch (action.type) {
        case ACTIONS_TYPES.SET_ROWS_TABLES:
            return {
                ...state,
                ...action.payload
            }
        case ACTIONS_TYPES.SET_ERROR_LOAD_TABLE:
            return {
                ...state,
                error: action.payload
            }
        case ACTIONS_TYPES.SET_LOADING_PAGE:
            return {
                ...state,
                loading: action.payload
            }
        case ACTIONS_TYPES.HANDLE_CHANGE_PAGE_TABLE:
            return {
                ...state,
                page: {
                    size: 0,
                    content: [],
                    numberOfElements: 0,
                },
                currentPage: action.payload.page,
                numeroFilas: action.payload.numRows,
                selected: []
            }
        case ACTIONS_TYPES.HANDLE_CHANGE_FILTERS_TABLE:
            return {
                ...state,
                paramSearch: action.payload,
                currentPage: "0",
                numeroFilas: "15",
            }
        case ACTIONS_TYPES.ADD_SELECTION_ITEMS:
            return {
                ...state,
                selected: action.payload
            }
        default:
            return state;
    }
}

export const AuthorizedReceivedVouchersProvaider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialContext);
    return (
        <context.Provider value={{ state, dispatch }}>
            {children}
        </context.Provider>
    );
}

export default context;