import React, { useState } from "react";
import EnumErrorsNotifications from "./EnumErrorsNotifications";
import PersonIcon from "@material-ui/icons/Person";
import { items } from "widgets/menu/items";
import { palette } from "configurations/styles/theme";
import { makeStyles } from "@material-ui/core";
import { selectedResourceAction } from "redux/actions/resourcesActions";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "redux/actions/userActions";
import Swal from "sweetalert2";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';

const useStyles = makeStyles((theme) => ({
  link: {
    color: "blue",
    textDecoration: "underline",
    "&:hover": {
      color: "#28ACFD",
      cursor: "pointer"
    },
  },
}));

const IntructionsCertificate = () => {
  return (
    <p>
      <hr />
      Recomendación: Si es un usuario administrador puede subir el Certificado de firma electrónica  (archivo .p12), haciendo clic en la parte
      superior derecha de la pantalla en el icono
      <PersonIcon
        style={{ color: palette.primary.menuHeader, margin: "1%" }}
        fontSize="small"
      />
      , posterior a ello, seleccione 'Subir Certificado'.
    </p>
  );
};

const IntructionsPackage = () => {
  return (
    <p>
      <hr />
      Recomendación: Si es un usuario administrador puede adquirir un paquete, haciendo clic en la parte
      superior derecha de la pantalla en el icono
      <ShoppingCartOutlinedIcon
        style={{ color: palette.primary.menuHeader, margin: "1%" }}
        fontSize="small"
      />
      , posterior a ello, seleccione 'Adquirir Plan'.
    </p>
  );
};

const InstructionsSecuenciales = ({ onClick }) => {
  const classes = useStyles();
  const [changePage, setChangePage] = useState(false);
  const tokenUser = useSelector(state => state.user.tk)
  const dispatch = useDispatch();
  const selectedResource = (resource) =>
    dispatch(selectedResourceAction(resource));

  const handleClickSecuenciales = () => {
    setChangePage(true);
    if (getUserProfile(tokenUser ? tokenUser : "")) selectedResource(items.find((item) => item.id === "CSE"));
    if (typeof onClick === "function" && getUserProfile(tokenUser ? tokenUser : "")) onClick();
  };

  const handleClickSecuencialesAsignado = () => {
    setChangePage(true);
    if (getUserProfile(tokenUser ? tokenUser : "")) selectedResource(items.find((item) => item.id === "ASE"));
    if (typeof onClick === "function" && getUserProfile(tokenUser ? tokenUser : "")) onClick();
  };

  if (changePage && !getUserProfile(tokenUser ? tokenUser : "")) {
    Swal.fire("", `Le recordamos que usted no es un usuario administrador. No 
    puede gestionar los secuenciales.`, "warning")
  }

  return (
    <p>
      <hr />
      Recomendaciones: (Solo los usuarios administradores pueden realizar el
      proceso)
      <ul>
        <li>
          Verifique que tenga al menos un Punto de Emisión agregado.{" "}
          <em onClick={handleClickSecuenciales} className={classes.link}>
            (Ver Puntos de Emisión)
          </em>{" "}
        </li>
        <li>
          Asigne el Punto de Emisión a un usuario{" "}
          <em
            onClick={handleClickSecuencialesAsignado}
            className={classes.link}
          >
            (Ver los Puntos de Emisión Asignados)
          </em>{" "}
        </li>
      </ul>
      {changePage && !getUserProfile(tokenUser ? tokenUser : "") ? <em>Le recordamos que usted no es un usuario administrador. No
        puede gestionar los Puntos de Emisión. </em> : null}
    </p>
  );
};



const analitic = {};

analitic.GetInstructionsErrors = (keyword) => {
  switch (keyword) {
    case EnumErrorsNotifications.sinCertificado:
    case EnumErrorsNotifications.certificadoExp:
      return IntructionsCertificate;
    case EnumErrorsNotifications.sinNumeracion:
      return InstructionsSecuenciales;
    case EnumErrorsNotifications.sinComprobantes:
      return IntructionsPackage;
    default:
      return null;
  }
};

export default analitic;
