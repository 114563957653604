import {
    ClickAwayListener, Grow, IconButton, ListItemIcon, ListItemText,
    makeStyles, MenuItem, MenuList, Paper, Popper, Tooltip, Grid
} from '@material-ui/core';
import React, { useRef, useState } from 'react';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { colorHeader1, palette } from 'configurations/styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { selectedResourceAction } from 'redux/actions/resourcesActions';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import usePermissons from "hooks/usePermissons";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "contents",
    },
    paper: {
        marginRight: theme.spacing(2),
        backgroundColor: palette.primary.light,
        color: palette.primary.contrastText,
    },
}));


const BuyOptions = () => {
    const classes = useStyles(colorHeader1);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const dispatch = useDispatch();
    const selectedResource = (resource) => dispatch(selectedResourceAction(resource));
    const { getConfigPermissons } = usePermissons(useSelector, dispatch);
    let configPermissons = getConfigPermissons() || [];
    configPermissons = configPermissons.filter(item => item.id === "APL" || item.id === "SCO");


    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };


    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleClickPuyPackege = () => {
        selectedResource({
            id: -5,
            nameMenu: "Adquirir Plan",
            titlePage: "Adquirir Plan",
            menuPadre: null,
        });
        setOpen(false);
    };

    const handleClickPayPackege = () => {
        selectedResource({
            id: -6,
            nameMenu: "Subir Comprobante",
            titlePage: "Subir Comprobante",
            menuPadre: null,
        });
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    const getMenuItem = (item, index) => {
        switch (item.id) {
            case "APL":

                return (
                    <MenuItem onClick={handleClickPuyPackege} key={index}>
                        <ListItemIcon>
                            <StoreOutlinedIcon
                                style={{ color: palette.primary.contrastText }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={"Adquirir plan"}
                        />
                    </MenuItem>);
            case "SCO":

                return (

                    <MenuItem onClick={handleClickPayPackege} key={index}>
                        <ListItemIcon>
                            <PublishOutlinedIcon
                                style={{ color: palette.primary.contrastText }}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Subir comprobante" />
                    </MenuItem>
                );
            default:
                return null;
        }
    }

    if (configPermissons.length === 0) {
        return null;
    }

    return (
        <Grid item xs={3} md={3} >
            <Tooltip title={`Adquirir Plan`}>
                <IconButton
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    <ShoppingCartOutlinedIcon style={{ color: palette.primary.menuHeader }} />
                </IconButton>
            </Tooltip>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper className={classes.paper}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {
                                        configPermissons.map(
                                            (per, index) => getMenuItem(per, index)
                                        )
                                    }

                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>


        </Grid>
    )
}

export default BuyOptions;