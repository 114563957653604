import React, { useState, Fragment } from "react";
import {
  Radio,
  makeStyles,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  CardHeader,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { palette } from "configurations/styles/theme";
import { Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { getRUCEmpresa } from "redux/actions/userActions";
import clientBackend from "configurations/axios";
import { useStylesGlobal } from "configurations/styles/theme";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AddCostumer from 'views/administración/Clients/Buttons/AddCostumer';

const useStyles = makeStyles((theme) => ({
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  divForm: {
    marginBottom: "0%",
    textAlign: "justify",
  },
  divInput: {
    marginBottom: "0%",
    paddingRight: "0%",
    paddingLeft: "0%",
    textAlign: "justify",
  },
}));

const ClientData1 = ({ setIdAsgCliente, setFoundClient, foundClient, setOptions, options }) => {
  const classes = useStyles();
  const classesGlobales = useStylesGlobal();
  const userToken = useSelector((state) => state.user.tk);
  //Hooks para obtener los clientes
  //const [options, setOptions] = React.useState([]);
  //const [foundClient, setFoundClient] = useState({});

  const [open, setOpen] = React.useState(false);
  const loading = open && options.length === 0;
  //Para saber si es con datos o consumidor final
  const [isData, setIsData] = useState("D");
  const [existClient, setExistClient] = useState(false);

  //Effect para obtener los clientes
  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const updateView = () => {

  }
  //método para la busqueda
  const searchClients = async (params) => {
    await clientBackend
      .get(
        `/admin/clientes/buscar/activos/${userToken ? getRUCEmpresa(userToken) : ""
        }?parametroBusqueda=${params}`
      )
      .then((response) => {
        if (response.status === 200) {
          //setOptions(Object.keys(response.data).map((cliente) => response.data[cliente].cliente));
          if (Array.isArray(response.data)) {
            if (response.data.length > 0) {
              setOptions(
                response.data.map(function (data, index, array) {
                  return {
                    idCliente: data.cliente.idCliente,
                    nombres: data.nombres,
                    correo: data.correo,
                    direccion: data.direccion,
                    idAsignacionEmpresaCliente: data.idAsignacionEmpresaCliente,
                    telefono: data.telefono,
                  };
                })
              );
              setExistClient(false)
            } else {
              setOptions([
                {
                  idCliente: "",
                  nombres: "",
                  correo: "No se encontró datos con el argumento ingresado",
                },
              ]);
              setExistClient(true)
            }
          }
          setOpen(true);
        }
      })
      .catch((exception) => {
        console.log({ exception });
        if (exception.response) {
          setOptions([
            {
              idCliente: "",
              nombres: "",
              correo: "No se encontró datos con el argumento ingresado",
            },
          ]);
        } else {
          setOptions([
            {
              idCliente: "",
              nombres: "",
              correo: "Error en la red al consultar el cliente.",
            },
          ]);
        }
      });
  };



  const handleChangeCheck = (event) => {
    if (event.target.value === "F") {
      setOptions([]);
      setFoundClient({});
      setIdAsgCliente({ idAsignacionEmpresaCliente: 0 });
    } else {
      setIdAsgCliente({});
    }
    setIsData(event.target.value);
  };

  return (
    <Fragment>
      <div className={classesGlobales.rootDiv}>
        <Grid container xs={12} md={12} justify="center">
          <CardHeader
            className={classesGlobales.cabecera}
            title={"Datos del Cliente"}
            avatar={<AssignmentIcon></AssignmentIcon>}
          />
        </Grid>
        <Grid container spacing={1} className={classesGlobales.containerForm}>
          <Grid container justify="center">
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="datosCliente"
                name="isData"
                value={isData}
                className={classes.radio}
                required
                onChange={handleChangeCheck}
              >
                <FormControlLabel
                  value="F"
                  control={
                    <Radio color="primary" className={classes.radioButton} />
                  }
                  label="Consumidor final"
                  labelPlacement="start"
                  className={classes.radioLable}
                />
                <FormControlLabel
                  value="D"
                  control={
                    <Radio color="primary" className={classes.radioButton} />
                  }
                  label="Datos"
                  labelPlacement="start"
                  className={classes.radioLable}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {isData === "D" ? (
            <Fragment>
              <Grid
                item
                xs={12}
                md={2}
                className={classes.divForm}
                justify="flex-start"
              >
                <label>
                  CI / RUC / Pasaporte:{" "}
                  <font color={palette.error.main}>*</font>
                </label>
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  id="rucCliente"
                  onChange={(e, value) => {
                    setFoundClient(value);
                    if (value !== null) {
                      setIdAsgCliente(value);
                    } else {
                      setIdAsgCliente({});
                    }
                  }}
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  getOptionDisabled={(option) =>
                    option.correo.includes("No se encontró datos con el argumento ingresado") ||
                    option.correo.includes("Error en la red al consultar el cliente.")

                  }
                  getOptionSelected={(option, value) => option.idCliente === value.idCliente}
                  getOptionLabel={(option) =>
                    `${option.idCliente} - ${option.nombres} - ${option.correo}`
                  }
                  options={options}
                  noOptionsText={
                    "No se encontró datos con el argumento ingresado"
                  }
                  //inputValue={foundClient}
                  loadingText="Ingrese al menos 3 caracteres"
                  loading={options.length === 0}
                  renderInput={(params) => (
                    <TextField
                      className={foundClient ? foundClient.idCliente ? "form-control  is-valid" : null : null}
                      onChange={(event) => {
                        if (event.target.value.length >= 2) {
                          searchClients(event.target.value);
                        }
                      }}
                      {...params}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <small style={{ color: "#818181" }}>También puede buscarlo por la Razón Social o sus Nombres.</small>
              </Grid>

              {existClient ?
                <Grid item xs={12} md={6}  >
                  <AddCostumer updateView={updateView}></AddCostumer>
                </Grid> :
                <Grid item xs={12} md={6}  ></Grid>
              }
              <Grid item xs={12} md={2} className={classes.divForm}>
                <label>
                  Razón Social / Nombres:{" "}
                  <font color={palette.error.main}>*</font>
                </label>
              </Grid>
              <Grid item xs={12} md={4} className={classes.divInput}>
                <input
                  className={
                    foundClient
                      ? foundClient.nombres
                        ? "form-control  is-valid"
                        : "form-control "
                      : "form-control "
                  }
                  type="text"
                  disabled
                  name="nombres"
                  value={foundClient ? foundClient.nombres ? foundClient.nombres : "" : ""}
                />
              </Grid>

              <Grid item xs={12} md={2} className={classes.divForm}>
                <label>Teléfono:</label>
              </Grid>
              <Grid item xs={12} md={4} className={classes.divInput}>
                <input
                  className={
                    foundClient
                      ? foundClient.telefono
                        ? "form-control  is-valid"
                        : "form-control "
                      : "form-control"
                  }
                  type="text"
                  disabled
                  name="telefonoCliente"
                  value={foundClient ? foundClient.telefono ? foundClient.telefono : "" : ""}
                />
              </Grid>

              <Grid item xs={12} md={2} className={classes.divForm}>
                <label>
                  Correo Principal: <font color={palette.error.main}>*</font>
                </label>
              </Grid>
              <Grid item xs={12} md={4} className={classes.divInput}>
                <input
                  className={
                    foundClient
                      ? foundClient.correo
                        ? "form-control  is-valid"
                        : "form-control "
                      : "form-control "
                  }
                  type="text"
                  disabled
                  name="emailCliente"
                  value={foundClient ? foundClient.correo ? foundClient.correo : "" : ""}
                />
              </Grid>

              <Grid item xs={12} md={2} className={classes.divForm}>
                <label>Dirección:</label>
              </Grid>
              <Grid item xs={12} md={4} className={classes.divInput}>
                <input
                  className={
                    foundClient
                      ? foundClient.direccion
                        ? "form-control  is-valid"
                        : "form-control "
                      : "form-control "
                  }
                  type="text"
                  disabled
                  name="direccionCliente"
                  value={foundClient ? foundClient.direccion ? foundClient.direccion : "" : ""}
                />
              </Grid>


            </Fragment>
          ) : null}
        </Grid>
      </div>
    </Fragment>
  );
};

export default ClientData1;
