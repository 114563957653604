import bigDecimal from "js-big-decimal";
import {
  getValorIVA,
  getValorICE,
  valorIva,
} from "views/facturacion/Forms/typeVoucher";

const valueConstant_100 = new bigDecimal(100);

export const calculateBase = (product) => {
  const count = new bigDecimal(product.cantidad);
  const price = new bigDecimal(product.precioProducto);
  let discount = new bigDecimal(product.descuento);
  const CienConstant = new bigDecimal(100);
  if (product.isDescuentoPorcentaje) {
    discount = price.multiply(count).multiply(discount).divide(CienConstant);
  }
  return count
    .multiply(price)
    .subtract(discount)
    .round(2, bigDecimal.RoundingModes.HALF_UP).value;
};

export const calculateTaxICE = (product) => {
  if (product.codigoPorcentajeICE) {
    const taxeICE = getValorICE(product.codigoPorcentajeICE);
    const base = new bigDecimal(calculateBase(product));
    const valueTax = new bigDecimal(taxeICE.VALOR);
    const result = base
      .multiply(valueTax)
      .divide(valueConstant_100)
      .round(2, bigDecimal.RoundingModes.HALF_UP).value;
    return result;
  } else {
    return 0;
  }
};

export const calculateTaxIVA = (product) => {
  let base = new bigDecimal(calculateBase(product));
  const valueTaxICE = new bigDecimal(calculateTaxICE(product));
  base = base.add(valueTaxICE);

  if (product.codigoPorcentajeIVA) {
    const taxIVA = getValorIVA(product.codigoPorcentajeIVA);
    const valueTax = new bigDecimal(taxIVA.VALOR);
    return base
      .multiply(valueTax)
      .divide(valueConstant_100)
      .round(2, bigDecimal.RoundingModes.HALF_UP).value;
  } else {
    console.error("Code percentage IVA is Empty");
    return 0;
  }
};

export const calculateTaxFromValueAndPercentage = (value, percentage) => {
  const base = new bigDecimal(value);
  const valuePercentage = new bigDecimal(percentage);
  return base
    .multiply(valuePercentage)
    .divide(valueConstant_100)
    .round(2, bigDecimal.RoundingModes.HALF_UP).value;
};

export const calculateSubtotalNoTax = (listProducts) => {
  let subTotal = new bigDecimal(0);
  for (let i = 0; i < listProducts.length; i++) {
    const base = new bigDecimal(calculateBase(listProducts[i]));
    subTotal = subTotal.add(base);
  }
  return subTotal.round(2, bigDecimal.RoundingModes.HALF_UP).value;
};

export const calculateTotalICE = (listProducts) => {
  let subTotal = new bigDecimal(0);
  for (let i = 0; i < listProducts.length; i++) {
    const valueTax = new bigDecimal(calculateTaxICE(listProducts[i]));
    subTotal = subTotal.add(valueTax);
  }
  return subTotal.round(2, bigDecimal.RoundingModes.HALF_UP).value;
};

export const calculateTotalDiscount = (listProducts) => {
  let subTotal = new bigDecimal(0);
  let price = 0;
  let discount = 0;
  let count = new bigDecimal(0);
  const CienConstant = new bigDecimal(100);
  for (let i = 0; i < listProducts.length; i++) {
    discount = new bigDecimal(listProducts[i].descuento);
    if (listProducts[i].isDescuentoPorcentaje) {
      count = new bigDecimal(listProducts[i].cantidad);
      price = new bigDecimal(listProducts[i].precioProducto);
      discount = price
        .multiply(count)
        .multiply(discount)
        .divide(CienConstant)
        .round(2, bigDecimal.RoundingModes.HALF_UP);
    }
    subTotal = subTotal.add(discount);
  }
  return subTotal.round(2, bigDecimal.RoundingModes.HALF_UP).value;
};

export const calculateSubtotalIVAByCode = (listProducts, code) => {
  if (code) {
    let subTotal = new bigDecimal(0);
    for (let i = 0; i < listProducts.length; i++) {
      if (listProducts[i].codigoPorcentajeIVA === code) {
        let base = new bigDecimal(calculateBase(listProducts[i]));
        const valueTaxICE = new bigDecimal(calculateTaxICE(listProducts[i]));
        base = base.add(valueTaxICE);
        subTotal = subTotal.add(base);
      }
    }
    return subTotal.round(2, bigDecimal.RoundingModes.HALF_UP).value;
  } else {
    return 0;
  }
};

export const calculateTotal = (listProducts) => {
  let total = new bigDecimal(0);
  for (let i = 0; i < listProducts.length; i++) {
    let base = new bigDecimal(calculateBase(listProducts[i]));
    const valueTaxICE = new bigDecimal(calculateTaxICE(listProducts[i]));
    const valueTaxIVA = new bigDecimal(calculateTaxIVA(listProducts[i]));
    total = base.add(valueTaxICE).add(valueTaxIVA).add(total);
  }
  return total.round(2, bigDecimal.RoundingModes.HALF_UP).value;
};

export const reportTotalsDetail = (listProducts) => {
  const Subtotal12 = calculateSubtotalIVAByCode(
    listProducts,
    valorIva[1].CODIGO
  );
  const Subtotal15 = calculateSubtotalIVAByCode(
    listProducts,
    valorIva[2].CODIGO
  );
  const Subtotal5 = calculateSubtotalIVAByCode(
    listProducts, 
    valorIva[5].CODIGO
  );
  const totalIVA12 = calculateTaxFromValueAndPercentage(
    Subtotal12,
    valorIva[1].VALOR
  );
  const totalIVA15 = calculateTaxFromValueAndPercentage(
    Subtotal15,
    valorIva[2].VALOR
  );
  const totalIVA5 = calculateTaxFromValueAndPercentage(
    Subtotal5,
    valorIva[5].VALOR
  )
  return {
    Subtotal12: Subtotal12,
    Subtotal5: Subtotal5,
    Subtotal15: Subtotal15,
    Subtotal0: calculateSubtotalIVAByCode(listProducts, valorIva[0].CODIGO),
    SubtotalNoIVA: calculateSubtotalIVAByCode(listProducts, valorIva[3].CODIGO),
    SubtotalSinImpuestos: calculateSubtotalNoTax(listProducts),
    SubtotalICE: calculateTotalICE(listProducts),
    totalIVA12: totalIVA12,
    totalIVA5: totalIVA5,
    totalIVA15: totalIVA15,
    totalDescuentos: calculateTotalDiscount(listProducts),
    total: calculateTotal(listProducts),
  };
};

export const reportTotalsDebitNote = (reasons, codeTaxIVA, codeTaxICE) => {
  const taxeICE = getValorICE(codeTaxICE);
  let subtotal = new bigDecimal(0);
  for (let i = 0; i < reasons.length; i++) {
    const value = new bigDecimal(reasons[i].valor);
    subtotal = value.add(subtotal);
  }

  const SubtotalSinImpuestos = subtotal.round(
    2,
    bigDecimal.RoundingModes.HALF_UP
  );

  let ICE = new bigDecimal(0);
  if (codeTaxICE) {
    ICE = new bigDecimal(
      calculateTaxFromValueAndPercentage(subtotal.value, taxeICE.VALOR)
    );
    subtotal = subtotal.add(ICE);
  }

  let subtotal12 = 0.0;
  let subtotal5 = 0.0;
  let subtotal15 = 0.0;
  let subtotal0 = 0.0;
  let subtotalNoIva = 0.0;
  let IVA12 = new bigDecimal(0);
  let IVA5 = new bigDecimal(0);
  let IVA15 = new bigDecimal(0);

  switch (codeTaxIVA) {
    case valorIva[0].CODIGO:
      subtotal0 = subtotal.round(2, bigDecimal.RoundingModes.HALF_UP).value;
      break;
    case valorIva[1].CODIGO:
      const auxValue = new bigDecimal(
        calculateTaxFromValueAndPercentage(subtotal.value, valorIva[1].VALOR)
      );
      IVA12 = IVA12.add(auxValue);
      subtotal12 = subtotal.round(2, bigDecimal.RoundingModes.HALF_UP).value;
      break;
    case valorIva[2].CODIGO:
      const auxValueIce = new bigDecimal(
        calculateTaxFromValueAndPercentage(subtotal.value, valorIva[2].VALOR)
      );
      IVA15 = IVA15.add(auxValueIce);
      subtotal15 = subtotal.round(2, bigDecimal.RoundingModes.HALF_UP).value;
      break;
    case valorIva[3].CODIGO:
      subtotalNoIva = subtotal.round(2, bigDecimal.RoundingModes.HALF_UP).value;
      break;
    case valorIva[5].CODIGO:
      const auxValue5 = new bigDecimal(
        calculateTaxFromValueAndPercentage(subtotal.value, valorIva[5].VALOR)
      )
      IVA5 = IVA5.add(auxValue5);
      subtotal5 = subtotal.round(2, bigDecimal.RoundingModes.HALF_UP).value;
      break;
    default:
      break;
  }

  let total = new bigDecimal(0);
  total = total.add(SubtotalSinImpuestos).add(IVA12).add(IVA15).add(ICE).add(IVA5);

  return {
    subtotal12,
    subtotal5,
    subtotal15,
    subtotal0,
    subtotalNoIva,
    SubtotalSinImpuestos: SubtotalSinImpuestos.value,
    IVA12: IVA12.value,
    IVA5: IVA5.value,
    IVA15: IVA15.value,
    ICE: ICE.value,
    total: total.round(2, bigDecimal.RoundingModes.HALF_UP).value,
  };
};

const servicesTax = {
  calculateBase,
  calculateTaxICE,
  calculateTaxIVA,
  calculateSubtotalNoTax,
  calculateTotalICE,
  calculateTotalDiscount,
  calculateSubtotalIVAByCode,
  reportTotalsDetail,
};

export default servicesTax;
