import React, { useState } from "react";
import {
  Grid,
  Divider,
  makeStyles,
  CircularProgress,
  Button,
  Input,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { getRUCEmpresa } from "redux/actions/userActions";
//componentes del sistema
import TablePaginated from "widgets/Tables/TablePaginated";
import { API_GET_ALL_PRODUCTS, API_GET_SEARCH_PRODUCTS } from "./apis";
import tableStyle from "configurations/styles/table";
import TableSimple from "widgets/Tables/TableSimple";
import clientBackend from "configurations/axios";
import AddProductBtn from "./Buttons/AddProduct";
import SearchProduct from "./Froms/SearchProduct";
import EditProductBtn from "./Buttons/EditProduct";
import { CardGeneric } from "../components/CardGeneric";
import { roundTwoDecimal } from "utils/parseInput";
import { countDecimals } from "utils/checkInputs";

import QueueIcon from "@material-ui/icons/Queue";
import { palette } from "configurations/styles/theme";
import { clientBackendXLSX } from "configurations/axios";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: "35px",
    flexGrow: 1,
    paddingLeft: "3%",
    paddingRight: "3%",
    marginBottom: "50px",
  },
  headerBottons: {
    padding: "0.5%",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    height: "300px",
  },
  uploadFile: {
    marginLeft: "9px",
    padding: "5px",
    color: "blue",
  },
}));

const ParseStateProduct = (data) => {
  if (data.data.estado) {
    return "Activo";
  } else {
    return "Inactivo";
  }
};

const roundPrice = (data) => {
  if (countDecimals(data) < 2) {
    return roundTwoDecimal(data);
  }
  return data;
};

const columns = (reloadPage) => [
  {
    title: "Código principal",
    field: "codigoPrincipal",
    cellStyle: tableStyle.cellTable,
    width: "5%",
  },
  {
    title: "Código auxiliar",
    field: "codigoAuxiliar",
    cellStyle: tableStyle.cellTable,
    width: "5%",
  },
  {
    title: "Descripción",
    field: "descripcion",
    cellStyle: tableStyle.cellLeft,
    width: "40%",
  },
  {
    title: "Precio Unitario",
    cellStyle: tableStyle.cellRight,
    width: "4%",
    render: (rowData) => roundPrice(rowData.precioUnitario),
  },
  {
    title: "Estado",
    cellStyle: tableStyle.cellTable,
    width: "10%",
    render: (rowData) => <ParseStateProduct data={rowData} />,
  },
  {
    title: "Editar",
    cellStyle: tableStyle.cellTable,
    width: "3%",
    render: (rowData) => (
      <EditProductBtn product={rowData} updateView={reloadPage} />
    ),
  },
];

const requestConfig = (ruc) => ({
  uri: API_GET_ALL_PRODUCTS + ruc,
  metodo: "get",
  body: null,
  page: 0,
  elementos: 15,
  // sort: "idProducto,desc",
  sort: "estado,idProducto,desc",
});

const ProductServicesHome = () => {
  const tokenUser = useSelector((state) => state.user.tk);
  const classesView = useStyles();
  const [update, setUpdate] = useState(0);
  const [foundProducts, setFoundProducts] = useState([]);
  const [fileXLSX, setFileXLSX] = useState();

  const getParam = (data) => {
    if (data.length > 0) {
      searchProduct(data);
    } else {
      reload();
    }
  };

  const reload = () => {
    setUpdate(1);
  };

  const searchProduct = async (param) => {
    setUpdate(3);
    await clientBackend
      .get(
        API_GET_SEARCH_PRODUCTS +
          (tokenUser ? getRUCEmpresa(tokenUser) : "0") +
          "?parametroBusqueda=" +
          param
      )
      .then((response) => {
        if (response.status === 200) {
          setFoundProducts(response.data);
          setUpdate(2);
        }
      })
      .catch((exception) => {
        console.log({ exception });
      });
  };

  const renderTable = () => {
    switch (update) {
      case 0:
        return (
          <TablePaginated
            title=""
            columns={columns(reload)}
            requestConfig={requestConfig(
              tokenUser ? getRUCEmpresa(tokenUser) : "0"
            )}
          />
        );
      case 1:
        setTimeout(() => {
          setUpdate(0);
        }, 500);
      case 3:
        return (
          <div className={classesView.loader}>
            <CircularProgress />
          </div>
        );
      case 2:
        return (
          <TableSimple
            columns={columns(reload)}
            data={foundProducts}
            title=""
            numberRowsPerPage={15}
          />
        );
      default:
        return (
          <TablePaginated
            title=""
            columns={columns}
            requestConfig={requestConfig(
              tokenUser ? getRUCEmpresa(tokenUser) : "0"
            )}
          />
        );
    }
  };

  const uploadFileXLSX = async () => {
    console.log(fileXLSX);

    let formData = new FormData();
    formData.append("file", fileXLSX);

    const response = await clientBackendXLSX.post(
      "/carga/masiva/productos/archivo",
      formData,
      {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "cache-control": "no-cache",
        },
      }
    );

    console.log(response);
  };

  const handleFileChange = async (event) => {
    console.log(event.target.files);
    let files = event.target.files;
    if (files.length === 1) {
      if (
        files[0].type === "application/vnd.ms-excel" ||
        files[0].type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        if (files[0].size < "1048576") {
          setFileXLSX(files[0]);
        } else if (files[0].size > "1048576") {
          console.log("El peso máximo del archivo es de 1 Mb.");
          setFileXLSX(null);
        }
      } else {
        console.log("Solo se admiten archivos en formato xls o xlsx.");
      }
    }
  };

  return (
    <section className={classesView.root}>
      <CardGeneric>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <AddProductBtn updateView={reload} />
            {/* <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "9px" }}
              onClick={uploadFileXLSX}
              disabled={fileXLSX == null ? true : false}
            >
              <QueueIcon
                style={{
                  color: palette.primary.contrastText,
                  paddingRight: "3%",
                }}
                fontSize="small"
              />
              Carga Masiva EXCEL
            </Button>
            <Input
              className={classesView.uploadFile}
              variant="contained"
              color="primary"
              type="file"
              placeholder="Carga Masiva EXCEL"
              onChange={handleFileChange}
            /> */}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={8}>
            <SearchProduct sendData={getParam} />
          </Grid>
        </Grid>
      </CardGeneric>
      {renderTable()}
    </section>
  );
};

export default React.memo(ProductServicesHome);
