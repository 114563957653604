import { Backdrop, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { msmSwalError, msmSwalExito, palette, useStylesGlobal } from 'configurations/styles/theme';
import { clientPay } from 'configurations/axios';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
    IconButton: {
        marginTop: "0%",
        marginBottom: "0%",
        padding: "0px",
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
}))

const CancelPaymentBtn = ({ data, reload }) => {
    const classes = useStyles();
    const classesGlobal = useStylesGlobal();
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [openLoader, setOpenLoader] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    /*const re = /^[0-9\b]{1,14}$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setProduct({
        ...product,
        [event.target.name]: event.target.value,
      });
      setEmty(true);
    }*/

    const addMessage = (event) => {
        event.preventDefault();
        setMessage(event.target.value)
    }

    const validateMesg = () => {
        if (typeof message !== 'undefined' && message.trim() !== '') {
            return true;
        } else {
            return false;
        }
    }

    const cancelPayment = async () => {

        if (validateMesg()) {
            handleClose();
            setOpenLoader(true);
            await clientPay
                .post(
                    `/pago/revision`
                    , {
                        idAsignacionPaqueteEmpresa: data.idAsignacionPaqueteEmpresa,
                        observacion: message,
                        revision: false
                    })
                .then((response) => {
                    if (response.status === 200) {
                        setOpenLoader(false)
                        msmSwalExito('Proceso Exitoso', 'El pago ha sido rechazado!');
                        reload();
                        return response.data;
                    }
                })
                .catch((exception) => {
                    setOpenLoader(false)
                    console.log({ exception });
                    msmSwalError(exception.response.data.apierror ? exception.response.data.apierror.mensaje ? exception.response.data.apierror.mensaje : 'Error en la red, transacción no realizada' : 'Error en la red, transacción no realizada')
                    return exception;
                });
        } else {
            enqueueSnackbar(
                `El campo motivo no puede estar vacío`,
                {
                    variant: "warning",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                    autoHideDuration: 4000,
                }
            );
        }
    };



    return (
        <div>

            <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleClickOpen}
                className={classes.IconButton}
            >
                <CancelOutlinedIcon style={{ color: palette.error.main }} className={classes.small} />
            </IconButton>

            <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose} >
                <DialogTitle id="max-width-dialog-title">Rechazar de Depósito</DialogTitle>
                <DialogContent>

                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >

                        <Grid item sm={12} md={4}>
                            <label>Ingrese el motivo por el cual rechaza el contrato del paquete</label>

                        </Grid>

                        <Grid item sm={12} md={6} >
                            <input type='text'
                                className={"form-control"}
                                onChange={addMessage}
                            />

                        </Grid>
                    </Grid>
                    <Grid container spacing={3} justify="center">
                        <Grid item md={12} xs={12}>
                            <br />
                            <Divider></Divider>
                        </Grid>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={cancelPayment}
                            className={classesGlobal.marginButton}
                        >
                            Agregar
                                </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleClose}
                            className={classesGlobal.marginButton}
                        >
                            Cancelar
                                </Button>
                    </Grid>


                </DialogContent>
            </Dialog>
            <Backdrop className={classes.backdrop} open={openLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>


        </div>
    );
}

export default CancelPaymentBtn;