import React, { useState } from "react";
import ClientData1 from "../../Forms/ClientData1";
import InvoiceData from "../../Forms/InvoiceData";
import useTitle from "hooks/useTitle";
import { Paper, makeStyles } from "@material-ui/core";
import { useStylesGlobal } from "configurations/styles/theme";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: "30px",
    flexGrow: 1,
    paddingLeft: "3%",
    paddingRight: "3%",
    marginBottom: "5%",
  },
}));

const Invoice = () => {
  const classesView = useStyles();
  const classesGlobales = useStylesGlobal();
  const [idAsgCliente, setIdAsgCliente] = useState({});
  const [foundClient, setFoundClient] = useState({});
  const [options, setOptions] = React.useState([]);
  useTitle({ title: "Factura" });

  return (
    <div className={classesView.root}>
      <Paper className={classesGlobales.paperHeader}>
        <h5 className={classesGlobales.titleHeader}>Emisión de Factura</h5>
      </Paper>
      <ClientData1
        setIdAsgCliente={setIdAsgCliente}
        setFoundClient={setFoundClient}
        foundClient={foundClient}
        setOptions={setOptions}
        options={options}
      />
      <InvoiceData
        setIdAsgCliente={setIdAsgCliente}
        idAsgCliente={idAsgCliente}
        setFoundClient={setFoundClient}
        setOptions={setOptions}
      />
    </div>
  );
};

export default Invoice;
