import {
  ADD_RESOURSE_LOADING,
  ADD_RESOURSE_SUCCESS,
  ADD_RESOURSE_ERROR,
  ADD_RESOURSE_DENIED,
  ADD_INFO_COMPANY,
  UPDATE_INFO_COMPANY_LOGO,
  ADD_ERROR_COMPANY,
  SELECTED_RESOURCE,
  REMOVE_RESOURCE,
  ISLOADING_INFO_COMPANY,
  ADD_CONFIG_RESOURCES,
  DELETE_CONFIG_RESOURCES
} from "../types/resourcesType";
import uuid from "react-uuid";
import { clientPublic, httpCore } from "configurations/axios";
import typesNotifications from "widgets/alert/types";
import { authUserSuccess, authUserError, parseJwt, authTempUserAction } from "./userActions";
import { addNotificationsActions } from "./notificationsAction";
import { addCompanyAction } from "./companyActions";
import {
  processoLoadingAction,
  processoFinishedAction,
} from "./processAsyncAction";
import { newKy } from "../localStorage";
import { setTkTmp } from "../sessionstorage";
import { apiKey, aYoSis, domainSis } from "configurations/constant";


export function addResourceAction(dataUser) {
  return async (dispatch) => {
    //se muestra un loader
    dispatch(processoLoadingAction());
    //para saber que dominio es
    const apiKeySis = window.location.hostname.includes(domainSis) ? aYoSis : apiKey;
    //cambio en el store -> reosurce el estado de carga a true
    dispatch(addReourcesLoading());
    let request = { ...dataUser, codigoSistema: apiKeySis }

    await clientPublic
      .post("/login", request)
      .then((result) => {
        newKy();
        if (result.status === 200) {
          //registra el token
          const stateUser = parseJwt(result.data.accessToken).state || null;

          if (stateUser !== "PRI") {
            dispatch(authUserSuccess(result.data));
          } else {
            dispatch(authTempUserAction());
            setTkTmp(result.data);
          }

          //se oculpta el loader
          dispatch(processoFinishedAction());
        }
      })
      .catch((exception) => {
        //cambio en el store -> reosurce cambia el estado de carga a false y el error a true
        dispatch(addResourcesError());
        if (exception.response) {
          dispatch(authUserError("Usuario o contraseña inválido."));
        } else {
          //colaca null en el token del usuario
          dispatch(
            authUserError(
              "Existen problemas en la red. Contáctese con el administrador del Sistema."
            )
          );
        }
        //se oculpta el loader
        dispatch(processoFinishedAction());
      });
  };
}

export const loadDataCompany = async (tk, dispatch) => {
  dispatch(isLoadingInfoCompany(true));
  const jwt = parseJwt(tk);
  const request = {
    idAsignacionEmpresaUsuario: jwt.sub,
    rucEmpresa: jwt.ruc,
  };

  await httpCore
    .post("/generacion/comprobantes/validacion", request)
    .then((response) => {
      let infoCompany = {
        nombreUsuario: null,
        nombreEmpresa: null,
        advertencias: {},
        errores: {},
      };
      let notifications = [];

      if (response.status >= 200 && response.status <= 300) {
        if (response.data) {
          infoCompany = response.data;
          notifications = notifications.concat(
            parseNotificationToArray(
              response.data.errores,
              typesNotifications.error
            )
          );
          infoCompany.errores = notifications;
          notifications = notifications.concat(
            parseNotificationToArray(
              response.data.advertencias,
              typesNotifications.warning
            )
          );
        }
      }
      if (jwt.state === "PRI") {
        dispatch(
          addNotificationsActions({
            lstNotifications: notifications,
            stateUser: "PRI",
          })
        );
      } else {
        dispatch(addNotificationsActions({ lstNotifications: notifications }));
      }

      dispatch(
        addInfoCompanyAction({
          infoCompany,
          loadingInfoCompany: false,
          errorsCompany: infoCompany.errores,
        })
      );

      if (infoCompany.nombreEmpresa && infoCompany.nombreUsuario) {
        dispatch(
          addCompanyAction({
            nombreEmpresa: infoCompany.nombreEmpresa,
            nombreUsuario: infoCompany.nombreUsuario,
            logoEmpresa: infoCompany.logoEmpresa,
          })
        );
      }
    })
    .catch(() => {
      let infoCompany = {};
      infoCompany.errorLoader =
        "Error al consultar las notificaciones de la empresa";
      dispatch(
        addInfoCompanyAction({ infoCompany, loadingInfoCompany: false })
      );
    });
};

export const addInfoCompanyAction = (info) => ({
  type: ADD_INFO_COMPANY,
  payload: info,
});

export const isLoadingInfoCompany = (isLoading) => ({
  type: ISLOADING_INFO_COMPANY,
  payload: isLoading,
});

export const addReourcesLoading = () => ({
  type: ADD_RESOURSE_LOADING,
});

export const addResourcesSuccess = (resources) => ({
  type: ADD_RESOURSE_SUCCESS,
  payload: resources,
});

export const addResourcesError = () => ({
  type: ADD_RESOURSE_ERROR,
});

export const selectedResourceAction = (resource) => ({
  type: SELECTED_RESOURCE,
  payload: resource,
});

export const addErrorCompanyAction = (errors) => ({
  type: ADD_ERROR_COMPANY,
  payload: errors,
});

export const removeResource = () => ({
  type: REMOVE_RESOURCE,
});

export const parseNotificationToArray = (data, typesNotification) => {
  let notifications = [];
  if (typeof data === "object") {
    let keys = Object.keys(data);
    Object.values(data).forEach((item, index) => {
      notifications.push({
        key: uuid(),
        type: typesNotification,
        msg: item,
        keyword: keys[index],
      });
    });
    return notifications;
  }
  return null;
};

export const updateCompanyLogoAction = (urlLogo) => ({
  type: UPDATE_INFO_COMPANY_LOGO,
  payload: urlLogo,
});

export const addResourceDeniedAction = (resource) => ({
  type: ADD_RESOURSE_DENIED,
  payload: resource,
});

export const addConfigResourcesAction = (configResources) => ({
  type: ADD_CONFIG_RESOURCES,
  payload: configResources
})
