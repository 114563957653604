import React, { useState, useCallback } from "react";

import { Badge, makeStyles, IconButton, Tooltip } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { palette } from "configurations/styles/theme";
import DialogNotifications from "./dialog";
import { useSelector } from "react-redux";
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "contents",
  },
  iconButton: {
    //flex: 0 0 auto;
    //color: rgba(0, 0, 0, 0.54);
    padding: '2px',
    transform: 'scale(1) translate(-250%, -350%)',
    //overflow: visible;
    /* font-size: 1.5rem; */
    /* text-align: center; */
    /* transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
    /* border-radius: 50%;*/
  }
}));

const IconButtonNotifications = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const lstNotifications = useSelector(
    (state) => state.notifications.lstNotifications
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <div className={classes.root}>
      <Tooltip title={`Notificaciones`}>
        <IconButton
          //className={lstNotifications.length > 0 ? classes.iconButton : ''}
          aria-controls={"menu-list-grow"}
          aria-haspopup="true"
        >

          <NotificationsNoneOutlinedIcon
            style={{ color: palette.primary.menuHeader }}
            onClick={handleClickOpen}
          />
          <Badge badgeContent={lstNotifications.length} color="primary" className={classes.iconButton} ></Badge>
        </IconButton>
      </Tooltip>

      <DialogNotifications
        open={open}
        onClose={handleClose}
        notifications={lstNotifications}
      />
    </div >
  );
};

export default IconButtonNotifications;
