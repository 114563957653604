import React, { useCallback, useEffect, useState } from 'react';

import { palette } from "configurations/styles/theme";
import { useSelector, useDispatch } from "react-redux";
import usePermissons from "hooks/usePermissons";
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import { Badge, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { getNumberVoucher } from 'redux/actions/numberVouchersAction';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "contents",
    },
    btnClose: {
        marginBottom: "1%",
    },
    large: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        marginTop: "0px",
    },
}));

export default function NumberVouchers() {

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const tokenUser = useSelector(state => state.user.tk);
    const dispatch = useDispatch();
    const getNumberVouchers = (resource) => dispatch(getNumberVoucher(resource));
    const { getConfigPermissons } = usePermissons(useSelector, dispatch);
    let configPermissons = getConfigPermissons() || [];
    configPermissons = configPermissons.filter(item => item.id === "CDI");

    const numberVoucher = useSelector(
        (state) => state.numberVouchers.numberVouchers
    );

    const configGetNumberVoucher = {
        uri: '/paquete/disponible/',
        tok: tokenUser ? tokenUser : '0',
    }


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    useEffect(() => {
        getNumberVouchers(configGetNumberVoucher)
    }, [numberVoucher])
    //badgeContent={typeof numberVoucher !== 'NaN' ? numberVoucher : ''}

    if (configPermissons.length === 0) {
        return null;
    }

    return (
        <Grid item xs={2} md={3} >
            <div className={classes.root}>
                <Badge color="primary">
                    <Tooltip title={`Comprobantes disponibles: ${typeof numberVoucher !== 'NaN' ? numberVoucher : ''}`}>
                        <IconButton
                            aria-controls={"menu-list-grow"}
                            aria-haspopup="true"
                        >
                            <ListAltOutlinedIcon
                                style={{ color: palette.primary.menuHeader }}
                                onClick={handleClickOpen}
                            />
                        </IconButton>
                    </Tooltip>
                </Badge>

                <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
                    <DialogTitle>Cantidad de comprobantes disponibles</DialogTitle>
                    <DialogContent>
                        <Grid container align="center" spacing={1} >
                            <Grid item xs={6} md={6}>
                                <label>Comprobantes disponibles:</label>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <input disabled value={typeof numberVoucher !== 'NaN' ? numberVoucher : ''} className='form-control' />
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <Grid container justify="center" align="center" direction="row">
                        <Grid item md={3} xs={12} className={classes.btnClose}>
                            <Button
                                onClick={handleClose}
                                color="secondary"
                                variant="outlined"
                                fullWidth
                            >
                                Cerrar
                        </Button>
                        </Grid>
                    </Grid>
                </Dialog>

            </div>
        </Grid>
    );
}