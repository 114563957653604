import React, { useEffect, useState } from "react";
import { makeStyles, Paper, CircularProgress } from "@material-ui/core";
import { palette, useStylesGlobal } from "configurations/styles/theme";
import { useDispatch, useSelector } from "react-redux";
import {
  AddCostumerAction,
  SaveCacheAction,
  addRUCAction,
  AddUserAction,
  changeErrorLabelsAction,
} from "redux/actions/debitNoteActions";
import ClientForm from "../General/ClientForm";
import { getRUCEmpresa, getSub } from "redux/actions/userActions";
import FormTaxes from "./Forms/FormTaxes";
import FormReason from "./Forms/FormsReasons";
import DataDebitNote from "./Forms/DataDebitNote";


const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "30px",
    flexGrow: 1,
    paddingLeft: "3%",
    paddingRight: "3%",
  },
  container: {
    marginTop: "1%",
  },
  msgDialog: {
    padding: "2%",
  },

  containerDebitNote: {
    borderRadius: "8px",
    borderStyle: "solid",
    borderWidth: "1px",
    boxShadow: "5px 3px 3px #aaaaaa",
    flexGrow: 1,
    marginBottom: "1%",
    padding: "0% 0% 0% 0%",
  },
  formTaxes: {
    borderStyle: "dashed",
    borderTopWidth: "0px",
    borderLeftWidth: "0px",
    borderLeftColor: palette.primary.light,
    borderRightWidth: "1px",
    borderBottomWidth: "0px",
    paddingRight: "2%",
    marginRight: "2%",
    marginTop: "2%",
  },
  FormReason: {
    marginTop: "2%",
  },
}));

const DebitNote = () => {
  const classesView = useStyles();
  const classesGlobales = useStylesGlobal();
  const tokenUser = useSelector((state) => state.user.tk);
  const formCostumer = useSelector((state) => state.debitNote.formCostumer);
  const debitNote = useSelector((state) => state.debitNote);
  const dispatch = useDispatch();
  const saveCache = (cache) => dispatch(SaveCacheAction(cache));
  const changeErrors = (errors) => dispatch(changeErrorLabelsAction(errors));
  const addCostumer = (costumer) => {
    changeErrors({ idAsignacionEmpresaCliente: null });
    dispatch(AddCostumerAction(costumer));
  };

  const [update, setUpdate] = useState(false);

  useEffect(() => {
    const addRuc = (ruc) => dispatch(addRUCAction(ruc));
    const addUser = (user) => dispatch(AddUserAction(user));
    if (tokenUser) {
      addRuc(getRUCEmpresa(tokenUser));
      addUser(getSub(tokenUser));
    }
  }, [tokenUser]);

  useEffect(() => {
    if (update) {
      setUpdate(false);
    }
  }, [update]);

  const handleUpdateView = () => {
    setUpdate(true);
  };

  return (
    <section className={classesView.root}>
      <Paper className={classesGlobales.paperHeader}>
        <h5 className={classesGlobales.titleHeader}>
          Emisión de Nota de Débito
        </h5>
      </Paper>
      <div className={classesView.container}>
        {update ? (
          <CircularProgress color="primary" />
        ) : (
          <>
            <ClientForm
              id="client-data"
              stateRedux={formCostumer}
              action={addCostumer}
              saveCache={saveCache}
              ErrorRedux={debitNote.errors.idAsignacionEmpresaCliente}
            />
            <DataDebitNote />
            <FormTaxes />
            <FormReason onClean={handleUpdateView} />
          </>
        )}
      </div>
    </section>
  );
};

export default DebitNote;
