import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  makeStyles,
  Backdrop,
  CircularProgress,
  FormControl,
  FormControlLabel,
  NativeSelect,
  withStyles,
  InputBase,
  TextField,
  Checkbox,
  Divider,
  RadioGroup,
  Radio,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
} from "@material-ui/core";
import {
  palette,
  useStylesGlobal,
  styleInput,
} from "configurations/styles/theme";
import { Alert, Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useStylesApp } from "hooks/useStyles";
import { getRUCEmpresa } from "redux/actions/userActions";
import clientBackend from "configurations/axios";
import { httpRepo } from "configurations/axios";
import bigDecimal from "js-big-decimal";
import {
  validationReimbursement,
  validationVoucher,
  calculateDiscount,
} from "../validations";
import AddProductsBtn from "views/administración/productsServices/Buttons/AddProduct";
import checkInputs from "utils/checkInputs";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";

import {
  reimbursementDocumentType,
  providerType,
} from "../../../Forms/typeVoucher";
import EditTableImpuestos from "widgets/Tables/EditTableImpuesto";

import EditIcon from "@material-ui/icons/Edit";
import AddProviderBtn from "views/administración/providers/Buttons/AddProvider";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(3),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  divForm: {
    marginBottom: "1%",
  },
  divInput: {
    marginBottom: "1%",
    paddingRight: "1%",
    paddingLeft: "0.5%",
  },
  divButton: {
    paddingLeft: "3%",
    paddingRight: "3%",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    marginTop: "1%",
    marginBottom: "0.5%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(0),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const EditReimbursementDetailBtn = ({
  data,
  reimbursementDetail,
  reimbursementDetailEdited,
  setReimbursementDetailEdited,
  setFlag,
  additionalInfoInvoice,
  setAdditionalInfoInvoice,
  additionalInfoInvoiceEdited,
  setAdditionalInfoInvoiceEdited,
  viewDetalleReimbursement,
  setViewDetalleReimbursement,
}) => {
  const [dataReimbursement, setDataReimbursement] = useState(data);
  const [dataAdditionalInfoInvoice, setDataAdditionalInfoInvoice] = useState(
    additionalInfoInvoiceEdited
  );
  const [originalProvider, setOriginalProvider] = useState(data.provider);
  const [originalTaxes, setOriginalTaxes] = useState(data.impuestos);

  const dateToday = new Date();
  dateToday.setDate(dateToday.getDate() - 30);
  //para la fecha del comprobante
  const [fechaEmision, setFechaEmision] = useState(new Date());

  const classes = useStyles();
  const classesGlobales = useStylesGlobal();
  const classesApp = useStylesApp();
  const [dataAditional, setDataAditional] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const userToken = useSelector((state) => state.user.tk);
  const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
  const { enqueueSnackbar } = useSnackbar();
  const [product, setProduct] = useState({
    descuentoProducto: "",
    cantidadProducto: "1",
    isDescuentoPorcentaje: "false",
    valorDescuento: "",
  });
  const [iva, setIva] = useState("2");
  const [ice, setIce] = useState();
  const [options, setOptions] = useState([]);
  const [foundProduct, setFoundProduct] = useState({
    codigoAuxiliar: "",
    codigoPrincipal: "",
    descripcion: "",
    estado: "",
    idProducto: "",
    precioUnitario: "",
  });
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const loading = openAutocomplete && options.length === 0;
  const [empty, setEmty] = useState(true);
  const [emptyCodigo, setEmtyCodigo] = useState(true);
  const [emptyPrecio, setEmtyPrecio] = useState(true);
  const [isAditional, setIsAdditional] = useState(false);
  const [errors, setErrors] = useState({});
  const [existProduct, setExistProduct] = useState(false);
  const [isDescuentoPorcentaje, setIsDescuentoPorcentaje] = useState("false");

  // reimbursement
  const [reimbursement, setReimbursement] = useState([]);
  const [fetchProvider, setFetchProvider] = useState([]);
  const [isThereProvider, setIsThereProvider] = useState(false);
  const [foundProvider, setFoundProvider] = useState({
    idAsignacionEmpresaCliente: "",
    nombres: "",
    direccion: "",
    correo: "",
    telefono: "",
    tipo: "",
    estado: "",
    fechaRegistro: "",
    cliente: {
      idCliente: "",
      fechaRegistro: "",
      nombres: "",
    },
  });

  const [dataAditionalImpuestos, setDataAditionalImpuestos] = useState([]);
  const [docAdditionalInfo, setDocAdditionalInfo] = useState([]);
  const [codDocReimbursement, setCodDocReimbursement] = useState("");

  const compareObject = (data, prod) => {
    var aKeys = Object.keys(data).sort();
    var bKeys = Object.keys(prod).sort();
    if (aKeys.length !== bKeys.length) {
      return false;
    }
    if (aKeys.join("") !== bKeys.join("")) {
      return false;
    }
    for (var i = 0; i < aKeys.length; i++) {
      if (data[aKeys[i]] !== prod[bKeys[i]]) {
        return false;
      }
    }
    return true;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    clearData();
    setOpen(false);
  };

  // const handleAddProvider = () => {
  //   // setReimbursement({...reimbursement, impuestos:dataAditionalImpuestos})
  //   setReimbursementDetail([
  //     ...reimbursementDetail,
  //     {
  //       ...reimbursement,
  //       fechaEmisionDocReembolso: fechaEmision,
  //       impuestos: dataAditionalImpuestos,
  //     },
  //   ]);
  //   setOpen(false);
  // };

  const getAcronymDocReimbursement = (test) => {
    switch (test) {
      case "01":
        return "FAC";
      case "03":
        return "LCP";
      case "04":
        return "NDC";
      case "05":
        return "NDB";
      case "06":
        return "GDR";
      case "07":
        return "CDR";
      case "08":
        return "BEP";
      case "09":
        return "TMR";
      case "11":
        return "PEA";
      case "12":
        return "DIF";
      case "15":
        return "CEE";
      case "16":
        return "FUE";
      case "18":
        return "DAE";
      case "19":
        return "CPC";
      case "20":
        return "DSA";
      case "21":
        return "CPA";
      case "22":
        return "REC";
      case "23":
        return "CTC";
      case "24":
        return "DTC";
      case "41":
        return "CVR";
      case "42":
        return "DPE";
      case "43":
        return "LEH";
      case "44":
        return "CCA";
      case "45":
        return "LRA";
      case "47":
        return "NCI";
      case "48":
        return "NDI";
      case "49":
        return "PEE";
      case "50":
        return "PIA";
      case "51":
        return "PNC";
      case "52":
        return "PND";
      case "294":
        return "LBM";
      case "344":
        return "LVU";
      case "364":
        return "ERP";
      case "370":
        return "FOT";
      case "371":
        return "CSO";
      case "372":
        return "NCO";
      case "373":
        return "NDO";
      case "374":
        return "NDO";
      case "375":
        return "LRB";
    }
  };

  const handleUpdateReimbursement = () => {
    // setReimbursementDetailEdited(data);

    // setReimbursementDetailEdited({
    //   ...dataReimbursement,
    //   impuestos: originalTaxes,
    // });

    setAdditionalInfoInvoiceEdited({
      id: data.id,
      nombre:
        getAcronymDocReimbursement(dataReimbursement.codDocReembolso) +
        " " +
        dataReimbursement.estabDocReembolso +
        "-" +
        dataReimbursement.ptoEmiDocReembolso +
        "-" +
        dataReimbursement.secuencialDocReembolso +
        " - " +
        dataReimbursement.provider.nombres,

      valor: dataReimbursement.detalleReembolso,
    });

    // productsDetalle.map(function updateProducts(prod, index, array) {
    //   if (compareObject(data, prod)) {
    //     productsDetalle.splice(index, 1, updateProduct);
    //     detalles.splice(index, 1, updateDetalle);

    //     enqueueSnackbar(`Detalle editado`, {
    //       variant: "success",
    //       anchorOrigin: {
    //         vertical: "top",
    //         horizontal: "right",
    //       },
    //       autoHideDuration: 4000,
    //     });
    //   }
    // });
    reimbursementDetail.map((reimb, index, array) => {
      if (compareObject(data, reimb)) {
        reimbursementDetail.splice(index, 1, {
          ...dataReimbursement,
          fechaEmisionDocReembolso: fechaEmision,
          impuestos: originalTaxes,
        });
        additionalInfoInvoice.splice(index, 1, {
          id: data.id,
          nombre:
            getAcronymDocReimbursement(dataReimbursement.codDocReembolso) +
            " " +
            dataReimbursement.estabDocReembolso +
            "-" +
            dataReimbursement.ptoEmiDocReembolso +
            "-" +
            dataReimbursement.secuencialDocReembolso +
            " - " +
            dataReimbursement.provider.nombres,

          valor: dataReimbursement.detalleReembolso,
        });
      }
    });
    setFlag(true);
    setOpen(false);
    // if (validateDocSustento()) {
    //   // docSustento is the current register
    //   // array is the set of register
    //   docSustentoDetail.map((ds, index, array) => {
    //     if (compareObject(data, ds)) {
    //       try {
    //         const aux = [ds];
    //         docsSustento.splice(index, 1, {
    //           ...ds,
    //           codigoDocSustento: dataDocSustento.codigoDocSustento,
    //           fechaEmisionDocSustento: dataDocSustento.fechaEmisionDocSustento,
    //           numeroDocSustento: dataDocSustento.numeroDocSustento,
    //           codSustento: dataDocSustento.codSustento,
    //         });

    //         docsSustento[index].pagos = dataAditionalPagos;
    //         docsSustento[index].impuestoRetencions = dataAditional;
    //         docsSustento[index].impuestos = dataAditionalImpuestos;
    //         docSustentoDetail.splice(index, 1, dataDocSustento);
    //         docSustentoDetail[index].pagos = dataAditionalPagos;
    //         docSustentoDetail[index].impuestoRetencions = dataAditional;
    //         docSustentoDetail[index].impuestos = dataAditionalImpuestos;

    //         enqueueSnackbar(`Impuesto editado`, {
    //           variant: "success",
    //           anchorOrigin: {
    //             vertical: "top",
    //             horizontal: "right",
    //           },
    //           autoHideDuration: 4000,
    //         });

    //         if (viewDetalle === 1) {
    //           setViewDetalle(2);
    //         } else {
    //           setViewDetalle(1);
    //         }
    //         handleClose();
    //       } catch (error) {
    //         console.log(error);
    //       }
    //     }
    //   });
    // }
    enqueueSnackbar(`Detalle de reembolso actualizado exitosamente`, {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      autoHideDuration: 4000,
    });
  };

  const handleChangeCheckDesc = (event) => {
    setIsDescuentoPorcentaje(event.target.value);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { error, value, keyWordError } = validationReimbursement.onChange(
      event.target.name,
      event.target.value
    );

    switch (event.target.name) {
      case "codPais":
        if (
          keyWordError !== "isNotDecimal" &&
          keyWordError !== "isLong" &&
          checkInputs.countDecimals(event.target.value.toString().trim()) <= 6
        ) {
          setDataReimbursement({
            ...dataReimbursement,
            [event.target.name]: event.target.value,
          });
        }
        setErrors({ ...errors, [event.target.name]: error });
        break;
      case "estabDocReembolso":
        if (
          keyWordError !== "isNotNumeric" &&
          keyWordError !== "isLong" &&
          checkInputs.countDecimals(event.target.value.toString().trim()) <= 3
        ) {
          setDataReimbursement({
            ...dataReimbursement,
            [event.target.name]: event.target.value,
          });
        }
        setErrors({ ...errors, [event.target.name]: error });
        break;
      case "ptoEmiDocReembolso":
        if (
          keyWordError !== "isNotNumeric" &&
          keyWordError !== "isLong" &&
          checkInputs.countDecimals(event.target.value.toString().trim()) <= 3
        ) {
          setDataReimbursement({
            ...dataReimbursement,
            [event.target.name]: event.target.value,
          });
        }
        setErrors({ ...errors, [event.target.name]: error });
        break;
      case "secuencialDocReembolso":
        if (
          keyWordError !== "isNotNumeric" &&
          keyWordError !== "isLong" &&
          checkInputs.countDecimals(event.target.value.toString().trim()) <= 9
        ) {
          setDataReimbursement({
            ...dataReimbursement,
            [event.target.name]: event.target.value,
          });
        }
        setErrors({ ...errors, [event.target.name]: error });
        break;
      case "numeroautorizacionDocReembolso":
        if (
          keyWordError !== "isNotNumeric" &&
          keyWordError !== "isLong" &&
          checkInputs.countDecimals(event.target.value.toString().trim()) <= 49
          //   &&
          // typeof searchInvoices(event.target.value) === "undefined"
        ) {
          setDataReimbursement({
            ...dataReimbursement,
            [event.target.name]: event.target.value,
          });
        }
        setErrors({ ...errors, [event.target.name]: error });
        break;
      case "detalleReembolso":
        setDataReimbursement({
          ...dataReimbursement,
          [event.target.name]: event.target.value,
        });
        setErrors({ ...errors, [event.target.name]: error });
        break;
      default:
        break;
    }

    // event.preventDefault();
    // const { error, value, keyWordError } = validationVoucher.onChange(
    //   event.target.name,
    //   event.target.value
    // );
    // switch (event.target.name) {
    //   case "codPais":
    //   case "estabDocReembolso":
    //   case "ptoEmiDocReembolso":
    //   case "secuencialDocReembolso":
    //   case "numeroautorizacionDocReembolso":
    //   case "detalleReembolso":
    //     setDataReimbursement({
    //       ...dataReimbursement,
    //       [event.target.name]: event.target.value,
    //     });
    //     setErrors({ ...errors, [event.target.name]: error });
    //     break;
    //   default:
    //     break;
    // }
  };

  const handleChangeCheck = (event) => {
    setIsAdditional(event.target.checked);
  };

  function closeAlert() {
    setMsg({ show: false });
  }

  const searchInvoices = async (params) => {
    const response = await httpRepo.get(
      `/comprobantes/autorizados/busqueda/validar/comprobante/${
        userToken ? getRUCEmpresa(userToken) : ""
      }?numeroAutorizacion=${params}`
    );
    // console.log(response);
    // console.table(response.data);
  };

  // método para la búsqueda
  const searchProviders = async (params) => {
    await clientBackend
      .get(
        `/admin/proveedores/buscar/activos/${
          userToken ? getRUCEmpresa(userToken) : ""
        }?parametroBusqueda=${params}`
      )
      .then((response) => {
        if (response.status === 200) {
          if (Array.isArray(response.data)) {
            if (response.data.length > 0) {
              console.log(response.data);
              setFetchProvider(response.data);
              setIsThereProvider(false);
            } else {
              setFetchProvider([
                {
                  idAsignacionEmpresaCliente: "",
                  // descripcion:
                  //   "No se encontró datos con el argumento ingresado",
                },
              ]);
              setIsThereProvider(true);
            }
          }
          setOpen(true);
        }
      })
      .catch((exception) => {
        if (exception.response) {
          setOptions([
            {
              codigoPrincipal: "",
              descripcion: "No se encontró datos con el argumento ingresado",
            },
          ]);
          setExistProduct(true);
        } else {
          setOptions([
            {
              codigoPrincipal: "",
              descripcion: "Error en la red al consultar el producto.",
            },
          ]);
        }
      });
  };

  const validateDiscount = (valorDiscount) => {
    if (product.descuentoProducto.trim() !== "" && Number(valorDiscount) > 0) {
      if (validateDecimal(product.descuentoProducto.toString().trim())) {
        let totalPrice =
          Number(foundProduct.precioUnitario) *
          Number(product.cantidadProducto);
        totalPrice = bigDecimal.round(
          totalPrice,
          2,
          bigDecimal.RoundingModes.HALF_UP
        );
        if (Number(valorDiscount) <= Number(totalPrice)) {
          return true;
        } else {
          enqueueSnackbar(`El descuento debe ser menor a ${totalPrice}`, {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 4000,
          });
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const validateDecimal = (numberValor) => {
    let splitList = numberValor.split(".");
    if (splitList.length > 2) {
      enqueueSnackbar(
        `El dato ingresado es erróneo utilice punto (.) para separar decimales`,
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 4000,
        }
      );
      return false;
    } else if (splitList.length > 1) {
      if (splitList[1].length > 2) {
        enqueueSnackbar(
          `Los datos numéricos debe tener máximo 2 decimales Ej, 12.43`,
          {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 4000,
          }
        );
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const clearData = () => {
    setEmtyCodigo(true);
    setEmty(true);
    setEmtyPrecio(true);
    setProduct({
      descuentoProducto: "",
      cantidadProducto: "1",
      isDescuentoPorcentaje: "false",
    });
    setIce("");
    setFoundProduct({
      codigoAuxiliar: "",
      codigoPrincipal: "",
      descripcion: "",
      estado: "",
      idProducto: "",
      precioUnitario: "",
    });
    setDataAditional([]);
    setOptions([]);
    setIsAdditional(false);
    setErrors({});
  };

  const updateView = () => {};

  const handleBugs = () => {
    console.log(data);
    console.log(dataReimbursement);
    console.log(originalTaxes);

    console.log("OTHER:");
    console.log(dataReimbursement.reimbursementTypeDocument.DESCRIPTION);
  };

  const getReimbursementTypeDocument = (code) => {
    const response = reimbursementDocumentType.find(
      (reim) => reim.CODE === code
    );
    return response;
  };

  const getProviderType = (code) => {
    const response = providerType.find((provider) => provider.CODE === code);
    return response;
  };

  return (
    <Fragment>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={handleClickOpen}
      >
        <EditIcon style={{ color: palette.info.dark }} />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="max-width-dialog-title">
          Actualizar detalle de reembolso
        </DialogTitle>
        <DialogContent>
          <form>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {/* <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleBugs}
                  >
                    Anti bugs
                  </Button> */}
                  <Grid item xs={12} md={12}>
                    <label>
                      Los campos marcados con ({" "}
                      <font color={palette.error.main}> *</font> ) son
                      obligatorios:
                    </label>
                  </Grid>
                  {msgError.show ? (
                    <Alert
                      tipo={msgError.type}
                      mensaje={msgError.msg}
                      mesajeSubrayado={null}
                      titulo={null}
                      mostrar={msgError.show}
                      cerrarAlerta={closeAlert}
                    />
                  ) : null}
                  {isThereProvider ? (
                    <Fragment>
                      <Grid item xs={12} md={12} className={classes.divForm}>
                        <Divider />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        align="center"
                        className={classes.divForm}
                      >
                        <AddProviderBtn updateView={updateView} />
                        {/* <AddProductsBtn updateView={updateView} /> */}
                      </Grid>
                      <Grid item xs={12} md={12} className={classes.divForm}>
                        <Divider />
                      </Grid>
                    </Fragment>
                  ) : null}

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>Nombre del proveedor:</label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <input
                      className={styleInput(false, true)}
                      type="text"
                      disabled
                      value={dataReimbursement.provider.nombres}
                    />
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Tipo documento reembolso:
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <FormControl className={classesGlobales.select}>
                      <NativeSelect
                        value={dataReimbursement.codDocReembolso}
                        onChange={(event) => {
                          // setCodDocReimbursement(
                          //   getAcronymDocReimbursement(event.target.value)
                          // );
                          switch (event.target.value) {
                            case "01":
                              setCodDocReimbursement("FAC");
                              break;
                            case "03":
                              setCodDocReimbursement("LCP");
                              break;
                            case "04":
                              setCodDocReimbursement("NDC");
                              break;
                            case "05":
                              setCodDocReimbursement("NDB");
                              break;
                            case "06":
                              setCodDocReimbursement("GDR");
                              break;
                            case "07":
                              setCodDocReimbursement("CDR");
                              break;
                            case "08":
                              setCodDocReimbursement("BEP");
                              break;
                            case "09":
                              setCodDocReimbursement("TMR");
                              break;
                            case "11":
                              setCodDocReimbursement("PEA");
                              break;
                            case "12":
                              setCodDocReimbursement("DIF");
                              break;
                            case "15":
                              setCodDocReimbursement("CEE");
                              break;
                            case "16":
                              setCodDocReimbursement("FUE");
                              break;
                            case "18":
                              setCodDocReimbursement("DAE");
                              break;
                            case "19":
                              setCodDocReimbursement("CPC");
                              break;
                            case "20":
                              setCodDocReimbursement("DSA");
                              break;
                            case "21":
                              setCodDocReimbursement("CPA");
                              break;
                            case "22":
                              setCodDocReimbursement("REC");
                              break;
                            case "23":
                              setCodDocReimbursement("CTC");
                              break;
                            case "24":
                              setCodDocReimbursement("DTC");
                              break;
                            case "41":
                              setCodDocReimbursement("CVR");
                              break;
                            case "42":
                              setCodDocReimbursement("DPE");
                              break;
                            case "43":
                              setCodDocReimbursement("LEH");
                              break;
                            case "44":
                              setCodDocReimbursement("CCA");
                              break;
                            case "45":
                              setCodDocReimbursement("LRA");
                              break;
                            case "47":
                              setCodDocReimbursement("NCI");
                              break;
                            case "48":
                              setCodDocReimbursement("NDI");
                              break;
                            case "49":
                              setCodDocReimbursement("PEE");
                              break;
                            case "50":
                              setCodDocReimbursement("PIA");
                              break;
                            case "51":
                              setCodDocReimbursement("PNC");
                              break;
                            case "52":
                              setCodDocReimbursement("PND");
                              break;
                            case "294":
                              setCodDocReimbursement("LBM");
                              break;
                            case "344":
                              setCodDocReimbursement("LVU");
                              break;
                            case "364":
                              setCodDocReimbursement("ERP");
                              break;
                            case "370":
                              setCodDocReimbursement("FOT");
                              break;
                            case "371":
                              setCodDocReimbursement("CSO");
                              break;
                            case "372":
                              setCodDocReimbursement("NCO");
                              break;
                            case "373":
                              setCodDocReimbursement("NDO");
                              break;
                            case "374":
                              setCodDocReimbursement("NDO");
                              break;
                            case "375":
                              setCodDocReimbursement("LRB");
                              break;
                            default:
                              break;
                          }
                          setDataReimbursement({
                            ...dataReimbursement,
                            reimbursementTypeDocument:
                              getReimbursementTypeDocument(event.target.value),
                            codDocReembolso: event.target.value,
                          });
                          setReimbursementDetailEdited({
                            ...dataReimbursement,
                            reimbursementTypeDocument:
                              getReimbursementTypeDocument(event.target.value),
                            codDocReembolso: event.target.value,
                          });
                          // setDataDocSustento({
                          //   ...dataDocSustento,
                          //   ["codSustento"]: event.target.value
                          //     ? event.target.value
                          //     : "",
                          // });
                        }}
                        input={<BootstrapInput />}
                      >
                        <option aria-label="None" value="">
                          --Seleccione--
                        </option>
                        {reimbursementDocumentType.map((voucher) => (
                          <option key={voucher.CODE} value={voucher.CODE}>
                            {`${voucher.CODE} - ${voucher.DESCRIPTION}`}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>

                    {errors.codDocReembolso ? (
                      <p className={classesApp.errorLetters}>
                        {errors.codDocReembolso}
                      </p>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Tipo proveedor: <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <FormControl className={classesGlobales.select}>
                      <NativeSelect
                        value={dataReimbursement.tipoProveedorReembolso}
                        onChange={(event) => {
                          // if (event.target.value.trim() === "12") {
                          //   setEmty({ ...empty, ["numero"]: true });
                          // }
                          setDataReimbursement({
                            ...dataReimbursement,
                            providerType: getProviderType(event.target.value),
                            tipoProveedorReembolso: event.target.value,
                          });
                          setReimbursementDetailEdited({
                            ...dataReimbursement,
                            providerType: getProviderType(event.target.value),
                            tipoProveedorReembolso: event.target.value,
                          });
                          // setDataDocSustento({
                          //   ...dataDocSustento,
                          //   ["codSustento"]: event.target.value
                          //     ? event.target.value
                          //     : "",
                          // });
                        }}
                        input={<BootstrapInput />}
                      >
                        <option aria-label="None" value="">
                          --Seleccione--
                        </option>
                        {providerType.map((voucher) => (
                          <option key={voucher.CODE} value={voucher.CODE}>
                            {`${voucher.CODE} - ${voucher.DESCRIPTION}`}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      País pago proveedor:
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <input
                      className={styleInput(
                        errors.codPais,
                        dataReimbursement.codPais
                      )}
                      type="number"
                      placeholder="Ej. 593"
                      name="codPais"
                      value={dataReimbursement.codPais}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    {errors.codPais ? (
                      <p className={classesApp.errorLetters}>
                        {errors.codPais}
                      </p>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Establecimiento Doc. Reembolso:
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <input
                      className={styleInput(
                        errors.estabDocReembolso,
                        dataReimbursement.estabDocReembolso
                      )}
                      type="number"
                      placeholder="Ej. 001"
                      name="estabDocReembolso"
                      value={dataReimbursement.estabDocReembolso}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    {errors.estabDocReembolso ? (
                      <p className={classesApp.errorLetters}>
                        {errors.estabDocReembolso}
                      </p>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Punto de emisión:
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <input
                      className={styleInput(
                        errors.ptoEmiDocReembolso,
                        dataReimbursement.ptoEmiDocReembolso
                      )}
                      type="number"
                      placeholder="Ej. 001"
                      name="ptoEmiDocReembolso"
                      value={dataReimbursement.ptoEmiDocReembolso}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    {errors.ptoEmiDocReembolso ? (
                      <p className={classesApp.errorLetters}>
                        {errors.ptoEmiDocReembolso}
                      </p>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Secuencial: <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <input
                      className={styleInput(
                        errors.secuencialDocReembolso,
                        dataReimbursement.secuencialDocReembolso
                      )}
                      type="number"
                      placeholder="Ej. 00000001"
                      name="secuencialDocReembolso"
                      value={dataReimbursement.secuencialDocReembolso}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    {errors.secuencialDocReembolso ? (
                      <p className={classesApp.errorLetters}>
                        {errors.secuencialDocReembolso}
                      </p>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>Fecha emisión:</label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={esLocale}
                    >
                      <DatePicker
                        className={
                          fechaEmision
                            ? "form-control  is-valid"
                            : "form-control "
                        }
                        disableToolbar
                        autoOk
                        name="fechaEmisionDocReembolso"
                        disableFuture
                        // minDate={dateToday}
                        variant="dialog"
                        inputVariant="outlined"
                        okLabel="Seleccionar"
                        cancelLabel="Cancelar"
                        format="dd-MM-yyyy"
                        margin="normal"
                        value={fechaEmision}
                        onChange={setFechaEmision}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Número autorización:
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <input
                      className={styleInput(
                        errors.numeroautorizacionDocReembolso,
                        dataReimbursement.numeroautorizacionDocReembolso
                      )}
                      type="number"
                      placeholder="Ingresar al menos 10 dígitos"
                      name="numeroautorizacionDocReembolso"
                      value={dataReimbursement.numeroautorizacionDocReembolso}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    {errors.numeroautorizacionDocReembolso ? (
                      <p className={classesApp.errorLetters}>
                        {errors.numeroautorizacionDocReembolso}
                      </p>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} md={4} className={classes.divForm}>
                    <label>
                      Descripción:
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divInput}>
                    <textarea
                      className={styleInput(
                        errors.detalleReembolso,
                        dataReimbursement.detalleReembolso
                      )}
                      type="text"
                      rows={foundProvider.name !== "" ? "7" : "5"}
                      placeholder="Ingresar el detalle del reembolso"
                      name="detalleReembolso"
                      value={dataReimbursement.detalleReembolso}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    {errors.detalleReembolso ? (
                      <p className={classesApp.errorLetters}>
                        {errors.detalleReembolso}
                      </p>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12}>
                <Divider />
              </Grid>

              <Grid item xs={12} md={12}>
                <Grid
                  container
                  xs={12}
                  md={12}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid
                    container
                    xs={12}
                    md={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {/* {dataAditionalImpuestos.length<0 ? (
                    <p className={classesApp.errorLetters}>
                      {errors.impuestos}
                    </p>
                  ) : null} */}
                  </Grid>
                  <EditTableImpuestos
                    // key={dataReimbursement.codigoDocSustento}
                    dataAditionalImpuestos={originalTaxes}
                    setDataAditionalImpuestos={setOriginalTaxes}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} md={12}>
                <Divider />
              </Grid>

              <Grid item xs={12} md={12} className={classes.divButton}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateReimbursement}
                  className={classesGlobales.button}
                >
                  Actualizar
                </Button>

                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  className={classesGlobales.button}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
};

export default EditReimbursementDetailBtn;
