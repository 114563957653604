import checkInputs from "utils/checkInputs";
import { numDecimales } from "configurations/constant";

const validations = {
  editProduct: function validationEditProduct(updatedProduct) {
    const errors = {};
    if (!checkInputs.notNull(updatedProduct.codigoPrincipal)) {
      errors.codigoPrincipal = "El Código principal es necesario.";
    } else if (!checkInputs.isAlphanumeric(updatedProduct.codigoPrincipal)) {
      errors.codigoPrincipal = "No ingrese caracteres especiales.  ('<', '>')";
    } else if (updatedProduct.codigoPrincipal.length > 300) {
      errors.codigoPrincipal = "No ingrese más de 300 caracteres.";
    }

    if (checkInputs.notNull(updatedProduct.codigoAuxiliar)) {
      if (!checkInputs.isAlphanumeric(updatedProduct.codigoAuxiliar)) {
        errors.codigoAuxiliar = "No ingrese caracteres especiales ('<', '>').";
      } else if (updatedProduct.codigoAuxiliar.length > 300) {
        errors.codigoAuxiliar = "No ingrese más de 300 caracteres.";
      }
    }

    if (!checkInputs.notNull(updatedProduct.descripcion)) {
      errors.descripcion = "La descripción es necesaria.";
    } else if (!checkInputs.isAlphanumeric(updatedProduct.descripcion)) {
      errors.descripcion = "No ingrese caracteres especiales.  ('<', '>')";
    } else if (updatedProduct.descripcion.length > 300) {
      errors.descripcion = "No ingrese más de 300 caracteres.";
    }

    if (!checkInputs.notNull(updatedProduct.precioUnitario.toString())) {
      errors.precioUnitario = "El precio unitario es necesario.";
    } else if (
      !checkInputs.isDecimal(updatedProduct.precioUnitario.toString())
    ) {
      errors.precioUnitario = "No ingrese caracteres especiales.";
    } else if (
      checkInputs.countDecimals(updatedProduct.precioUnitario) > numDecimales
    ) {
      errors.precioUnitario = `No ingrese más de ${numDecimales} decimales.`;
    } else if (
      checkInputs.countLenth(updatedProduct.precioUnitario) > 14 ||
      Number(updatedProduct.precioUnitario) < 0
    ) {
      errors.precioUnitario = "Ingrese un valor mayor 0 y máximo 14 dígitos";
    }

    return errors;
  },
  addProduct: function validationAddProduct(newProduct) {
    const errors = {};

    if (!checkInputs.notNull(newProduct.codigoPrincipal)) {
      errors.codigoPrincipal = "El Código principal es necesario.";
    } else if (!checkInputs.isAlphanumeric(newProduct.codigoPrincipal)) {
      errors.codigoPrincipal = "No ingrese caracteres especiales.  ('<', '>')";
    } else if (newProduct.codigoPrincipal.length > 300) {
      errors.codigoPrincipal = "No ingrese más de 300 caracteres.";
    }

    if (checkInputs.notNull(newProduct.codigoAuxiliar)) {
      if (!checkInputs.isAlphanumeric(newProduct.codigoAuxiliar)) {
        errors.codigoAuxiliar = "No ingrese caracteres especiales.  ('<', '>')";
      } else if (newProduct.codigoAuxiliar.length > 300) {
        errors.codigoAuxiliar = "No ingrese más de 300 caracteres.";
      }
    }

    if (!checkInputs.notNull(newProduct.descripcion)) {
      errors.descripcion = "La descripción es necesaria.";
    } else if (!checkInputs.isAlphanumeric(newProduct.descripcion)) {
      errors.descripcion = "No ingrese caracteres especiales.  ('<', '>')";
    } else if (newProduct.descripcion.length > 300) {
      errors.descripcion = "No ingrese más de 300 caracteres.";
    }

    if (!checkInputs.notNull(newProduct.precioUnitario)) {
      errors.precioUnitario = "El precio unitario es necesario.";
    } else if (!checkInputs.isDecimal(newProduct.precioUnitario)) {
      errors.precioUnitario = "No ingrese caracteres especiales.";
    } else if (newProduct.precioUnitario.length > 300) {
      errors.precioUnitario = "No ingrese más de 300 caracteres.";
    } else if (
      checkInputs.countDecimals(newProduct.precioUnitario) > numDecimales
    ) {
      errors.precioUnitario = `No ingrese más de ${numDecimales} decimales.`;
    } else if (
      checkInputs.countLenth(newProduct.precioUnitario) > 14 ||
      Number(newProduct.precioUnitario) < 0
    ) {
      errors.precioUnitario = "Ingrese un valor mayor 0 y máximo 14 dígitos";
    }

    return errors;
  },
  changeProduct: function validationChangeProduct(nameInput, valueInput) {
    let value = valueInput;
    switch (nameInput) {
      case "codigoPrincipal":
        if (checkInputs.isAlphanumeric(value)) {
          return { isValid: true, value: value.trim() };
        } else {
          return { isValid: false, value: value.trim() };
        }
      case "codigoAuxiliar":
        if (checkInputs.isAlphanumeric(value)) {
          return { isValid: true, value: value };
        } else {
          return { isValid: false, value: value };
        }
      case "descripcion":
        if (checkInputs.isAlphanumeric(value)) {
          return { isValid: true, value: value };
        } else {
          return { isValid: false, value: value };
        }
      case "precioUnitario":
        if (value === "") {
          return { isValid: true, value };
        } else if (checkInputs.isDecimal(value)) {
          return { isValid: true, value };
        } else if (checkInputs.countDecimals(value) > numDecimales) {
          return { isValid: false, value };
        } else {
          return { isValid: false, value };
        }

      default:
        return { isValid: true, value };
    }
  },
};

export default validations;
