import { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
//axios
import { httpRepo, clientBackendZIP } from 'configurations/axios';

import { API_GET_SEARCH_AUTHORIZED, API_GET_VOUCHER_AUTHORIZED } from "../apis";
import { useSelector } from 'react-redux';

import { getRUCEmpresa } from 'redux/actions/userActions';

import AuthorizedIssuedVouchersContext, { ACTIONS_TYPES } from "./index";


const fileSaver = require("file-saver");

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '50px',
        flexGrow: 1,
        paddingLeft: '3%',
        paddingRight: '3%',
        marginBottom: '50px'
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    backdrop: {
        zIndex: "3000",
        color: "#fff",
    },
    margin: {
        marginBottom: '0.5%',
    },
    zip: {
        padding: "14px",
    },
    iconzip: {
        padding: "50px"
    },
}));


export default function useIndexAuthorizedIssuedVoucher(enqueueSnackbar) {
    const { state, dispatch } = useContext(AuthorizedIssuedVouchersContext)
    const tokenUser = useSelector(state => state.user.tk)

    const classesView = useStyles();

    const getZip = async () => {
        dispatch({
            type: ACTIONS_TYPES.SET_LOADING_PAGE,
            payload: true
        })
        const selected = state.selected
        if (typeof selected !== 'undefined' && Array.isArray(selected) && selected.length !== 0) {
            await clientBackendZIP.post('/utilidades/generar/zip', {
                idComprobantes: selected
            }).then(
                response => {
                    if (response.status === 200) {
                        var blob = new Blob([response.data], {
                            type:
                                "application/zip",
                        });
                        fileSaver.saveAs(blob, "Facturar-Comprobantes-Electronicos.zip");
                    }
                }
            ).catch(() => {
                enqueueSnackbar(`No existen datos para el/los comprobantes seleccionados`, {
                    variant: "warning",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                });
            })
                .finally(() => {
                    dispatch({
                        type: ACTIONS_TYPES.SET_LOADING_PAGE,
                        payload: false
                    })
                })
        } else {
            enqueueSnackbar(`Seleccione al menos un comprobante`, {
                variant: "warning",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });
            dispatch({
                type: ACTIONS_TYPES.SET_LOADING_PAGE,
                payload: false
            })
        }
    }

    const setParamSearch = (param) => {        
        dispatch({
            type: ACTIONS_TYPES.SET_LOADING_PAGE,
            payload: true
        })
        let uri = param !== "" ? API_GET_SEARCH_AUTHORIZED : API_GET_VOUCHER_AUTHORIZED;
        httpRepo.get(`${API_GET_SEARCH_AUTHORIZED}${tokenUser ? getRUCEmpresa(tokenUser) : "0"}?page=0&size=15${param}&sort=${state.atributoAOrdenar}`)
            .then(
                result => {
                    if (result.status === 200) {
                        dispatch({
                            type: ACTIONS_TYPES.SET_ROWS_TABLES,
                            payload: {
                                page: {
                                    size: result.data.content.size,
                                    content: result.data.content,
                                    numberOfElements: result.data.numberOfElements,
                                },
                                totalPages: result.data.totalPages,
                                totalElements: result.data.totalElements,
                            }
                        })
                    }
                }
            ).catch(
                error => {
                    const exception = { error };
                    if (typeof exception.error.response === 'undefined') {
                        dispatch({
                            type: ACTIONS_TYPES.SET_ERROR_LOAD_TABLE,
                            payload: {
                                show: true,
                                msg: 'Ocurrió un problema en la red al consultar los datos',
                                type: "error"
                            }
                        });
                    }
                }
            ).finally(() => {
                dispatch({
                    type: ACTIONS_TYPES.SET_LOADING_PAGE,
                    payload: false
                })
                dispatch({
                    type: ACTIONS_TYPES.HANDLE_CHANGE_FILTERS_TABLE,
                    payload: param
                })
            })
    }

    const setUpdate = (caseUpdate) => {
        if (caseUpdate === 2) {
            setParamSearch("")
        }
    }

    return {
        setParamSearch,
        classesView,
        getZip,
        setUpdate,
        update: 1,
        openLoader: state.loading
    }
}
