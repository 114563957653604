import React, { Fragment, useState } from "react";
import InfoIcon from "@material-ui/icons/Info";
import {
  makeStyles,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
} from "@material-ui/core";
import { palette } from "../../../../../configurations/styles/theme";
import AddBoxIcon from "@material-ui/icons/AddBox";

const useStyles = makeStyles((theme) => ({
  IconButton: {
    marginTop: "0%",
    marginBottom: "0%",
    padding: "0px",
  },
  divInfoUser: {
    borderStyle: "solid",
    borderColor: palette.primary.main,
    borderWidth: "1px",
    borderRadius: "8px",
    marginTop: "1%",
  },
  divlabel: {
    marginTop: "1%",
  },
  buttonClose: {
    width: "75%",
  },
  table: {
    flexGrow: 1,
    borderWidth: "2px",
    borderRadius: "8px",
    borderCollapse: "collapse",
    border: "1px solid black",
    textAlign: "center",
    padding: "0px",
  },
}));

const InfoAdditionalBtn = ({ data }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleTable = (data) => {
    setOpen(true);
  };
  return (
    <Fragment>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={() => handleTable(data)}
      >
        <AddBoxIcon style={{ color: palette.info.dark }} />
      </IconButton>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle id="max-width-dialog-title">
          Detalles adicionales
        </DialogTitle>
        <DialogContent>
          <form>
            {data.informacionAdicional.length > 0 ? (
              <table class="table">
                <thead class="thead-light">
                  <tr className={classes.table}>
                    <th scope="col">Nombre</th>
                    <th scope="col">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  {data.informacionAdicional
                    ? data.informacionAdicional.map((info) => (
                        <tr>
                          <td className={classes.table}>{info.nombre}</td>
                          <td className={classes.table}>{info.valor}</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            ) : (
              <p>No ha agregado detalles adicionales</p>
            )}
          </form>
          <Grid container justifyContent="center">
            <Button
              size="large"
              variant="outlined"
              color="primary"
              onClick={handleClose}
            >
              Cerrar
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default InfoAdditionalBtn;
