import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Backdrop,
  CircularProgress,
  Grid,
  makeStyles,
  TextField,
  Divider,
} from "@material-ui/core";
import {
  typeSupport,
  typeInvoice,
  tipoRegiType,
  tipoRegi01Type,
  tipoRegi02Type,
  tipoRegi03Type,
} from "views/facturacion/Forms/typeVoucher";
import { Autocomplete } from "@material-ui/lab";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import InputMask from "react-input-mask";
import useAddTax from "hooks/useAddTax";
import useAddSupportDoc from "hooks/useAddSupportDoc";
import AddTax from "./AddTax";
import TableDataSimplePag from "widgets/Tables/TableDataSimplePag";
import TableDataSimple from "widgets/Tables/TableDataSimple";
import tableStyle from "configurations/styles/table";
import DeleteTaxBtn from "../Buttons/DeleteTaxBtn";
import EditTaxBtn from "../Buttons/EditTaxBtn";
import EditTableRetenciones from "widgets/Tables/EditTableImpuestoRetencions";
import EditTableRetencionesDividends from "widgets/Tables/EditTableImpuestoRetencionsDividends";
import EditTableImpuestos from "widgets/Tables/EditTableImpuesto";
import EditTablePagos from "widgets/Tables/EditTablePagos";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { palette, useStylesGlobal } from "configurations/styles/theme";
import { useStylesApp } from "hooks/useStyles";
import { green } from "@material-ui/core/colors";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#4CAF50", // Color verde
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(3),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  divForm: {
    marginBottom: "1%",
  },
  divInput: {
    marginBottom: "1%",
    paddingRight: "1%",
    paddingLeft: "0.5%",
  },
  divButton: {
    paddingLeft: "3%",
    paddingRight: "3%",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    marginTop: "1%",
    marginBottom: "1%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
}));

function InfoExteriorDetail({ addInformationExterior }) {
  const classes = useStyles();
  const classesApp = useStylesApp();
  const classesGlobales = useStylesGlobal();
  const [open, setOpen] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const informationExterior = {
    tipoRegi: addInformationExterior.tipoRegi,
    paisEfecPago: addInformationExterior.paisEfecPago,
    aplicConvDobTrib: addInformationExterior.aplicConvDobTrib,
    pagExtSujRetNorLeg: addInformationExterior.pagExtSujRetNorLeg,
    pagoRegFis: addInformationExterior.pagoRegFis,
  };

  return (
    <>
      <Grid container justifyContent="center" className={classes.divButton}>
        {addInformationExterior.tipoRegi === "" ? (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClickOpen}
            disabled
          >
            Visualizar información exterior
          </Button>
        ) : (
          <ThemeProvider theme={theme}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickOpen}
            >
              Visualizar información
            </Button>
          </ThemeProvider>
        )}
      </Grid>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="max-width-dialog-title">
          Información exterior
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={() => {
              setOpenLoader(true);
            }}
          >
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} md={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} md={8} className={classes.divForm}>
                    <label>
                      Tipos de régimen fiscal del exterior:{" "}
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.divInput}>
                    <label>{informationExterior.tipoRegi}</label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divForm}>
                    <label>
                      País de residencia o establecimiento permanente a quién se
                      efectúa el pago: <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.divInput}>
                    <label>{informationExterior.paisEfecPago}</label>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.divForm}>
                    <label>
                      Aplica Convenio de Doble Tributación en el pago?{" "}
                      <font color={palette.error.main}>*</font>
                    </label>
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.divInput}>
                    <label>{informationExterior.aplicConvDobTrib}</label>
                  </Grid>
                  {informationExterior.aplicConvDobTrib === "NO" ? (
                    <>
                      <Grid item xs={12} md={8} className={classes.divForm}>
                        <label>
                          Pago al exterior sujeto a retención en aplicación a la
                          norma legal? <font color={palette.error.main}>*</font>
                        </label>
                      </Grid>
                      <Grid item xs={12} md={4} className={classes.divInput}>
                        <label>{informationExterior.pagExtSujRetNorLeg}</label>
                      </Grid>
                    </>
                  ) : null}
                  <Grid item xs={12} md={8} className={classes.divForm}>
                    <label>
                      El pago es a un régimen fiscal preferente o de menor
                      imposición?
                    </label>
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.divInput}>
                    <label>{informationExterior.pagoRegFis}</label>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} className={classes.divButton}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  className={classesGlobales.button}
                >
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default InfoExteriorDetail;
