import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
//redux
import UseSecurity from "security/useSecurity";
import { useDispatch } from "react-redux";
import { addResourceAction } from "redux/actions/resourcesActions";
import {
    processoFinishedAction,
    processoLoadingAction,
} from "redux/actions/processAsyncAction";

import { clientPublic } from "configurations/axios";
import { useSelector } from "react-redux";
import { signOffUserAction } from "redux/actions/userActions";

import UseTitle from "hooks/useTitle";
import { apiKey, aYoSis, domainSis } from "configurations/constant";

export default function useLogin() {

    const [user, setUser] = useState({ username: "", password: "" });
    const [open, setOpen] = useState(false);
    //const [openMsm, setOpenMsm] = useState(true);

    const [errorInputs, setErrorInputs] = useState(false);
    const [userRecovery, setUserRecovery] = useState();
    const [msgForgetPass, setmsgForgetPass] = useState({
        msg:
            "Ingrese el nombre del usuario o RUC con el que inicia sesión para recuperar  su contraseña.",
        type: "alert-primary",
    });
    const { isSingIn } = UseSecurity();
    const history = useHistory();
    const dispatch = useDispatch();
    UseTitle({ title: "Login" });
    const addResources = (dataUser) => dispatch(addResourceAction(dataUser));
    const processLoading = () => dispatch(processoLoadingAction());
    const processFinised = () => dispatch(processoFinishedAction());
    const clearProcess = () => dispatch(signOffUserAction());
    const error = useSelector((state) => state.user.error);
    //para saber que dominio es
    const apiKeySis = window.location.hostname.includes(domainSis) ? aYoSis : apiKey;

    const [inputClave, setInputClave] = useState({
        error: false,
        helperText: null,
    });

    const handleChange = (event) => {
        setUser({
            ...user,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeUserRecovey = (event) => {
        setUserRecovery(event.target.value);
    };

    const handleAutentication = async (event) => {
        event.preventDefault();
        if (user.username.trim() !== "" && user.password.trim() !== "") {
            addResources(user);
        } else {
            setErrorInputs(true);
        }
    };

    const handleRedirectionRegister = () => {
        history.push("/registro");
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    /*const handleCloseMsm = () => {
      setOpenMsm(false);
    };*/

    const handleClose = () => {
        setUserRecovery("");
        setmsgForgetPass({
            msg:
                "Ingrese el nombre del usuario o RUC con el que inicia sesión para recuperar su contraseña.",
            type: "alert-primary",
        });
        setInputClave({
            error: false,
            helperText: null,
        });
        setOpen(false);
    };

    useEffect(() => {
        if (isSingIn()) {
            history.push("/facturacion");
        } else {
            clearProcess();
        }
    }, []);

    //Solo vale una ves
    const handleRestorePass = async () => {
        if (userRecovery !== null) {
            if (userRecovery === undefined) {
                setInputClave({
                    error: true,
                    helperText: "Ingrese el usuario",
                });
            } else {
                setInputClave({
                    error: false,
                    helperText: null,
                });

                processLoading();
                const nombreUsuarioAcceso = userRecovery;
                await clientPublic
                    .post(
                        `/reestablecer/clave?nombreUsuarioAcceso=${nombreUsuarioAcceso}&codigoSistema=${apiKeySis}`
                    )
                    .then((response) => {
                        if (response.status >= 200 && response.status <= 300) {
                            setmsgForgetPass({
                                msg:
                                    "La información necesaria para poder iniciar sesión fue enviada a su correo: " +
                                    response.data.correoPrincipalUsuario,
                                type: "alert-success",
                            });
                        }
                        processFinised();
                    })
                    .catch((exception) => {
                        if (exception.response) {
                            if (exception.response.status === 400) {
                                setmsgForgetPass({
                                    msg: "No se pudo reestablecer la contraseña.",
                                    type: "alert-danger",
                                });
                            } else if (exception.response.status === 404) {
                                setmsgForgetPass({
                                    msg:
                                        "No se encontró el usuario que desea recuperar la contraseña.",
                                    type: "alert-danger",
                                });
                            } else {
                                setmsgForgetPass({
                                    msg:
                                        "Error al recuperar la contraseña. Contáctese con el administrador del Sistema.",
                                    type: "alert-danger",
                                });
                            }
                        } else {
                            setmsgForgetPass({
                                msg:
                                    "Existen problemas en la red. Contáctese con el administrador del Sistema.",
                                type: "alert-danger",
                            });
                        }
                        processFinised();
                    });
            }
        }
    };

    return {
        handleRestorePass,
        handleClose,
        handleClickOpen,
        handleRedirectionRegister,
        handleAutentication,
        handleChangeUserRecovey,
        handleChange,
        inputClave,
        msgForgetPass,
        error,
        errorInputs,
        open,
        userRecovery,
        inputClave,
        user
    };
}