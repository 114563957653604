import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { palette } from "configurations/styles/theme";
import { getByteArray } from "utils/servicesForFiles";

const useStyles = makeStyles({
    container: {
        position: "relative",
        width: "100%",
        maxWidth: "100",
    },
    img: {
        minWidth: "auto",
        maxWidth: "90%",
        minHeight: "150px",
        maxHeight: "180px",
        border: "1px solid  #aaaaaa",
        borderRadius: "8px",
        display: "block"
    },
    imgNone: {
        width: "50%",
        height: "auto",
        minHeight: "150px",
        maxHeight: "180px",
        border: "1px solid  #aaaaaa",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      //  background: "linear-gradient(66deg, rgba(167,167,167,1) 0%, rgba(255,255,255,0.5550595238095238) 50%, rgba(167,167,167,1) 100%)",
    },
    button: {
        marginTop: "0.5%",
        width: "50%",
        maxWidth: "100%",
        padding: "0.5%",
        borderStyle: "solid",
        borderWidth: "1px",
        display: "flex",
        justifyContent: "center",
        borderColor: palette.primary.dark,
        borderRadius: "8px",
        "&:hover": {
            color: palette.primary.contrastText,
            background: palette.primary.main
        }
    }
});

const RideLogo = ({ urlImage, onShowAlert, onSubmit, textBtn = "Actualizar" }) => {
    const classes = useStyles();

    const procesarArchivo = event => {
        let files = event.target.files;
        //verifica que solo sea un archivo seleccionado
        if (files.length === 1) {
            //verifica la extension del archivo
            if (files[0].type === "image/png" || files[0].type === "image/jpeg") {
                //verifica el tamanio del peso
                if (files[0].size < "1000001") {
                    getByteArray(files[0]).then(
                        (byteArray) => {
                            //onSubmit(byteArray.substring(23,))
                            if (files[0].type === "image/png") {
                                onSubmit(byteArray.substring(22));
                            } else if (files[0].type === "image/jpeg") {
                                onSubmit(byteArray.substring(23));
                            }

                        }
                    ).catch(
                        error => console.log("error", error)
                    );

                } else if (files[0].size >= "1000001") {
                    onShowAlert({ show: true, msg: 'El peso máximo del archivo deber ser de 1MB.', type: "warning" });
                }
            } else {
                onShowAlert({ show: true, msg: 'Solo se admiten archivos en .jpg o .png', type: "error" });
            }
        } else if (files.length > 1) {
            onShowAlert({ show: true, msg: '"Se detectó varios archivos seleccionados, por favor solo seleccione uno.', type: "error" });
        }
    }


    return (
        <div className={classes.container}>
            <strong>Logo para los comprobantes (PDF)</strong>
            {urlImage ?
                <img src={"data:image/png;base64," + urlImage} alt="Logo Ride" className={classes.img} />
                :
                <div className={classes.imgNone} > Inserta tu Logo </div>
            }

            <label className={classes.button} >
                <input type="file" style={{ display: "none" }} onChange={procesarArchivo} />
                {textBtn}
            </label>
        </div>
    );
}

export default RideLogo;