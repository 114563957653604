import clientBackend from "configurations/axios";
import { bookApis, domainSis, apiKey, aYoSis } from "configurations/constant";
import { notNull } from "utils/checkInputs";
import { getPerm } from "redux/actions/userActions";
import { useHistory } from "react-router-dom";
import { addResourcesSuccess, addConfigResourcesAction } from "redux/actions/resourcesActions";



export const findPermissons = (tokenUser) => {

    const apiKeySis = window.location.hostname.includes(domainSis) ? aYoSis : apiKey;
    return new Promise((resolve, reject) => {
        const idProfile = getPerm(tokenUser);
        clientBackend
            .get(bookApis.auth.get_all_permissons(idProfile, apiKeySis))
            .then(result => resolve(result))
            .catch(error => reject(error));
    });
}

/**
 * @description hook para manejar los permisos del usuario
 */
export default function usePermissons(useSelector, dispatch) {
    let tokenUser = useSelector(state => state.user.tk);
    tokenUser = notNull(tokenUser) ? tokenUser : "";

    const history = useHistory();

    const perrmissonCurrent = useSelector(state => state.resources.resources);

    const configPermissons = useSelector(state => state.resources.configResources);

    const savePermissons = (data = []) => {
        let configPermissons = [];
        let menuPermissons = [];
        data.forEach(item => {
            if (item.menuPadre === "CFG") {
                configPermissons.push(item);
            } else if (item.id !== "CFG") {
                menuPermissons.push(item);
            }
        })
        dispatch(addResourcesSuccess(menuPermissons));
        dispatch(addConfigResourcesAction(configPermissons));
    }


    const updatePermissons = () => {
        if (Array.isArray(perrmissonCurrent) && perrmissonCurrent.length === 0) {
            findPermissons(tokenUser)
                .then(result => savePermissons(result.data))
                .catch(() => history.push("/"));
        } else if (!Array.isArray(perrmissonCurrent)) {
            findPermissons(tokenUser)
                .then(result => dispatch(addResourcesSuccess(result.data)))
                .catch(() => history.push("/"));
        }
    }

    const hasPermisson = () => {

    }

    const getPermissons = () => perrmissonCurrent

    const getConfigPermissons = () => configPermissons


    return {
        getPermissons,
        updatePermissons,
        hasPermisson,
        getConfigPermissons
    }

}