import React, { Fragment } from 'react';
import { Button, Grid, Dialog, DialogTitle, DialogContent, FormControl, NativeSelect, Divider } from '@material-ui/core';
import { palette } from 'configurations/styles/theme';
import { formasPago, unidadTiempo } from 'views/facturacion/Forms/typeVoucher';
import usePaymentsMethods, { BootstrapInput } from "hooks/usePaymentsMethods";



const MultiplePayments = ({ pagos, setPagos, viewPago, setViewPago, totals }) => {

    const {
        setPago,
        classes,
        classesApp,
        classesGlobal,
        open,
        pago,
        empty,
        handleClickOpen,
        handleClose,
        hadleAddPago,
        handleChange,
        handleChangeTotal,
        summation
    } = usePaymentsMethods(pagos, setPagos, viewPago, setViewPago, totals);

    return (
        <Fragment>
            <Grid container justify='center' className={classes.divButton}>
                <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                    Agregar Pago
                </Button>
            </Grid>
            <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="max-width-dialog-title">Nuevo pago </DialogTitle>
                <DialogContent>
                    <form >
                        <Grid container >
                            <Grid item xs={12} md={12}>
                                <label>Los campos marcados con (  <font color={palette.error.main}> *</font> ) son obligatorios:</label>
                            </Grid>
                            <Grid item xs={12} md={12} className={classes.divForm}>
                                <label> <strong>Pago faltante: <font color={palette.info.main}> {`${summation()}`}</font></strong></label>
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.divForm}>
                                <label>Forma: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid item xs={12} md={8} className={classes.divInput}>
                                <FormControl className={classes.divSelect} >
                                    <NativeSelect
                                        name='formaPago'
                                        value={pago.formaPago}
                                        onChange={event => setPago({ ...pago, [event.target.name]: event.target.value })}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value='' >--Seleccione--</option>
                                        {
                                            formasPago.map(pago => (
                                                <option
                                                    key={pago.CODIGO}
                                                    value={pago.CODIGO}>
                                                    {pago.CODIGO} - {pago.NOMBRE}
                                                </option>
                                            ))
                                        }

                                    </NativeSelect>
                                </FormControl>

                                {empty.pago === true ?
                                    null
                                    :
                                    <p className={classesApp.errorLetters}>Seleccione la forma de pago</p>
                                }
                            </Grid>

                            <Grid item xs={12} md={4} className={classes.divForm}>
                                <label>Tiempo: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid item xs={12} md={8} className={classes.divInput}>
                                <FormControl className={classes.divSelect}>
                                    <NativeSelect
                                        name='unidadTiempo'
                                        value={pago.unidadTiempo}
                                        onChange={event => setPago({ ...pago, [event.target.name]: event.target.value })}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value=''>--Seleccione--</option>
                                        {
                                            unidadTiempo.map(unidad => (
                                                <option
                                                    key={unidad.CODIGO}
                                                    placeholder='--Seleccione--'
                                                    value={unidad.NOMBRE}>
                                                    {unidad.NOMBRE}
                                                </option>
                                            ))
                                        }

                                    </NativeSelect>
                                </FormControl>

                                {empty.tiempo === true ?
                                    null
                                    :
                                    <p className={classesApp.errorLetters}>Seleccione la unidad de tiempo</p>
                                }
                            </Grid>

                            <Grid item xs={12} md={4} className={classes.divForm}>
                                <label>Plazo: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid item xs={12} md={8} className={classes.divInput}>
                                <input type="text"
                                    maxLength={3}
                                    placeholder="Ej. 3"
                                    name="plazo"
                                    value={pago.plazo}
                                    onChange={handleChange}
                                    autoComplete="off"
                                    className={pago ? pago.plazo ? 'form-control is-valid' : 'form-control' : 'form-control'} />

                                {empty.plazo === true ?
                                    null
                                    :
                                    <p className={classesApp.errorLetters}>Ingrese el número de plazos</p>
                                }
                            </Grid>

                            <Grid item xs={12} md={4} className={classes.divForm}>
                                <label>Total: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid item xs={12} md={8} className={classes.divInput}>
                                <input type="text"
                                    placeholder="Ej. 300"
                                    name="total"
                                    value={pago.total}
                                    onChange={handleChangeTotal}
                                    autoComplete="off"
                                    className={pago ? pago.total ? 'form-control is-valid' : 'form-control' : 'form-control'} />

                                {empty.total != null &&
                                    <p className={classesApp.errorLetters}>{empty.total}</p>
                                }

                            </Grid>

                            <Grid container spacing={3} justify='center' >
                                <Grid item md={12} xs={12} >
                                    <Divider />
                                </Grid>
                                <Button variant="contained" color="primary" onClick={hadleAddPago} className={classesGlobal.marginButton}>
                                    Agregar
                                </Button>
                                <Button variant="outlined" color="secondary" onClick={handleClose} className={classesGlobal.marginButton}>
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>


                    </form>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}

export default MultiplePayments;