import { useContext, useEffect } from "react";
//axios
import { httpRepo } from 'configurations/axios';
import { API_GET_SEARCH_AUTHORIZED, API_GET_VOUCHER_AUTHORIZED } from "../apis";
import { useSelector } from 'react-redux';

import { getRUCEmpresa } from 'redux/actions/userActions';

import AuthorizedIssuedVouchersContext, { ACTIONS_TYPES } from "./index";

export default function useTableAuthorizedIssuedVoucher() {
    const { state, dispatch } = useContext(AuthorizedIssuedVouchersContext);
    const tokenUser = useSelector(state => state.user.tk);


    useEffect(() => {
        dispatch({
            type: ACTIONS_TYPES.SET_LOADING_PAGE,
            payload: true
        })
        httpRepo.get(`${API_GET_VOUCHER_AUTHORIZED}${tokenUser ? getRUCEmpresa(tokenUser) : "0"}?page=${state.currentPage}&size=${state.numeroFilas}${state.paramSearch}&sort=${state.atributoAOrdenar}`).then(
            result => {
                if (result.status === 200) {
                    dispatch({
                        type: ACTIONS_TYPES.SET_ROWS_TABLES,
                        payload: {
                            page: {
                                size: result.data.content.size,
                                content: result.data.content,
                                numberOfElements: result.data.numberOfElements,
                            },
                            totalPages: result.data.totalPages,
                            totalElements: result.data.totalElements,
                        }
                    })
                }
            }
        ).catch(
            error => {
                const exception = { error };
                if (typeof exception.error.response === 'undefined') {
                    dispatch({
                        type: ACTIONS_TYPES.SET_ERROR_LOAD_TABLE,
                        payload: {
                            show: true,
                            msg: 'Ocurrió un problema en la red al consultar los datos',
                            type: "error"
                        }
                    });
                }
            }
        ).finally(() => {
            dispatch({
                type: ACTIONS_TYPES.SET_LOADING_PAGE,
                payload: false
            })
        })
    }, [])

    const setSelected = (items) => {
        dispatch({
            type: ACTIONS_TYPES.ADD_SELECTION_ITEMS,
            payload: items
        })
    }

    const handleChangePage = (event, newPage) => {
        dispatch({
            type: ACTIONS_TYPES.HANDLE_CHANGE_PAGE_TABLE,
            payload: {
                page: newPage,
                numRows: state.numeroFilas
            }
        })
        getRows(newPage, state.numeroFilas)
    };

    const handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10)
        dispatch({
            type: ACTIONS_TYPES.HANDLE_CHANGE_PAGE_TABLE,
            payload: {
                page: state.currentPage,
                numRows: rowsPerPage
            }
        })
        getRows(state.currentPage, rowsPerPage)
    };

    const getRows = (page, size) => {
        console.log(state.paramSearch)
        dispatch({
            type: ACTIONS_TYPES.SET_LOADING_PAGE,
            payload: true
        })
        let uri=state.paramSearch !== "" ? API_GET_SEARCH_AUTHORIZED : API_GET_VOUCHER_AUTHORIZED;
        httpRepo.get(`${uri}${tokenUser ? getRUCEmpresa(tokenUser) : "0"}?page=${page}&size=${size}&${state.paramSearch}&sort=${state.atributoAOrdenar}`).then(
            result => {
                if (result.status === 200) {
                    dispatch({
                        type: ACTIONS_TYPES.SET_ROWS_TABLES,
                        payload: {
                            page: {
                                size: result.data.content.size,
                                content: result.data.content,
                                numberOfElements: result.data.numberOfElements,
                            },
                            totalPages: result.data.totalPages,
                            totalElements: result.data.totalElements,
                        }
                    })
                }
            }
        ).catch(
            error => {
                const exception = { error };
                if (typeof exception.error.response === 'undefined') {
                    dispatch({
                        type: ACTIONS_TYPES.SET_ERROR_LOAD_TABLE,
                        payload: {
                            show: true,
                            msg: 'Ocurrió un problema en la red al consultar los datos',
                            type: "error"
                        }
                    });
                }
            }
        ).finally(() => {
            dispatch({
                type: ACTIONS_TYPES.SET_LOADING_PAGE,
                payload: false
            })
        })
    }

    const isSelected = (name) => state.selected.indexOf(name) !== -1;

    const handleSelectAllClick = (event) => {
        const rows = state.page.content

        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.comprobanteElectronico.idComprobanteElectronico);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {

        const selected = state.selected
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    return {
        rows: state.page.content,
        currentPage: state.currentPage,
        handleChangePage,
        rowsPerPage: state.numeroFilas,
        handleChangeRowsPerPage,
        totalPages: state.totalPages,
        totalElements: state.totalElements,
        setSelected,
        selected: state.selected,
        isSelected,
        handleSelectAllClick,
        handleClick
    }
}
