import React from "react";
import {
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    TextField,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { Link } from "react-router-dom";
import CancelIcon from "@material-ui/icons/Cancel";
import useLogin from "./useLogin";



export default function RecoveryCredential() {
    const classes = useStyles();
    const {
        handleRestorePass,
        handleClose,
        handleChangeUserRecovey,
        msgForgetPass,
        open,
        handleClickOpen,
        userRecovery,
        inputClave } = useLogin();

    return (
        <>
            <Link
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
                underline="always"
            >
                ¿Olvidó su Contraseña?
                </Link>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={handleClose}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle>Recuperación de contraseña</DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item sm={12}>
                            <div className={"alert " + msgForgetPass.type} role="alert">
                                <label> {msgForgetPass.msg}</label>
                            </div>
                        </Grid>
                        <Grid item sm={2}>
                            <label>Usuario :</label>
                        </Grid>
                        <Grid item sm={10}>
                            <TextField
                                type="email"
                                className="form-control"
                                required
                                name="emailUser"
                                variant="outlined"
                                placeholder="user@ejemplo.com"
                                onChange={handleChangeUserRecovey}
                                value={userRecovery}
                                autoComplete="off"
                                error={inputClave.error}
                                helperText={inputClave.helperText}
                            />
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            spacing={1}
                            className={classes.butonRecovery}
                        >
                            <Grid item xs={12}>
                                <Divider></Divider>
                            </Grid>
                            <Grid item sm={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={handleRestorePass}
                                    disabled={msgForgetPass.type === "alert-success"}
                                >
                                    Recuperar contraseña
                </Button>
                            </Grid>
                            <Grid item sm={6}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    <CancelIcon></CancelIcon>
                  Cancelar
                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}