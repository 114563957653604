import { Backdrop, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, makeStyles } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import BrokenImageOutlinedIcon from '@material-ui/icons/BrokenImageOutlined';
import { palette, useStylesGlobal } from 'configurations/styles/theme';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { httpRepo } from 'configurations/axios';
import { parseDate } from "utils/checkDate";
import { getRUCEmpresa } from 'redux/actions/userActions';
import RangeDataOption from 'views/facturacion/Reports/RangeDataOption';


const useStyles = makeStyles((theme) => ({
    IconButton: {
        marginTop: "0%",
        marginBottom: "0%",
        padding: "0px",
    },
    textFieldComboBox: {
        minWidth: "75%",
        maxWidth: "95%",
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 1,
        color: palette.primary.main,
    },
}));

const GenerateReportIssuedBtn = ({ data }) => {

    const classes = useStyles();
    const tokenUser = useSelector((state) => state.user.tk);
    const classesGlobal = useStylesGlobal();
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    var fileSaver = require("file-saver");
    const [options, setOptions] = useState([
        { value: "01", label: "Factura" },
        { value: "03", label: "Liquidación de compra" },
        { value: "04", label: "Nota de crédito" },
        { value: "05", label: "Nota de débito" },
        { value: "06", label: "Guía de remisión" },
        { value: "07", label: "Retención" },
    ]);
    const handleOpenClick = () => {
        setOpenDialog(true);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }


    function generateReport(startDate, endDate, optionSelect) {
        if (optionSelect.value) {
            let objReport = {};
            objReport = {
                fechaFin: parseDate(endDate, 2),
                fechaInicio: parseDate(startDate, 1),
                rucEmpresa: data.idempresacontratante,
                tipoComprobante: optionSelect.value,
            };
            getReportIssued(objReport);
        } else {
            enqueueSnackbar(`Seleccione un tipo de comprobante`, {
                variant: "info",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
                autoHideDuration: 4000,
            });
        }
    }

    const getReportIssued = async (objReport) => {
        setOpen(true);
        await httpRepo
            .post("reporte/comprobantes/emitidos", objReport, {
                responseType: "arraybuffer",
            })
            .then((response) => {
                if (response.status === 200) {
                    var blob = new Blob([response.data], {
                        type:
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    });
                    fileSaver.saveAs(blob, "Reporte.xlsx");
                    setOpen(false);
                }
            })
            .catch(() => {
                enqueueSnackbar(`No existen datos para los filtros seleccionados`, {
                    variant: "info",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                });
                setOpen(false);
            });
    };

    return (
        <Fragment>
            <IconButton
                aria-label="delete"
                className={classes.IconButton}
                variant="outlined"
                color="secondary"
                onClick={handleOpenClick}
            >
                <BrokenImageOutlinedIcon
                    style={{ color: palette.primary.main }}
                />
            </IconButton>

            <Dialog fullWidth maxWidth="lg" open={openDialog} onClose={handleCloseDialog}
            >
                <DialogTitle id="max-width-dialog-title">Generar Reporte de Comprobantes Emitidos:<strong> {data.razonSocialempresa} </strong></DialogTitle>
                <DialogContent>

                    <RangeDataOption
                        generateReport={generateReport}
                        title={"Reporte de Comprobantes Emitidos"}
                        options={options}
                    ></RangeDataOption>

                    <Grid container spacing={3} justify="center">
                        <Grid item md={12} xs={12}>
                            <Divider></Divider>
                        </Grid>

                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleCloseDialog}
                            className={classesGlobal.marginButton}
                        >
                            Cerrar
                    </Button>
                    </Grid>

                </DialogContent>
            </Dialog>

            <Backdrop open={open} className={classes.backdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Fragment>
    );

}

export default GenerateReportIssuedBtn;