export const items = [
  {
    "id": "INI",
    "nameMenu": "Inicio",
    "titlePage": "Inicio",
    titleTab: "Inicio",
    "menuPadre": null
  },

  {
    "id": "ADM",
    "nameMenu": "Administración",
    "titlePage": "Administración",
    titleTab: "Admin.",
    "menuPadre": null
  },
  {
    "id": "CLI",
    "nameMenu": "Clientes",
    "titlePage": "Administracion de Clientes",
    titleTab: "Clientes",
    "menuPadre": "ADM"
  },
  {
    "id": "PRV",
    "nameMenu": "Proveedores",
    "titlePage": "Administración de Proveedores",
    titleTab: "Proveedores",
    "menuPadre": "ADM"
  },
  {
    "id": "USU",
    "nameMenu": "Usuarios",
    "titlePage": "Administración de Usuarios",
    titleTab: "Usuarios",
    "menuPadre": "ADM"
  },
  {
    "id": "PRO",
    "nameMenu": "Productos/Servicios",
    "titlePage": "Administración de Productos/Servicios",
    titleTab: "Productos/Servicios",
    "menuPadre": "ADM"
  },
  {
    "id": "CSE",
    "nameMenu": "Puntos de Emisión",
    "titlePage": "Administración de Puntos de Emisión",
    titleTab: "Admin. Secuenciales",
    "menuPadre": "ADM"
  },
  {
    "id": "ASE",
    "nameMenu": "Asignación de Puntos de Emisión",
    "titlePage": "Asignación de Puntos de Emisión",
    titleTab: "Asignación Secuenciales",
    "menuPadre": "ADM"
  },
  {
    "id": "AEM",
    "nameMenu": "Empresas",
    "titlePage": "Administración de Empresas",
    titleTab: "Admin. Empresas",
    "menuPadre": "ADM"
  },

  {
    "id": "ACT",
    "nameMenu": "Contadores",
    "titlePage": "Administración de Contadores",
    titleTab: "Admin. Contadores",
    "menuPadre": "ADM"
  },
  {
    "id": "APQ",
    "nameMenu": "Pagos",
    "titlePage": "Administración de Pagos",
    titleTab: "Admin. Pagos",
    "menuPadre": "ADM"
  },

  {
    "id": "GEN",
    "nameMenu": "Generación de Comprobantes",
    "titlePage": "Generación de Comprobantes",
    titleTab: "Generación Comprobantes",
    "menuPadre": null
  },

  {
    "id": "REP",
    "nameMenu": "Repositorio de Comprobantes",
    "titlePage": "Repositorio de Comprobantes",
    titleTab: "Repositorio de Comprobantes",
    "menuPadre": null
  },
  {
    "id": "REPO",
    "nameMenu": "Reporte de Comprobantes",
    "titlePage": "Reporte de Comprobantes",
    titleTab: "Reporte de Comprobantes",
    "menuPadre": null
  },
  {
    "id": "AUT",
    "nameMenu": "Emitidos Autorizados",
    "titlePage": "Emitidos Autorizados",
    titleTab: "Comp. Emitidos Autorizados",
    "menuPadre": "REP"
  },
  {
    "id": "NAU",
    "nameMenu": "Emitidos No Autorizados",
    "titlePage": "Emitidos No Autorizados",
    titleTab: "Comp. Emitidos No Autorizados",
    "menuPadre": "REP"
  },
  {
    "id": "PEN",
    "nameMenu": "Pendientes",
    "titlePage": "Pendientes",
    titleTab: "Comp. Pendientes",
    "menuPadre": "REP"
  },
  {
    "id": "ANU",
    "nameMenu": " Anulados",
    "titlePage": " Anulados",
    titleTab: "Comp. Anulados",
    "menuPadre": "REP"
  },
  {
    "id": "CRA",
    "nameMenu": "Recibidos",
    "titlePage": "Recibidos",
    titleTab: "Comprobantes Recibidos",
    "menuPadre": "REP"
  },
  {
    "id": "CAC",
    "nameMenu": "Cargar Comprobantes Recibidos",
    "titlePage": "Cargar Comprobantes Recibidos",
    titleTab: "Cargar Comprobantes Recibidos",
    "menuPadre": "REP"
  },
  {
    "id": "FAC",
    "nameMenu": "Factura",
    "titlePage": "Factura",
    titleTab: "Factura",
    "menuPadre": "GEN"
  },
  {
    "id": "RET",
    "nameMenu": "Retención",
    "titlePage": "Retención",
    titleTab: "Retención",
    "menuPadre": "GEN"
  },
  {
    "id": "CRE",
    "nameMenu": "Nota de Crédito",
    "titlePage": "Nota de Crédito",
    titleTab: "Nota de Crédito",
    "menuPadre": "GEN"
  },
  {
    "id": "DEB",
    "nameMenu": "Nota de Débito",
    "titlePage": "Nota de Débito",
    titleTab: "Nota de Débito",
    "menuPadre": "GEN"
  },
  {
    "id": "LIQ",
    "nameMenu": "Liquidación de Compra",
    "titlePage": "Liquidación de Compra",
    titleTab: "Liquidación de Compra",
    "menuPadre": "GEN"
  },
  {
    "id": "GUI",
    "nameMenu": "Guía de Remisión",
    "titlePage": "Guía de Remisión",
    titleTab: "Guía de Remisión",
    "menuPadre": "GEN"
  },
  {
    "id": "RCE",
    "nameMenu": "Reporte Comprobantes Emitidos",
    "titlePage": "Reporte Comprobantes Emitidos",
    titleTab: "Reporte comprobantes emitidos",
    "menuPadre": "REPO"
  },
  {
    "id": "RCR",
    "nameMenu": "Reporte Comprobantes Recibidos",
    "titlePage": "Reporte Comprobantes Recibidos",
    titleTab: "Reporte comprobantes recibidos",
    "menuPadre": "REPO"
  },

]

