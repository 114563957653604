import React, { Fragment } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  Divider,
} from "@material-ui/core";
import { domainSis } from "configurations/constant";

const useStyles = makeStyles((theme) => ({
  large: {
    width: "200px",
    height: "60px",
  },
  welcome: {
    fontSize: "20px",
    textAlign: "center",
    fontWeight: "lighter",
  },
  newVersion: {
    fontSize: "16px",
    textAlign: "center",
    fontWeight: "bold",
  },
  legend: {
    textAlign: "center",
  },
  button: {
    textAlign: "center",
  },
}));

const MsmWelcome = ({ openMsm, handleCloseMsm }) => {
  const classes = useStyles();
  const logo = window.location.hostname.includes(domainSis) ? "../oYmSinFondo.png" : "../facturar12020.png";

  return (
    <Fragment>
      <Dialog fullWidth maxWidth="sm" open={openMsm} onClose={handleCloseMsm} disableBackdropClick >
        <DialogContent >
          <Grid
            container
            justify="center"
            align="center"
            direction="row"
            spacing={2}
          >
            <img
              src={logo}
              alt="logo"
              className={classes.large}
            />
          </Grid>
          <br></br>
          <Divider></Divider>
          <br></br>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
          >

            <Grid item md={12}>
              <Typography>
                Estimado cliente, de acuerdo a las nuevas actualizaciones al Régimen Tributario Interno le recordamos revisar si usted pertenece o no
                al Régimen Simplificado para Emprendedores y Negocios Populares (RIMPE), y actualizar esta información en la opción <strong> Información Empresa</strong>
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
            ></Grid>

            <Grid item md={12} className={classes.button}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCloseMsm}
              >
                Aceptar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default MsmWelcome;
