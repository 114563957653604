import { CircularProgress, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TablePaginatedPay from 'widgets/Tables/TablePaginatedPay';
import TableSimple from 'widgets/Tables/TableSimple';
import tableStyle from "configurations/styles/table";
import { API_GET_ALL_PAYMENTS } from './apis';
import { getRUCEmpresa } from 'redux/actions/userActions';
import AcceptPaymentBtn from './Buttons/AcceptPaymentBtn';
import CancelPaymentBtn from './Buttons/CancelPaymentBtn';
import ReviewPaymentBtn from './Buttons/ReviewPaymentBtn';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: "35px",
        paddingBottom: "7%",
        flexGrow: 1,
        paddingLeft: "3%",
        paddingRight: "3%",
    },
    headerBottons: {
        padding: "0.5%",
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        height: "300px"
    }
}));



const Payments = () => {

    const classesView = useStyles();
    const tokenUser = useSelector((state) => state.user.tk);
    const [update, setUpdate] = useState(0);

    const columns = [
        { title: "RUC", field: "empresaContratante.idempresacontratante", cellStyle: tableStyle.cellTable, width: "8%", },
        { title: "Razón Social/Nombres", field: "empresaContratante.razonSocialempresa", cellStyle: tableStyle.cellLeft, width: "25%", },
        { title: "Plan", field: "paquete.nombre", cellStyle: tableStyle.cellTable, width: "7%", },
        { title: "Total ", field: "valorTotalPaquete", cellStyle: tableStyle.cellTable, width: "7%", render: rowData => rowData.valorTotalPaquete.toFixed(2) },
        { title: "Revisar Pago", cellStyle: tableStyle.cellTable, render: rowData => <ReviewPaymentBtn data={rowData} /> },
        { title: "Aprobar", field: "correo", cellStyle: tableStyle.cellTable, render: rowData => <AcceptPaymentBtn data={rowData} reload={reload} /> },
        { title: "Rechazar", cellStyle: tableStyle.cellTable, render: rowData => <CancelPaymentBtn data={rowData} reload={reload} /> },
    ];


    const requestConfig = {
        uri: API_GET_ALL_PAYMENTS + (tokenUser ? getRUCEmpresa(tokenUser) : "0"),
        metodo: "get",
        body: null,
        page: 0,
        elementos: 15,
        sort: 'codigoAsignacion,desc',
    };

    const reload = () => {
        setUpdate(1);
    };

    useEffect(() => {
        if (update === 1) {
            setUpdate(0);
        }
    }, [update]);

    const renderTable = () => {
        switch (update) {
            case 0:
                return (
                    <TablePaginatedPay
                        title=""
                        columns={columns}
                        requestConfig={requestConfig}
                        seleccion={false}
                    />
                );

            case 1:
            case 3:
                return (
                    <div className={classesView.loader}>
                        <CircularProgress />
                    </div>
                );
            case 2:
                return (
                    <TableSimple
                        columns={columns}
                        data={''}
                        title=""
                        numberRowsPerPage={15}
                    />
                );
            default:
                break;
        }
    };

    return (
        <div className={classesView.root}>
            {renderTable()}
        </div>
    );
}

export default Payments;