import React, { useState } from 'react';
import ClientData1 from './Forms/ClientData1';
import PerchaseSettlementData from './Forms/PerchaseSettlementData';
import useTitle from 'hooks/useTitle';
import { makeStyles, Paper } from '@material-ui/core';
import { useStylesGlobal } from 'configurations/styles/theme';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: '30px',
        flexGrow: 1,
        paddingLeft: '3%',
        paddingRight: '3%',
        marginBottom: "5%",
    },
}));


const PerchaseSettlement = () => {

    const classesView = useStyles();
    const classesGlobales = useStylesGlobal();
    const [idAsgCliente, setIdAsgCliente] = useState({});
    const [foundClient, setFoundClient] = useState({});
    const [options, setOptions] = React.useState([]);
    useTitle({ title: 'Liquidación de Compra' });



    return (
        <div className={classesView.root}>
            <Paper className={classesGlobales.paperHeader}>
                <h5 className={classesGlobales.titleHeader}>Emisión de Liquidación de Compra</h5>
            </Paper>

            <ClientData1
                setIdAsgCliente={setIdAsgCliente}
                setFoundClient={setFoundClient}
                foundClient={foundClient}
                setOptions={setOptions}
                options={options}
            />

            <PerchaseSettlementData
                setIdAsgCliente={setIdAsgCliente}
                idAsgCliente={idAsgCliente}
                setFoundClient={setFoundClient}
                setOptions={setOptions}
            />

        </div>
    );
}

export default PerchaseSettlement;