import React, { Fragment, useState, useEffect } from 'react'
import { IconButton, makeStyles, Dialog, DialogTitle, DialogContent, Button, Grid } from '@material-ui/core';
import RateReviewSharpIcon from '@material-ui/icons/RateReviewSharp';
import { palette, useStylesGlobal } from '../../../../../configurations/styles/theme';
import bigDecimal from 'js-big-decimal';

const useStyles = makeStyles(theme => ({
    IconButton: {
        marginTop: "0%",
        marginBottom: "0%",
        padding: "0px",
    },
    divInfoUser: {
        borderStyle: 'solid',
        borderColor: palette.primary.main,
        borderWidth: '1px',
        borderRadius: '8px',
        marginTop: '1%'
    },
    divlabel: {
        marginTop: '1%'
    },
    buttonClose: {
        width: '75%'
    },
    table: {
        flexGrow: 1,
        borderWidth: '2px',
        borderRadius: '8px',
        borderCollapse: 'collapse',
        border: '1px solid black',
        textAlign: 'center',
        padding: "0px",

    }
}))

const InfoTaxesBtn = ({ data }) => {
    const classes = useStyles();
    const classesGlobales = useStylesGlobal();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const handleTable = (data) => {
        setOpen(true);
    }
    useEffect(() => {

    }, [open])

    return (
        <Fragment>
            <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => handleTable(data)} >
                <RateReviewSharpIcon style={{ color: palette.info.dark }} />
            </IconButton>
            <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}  >
                <DialogTitle id="max-width-dialog-title">Impuestos</DialogTitle>
                <DialogContent >
                    <form >

                        <table class="table"  >

                            <thead class="thead-light">
                                <tr className={classes.table}>
                                    <th>Tipo</th>
                                    <th >Código</th>
                                    <th >Descripción</th>
                                    <th >Porcentaje</th>
                                    <th >Base imponible</th>
                                    <th >Total impuesto</th>
                                </tr>
                            </thead>
                            {data.impuestoIce.length >= 1 && data.impuestoIce[0].CODIGO !== 'Ninguno' ?
                                <tbody>

                                    <tr className={classes.table}>
                                        <td className={classes.table}>ICE </td>
                                        <td className={classes.table}>{data.impuestoIce[0].CODIGO}</td>
                                        <td className={classes.table}>{data.impuestoIce[0].NOMBRE}</td>
                                        <td className={classes.table}>{data.impuestoIce[0].VALOR}%</td>
                                        <td className={classes.table}>{data.total} </td>
                                        <td className={classes.table}>{bigDecimal.round(data.totalIceProducto, 2, bigDecimal.RoundingModes.HALF_UP)}</td>
                                    </tr>

                                    <tr className={classes.table}>
                                        <td>IVA  </td>
                                        <td className={classes.table}>{data.impuestoIva[0].CODIGO}</td>
                                        <td className={classes.table}>{data.impuestoIva[0].NOMBRE}</td>
                                        <td className={classes.table}>{data.impuestoIva[0].VALOR}%</td>
                                        <td className={classes.table}>{bigDecimal.round(bigDecimal.add(data.total, data.totalIceProducto), 2, bigDecimal.RoundingModes.HALF_UP)}</td>
                                        <td className={classes.table}>{bigDecimal.round(bigDecimal.multiply(bigDecimal.add(data.total, data.totalIceProducto), bigDecimal.divide(data.impuestoIva[0].VALOR, '100')), 2, bigDecimal.RoundingModes.HALF_UP)}</td>
                                    </tr>

                                </tbody> :
                                <tbody>
                                    <tr className={classes.table}>
                                        <td className={classes.table}>IVA  </td>
                                        <td className={classes.table}>{data.impuestoIva[0].CODIGO}</td>
                                        <td className={classes.table}>{data.impuestoIva[0].NOMBRE}</td>
                                        <td className={classes.table}>{data.impuestoIva[0].VALOR}%</td>
                                        <td className={classes.table}>{bigDecimal.round(data.total, 2, bigDecimal.RoundingModes.HALF_UP)}</td>
                                        <td className={classes.table}>{bigDecimal.round(bigDecimal.multiply(data.total, bigDecimal.divide(data.impuestoIva[0].VALOR, '100')), 2, bigDecimal.RoundingModes.HALF_UP)}</td>
                                    </tr>
                                </tbody>
                            }
                        </table>

                    </form>
                    <Grid container justify='center'>
                        <Button size='large' variant="outlined" color="primary" onClick={handleClose} >
                            Cerrar
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>

        </Fragment >
    );
}

export default InfoTaxesBtn;