import { Backdrop, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, makeStyles } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { palette, useStylesGlobal } from 'configurations/styles/theme';
import middleware from "configurations/axios";
import apis from 'views/administración/UserAccount/apis';
import CompanyForm from 'views/administración/UserAccount/Forms/CompanyForm';
import CompanyLogo from 'views/administración/UserAccount/CompanyLogo';
import UpdateCertificate from 'views/administración/UserAccount/UpdateCertificate';
import RideLogo from 'views/administración/UserAccount/RideLogo';
import { useDispatch } from 'react-redux';
import { updateCompanyLogoAction } from 'redux/actions/resourcesActions';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    IconButton: {
        marginTop: "0%",
        marginBottom: "0%",
        padding: "0px",
    },
    textFieldComboBox: {
        minWidth: "75%",
        maxWidth: "95%",
    },
    container: {
        marginTop: "1%",
        border: "1px solid #BFBFBF",
        padding: "1%",
        borderRadius: "8px",
        boxShadow: "10px 10px 5px #aaaaaa",
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 1,
        color: palette.primary.main,
    },
}));

const EditCompanyBtn = ({ data, reload }) => {
    const classes = useStyles();
    const classesGlobal = useStylesGlobal();
    const [openLoader, setOpenLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [company, setCompany] = useState(null);
    const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
    const dispatch = useDispatch();
    const updateLogo = (urlLogo) => dispatch(updateCompanyLogoAction(urlLogo));
    const { enqueueSnackbar } = useSnackbar();


    const handleOpenClick = () => {
        setOpenLoader(true)
        getCompany()

    }

    const handleClose = () => {
        console.log('entro a cerrar')
        setOpen(false);
    }

    const handleCloseReload = () => {
        enqueueSnackbar(`Empresa actualizada.`, {
            variant: "success",
            anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            },
            autoHideDuration: 3000,
        });
        reload();
        setOpen(false);
    }

    const getCompany = async () => {
        await middleware
            .get(apis.get_company + (data ? data.idempresacontratante : "0"))
            .then((response) => {
                if (response.status >= 200 && response.status <= 300) {
                    setCompany(response.data);
                    setOpenLoader(false)
                    setOpen(true);

                }
            })
            .catch((excepcion) => {
                if (excepcion.response) {
                    if (excepcion.response.status === 401) {
                        //history.push("/");
                    }
                } else {
                }
            });
    };

    function closeAlert() {
        setMsg({ show: false });
    }

    const handleShowAlert = (alert) => {
        setMsg(alert);
    };

    const handleSubmitLogo = (logo) => {
        setOpenLoader(true);
        updateCompany({ ...company, logo });
    };

    const handleSubmitLogoRide = (logoRide) => {
        setOpenLoader(true);
        updateCompany({ ...company, logoRide });
    };

    const updateCompany = async (newCompany) => {
        await middleware
            .post(apis.update_company, newCompany)
            .then((response) => {
                if (response.status >= 200 && response.status <= 300) {
                    enqueueSnackbar(`Logo de la empresa actualizado.`, {
                        variant: "success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        },
                        autoHideDuration: 3000,
                    });
                    updateLogo(newCompany.logo);
                }
                setCompany(newCompany);
                handleClose();
                reload();
                setOpenLoader(false);
            })
            .catch((exception) => {
                if (exception.response) {
                    if (exception.response.status === 400) {
                        setMsg({
                            show: true,
                            msg: "No se pudo actualizar el logo la empresa.",
                            type: "error",
                        });
                    }
                } else {
                    setMsg({
                        show: true,
                        msg:
                            "Existen problemas en la red. Contáctese con el administrador del Sistema.",
                        type: "error",
                    });
                }
                setOpenLoader(false);
            });
    };

    return (
        <Fragment>

            <IconButton
                aria-label="delete"
                className={classes.IconButton}
                variant="outlined"
                color="secondary"
                onClick={handleOpenClick}
            >
                <EditIcon
                    style={{ color: palette.primary.main }}
                />
            </IconButton>

            <Dialog fullWidth maxWidth="xl" open={open} onClose={handleClose}
            >
                <DialogTitle id="max-width-dialog-title">Editar empresa:<strong> {data.razonSocialempresa} </strong></DialogTitle>
                <DialogContent>

                    <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.container} >
                        {msgError.show ? (
                            <Alert
                                tipo={msgError.type}
                                mensaje={msgError.msg}
                                mesajeSubrayado={null}
                                titulo={null}
                                mostrar={msgError.show}
                                cerrarAlerta={closeAlert}
                            />
                        ) : null}
                        {company ? (
                            <div class="row mt-2">
                                <div class="col-sm-8" align="left">
                                    <CompanyForm data={company} onShowAlert={handleShowAlert} handleCloseReload={handleCloseReload} />
                                </div>
                                <div class="col-sm-4">
                                    <div class="row">
                                        <div class="col-sm-12 mb-3">
                                            <CompanyLogo
                                                title={'Logo de la Empresa'}
                                                urlImage={company.logo}
                                                onSubmit={handleSubmitLogo}
                                                onShowAlert={handleShowAlert}
                                            />
                                        </div>
                                        <div class="col-sm-6 pl-0 pr-0">
                                            <UpdateCertificate
                                                idEmpresa={company.idempresa}
                                                onShowAlert={handleShowAlert}
                                            />
                                        </div>
                                        <div class="col-sm-6">
                                            <RideLogo
                                                urlImage={company.logoRide}
                                                onSubmit={handleSubmitLogoRide}
                                                onShowAlert={handleShowAlert}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                                <CircularProgress
                                    className={classes.circularProgress}
                                    color="secondary"
                                />
                            )}


                    </Grid>

                    <Grid container spacing={3} justify="center">
                        <Grid item md={12} xs={12}>
                            <Divider></Divider>
                        </Grid>

                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleClose}
                            className={classesGlobal.marginButton}
                        >
                            Cerrar
                </Button>
                    </Grid>

                </DialogContent>
            </Dialog>


            <Backdrop open={openLoader} className={classes.backdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>


        </Fragment>
    )
}

export default EditCompanyBtn;