import checkDocument from "../../../utils/checkDocument";
import checkInputs from "../../../utils/checkInputs";

const validations = {
  editCostumer: function validationEditUser(updatedCostumer) {
    const errors = {};
    if (!checkInputs.notNull(updatedCostumer.nombres)) {
      errors.nombres = "Es necesario.";
    } else if (!checkInputs.isAlphanumeric(updatedCostumer.nombres)) {
      errors.nombres = "Los nombres solo contienen letras.";
    } else if (updatedCostumer.nombres.length > 300) {
      errors.nombres = "No ingrese más de 300 caracteres.";
    }

    if (updatedCostumer.telefono) {
      if (!checkInputs.isNumeric(updatedCostumer.telefono)) {
        errors.telefono = "El teléfono solo puede contener números.";
      } else if (
        updatedCostumer.telefono.length < 7 ||
        updatedCostumer.telefono.length > 10
      ) {
        errors.telefono =
          "La cantidad de dígitos debe estar entre 7 (convencionales) a 10 (teléfonos celulares).";
      } else if (updatedCostumer.telefono.length > 300) {
        errors.telefono = "No ingrese más de 300 caracteres.";
      }
    }

    if (updatedCostumer.direccion) {
      if (!checkInputs.isAlphanumeric(updatedCostumer.direccion)) {
        errors.direccion =
          "Solo ingrese: letras, números, '#', '.' o paréntesis";
      } else if (updatedCostumer.direccion.length > 300) {
        errors.direccion = "No ingrese más de 300 caracteres.";
      }
    }

    if (!checkInputs.notNull(updatedCostumer.correo)) {
      errors.correo = "El correo es necesario.";
    } else if (!checkInputs.validationEmail(updatedCostumer.correo)) {
      errors.correo =
        "El correo es incorrecto. Debe contener el usuario, @ y el dominio.";
    } else if (updatedCostumer.correo.length > 300) {
      errors.correo = "No ingrese más de 300 caracteres.";
    }
    return errors;
  },
  addCostumer: function validationAddCostumer(newCostumer) {
    const errors = {};

    if (!checkInputs.notNull(newCostumer.identificacionCliente)) {
      errors.identificacionCliente = "Ingrese la identificación del usuario.";
    } else if (newCostumer.typeDocument === "C") {
      /*if (
        !checkDocument.validarCedula(newCostumer.identificacionCliente) &&
        !checkDocument.validarRucPersonaNatural(
          newCostumer.identificacionCliente
        ) &&
        !checkDocument.validarRucSociedadPrivada(
          newCostumer.identificacionCliente
        ) &&
        !checkDocument.validarRucSociedadPublica(
          newCostumer.identificacionCliente
        )
      ) {
        errors.identificacionCliente = "Cédula o RUC inválido.";
      }*/
      if (newCostumer.identificacionCliente.length <10) {
        errors.identificacionCliente = "Cédula o RUC inválido.";
      }

    } else if (!checkInputs.isAlphanumeric(newCostumer.identificacionCliente)) {
      errors.identificacionCliente =
        "No puede contener caracteres especiales, solo letras o números.";
    } else if (newCostumer.identificacionCliente.length > 300) {
      errors.identificacionCliente = "No ingrese más de 300 caracteres.";
    }

    if (!checkInputs.notNull(newCostumer.nombreCliente)) {
      errors.nombreCliente = "El nombre es necesario.";
    } else if (!checkInputs.notNull(newCostumer.nombreCliente)) {
      errors.nombreCliente = "El nombre es necesario.";
    } else if (!checkInputs.isAlphanumeric(newCostumer.nombreCliente)) {
      errors.nombreCliente = "Ingrese caracteres alfanuméricos.";
    } else if (newCostumer.nombreCliente.length > 300) {
      errors.nombreCliente = "No ingrese más de 300 caracteres.";
    }

    if (!checkInputs.notNull(newCostumer.correoCliente)) {
      errors.correoCliente = "El correo del usuario es necesario.";
    } else if (!checkInputs.notNull(newCostumer.correoCliente)) {
      errors.nombreCliente = "El correo del usuario es necesario.";
    } else if (!checkInputs.validationEmail(newCostumer.correoCliente)) {
      errors.correoCliente =
        "El correo del usuario es erroneo. Ingrese un correo con el formato user@ejemplo.com";
    } else if (newCostumer.correoCliente.length > 300) {
      errors.correoCliente = "No ingrese más de 300 caracteres.";
    }

    if (checkInputs.notNull(newCostumer.telefonoCliente)) {
      if (!checkInputs.isNumeric(newCostumer.telefonoCliente)) {
        errors.telefonoCliente = "Solo ingrese números.";
      } else if (newCostumer.telefonoCliente.length > 300) {
        errors.telefonoCliente = "No ingrese más de 300 caracteres.";
      }
    }

    if (checkInputs.notNull(newCostumer.direccionCliente)) {
      if (!checkInputs.isAlphanumeric(newCostumer.direccionCliente)) {
        errors.direccionCliente = "No ingrese caracteres especiales.";
      } else if (newCostumer.direccionCliente.length > 300) {
        errors.direccionCliente = "No ingrese más de 300 caracteres.";
      }
    }

    return errors;
  },
};

export default validations;
