// import React, { useState, useEffect } from "react";
// import {
//   Button,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   Backdrop,
//   CircularProgress,
//   makeStyles,
//   Grid,
//   TextField,
//   Divider,
// } from "@material-ui/core";
// import { palette, useStylesGlobal } from "configurations/styles/theme";
// import { useStylesApp } from "hooks/useStyles";
// import {
//   retentionCode,
//   typeTax,
//   retentionCodeIva,
//   retentionCodeIcd,
//   typeInvoice,
// } from "views/facturacion/Forms/typeVoucher";
// import { Autocomplete } from "@material-ui/lab";
// import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
// import esLocale from "date-fns/locale/es";
// import bigDecimal from "js-big-decimal";
// import { useSnackbar } from "notistack";
// import uuid from "react-uuid";
// import InputMask from "react-input-mask";
// import checkInputs from "utils/checkInputs";

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     marginTop: theme.spacing(3),
//     minWidth: 120,
//   },
//   radio: {
//     display: "-webkit-box",
//     marginTop: "0px",
//   },
//   radioLable: {
//     color: palette.primary.main,
//     display: "flex",
//   },
//   radioButton: {
//     marginTop: "0px",
//     paddingTop: "0px",
//   },
//   divForm: {
//     marginBottom: "1%",
//   },
//   divInput: {
//     marginBottom: "1%",
//     paddingRight: "1%",
//     paddingLeft: "0.5%",
//   },
//   divButton: {
//     paddingLeft: "3%",
//     paddingRight: "3%",
//     textAlign: "center",
//     display: "flex",
//     justifyContent: "space-around",
//     marginTop: "1%",
//     marginBottom: "1%",
//   },
//   backdrop: {
//     zIndex: theme.zIndex.modal + 999,
//     color: palette.primary.main,
//   },
// }));

// export default function useAddSupportDoc(
//   docsSustento,
//   setDocsSustento,
//   viewDetalle,
//   setViewDetalle,
//   docSustentoDetail,
//   setDocSustentoDetail
// ) {
//   const classes = useStyles();
//   const classesApp = useStylesApp();
//   const classesGlobales = useStylesGlobal();
//   const { enqueueSnackbar } = useSnackbar();
//   const minDate = new Date();
//   const minDate1 = minDate.setDate(minDate.getDate() - 90);
//   const [open, setOpen] = useState(false);
//   const [dataAditional, setDataAditional] = useState([]);
//   const [dataAditionalImpuestos, setDataAditionalImpuestos] = useState([]);
//   const [dataAditionalPagos, setDataAditionalPagos] = useState([]);
//   const [openLoader, setOpenLoader] = useState(false);
//   const [retention, setRetention] = useState({
//     CODIGO: "",
//     VALOR: 0,
//     NOMBRE: "",
//   });
//   const [tax, setTax] = useState();
//   const [retainedValue, setRetainedValue] = useState();
//   const [fechaEmisionDocSustento, setFechaEmisionDocSustento] = useState(
//     new Date()
//   );
//   const [docSustento, setDocSustento] = useState();
//   const [tipoSustento, setTipoSustento] = useState();
//   const [dataDocSustento, setDataDocSustento] = useState();
//   const [retentionDocSustento, setRetentionDocSustento] = useState({
//     codigoDocSustento: "",
//     fechaEmisionDocSustento: fechaEmisionDocSustento,
//     numeroDocSustento: "",
//     codSustento: "",
//   });
//   const [empty, setEmty] = useState({
//     documento: true,
//     numero: true,
//     fecha: true,
//     codigo: true,
//   });

//   const handleClickOpen = () => {
//     setOpen(true);
//     //setUser({ idEmpresa: getRUCEmpresa(userToken) });
//   };

//   const handleClose = () => {
//     setOpen(false);
//     clearTax();
//   };

//   const handleChangeData = (event) => {
//     event.preventDefault();
//     console.log(event.target.value);
//     const re = /^[0-9\b.]+$/;
//     switch (event.target.name) {
//       case "VALOR":
//         if (event.target.value === "" || re.test(event.target.value)) {
//           if (Number(event.target.value.trim()) >= 0) {
//             if (
//               checkInputs.countDecimals(event.target.value) <= 2 &&
//               checkInputs.countDigit(event.target.value) <= 3
//             ) {
//               setRetention({
//                 ...retention,
//                 ["VALOR"]: event.target.value,
//               });

//               if (
//                 typeof event.target.value !== "undefined" &&
//                 event.target.value.toString().trim() !== "" &&
//                 Number(event.target.value) !== 0
//               ) {
//                 setEmty({ ...empty, ["codigo"]: true });
//               }
//             }
//           }
//         }

//         break;
//       default:
//         break;
//     }
//   };

//   const calculateRetainedValue = (base, porcent) => {
//     if (validateDecimal(base.toString())) {
//       let retainedValue = bigDecimal.multiply(
//         base,
//         bigDecimal.divide(porcent ? porcent : "0", "100")
//       );
//       setRetainedValue(
//         bigDecimal.round(retainedValue, 2, bigDecimal.RoundingModes.HALF_UP)
//       );
//     }
//   };

//   const handleChangeDocSustento = (event) => {
//     event.preventDefault();
//     setRetentionDocSustento({
//       ...retentionDocSustento,
//       [event.target.name]: event.target.value,
//     });
//     if (
//       typeof retentionDocSustento.numeroDocSustento !== "undefined" &&
//       retentionDocSustento.numeroDocSustento.trim() !== "" &&
//       retentionDocSustento.numeroDocSustento.replace(/\D/g, "").length === 14
//     ) {
//       setEmty({ ...empty, ["numero"]: true });
//     }
//   };

//   const handleAddDocSustento = () => {
//     const errors = validateDocSustento();
//     const isValid =
//       Object.values(errors).filter((item) => item === false).length === 0;
//     setEmty(errors);

//     if (
//       isValid &&
//       dataAditional.length > 0 &&
//       dataAditionalImpuestos.length > 0 &&
//       dataAditionalPagos.length > 0 
//       // &&
//       // retentionDocSustento.numeroDocSustento != "" &&
//       // !retentionDocSustento.numeroDocSustento.includes("_")
//     ) {
//       let uuId = uuid();
//       setDocSustentoDetail([
//         ...docSustentoDetail,
//         {
//           id: uuId,
//           codigoDocSustento: retentionDocSustento.codigoDocSustento,
//           numeroDocSustento: retentionDocSustento.numeroDocSustento,
//           fechaEmisionDocSustento: fechaEmisionDocSustento,
//           codSustento: retentionDocSustento.codSustento,
//           impuestoRetencions: dataAditional.length > 0 ? dataAditional : [],
//           impuestos:
//             dataAditionalImpuestos.length > 0 ? dataAditionalImpuestos : [],
//           pagos: dataAditionalPagos.length > 0 ? dataAditionalPagos : [],
//         },
//       ]);

//       setDocsSustento([
//         ...docsSustento,
//         {
//           ...retentionDocSustento,
//           id: uuId,
//           fechaEmisionDocSustento: fechaEmisionDocSustento,
//           impuestoRetencions: dataAditional.length > 0 ? dataAditional : [],
//           impuestos:
//             dataAditionalImpuestos.length > 0 ? dataAditionalImpuestos : [],
//           pagos: dataAditionalPagos.length > 0 ? dataAditionalPagos : [],
//         },
//       ]);

//       if (viewDetalle === 1) {
//         setViewDetalle(2);
//       } else if (viewDetalle === 2) {
//         setViewDetalle(1);
//       }
//       setDataAditional([]);
//       setDataAditionalImpuestos([]);
//       setDataAditionalPagos([]);
//       handleClose();
//       clearTax();
//       enqueueSnackbar(`Documento de sustento agregado al comprobante`, {
//         variant: "success",
//         anchorOrigin: {
//           vertical: "top",
//           horizontal: "right",
//         },
//         autoHideDuration: 4000,
//       });
//     }
//   };

//   const validateDocSustento = () => {
//     let errors = {
//       documento: true,
//       numero: true,
//       fecha: true,
//       codigo: true,
//     };

//     if (
//       typeof retentionDocSustento.codigoDocSustento === "undefined" ||
//       retentionDocSustento.codigoDocSustento.trim() === ""
//     ) {
//       errors.documento = false;
//     }

//     if (
//       typeof retentionDocSustento.numeroDocSustento === "undefined" ||
//       retentionDocSustento.numeroDocSustento.trim() === "" ||
//       retentionDocSustento.numeroDocSustento.includes("_")
//     ) {
//       errors.numero = false;
//     }

//     if (
//       typeof retentionDocSustento.codSustento === "undefined" ||
//       retentionDocSustento.codSustento.trim() === ""
//     ) {
//       errors.codigo = false;
//     }

//     // if (typeof retentionDocSustento.codigoDocSustento !== 'undefined' && retentionDocSustento.codigoDocSustento.trim() !== '' && retentionDocSustento.codigoDocSustento !== '12') {
//     //     if (typeof retentionDocSustento.numeroDocSustento === 'undefined' || retentionDocSustento.numeroDocSustento.trim() === '' || setRetentionDocSustento.numeroDocSustento.replace(/\D/g, '').length < 15) {
//     //         errors.numero = false
//     //     }
//     // }

//     // if (typeof fechaEmisionDocSustento === 'undefined' || fechaEmisionDocSustento === '') {
//     //     errors.fecha = false
//     // }

//     return errors;
//   };

//   const clearTax = () => {
//     setRetentionDocSustento({
//       codigoDocSustento: "",
//       fechaEmisionDocSustento: fechaEmisionDocSustento,
//       numeroDocSustento: "",
//       codSustento: "",
//     });
//     setRetention({
//       CODIGO: "",
//       VALOR: 0,
//       NOMBRE: "",
//     });
//     setDocSustento();
//     setTipoSustento();
//     setDataAditional([]);
//     setDataAditionalImpuestos([]);
//     setDataAditionalPagos([]);
//     setEmty({
//       codigo: true,
//       documento: true,
//       numero: true,
//       fecha: true,
//     });
//     setFechaEmisionDocSustento(new Date());
//   };

//   const validateDecimal = (numberValor) => {
//     let splitList = numberValor.split(".");
//     if (splitList.length > 2) {
//       enqueueSnackbar(
//         `El dato ingresado es erroneo utilice punto (.) para separar decimales`,
//         {
//           variant: "error",
//           anchorOrigin: {
//             vertical: "top",
//             horizontal: "right",
//           },
//           autoHideDuration: 4000,
//         }
//       );
//       return false;
//     } else if (splitList.length > 1) {
//       if (splitList[1].length > 2) {
//         enqueueSnackbar(
//           `Los datos numéricos debe tener máximo 2 decimales Ej, 12.43`,
//           {
//             variant: "error",
//             anchorOrigin: {
//               vertical: "top",
//               horizontal: "right",
//             },
//             autoHideDuration: 4000,
//           }
//         );
//         return false;
//       } else {
//         return true;
//       }
//     } else {
//       return true;
//     }
//   };

//   return {
//     classes,
//     classesApp,
//     classesGlobales,
//     minDate,
//     minDate1,
//     open,
//     openLoader,
//     setOpenLoader,
//     retention,
//     docSustento,
//     tipoSustento,
//     fechaEmisionDocSustento,
//     dataDocSustento,
//     setDataDocSustento,
//     dataAditional,
//     setDataAditional,
//     dataAditionalImpuestos,
//     setDataAditionalImpuestos,
//     dataAditionalPagos,
//     setDataAditionalPagos,
//     retentionDocSustento,
//     empty,
//     handleClickOpen,
//     handleClose,
//     handleChangeData,
//     handleChangeDocSustento,
//     handleAddDocSustento,
//     setTipoSustento,
//     setRetentionDocSustento,
//     setEmty,
//     setRetention,
//     setDocSustento,
//     setFechaEmisionDocSustento,
//   };
// }


import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Backdrop,
  CircularProgress,
  makeStyles,
  Grid,
  TextField,
  Divider,
} from "@material-ui/core";
import { palette, useStylesGlobal } from "configurations/styles/theme";
import { useStylesApp } from "hooks/useStyles";
import {
  retentionCode,
  typeTax,
  retentionCodeIva,
  retentionCodeIcd,
  typeInvoice,
} from "views/facturacion/Forms/typeVoucher";
import { Autocomplete } from "@material-ui/lab";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import bigDecimal from "js-big-decimal";
import { useSnackbar } from "notistack";
import uuid from "react-uuid";
import InputMask from "react-input-mask";
import checkInputs from "utils/checkInputs";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(3),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  divForm: {
    marginBottom: "1%",
  },
  divInput: {
    marginBottom: "1%",
    paddingRight: "1%",
    paddingLeft: "0.5%",
  },
  divButton: {
    paddingLeft: "3%",
    paddingRight: "3%",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    marginTop: "1%",
    marginBottom: "1%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
}));

export default function useAddSupportDoc(
  docsSustento,
  setDocsSustento,
  viewDetalle,
  setViewDetalle,
  docSustentoDetail,
  setDocSustentoDetail
) {
  const classes = useStyles();
  const classesApp = useStylesApp();
  const classesGlobales = useStylesGlobal();
  const { enqueueSnackbar } = useSnackbar();
  const minDate = new Date();
  const minDate1 = minDate.setDate(minDate.getDate() - 90);
  const [open, setOpen] = useState(false);
  const [dataAditional, setDataAditional] = useState([]);
  const [dataAditionalImpuestos, setDataAditionalImpuestos] = useState([]);
  const [dataAditionalPagos, setDataAditionalPagos] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);
  const [retention, setRetention] = useState({
    CODIGO: "",
    VALOR: 0,
    NOMBRE: "",
  });
  const [tax, setTax] = useState();
  const [retainedValue, setRetainedValue] = useState();
  const [fechaEmisionDocSustento, setFechaEmisionDocSustento] = useState(
    new Date()
  );

  const [docSustento, setDocSustento] = useState();
  const [tipoSustento, setTipoSustento] = useState();
  const [dataDocSustento, setDataDocSustento] = useState();
  const [retentionDocSustento, setRetentionDocSustento] = useState({
    codigoDocSustento: "",
    fechaEmisionDocSustento: fechaEmisionDocSustento,
    numeroDocSustento: "",
    codSustento: "",
  });
  const [empty, setEmty] = useState({
    documento: true,
    numero: true,
    fecha: true,
    codigo: true,
  });

  const [pagoLocExt, setPagoLocExt] = useState("01");

  const [addInformationExterior, setAddInformationExterior] = useState({
    tipoRegi: "",
    paisEfecPago: "",
    aplicConvDobTrib: "",
    pagExtSujRetNorLeg: "",
    pagoRegFis: "",
  });

  const handleChangePagoLocExt = (event) => {
    setPagoLocExt(event.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
    //setUser({ idEmpresa: getRUCEmpresa(userToken) });
  };

  const handleClose = () => {
    setOpen(false);
    clearTax();
  };

  const handleChangeData = (event) => {
    event.preventDefault();
    console.log(event.target.value);
    const re = /^[0-9\b.]+$/;
    switch (event.target.name) {
      case "VALOR":
        if (event.target.value === "" || re.test(event.target.value)) {
          if (Number(event.target.value.trim()) >= 0) {
            if (
              checkInputs.countDecimals(event.target.value) <= 2 &&
              checkInputs.countDigit(event.target.value) <= 3
            ) {
              setRetention({
                ...retention,
                ["VALOR"]: event.target.value,
              });

              if (
                typeof event.target.value !== "undefined" &&
                event.target.value.toString().trim() !== "" &&
                Number(event.target.value) !== 0
              ) {
                setEmty({ ...empty, ["codigo"]: true });
              }
            }
          }
        }

        break;
      default:
        break;
    }
  };

  const calculateRetainedValue = (base, porcent) => {
    if (validateDecimal(base.toString())) {
      let retainedValue = bigDecimal.multiply(
        base,
        bigDecimal.divide(porcent ? porcent : "0", "100")
      );
      setRetainedValue(
        bigDecimal.round(retainedValue, 2, bigDecimal.RoundingModes.HALF_UP)
      );
    }
  };

  const handleChangeDocSustento = (event) => {
    event.preventDefault();
    setRetentionDocSustento({
      ...retentionDocSustento,
      [event.target.name]: event.target.value,
    });
    if (
      typeof retentionDocSustento.numeroDocSustento !== "undefined" &&
      retentionDocSustento.numeroDocSustento.trim() !== "" &&
      retentionDocSustento.numeroDocSustento.replace(/\D/g, "").length === 14
    ) {
      setEmty({ ...empty, ["numero"]: true });
    }
  };

  const handleAddDocSustento = () => {
    const errors = validateDocSustento();
    const isValid =
      Object.values(errors).filter((item) => item === false).length === 0;
    setEmty(errors);

    if (
      isValid &&
      dataAditional.length > 0 &&
      dataAditionalImpuestos.length > 0 &&
      dataAditionalPagos.length > 0
      // &&
      // retentionDocSustento.numeroDocSustento != "" &&
      // !retentionDocSustento.numeroDocSustento.includes("_")
    ) {
      let uuId = uuid();
      setDocSustentoDetail([
        ...docSustentoDetail,
        {
          id: uuId,
          codigoDocSustento: retentionDocSustento.codigoDocSustento,
          numeroDocSustento: retentionDocSustento.numeroDocSustento,
          fechaEmisionDocSustento: fechaEmisionDocSustento,
          codSustento: retentionDocSustento.codSustento,
          impuestoRetencions: dataAditional.length > 0 ? dataAditional : [],
          impuestos:
            dataAditionalImpuestos.length > 0 ? dataAditionalImpuestos : [],
          pagos: dataAditionalPagos.length > 0 ? dataAditionalPagos : [],
          pagoLocExt: pagoLocExt,
          tipoRegi: addInformationExterior.tipoRegi,
          paisEfecPago: addInformationExterior.paisEfecPago,
          aplicConvDobTrib: addInformationExterior.aplicConvDobTrib,
          pagExtSujRetNorLeg: addInformationExterior.pagExtSujRetNorLeg,
          pagoRegFis: addInformationExterior.pagoRegFis,
        },
      ]);

      setDocsSustento([
        ...docsSustento,
        {
          ...retentionDocSustento,
          id: uuId,
          fechaEmisionDocSustento: fechaEmisionDocSustento,
          impuestoRetencions: dataAditional.length > 0 ? dataAditional : [],
          impuestos:
            dataAditionalImpuestos.length > 0 ? dataAditionalImpuestos : [],
          pagos: dataAditionalPagos.length > 0 ? dataAditionalPagos : [],
          pagoLocExt: pagoLocExt,
          tipoRegi: addInformationExterior.tipoRegi,
          paisEfecPago: addInformationExterior.paisEfecPago,
          aplicConvDobTrib: addInformationExterior.aplicConvDobTrib,
          pagExtSujRetNorLeg: addInformationExterior.pagExtSujRetNorLeg,
          pagoRegFis: addInformationExterior.pagoRegFis,
        },
      ]);

      if (viewDetalle === 1) {
        setViewDetalle(2);
      } else if (viewDetalle === 2) {
        setViewDetalle(1);
      }
      setDataAditional([]);
      setDataAditionalImpuestos([]);
      setDataAditionalPagos([]);
      setAddInformationExterior({
        tipoRegi: "",
        paisEfecPago: "",
        aplicConvDobTrib: "",
        pagExtSujRetNorLeg: "",
        pagoRegFis: "",
      });
      handleClose();
      clearTax();
      enqueueSnackbar(`Documento de sustento agregado al comprobante`, {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        autoHideDuration: 4000,
      });
    }
  };

  const validateDocSustento = () => {
    let errors = {
      documento: true,
      numero: true,
      fecha: true,
      codigo: true,
    };

    if (
      typeof retentionDocSustento.codigoDocSustento === "undefined" ||
      retentionDocSustento.codigoDocSustento.trim() === ""
    ) {
      errors.documento = false;
    }

    if (
      typeof retentionDocSustento.numeroDocSustento === "undefined" ||
      retentionDocSustento.numeroDocSustento.trim() === "" ||
      retentionDocSustento.numeroDocSustento.includes("_")
    ) {
      errors.numero = false;
    }

    if (
      typeof retentionDocSustento.codSustento === "undefined" ||
      retentionDocSustento.codSustento.trim() === ""
    ) {
      errors.codigo = false;
    }

    // if (typeof retentionDocSustento.codigoDocSustento !== 'undefined' && retentionDocSustento.codigoDocSustento.trim() !== '' && retentionDocSustento.codigoDocSustento !== '12') {
    //     if (typeof retentionDocSustento.numeroDocSustento === 'undefined' || retentionDocSustento.numeroDocSustento.trim() === '' || setRetentionDocSustento.numeroDocSustento.replace(/\D/g, '').length < 15) {
    //         errors.numero = false
    //     }
    // }

    // if (typeof fechaEmisionDocSustento === 'undefined' || fechaEmisionDocSustento === '') {
    //     errors.fecha = false
    // }

    return errors;
  };

  const clearTax = () => {
    setRetentionDocSustento({
      codigoDocSustento: "",
      fechaEmisionDocSustento: fechaEmisionDocSustento,
      numeroDocSustento: "",
      codSustento: "",
    });
    setRetention({
      CODIGO: "",
      VALOR: 0,
      NOMBRE: "",
    });
    setDocSustento();
    setTipoSustento();
    setDataAditional([]);
    setDataAditionalImpuestos([]);
    setDataAditionalPagos([]);
    setEmty({
      codigo: true,
      documento: true,
      numero: true,
      fecha: true,
    });
    setFechaEmisionDocSustento(new Date());
  };

  const validateDecimal = (numberValor) => {
    let splitList = numberValor.split(".");
    if (splitList.length > 2) {
      enqueueSnackbar(
        `El dato ingresado es erroneo utilice punto (.) para separar decimales`,
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 4000,
        }
      );
      return false;
    } else if (splitList.length > 1) {
      if (splitList[1].length > 2) {
        enqueueSnackbar(
          `Los datos numéricos debe tener máximo 2 decimales Ej, 12.43`,
          {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 4000,
          }
        );
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  return {
    classes,
    classesApp,
    classesGlobales,
    minDate,
    minDate1,
    open,
    openLoader,
    setOpenLoader,
    retention,
    docSustento,
    tipoSustento,
    fechaEmisionDocSustento,
    dataDocSustento,
    setDataDocSustento,
    dataAditional,
    setDataAditional,
    dataAditionalImpuestos,
    setDataAditionalImpuestos,
    dataAditionalPagos,
    setDataAditionalPagos,
    retentionDocSustento,
    empty,
    pagoLocExt,
    addInformationExterior,
    handleClickOpen,
    handleClose,
    handleChangeData,
    handleChangeDocSustento,
    handleAddDocSustento,
    setTipoSustento,
    setRetentionDocSustento,
    setEmty,
    setRetention,
    setDocSustento,
    setFechaEmisionDocSustento,
    setPagoLocExt,
    setAddInformationExterior,
    handleChangePagoLocExt,
  };
}
