export const ADD_RESOURSE_LOADING = "ADD_RESOURSE_LOADING";
export const ADD_RESOURSE_SUCCESS = "ADD_RESOURSE_SUCCESS";
export const ADD_RESOURSE_ERROR = "ADD_RESOURSE_ERROR";
export const ADD_RESOURSE_DENIED = "ADD_RESOURSE_DENIED";

export const ADD_INFO_COMPANY = "ADD_INFO_COMPANY"
export const UPDATE_INFO_COMPANY_LOGO = "UPDATE_INFO_COMPANY_LOGO"
export const ADD_ERROR_COMPANY = "ADD_ERROR_COMPANY"

export const SELECTED_RESOURCE = "SELECTED_RESOURCE";

export const ISLOADING_INFO_COMPANY = "ISLOADING_INFO_COMPANY";

export const REMOVE_RESOURCE = "REMOVE_RESOURCE";

export const ADD_CONFIG_RESOURCES = "ADD_CONFIG_RESOURCES";
export const DELETE_CONFIG_RESOURCES = "DELETE_CONFIG_RESOURCES";