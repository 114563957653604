import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  CardHeader,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import {
  msmSwalExito,
  msmSwalError,
  palette,
  useStylesGlobal,
} from "configurations/styles/theme";

import {
  addReasonsAction,
  AddMoreInfoAction,
  clearDebitNoteAction,
  changeErrorLabelsAction,
} from "redux/actions/debitNoteActions";
import { useDispatch, useSelector } from "react-redux";
import AssignmentIcon from "@material-ui/icons/Assignment";
import FooterForm from "../../General/FooterForm";
import Totals from "../Forms/Totals";
import apis from "../apis";
import { httpCore, clientBackendPDF } from "configurations/axios";
import { ConfirmAlert, iconsAlert, ErrorsAlert } from "utils/AlertsCustom";
import BtnAddReason from "../Buttons/AddReason";
import AllReasons from "../Tables/AllReasons";
import validations from "../validations";
import { selectedResourceAction } from "redux/actions/resourcesActions";
import { checkPreRequirements } from "widgets/menu/preRedirect";
import { getRUCEmpresa, getSub } from "redux/actions/userActions";
import { getNumberVoucherSuccess } from "redux/actions/numberVouchersAction";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "50px",
    flexGrow: 1,
  },
  IconButton: {
    marginTop: "0%",
    marginBottom: "0%",
    padding: "0px",
  },
  subtitel: {
    color: palette.primary.contrastText,
    background: palette.primary.light,
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
  },

  divider: {
    marginTop: "2%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 10,
    color: "#fff",
  },
  containerDebitNote: {
    borderRadius: "8px",
    borderStyle: "solid",
    borderWidth: "1px",
    boxShadow: "5px 3px 3px #aaaaaa",
    marginBottom: "1%",
    padding: "0% 0% 0% 0%",
  },
}));

const FormsReasons = ({ onClean }) => {
  const classes = useStyles();
  const classesGlobales = useStylesGlobal();
  const [update, setUpdate] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const tokenUser = useSelector((state) => state.user.tk);
  const debitNote = useSelector((state) => state.debitNote);
  const dispatch = useDispatch();
  const changeErrors = (errors) => dispatch(changeErrorLabelsAction(errors));
  const clearDebitNote = () => dispatch(clearDebitNoteAction());
  const selectedResource = (resource) =>
    dispatch(selectedResourceAction(resource));
  const addMoreInfoDebitNote = (moreInfo) =>
    dispatch(AddMoreInfoAction(moreInfo));

  const addReasons = (reason) => {
    dispatch(addReasonsAction([...debitNote.motivos, reason]));
  };

  const validation = () => {
    const errors = validations.allData(debitNote);
    changeErrors(errors);
    ErrorsAlert(errors);
    return Object.values(errors).length === 0;
  };

  const handleClickPdf = async () => {
    if (validation()) {
      setOpenLoader(true);
      return await clientBackendPDF
        .post(apis.post_generate_ride, debitNote)
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          setOpenLoader(false);
          window.open(fileURL);
        })
        .catch((exception) => {
          if (exception.response) {
            msmSwalError("Error al generar el pdf.");
            console.error("error", { exception });
          } else {
            msmSwalError(
              "Existen problemas en la red. Contáctese con el administrador del Sistema."
            );
            console.error({ exception });
          }
          setOpenLoader(false);
        });
    }
  };

  const handleClickSigned = async () => {
    if (validation()) {
      setOpenLoader(true);
      await httpCore
        .post(apis.post_save_debit_note, debitNote)
        .then((response) => {
          if (response.status >= 200 && response.status <= 300) {
            if (typeof onClean === "function") {
              onClean();
            }
            //para actualizar el contador de comprobantes
            dispatch(getNumberVoucherSuccess(response.numeroComprobantesDisponibles));
            clearDebitNote();
            setUpdate(true);
            selectedResource({
              "id": -4,
              "nameMenu": "Verificando pre-requisitos",
              "titlePage": "Verificando pre-requisitos",
              titleTab: "Inicio",
              "menuPadre": ""
            })
            msmSwalExito(
              "Comprobante generado",
              `En unos minutos será enviado al SRI para su autorización.`,
              { show: true, text: "Ver Comprobantes Pendientes" }
            ).then((response) => {
              if (response.isDismissed) {
                selectedResource({
                  id: "PEN",
                  nameMenu: "Pendientes",
                  titlePage: "Pendientes",
                  titleTab: "Comp. Pendientes",
                  menuPadre: "REP",
                });
              }
            });
          }
          setOpenLoader(false);
        })
        .catch((excepcion) => {
          if (excepcion.response) {
            msmSwalError("Error al registar la Nota de Débito.");
          } else {
            msmSwalError(
              "Existen problemas en la red. Contáctese con el administrador del Sistema."
            );
          }

          setOpenLoader(false);
        });
    }
  };

  const handleCleanForm = () => {
    ConfirmAlert(
      "¿Está seguro de limpiar los datos del formulario?",
      "",
      iconsAlert.question,
      true,
      "Sí",
      "Cancelar",
      () => {
        clearDebitNote();
        if (typeof onClean === "function") {
          onClean();
        }
        setUpdate(true);
        return Promise.resolve({
          text: "Los datos del formulario fueron eliminados.",
        });
      },
      () => { }
    );
  };


  const validatePrerequisities = (ride) => {
    const tk = tokenUser ? tokenUser : "";
    if (tk !== "") {
      setOpenLoader(true);
      checkPreRequirements(getSub(tk), getRUCEmpresa(tk), dispatch)
        .then((newErrorsCompany) => {
          console.log(newErrorsCompany)
          if (Array.isArray(newErrorsCompany)) {
            if (newErrorsCompany.length === 0) {
              if (ride) {
                handleClickPdf();
              } else {
                handleClickSigned()
              }
            } else {
              selectedResource({
                "id": -4,
                "nameMenu": "Verificando pre-requisitos",
                "titlePage": "Verificando pre-requisitos",
                titleTab: "Inicio",
                "menuPadre": ""
              })
            }
          }
        }).finally(() => {
          setOpenLoader(false);
        })
    }
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.containerDebitNote}>
        <Grid container xs={12} justify="center">
          <CardHeader
            className={classesGlobales.cabecera}
            title={"Motivos"}
            avatar={<AssignmentIcon />}
          />
        </Grid>

        <BtnAddReason onSubmit={addReasons} />
        <AllReasons />

        {debitNote.motivos.length > 0 ? (
          <Grid item xs={12}>
            <Totals listOfReasons={debitNote.motivos} update={update} />
          </Grid>
        ) : null}
      </Grid>
      {debitNote.motivos.length > 0 ? (
        <FooterForm
          dataAditional={debitNote.infromacionAdicional}
          msgMoreInfo="¿Desea agregar Información Adicional a la nota de débito?"
          setDataAditional={addMoreInfoDebitNote}
          onClickBtnPdf={handleClickPdf}
          onClickBtnSign={handleClickSigned}
          validatePrerequisities={validatePrerequisities}
          onClickCleanForm={handleCleanForm}
          id="more-information"
        />
      ) : null}
      <Backdrop className={classes.backdrop} open={openLoader}>
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
};

export default FormsReasons;
