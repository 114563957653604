import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { palette } from "configurations/styles/theme";


const useStyles = makeStyles((theme) => ({
    //tableHead
    tableHead: {
        background: palette.primary.main,
    },
    tableHeadRow: {
        color: "#FFF"
    },
}));


const TableHeadVoucherAuthorized = ({ onSelectAllClick, numSelected, rowCount }) => {
    const classes = useStyles();

    return (<TableHead classes={{
        root: classes.tableHead,
    }} >
        <TableRow >
            <TableCell padding="checkbox"  >
                <Checkbox
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={onSelectAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                />
            </TableCell>
            <TableCell align="center" classes={{ root: classes.tableHeadRow }} padding="none" >Número</TableCell>
            <TableCell align="center" classes={{ root: classes.tableHeadRow }} padding="none">Fecha Emisión</TableCell>
            <TableCell align="center" classes={{ root: classes.tableHeadRow }} padding="none">Fecha Autorización</TableCell>
            <TableCell align="left" classes={{ root: classes.tableHeadRow }} padding="none">Receptor</TableCell>
            <TableCell align="center" classes={{ root: classes.tableHeadRow }} padding="none">Tipo</TableCell>
            <TableCell align="center" classes={{ root: classes.tableHeadRow }} padding="none">PDF</TableCell>
            <TableCell align="center" classes={{ root: classes.tableHeadRow }} padding="none">XML</TableCell>            
            <TableCell align="center" classes={{ root: classes.tableHeadRow }} padding="none">Reenviar</TableCell>
        </TableRow>
    </TableHead>)
}

TableHeadVoucherAuthorized.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
};


export default TableHeadVoucherAuthorized;