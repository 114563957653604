import React, { useEffect, useState } from "react";
import { makeStyles, Backdrop, CircularProgress } from "@material-ui/core";

//componentes propios
import CompanyLogo from "./CompanyLogo";
import CompanyForm from "./Forms/CompanyForm";
import UpdateCertificate from "./UpdateCertificate";
import middleware from "configurations/axios";
import apis from "./apis";
import { useSelector, useDispatch } from "react-redux";
import { getRUCEmpresa } from "redux/actions/userActions";
import { updateCompanyLogoAction } from "redux/actions/resourcesActions";
import Alert from "widgets/alert";
import RideLogo from "./RideLogo";
import { palette } from "configurations/styles/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "1%",
    border: "1px solid #BFBFBF",
    padding: "1%",
    borderRadius: "8px",
    boxShadow: "10px 10px 5px #aaaaaa",
  },
  circularProgress: {
    margin: "10%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
}));

const CompanyData = () => {
  const classes = useStyles();
  const tokenUser = useSelector((state) => state.user.tk);
  const [company, setCompany] = useState(null);
  const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
  const [openLoader, setOpenLoader] = useState(false);
  const dispatch = useDispatch();
  const updateLogo = (urlLogo) => dispatch(updateCompanyLogoAction(urlLogo))
  useEffect(() => {
    const getCompany = async () => {
      await middleware
        .get(apis.get_company + (tokenUser ? getRUCEmpresa(tokenUser) : "0"))
        .then((response) => {
          if (response.status >= 200 && response.status <= 300) {
            setCompany(response.data);
          }
        })
        .catch((excepcion) => {
          if (excepcion.response) {
            if (excepcion.response.status === 401) {
              //history.push("/");
            }
          } else {
          }
        });
    };
    getCompany();
  }, []);

  function closeAlert() {
    setMsg({ show: false });
  }

  const handleShowAlert = (alert) => {
    setMsg(alert);
  };

  const handleSubmitLogo = (logo) => {
    setOpenLoader(true);
    updateCompany({ ...company, logo });
  };

  const handleSubmitLogoRide = (logoRide) => {
    setOpenLoader(true);
    updateCompany({ ...company, logoRide });
  };

  const updateCompany = async (newCompany) => {
    await middleware
      .post(apis.update_company, newCompany)
      .then((response) => {
        if (response.status >= 200 && response.status <= 300) {
          setMsg({
            show: true,
            msg: "Logo de la empresa actualizado.",
            type: "success",
          });
          updateLogo(newCompany.logo);
        }
        setCompany(newCompany);
        setOpenLoader(false);
      })
      .catch((exception) => {
        if (exception.response) {
          if (exception.response.status === 400) {
            setMsg({
              show: true,
              msg: "No se pudo actualizar el logo la empresa.",
              type: "error",
            });
          }
        } else {
          setMsg({
            show: true,
            msg:
              "Existen problemas en la red. Contáctese con el administrador del Sistema.",
            type: "error",
          });
        }
        setOpenLoader(false);
      });
  };

  return (
    <>
      <div className={classes.container} align="center">
        {msgError.show ? (
          <Alert
            tipo={msgError.type}
            mensaje={msgError.msg}
            mesajeSubrayado={null}
            titulo={null}
            mostrar={msgError.show}
            cerrarAlerta={closeAlert}
          />
        ) : null}
        {company ? (
          <div class="row mt-2">
            <div class="col-sm-8" align="left">
              <CompanyForm data={company} onShowAlert={handleShowAlert} />
            </div>
            <div class="col-sm-4">
              <div class="row">
                <div class="col-sm-12 mb-3">
                  <CompanyLogo
                    title={'Logo de la Empresa'}
                    urlImage={company.logo}
                    onSubmit={handleSubmitLogo}
                    onShowAlert={handleShowAlert}
                  />
                </div>
                <div class="col-sm-6 pl-0 pr-0">
                  <UpdateCertificate
                    idEmpresa={company.idempresa}
                    onShowAlert={handleShowAlert}
                  />
                </div>
                <div class="col-sm-6">
                  <RideLogo
                    urlImage={company.logoRide}
                    onSubmit={handleSubmitLogoRide}
                    onShowAlert={handleShowAlert}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
            <CircularProgress
              className={classes.circularProgress}
              color="secondary"
            />
          )}
      </div>
      <Backdrop className={classes.backdrop} open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default CompanyData;
