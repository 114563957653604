import React, { useState } from "react";
import ClientData1 from "views/facturacion/Vouchers/perchaseSettlement/Forms/ClientData1";
import RetentionData from "./Forms/RetentionData";
import UseTitle from "hooks/useTitle";
import { makeStyles, Paper } from "@material-ui/core";
import { useStylesGlobal } from "configurations/styles/theme";
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "30px",
    flexGrow: 1,
    paddingLeft: "3%",
    paddingRight: "3%",
    marginBottom: "5%",
  },
}));

const Retention = () => {
  const classesView = useStyles();
  const classesGlobales = useStylesGlobal();
  const [idAsgCliente, setIdAsgCliente] = useState({});
  const [foundClient, setFoundClient] = useState({});
  const [options, setOptions] = React.useState([]);
  UseTitle({ title: "Retención" });

  return (
    <div className={classesView.root}>
      <Paper className={classesGlobales.paperHeader}>
        <h5 className={classesGlobales.titleHeader}>
          Emisión de Comprobante de Retención
        </h5>
      </Paper>

      <ClientData1
        setIdAsgCliente={setIdAsgCliente}
        setFoundClient={setFoundClient}
        foundClient={foundClient}
        setOptions={setOptions}
        options={options}
      />

      <RetentionData
        setIdAsgCliente={setIdAsgCliente}
        idAsgCliente={idAsgCliente}
        setFoundClient={setFoundClient}
        setOptions={setOptions}
      />
    </div>
  );
};

export default Retention;
