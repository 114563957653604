import React, { Fragment, useEffect, useState } from "react";
import {
    Grid,
    Button,
    Dialog,
    DialogContent,
    Typography,
    makeStyles,
    Divider,
    withWidth
} from "@material-ui/core";
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    large: {
        width: "200px",
        height: "60px",
    },
    welcome: {
        fontSize: "20px",
        textAlign: "center",
        fontWeight: "lighter",
    },
    newVersion: {
        fontSize: "16px",
        textAlign: "center",
        fontWeight: "bold",
    },
    legend: {
        textAlign: "center",
    },
    button: {
        textAlign: "center",
    },
}));

const MsmPhone = ({ width }) => {
    const classes = useStyles();

    const [openMsm, setOpenMsm] = useState((width === "sm" || width === "xs"));

    const handleCloseMsm = () => {
        setOpenMsm(false);
    };

    useEffect(() => {
        setOpenMsm((width === "sm" || width === "xs"));
    }, [width]);

    return (
        <Fragment>
            <Dialog fullWidth maxWidth="sm" open={openMsm} onClose={handleCloseMsm}>
                <DialogContent>
                    <Grid
                        container
                        justify="center"
                        align="center"
                        direction="row"
                        spacing={2}
                    >
                        <img
                            src="../facturar12020.png"
                            alt="logo"
                            className={classes.large}
                        />
                    </Grid>
                    <br></br>
                    <Divider></Divider>
                    <br></br>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={2}
                    >

                        <Grid item md={12}>
                            <Typography align="justify">
                                Estimado cliente, muy pronto publicaremos la App Móvil de FacturarEC, la cual contará con todas las funcionalidades de la versión Web.<br />
                                Para obtener más información sobre nuestros productos por favor visitar: {"  "}
                                <a href="https://www.facturar.ec/" target="_blank" >www.facturar.ec</a> <br />
                             Por su seguridad evite usar aplicaciones no oficiales.
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            spacing={2}
                        ></Grid>

                        <Grid item md={12} className={classes.button}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleCloseMsm}
                            >
                                Aceptar
              </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
};

MsmPhone.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(MsmPhone);
