import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Card,
  Typography,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { palette } from "configurations/styles/theme";
import CompanyLogo from "views/administración/UserAccount/CompanyLogo";
import RideLogo from "views/administración/UserAccount/RideLogo";
import validations from "./validations";
import useForm from "hooks/useFrom";
import { clientPublic } from "configurations/axios";
import Alert from "widgets/alert";
//estilos
import "../Login/login.css";
import apis from "./apis";
import UseTittle from "hooks/useTitle";
import { apiKey, aYoSis, domainSis } from "configurations/constant";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formLogin: {
    borderStyle: "solid",
    borderWidth: "1px",
    padding: "1%",
    borderRadius: "8px",
    marginLeft: "10px",
    marginRigth: "10px",
    borderColor: "#d9d9d9",
  },
  from: {
    width: "100%",
    margin: "1%",
  },
  formControl: {
    marginTop: theme.spacing(0),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
  imgRide: {
    paddingLeft: "25%",
    paddingRight: "25%",
  },
  btn: {
    paddingLeft: "25%",
    paddingRight: "25%",
  },
  section: {
    margin: "1%",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    background: "#FFFFFF",
    color: "#fafbfb",
    borderBottomColor: "#3f51b5",
    borderBottomStyle: "solid",
    marginTop: "10px",
    marginBottom: "10px",
  },
  large: {
    width: "200px",
    height: "55px",
    marginBottom: "10px",
  },
  space: {
    margin: "10px",
  },
}));

const Register = () => {
  const classes = useStyles();
  const logo = window.location.hostname.includes(domainSis)
    ? "../oYmSinFondo.png"
    : "../facturar12020.png";
  const history = useHistory();
  const [company, setCompany] = useState({
    correoElectronico: "",
    direccionEmpresa: "",
    logoEmpresa: "",
    logoRide: "",
    nombreComercial: "",
    numeroResolucion: "",
    obligadoContabilidad: false,
    contribuyenteMicroEmpresa: false,
    negocioPopular: false,
    razonSocial: "",
    rucEmpresa: "",
    telefono: "",
    agenteRetencion: "",
  });
  const [openDialog, setOpenDiaglog] = useState(false);
  const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
  const [openLoader, setOpenLoader] = useState(false);

  function closeAlert() {
    setMsg({ show: false });
  }
  UseTittle({ title: "Registro Empresas" });

  const handleClose = () => {
    setOpenDiaglog(false);
    setCompany({
      correoElectronico: "",
      direccionEmpresa: "",
      logoEmpresa: "",
      logoRide: "",
      nombreComercial: "",
      numeroResolucion: "",
      obligadoContabilidad: false,
      contribuyenteMicroEmpresa: false,
      negocioPopular: false,
      razonSocial: "",
      rucEmpresa: "",
      telefono: "",
      agenteRetencion: "",
    });
    history.push("/");
  };

  const [contribuyenteEspecial, setContribuyenteEspecial] = useState(false);
  const existError = () => {};
  //para saber que dominio es
  const apiKeySis = window.location.hostname.includes(domainSis)
    ? aYoSis
    : apiKey;
  const handleSave = async () => {
    setOpenLoader(true);
    let resquest = { codigoSistema: apiKeySis, ...company };

    await clientPublic
      .post(apis.post_save_company, resquest)
      .then((response) => {
        if (response.status >= 200 && response.status <= 300) {
          setMsg({ show: true, msg: "Empresa registrada", type: "success" });
          setOpenDiaglog(true);
        }
        setOpenLoader(false);
      })
      .catch((exception) => {
        if (exception.response) {
          if (exception.response.status === 400) {
            setMsg({
              show: true,
              msg: "No se pudo registrar la empresa. Contáctese con el administrador del Sistema.",
              type: "error",
            });
          } else if (exception.response.status === 404) {
            setMsg({
              show: true,
              msg:
                exception.response.data.apierror.mensaje +
                ". Contáctese con el administrador del Sistema.",
              type: "error",
            });
          } else if (exception.response.status === 409) {
            setMsg({
              show: true,
              msg: "La empresa ya existe. Contáctese con el administrador del Sistema.",
              type: "error",
            });
          } else {
            setMsg({
              show: true,
              msg: "Ocurrió un error interno. Contáctese con el administrador del Sistema.",
              type: "error",
            });
          }
        } else {
          setMsg({
            show: true,
            msg: "Existen problemas en la red. Contáctese con el administrador del Sistema.",
            type: "error",
          });
        }
        setOpenLoader(false);
      });
  };

  const { errors, handleSubmit, getObject } = useForm(
    handleSave,
    validations.checkCompany,
    existError
  );

  useEffect(() => {
    getObject({
      correoElectronico: "",
      direccionEmpresa: "",
      logoEmpresa: "",
      logoRide: "",
      nombreComercial: "",
      numeroResolucion: "",
      obligadoContabilidad: false,
      contribuyenteMicroEmpresa: false,
      negocioPopular: false,
      razonSocial: "",
      rucEmpresa: "",
      telefono: "",
      agenteRetencion: "",
    });
  }, []);

  const handleShowAlert = (alert) => {
    setMsg(alert);
  };

  const handleChange = (event) => {
    setCompany({
      ...company,
      [event.target.name]: event.target.value,
    });
    getObject({
      ...company,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeResolucion = (event) => {
    event.preventDefault();
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setCompany({
        ...company,
        [event.target.name]: event.target.value,
      });
      getObject({
        ...company,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleChangeCheck = (event) => {
    setCompany({
      ...company,
      [event.target.name]: event.target.value === "S" ? true : false,
    });
  };

  const handleChangeRimpeEmprendedor = (event) => {
    if (event.target.name !== "S") {
      setCompany({
        ...company,
        [event.target.name]: event.target.value === "S" ? true : false,
      });
      getObject({
        ...company,
        [event.target.name]: event.target.value === "S" ? true : false,
      });
    }
  };

  const handleChangeCheckNegPopular = (event) => {
    setCompany({
      ...company,
      [event.target.name]: event.target.value === "S" ? true : false,
    });
  };

  const handleChangeCheckAgent = (event) => {
    setCompany({
      ...company,
      [event.target.name]:
        event.target.value === "S" ? "NAC-DNCRASC20-00000001" : "",
    });
  };

  const handleSubmitLogoRide = (logoRide) => {
    setCompany({ ...company, logoRide });
    setMsg({ show: false, msg: null, type: null });
  };

  const handleSubmitLogo = (logoEmpresa) => {
    setCompany({ ...company, logoEmpresa });
    setMsg({ show: false, msg: null, type: null });
  };

  const handleReturnLogin = () => {
    history.push("/");
  };

  return (
    <>
      <Card>
        <section className={classes.title}>
          <Grid
            container
            justifyContent="center"
            align="center"
            direction="row"
            spacing={2}
          >
            <Grid item md={12}>
              {/* <Avatar
                alt="Remy Sharp"
                variant="square"
                src="../facturar12020.png"
                className={classes.large}
              /> */}
              <img src={logo} alt="logo" className={classes.large} />
            </Grid>
          </Grid>
        </section>
        {msgError.show ? (
          <Alert
            tipo={msgError.type}
            mensaje={msgError.msg}
            mesajeSubrayado={null}
            titulo={null}
            mostrar={msgError.show}
            cerrarAlerta={closeAlert}
          />
        ) : null}
        <section>
          <form onSubmit={handleSubmit}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item sm={5} className={classes.formLogin}>
                <Grid container spacing={1}>
                  <Grid item md={12}>
                    <Typography variant="h6">
                      <strong>Registro de empresa</strong>
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Divider></Divider>
                  </Grid>
                  <Grid item md={12}>
                    <label>
                      Los campos marcados con ({" "}
                      <font color={palette.error.main}> *</font> ) son
                      obligatorios:
                    </label>
                  </Grid>
                  <Grid item sm={3}>
                    <label>
                      Ruc: <font color={palette.error.main}> *</font>
                    </label>
                  </Grid>
                  <Grid item sm={9}>
                    <input
                      type="text"
                      className={
                        errors.rucEmpresa
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={handleChangeResolucion}
                      required
                      name="rucEmpresa"
                      value={company.rucEmpresa}
                      placeholder="Ej. 0123456789001"
                      autoComplete="off"
                      maxLength="13"
                    />
                    {errors.rucEmpresa ? (
                      <small style={{ color: palette.error.main }}>
                        {errors.rucEmpresa}
                      </small>
                    ) : null}
                  </Grid>
                  <Grid item sm={3}>
                    <label>
                      Razón Social: <font color={palette.error.main}> *</font>
                    </label>
                  </Grid>
                  <Grid item sm={9}>
                    <input
                      type="text"
                      className={
                        errors.razonSocial
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={handleChange}
                      required
                      name="razonSocial"
                      value={company.razonSocial}
                      placeholder="Ej. Empresa C.A."
                      autoComplete="off"
                      maxLength="300"
                    />
                    {errors.razonSocial ? (
                      <small style={{ color: palette.error.main }}>
                        {errors.razonSocial}
                      </small>
                    ) : null}
                  </Grid>
                  <Grid item sm={3}>
                    <label>Nombre Comercial: </label>
                  </Grid>
                  <Grid item sm={9}>
                    <input
                      type="text"
                      className={
                        errors.nombreComercial
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={handleChange}
                      name="nombreComercial"
                      value={company.nombreComercial}
                      placeholder="Ej. EmpresaCorp."
                      autoComplete="off"
                    />
                    {errors.nombreComercial ? (
                      <small style={{ color: palette.error.main }}>
                        {errors.nombreComercial}
                      </small>
                    ) : null}
                  </Grid>
                  <Grid item sm={3}>
                    <label>
                      Dirección: <font color={palette.error.main}> *</font>
                    </label>
                  </Grid>
                  <Grid item sm={9}>
                    <input
                      type="text"
                      className={
                        errors.direccionEmpresa
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={handleChange}
                      name="direccionEmpresa"
                      value={company.direccionEmpresa}
                      placeholder="Ej. Av. y Calle 2"
                      autoComplete="off"
                      required
                    />
                    {errors.direccionEmpresa ? (
                      <small style={{ color: palette.error.main }}>
                        {errors.direccionEmpresa}
                      </small>
                    ) : null}
                  </Grid>
                  <Grid item sm={3}>
                    <label>
                      Correo Electrónico:
                      <font color={palette.error.main}> *</font>{" "}
                    </label>
                  </Grid>
                  <Grid item sm={9}>
                    <input
                      type="email"
                      className={
                        errors.correoElectronico
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={handleChange}
                      name="correoElectronico"
                      value={company.correoElectronico}
                      placeholder="Ej. info@ejemplo.com.ec"
                      autoComplete="off"
                      required
                    />
                    {errors.correoElectronico ? (
                      <small style={{ color: palette.error.main }}>
                        {errors.correoElectronico}
                      </small>
                    ) : null}
                  </Grid>
                  <Grid item sm={3}>
                    <label>Teléfono: </label>
                  </Grid>
                  <Grid item sm={9}>
                    <input
                      type="text"
                      className={
                        errors.telefono
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={handleChangeResolucion}
                      name="telefono"
                      value={company.telefono}
                      placeholder="Ej. 021234567"
                      autoComplete="off"
                    />
                    {errors.telefono ? (
                      <small style={{ color: palette.error.main }}>
                        {errors.telefono}
                      </small>
                    ) : null}
                  </Grid>

                  <Grid item sm={6}>
                    <label>
                      Obligado a llevar Contabilidad:
                      <font color={palette.error.main}> *</font>
                    </label>
                  </Grid>
                  <Grid item sm={6}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <RadioGroup
                        aria-label="tipoContabilidad"
                        name="obligadoContabilidad"
                        className={classes.radio}
                        required
                        value={company.obligadoContabilidad ? "S" : "N"}
                        onChange={handleChangeCheck}
                      >
                        <FormControlLabel
                          value="S"
                          control={
                            <Radio
                              color="primary"
                              className={classes.radioButton}
                            />
                          }
                          label="Si"
                          labelPlacement="start"
                          className={classes.radioLable}
                        />
                        <FormControlLabel
                          value="N"
                          control={
                            <Radio
                              color="primary"
                              className={classes.radioButton}
                            />
                          }
                          label="No"
                          labelPlacement="start"
                          className={classes.radioLable}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item sm={6}>
                    <label>
                      Contribuyente Régimen Simplificado para Emprendedores y
                      Negocios Populares (RIMPE):
                      <font color={palette.error.main}> *</font>
                    </label>
                  </Grid>
                  <Grid item sm={6}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <RadioGroup
                        aria-label="tipoContabilidad"
                        name="contribuyenteMicroEmpresa"
                        className={classes.radio}
                        value={company.contribuyenteMicroEmpresa ? "S" : "N"}
                        onChange={handleChangeCheck}
                      >
                        <FormControlLabel
                          value="S"
                          control={
                            <Radio
                              color="primary"
                              className={classes.radioButton}
                            />
                          }
                          label="Si"
                          labelPlacement="start"
                          className={classes.radioLable}
                        />
                        <FormControlLabel
                          value="N"
                          control={
                            <Radio
                              color="primary"
                              className={classes.radioButton}
                            />
                          }
                          label="No"
                          labelPlacement="start"
                          className={classes.radioLable}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {company.contribuyenteMicroEmpresa ? (
                    <>
                      <Grid item sm={6}>
                        <label>
                          Seleccione el tipo de (RIMPE):
                          <font color={palette.error.main}> *</font>
                        </label>
                      </Grid>
                      <Grid item sm={6}>
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          <RadioGroup
                            aria-label="tipoContabilidad"
                            name="negocioPopular"
                            className={classes.radio}
                            value={company.negocioPopular ? "S" : "N"}
                            onChange={handleChangeCheckNegPopular}
                          >
                            <FormControlLabel
                              value="S"
                              control={
                                <Radio
                                  color="primary"
                                  className={classes.radioButton}
                                />
                              }
                              label="Negocio Popular"
                              labelPlacement="start"
                              className={classes.radioLable}
                            />
                            <FormControlLabel
                              value="N"
                              control={
                                <Radio
                                  color="primary"
                                  className={classes.radioButton}
                                />
                              }
                              label="Emprendedores"
                              labelPlacement="start"
                              className={classes.radioLable}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </>
                  ) : null}

                  {company.negocioPopular === false &&
                  company.contribuyenteMicroEmpresa === true ? (
                    <>
                      <Grid item sm={6}>
                        <label>
                          Desea que se muestre la leyenda: "Contribuyente
                          Régimen RIMPE EMPRENDEDOR"?{" "}
                          <font color={palette.error.main}> *</font> :
                        </label>
                      </Grid>
                      <Grid item sm={6}>
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          <RadioGroup
                            aria-label="tipoContabilidad"
                            name="leyendaRimpeEmprendedor"
                            className={classes.radio}
                            required
                            value={company.leyendaRimpeEmprendedor ? "S" : "N"}
                            onChange={handleChangeRimpeEmprendedor}
                          >
                            <FormControlLabel
                              value="S"
                              control={
                                <Radio
                                  color="primary"
                                  className={classes.radioButton}
                                />
                              }
                              label="Si"
                              labelPlacement="start"
                              className={classes.radioLable}
                            />
                            <FormControlLabel
                              value="N"
                              control={
                                <Radio
                                  color="primary"
                                  className={classes.radioButton}
                                />
                              }
                              label="No"
                              labelPlacement="start"
                              className={classes.radioLable}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </>
                  ) : null}

                  <Grid item sm={6}>
                    <label>Es contribuyente especial?</label>
                  </Grid>
                  <Grid item sm={6}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <RadioGroup
                        aria-label="tipoContabilidad"
                        name="contribuyenteEspecial"
                        className={classes.radio}
                        value={contribuyenteEspecial === true ? "S" : "N"}
                        onClick={() =>
                          setContribuyenteEspecial(!contribuyenteEspecial)
                        }
                      >
                        <FormControlLabel
                          value="S"
                          control={
                            <Radio
                              color="primary"
                              className={classes.radioButton}
                            />
                          }
                          label="Si"
                          labelPlacement="start"
                          className={classes.radioLable}
                        />
                        <FormControlLabel
                          value="N"
                          control={
                            <Radio
                              color="primary"
                              className={classes.radioButton}
                            />
                          }
                          label="No"
                          labelPlacement="start"
                          className={classes.radioLable}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {contribuyenteEspecial && (
                    <>
                      <Grid item sm={6}>
                        <label>Número de Resolución: </label>
                      </Grid>
                      <Grid item sm={6}>
                        <input
                          type="text"
                          className={
                            errors.numeroResolucion
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          onChange={handleChangeResolucion}
                          name="numeroResolucion"
                          value={
                            contribuyenteEspecial
                              ? company.numeroResolucion
                              : ""
                          }
                          placeholder="Ej. 122"
                          autoComplete="off"
                        />
                        {errors.numeroResolucion ? (
                          <small style={{ color: palette.error.main }}>
                            {errors.numeroResolucion}
                          </small>
                        ) : null}
                      </Grid>
                    </>
                  )}
                  <Grid item sm={6}>
                    <label>Resolución Agente de Retención: </label>
                  </Grid>
                  <Grid item sm={6}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <RadioGroup
                        name="agenteRetencion"
                        className={classes.radio}
                        required
                        value={company.agenteRetencion === "" ? "N" : "S"}
                        onChange={handleChangeCheckAgent}
                      >
                        <FormControlLabel
                          value="S"
                          control={
                            <Radio
                              color="primary"
                              className={classes.radioButton}
                            />
                          }
                          label="Si"
                          labelPlacement="start"
                          className={classes.radioLable}
                        />
                        <FormControlLabel
                          value="N"
                          control={
                            <Radio
                              color="primary"
                              className={classes.radioButton}
                            />
                          }
                          label="No"
                          labelPlacement="start"
                          className={classes.radioLable}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={5} className={classes.formLogin}>
                <Grid
                  container
                  justifyContent="center"
                  align="center"
                  direction="row"
                  spacing={2}
                >
                  <Grid item sm={12}>
                    <CompanyLogo
                      title={"Logo de la Empresa"}
                      urlImage={company.logoEmpresa}
                      onSubmit={handleSubmitLogo}
                      onShowAlert={handleShowAlert}
                      textBtn="Subir Logo"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Divider></Divider>
                  </Grid>
                  <Grid item sm={12} className={classes.imgRide}>
                    <RideLogo
                      urlImage={company.logoRide}
                      onSubmit={handleSubmitLogoRide}
                      onShowAlert={handleShowAlert}
                      textBtn="Subir Logo"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <br></br>
            <Grid
              container
              justifyContent="center"
              align="center"
              spacing={1}
              className={classes.space}
            >
              <Grid item md={1} xs={12}>
                <Button variant="contained" color="primary" type="submit">
                  Registrarse
                </Button>
              </Grid>
              <Grid item md={1} xs={12}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleReturnLogin}
                >
                  Cancelar
                </Button>
              </Grid>
              <br></br>
            </Grid>
            <br />
            <br />
          </form>
        </section>
        <Backdrop className={classes.backdrop} open={openLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Card>
      <Dialog fullWidth maxWidth="sm" open={openDialog} onClose={handleClose}>
        <DialogTitle>Registro Exitoso</DialogTitle>
        <br />
        <DialogContent>
          <Grid
            container
            spacing={2}
            justify="center"
            alignItems="center"
            align="center"
            direction="row"
          >
            <Grid item sm={12} className="alert alert-primary">
              <strong>Gracias por registrarse.</strong>
              <br />
              La información necesaria para iniciar sesión ha sido enviada al
              correo: {company.correoElectronico}
            </Grid>
            <Grid item sm={12}>
              <Button variant="contained" color="primary" onClick={handleClose}>
                Aceptar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Register;
