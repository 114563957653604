import React, { Fragment, useState } from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    makeStyles,
    Backdrop,
    CircularProgress,
    FormControl,
    FormControlLabel,
    NativeSelect,
    withStyles,
    InputBase,
    Checkbox,
    Divider,
    RadioGroup,
    Radio,
} from "@material-ui/core";
import { palette, useStylesGlobal, styleInput } from "configurations/styles/theme";
import { Alert } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useStylesApp } from "hooks/useStyles";
import EditTable from "widgets/Tables/EditTable";
import { getRUCEmpresa } from "redux/actions/userActions";
import clientBackend from "configurations/axios";
import bigDecimal from "js-big-decimal";
import uuid from "react-uuid";
import AddProductsBtn from 'views/administración/productsServices/Buttons/AddProduct'
import checkInputs from "utils/checkInputs";
import { numDecimalesDescuento, numDecimales } from "configurations/constant";
import { valorICE, valorIva } from "views/facturacion/Forms/typeVoucher";
import { calculateDiscount, validationVoucher } from "../../invoices/validations";

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(3),
        minWidth: 120,
    },
    radio: {
        display: "-webkit-box",
        marginTop: "0px",
    },
    radioLable: {
        color: palette.primary.main,
        display: "flex",
    },
    radioButton: {
        marginTop: "0px",
        paddingTop: "0px",
    },
    divForm: {
        marginBottom: "1%",
    },
    divInput: {
        marginBottom: "1%",
        paddingRight: "1%",
        paddingLeft: "0.5%",
    },
    divButton: {
        paddingLeft: "3%",
        paddingRight: "3%",
        textAlign: "center",
        display: "flex",
        justifyContent: "space-around",
        marginTop: "1%",
        marginBottom: "0.5%",
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
}));
const BootstrapInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(0),
        },
    },
    input: {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            borderRadius: 4,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}))(InputBase);

export function AddDetailPhysical({
    productsDetalle,
    setProductsDetalle,
    viewDetalle,
    setViewDetalle,
    detalles,
    setDetalles,
}) {
    const classes = useStyles();
    const classesGlobales = useStylesGlobal();
    const classesApp = useStylesApp();
    const [dataAditional, setDataAditional] = useState([]);
    const [open, setOpen] = useState(false);

    const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
    const { enqueueSnackbar } = useSnackbar();
    const [product, setProduct] = useState({
        descuentoProducto: "",
        cantidadProducto: "1",
        isDescuentoPorcentaje: 'false',
        valorDescuento: ''
    });
    const [iva, setIva] = useState("2");
    const [ice, setIce] = useState();

    const [foundProduct, setFoundProduct] = useState({
        codigoAuxiliar: '',
        codigoPrincipal: '',
        descripcion: '',
        estado: '',
        idProducto: '',
        precioUnitario: '',
    });

    const [isAditional, setIsAdditional] = useState(false);
    const [errors, setErrors] = useState({});

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        clearData();
        setOpen(false);
    };

    const validateProductData = (data) => {
        let newErros = validationVoucher.onSubmit(
            {
                codigoPrincipal: data.codigoPrincipal,
                cantidadProducto: data.cantidadProducto,
                precioUnitario: data.precioUnitario,
                descripcion: data.descripcion,
            }
        );
        if (Object.entries(newErros).length > 0) {
            setErrors(newErros)
            return false;
        } else {
            return true;
        }
    }

    const handleAddProduct = () => {
        if (validateProductData({
            codigoPrincipal: foundProduct.codigoPrincipal,
            cantidadProducto: product.cantidadProducto,
            precioUnitario: foundProduct.precioUnitario,
            descripcion: foundProduct.descripcion,
        })) {


            if (
                foundProduct &&
                typeof foundProduct !== "undefined" &&
                typeof foundProduct !== null && foundProduct.codigoPrincipal.trim() !== ''
            ) {
                if (Object.entries(foundProduct).length > 0) {

                    if (Number(product.cantidadProducto) > 0) {
                        if (
                            foundProduct.precioUnitario.toString() !== "" &&
                            Number(foundProduct.precioUnitario) >= 0
                        ) {
                            //if (validateDecimal(foundProduct.precioUnitario.toString())) {
                            let valorDiscount = calculateDiscount({
                                isDescuentoPorcentaje: product.isDescuentoPorcentaje,
                                cantidadProducto: product.cantidadProducto,
                                precioUnitario: foundProduct.precioUnitario,
                                descuentoProducto: product.descuentoProducto,
                            });
                            valorDiscount = bigDecimal.round(valorDiscount, 2, bigDecimal.RoundingModes.HALF_UP)
                            if (validateDiscount(valorDiscount)) {
                                let uuId = uuid();
                                let totProducto = Number(
                                    bigDecimal.multiply(
                                        product.cantidadProducto.toString(),
                                        foundProduct.precioUnitario.toString()
                                    ) - Number(valorDiscount)
                                );

                                let iceProducto = valorICE.reduce(function (iceProducto, vIce) {
                                    if (vIce.CODIGO === ice) {
                                        return (
                                            Number(iceProducto) +
                                            (Number(totProducto) * Number(vIce.VALOR)) / 100
                                        );
                                    } else {
                                        return Number(iceProducto);
                                    }
                                }, 0);

                                setProductsDetalle([
                                    ...productsDetalle,
                                    {
                                        ...foundProduct,
                                        id: uuId,
                                        descuentoProducto: product.descuentoProducto
                                            ? Number(
                                                bigDecimal.round(
                                                    product.descuentoProducto,
                                                    2,
                                                    bigDecimal.RoundingModes.HALF_UP
                                                )
                                            )
                                            : 0,
                                        cantidadProducto: product.cantidadProducto
                                            ? Number(
                                                bigDecimal.round(
                                                    product.cantidadProducto,
                                                    checkInputs.countDecimals(product.cantidadProducto.toString()) <= 2 ? 2 : checkInputs.countDecimals(product.cantidadProducto.toString()),
                                                    bigDecimal.RoundingModes.HALF_UP
                                                )
                                            )
                                            : 0,
                                        impuestoIva: valorIva.filter((aiva) => aiva.CODIGO === iva),
                                        impuestoIce: valorICE.filter((vice) => vice.CODIGO === ice),
                                        totalIceProducto: Number(
                                            bigDecimal.round(
                                                iceProducto,
                                                checkInputs.countDecimals(iceProducto.toString()),
                                                bigDecimal.RoundingModes.HALF_UP
                                            )
                                        ),
                                        total: product.cantidadProducto
                                            ? Number(
                                                bigDecimal.round(
                                                    totProducto,
                                                    checkInputs.countDecimals(totProducto.toString()),
                                                    bigDecimal.RoundingModes.HALF_UP
                                                )
                                            )
                                            : 0,
                                        precioUnitario: Number(
                                            bigDecimal.round(
                                                foundProduct.precioUnitario,
                                                checkInputs.countDecimals(foundProduct.precioUnitario.toString()) > 2 ? checkInputs.countDecimals(foundProduct.precioUnitario.toString()) : 2,
                                                bigDecimal.RoundingModes.HALF_UP
                                            )
                                        ),
                                        informacionAdicional:
                                            dataAditional.length > 0 ? dataAditional : [],
                                        isDescuentoPorcentaje: product.isDescuentoPorcentaje,
                                    },

                                ]);

                                //agrego los detalles de la factura
                                setDetalles([
                                    ...detalles,
                                    {
                                        id: uuId,
                                        cantidad: product.cantidadProducto
                                            ? Number(
                                                bigDecimal.round(
                                                    product.cantidadProducto,
                                                    checkInputs.countDecimals(product.cantidadProducto.toString()),
                                                    bigDecimal.RoundingModes.HALF_UP
                                                )
                                            )
                                            : 0,
                                        codigoAuxiliar: foundProduct.codigoAuxiliar,
                                        codigoPorcentajeICE: ice ? ice : "",
                                        codigoPorcentajeIVA: iva,
                                        descripcion: foundProduct.descripcion,
                                        descuento: product.descuentoProducto
                                            ? Number(
                                                bigDecimal.round(
                                                    product.descuentoProducto,
                                                    2,
                                                    bigDecimal.RoundingModes.HALF_UP
                                                )
                                            )
                                            : 0,
                                        codigoPrincipal: foundProduct.codigoPrincipal,
                                        informacionAdicional:
                                            dataAditional.length > 0 ? dataAditional : [],
                                        precioProducto: Number(
                                            bigDecimal.round(
                                                foundProduct.precioUnitario,
                                                checkInputs.countDecimals(foundProduct.precioUnitario.toString()),
                                                bigDecimal.RoundingModes.HALF_UP
                                            )
                                        ),
                                        isDescuentoPorcentaje: product.isDescuentoPorcentaje,
                                    },
                                ]);

                                if (viewDetalle === 1) {
                                    setViewDetalle(2);
                                } else if (viewDetalle === 2) {
                                    setViewDetalle(1);
                                }
                                setProduct({
                                    descuentoProducto: "",
                                    cantidadProducto: "1",
                                    isDescuentoPorcentaje: 'false',
                                });
                                setIce("");
                                setFoundProduct({});
                                setDataAditional([]);
                                handleClose();
                                enqueueSnackbar(`Detalle agregado al comprobante`, {
                                    variant: "success",
                                    anchorOrigin: {
                                        vertical: "top",
                                        horizontal: "right",
                                    },
                                    autoHideDuration: 4000,
                                });
                            }
                            //}
                        }
                    }
                }
            }
        }
    };




    const handleChange = (event) => {
        event.preventDefault();
        const { error, keyWordError } = validationVoucher.onChange(
            event.target.name,
            event.target.value
        );

        switch (event.target.name) {
            case "codigoPrincipal":
            case 'codigoAuxiliar':
                if (keyWordError !== "isLong" && checkInputs.countLenth(event.target.value.toString().trim()) <= 25) {
                    setFoundProduct({
                        ...foundProduct,
                        [event.target.name]: event.target.value,
                    });
                }
                setErrors({ ...errors, [event.target.name]: error });
                break;
            case 'descripcion':
                if (keyWordError !== "isLong" && checkInputs.countLenth(event.target.value.toString().trim()) <= 50) {
                    setFoundProduct({
                        ...foundProduct,
                        [event.target.name]: event.target.value,
                    });
                }
                setErrors({ ...errors, [event.target.name]: error });
                break;
            case 'cantidadProducto':
                if (keyWordError !== "isNotNumeric" && keyWordError !== "isLong" && checkInputs.countDecimals(event.target.value.toString().trim()) <= numDecimales) {
                    setProduct({
                        ...product,
                        [event.target.name]: event.target.value,
                    });

                }
                setErrors({ ...errors, [event.target.name]: error });
                break;
            case 'precioUnitario':
                if (keyWordError !== "isNotDecimal" && keyWordError !== "isLong" && checkInputs.countDecimals(event.target.value.toString().trim()) <= numDecimales) {
                    setFoundProduct({
                        ...foundProduct,
                        [event.target.name]: event.target.value,
                    });
                }
                setErrors({ ...errors, [event.target.name]: error });
                break;
            case 'isDescuentoPorcentaje':
                setProduct({
                    ...product,
                    [event.target.name]: event.target.value,
                });
                break;
            case 'descuentoProducto':
                if (keyWordError !== "isNotDecimal" && keyWordError !== "isLong" && checkInputs.countDecimals(event.target.value.toString().trim()) <= numDecimalesDescuento) {
                    setProduct({
                        ...product,
                        [event.target.name]: event.target.value,
                    });
                }
                setErrors({ ...errors, [event.target.name]: error });
                break;
            default:
                break;
        }


    };
    const handleChangeCheck = (event) => {
        setIsAdditional(event.target.checked);
    };

    function closeAlert() {
        setMsg({ show: false });
    }



    const validateDiscount = (valorDiscount) => {
        if (
            product.descuentoProducto.trim() !== "" &&
            Number(valorDiscount) > 0
        ) {
            if (validateDecimal(product.descuentoProducto.toString().trim())) {
                let totalPrice = Number(foundProduct.precioUnitario) * Number(product.cantidadProducto);
                totalPrice = bigDecimal.round(totalPrice, 2, bigDecimal.RoundingModes.HALF_UP);
                if (Number(valorDiscount) <= Number(totalPrice)) {
                    return true;
                } else {
                    enqueueSnackbar(`El descuento debe ser menor a ${totalPrice}`, {
                        variant: "warning",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        },
                        autoHideDuration: 4000,
                    });
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    const validateDecimal = (numberValor) => {
        let splitList = numberValor.split(".");
        if (splitList.length > 2) {
            enqueueSnackbar(
                `El dato ingresado es erróneo utilice punto (.) para separar decimales`,
                {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                    autoHideDuration: 4000,
                }
            );
            return false;
        } else if (splitList.length > 1) {
            if (splitList[1].length > 2) {
                enqueueSnackbar(
                    `Los datos numéricos debe tener máximo 2 decimales Ej, 12.43`,
                    {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        },
                        autoHideDuration: 4000,
                    }
                );
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    };

    const clearData = () => {
        setProduct({
            descuentoProducto: "",
            cantidadProducto: "1",
            isDescuentoPorcentaje: 'false',

        });
        setIce("");
        setFoundProduct({
            codigoAuxiliar: '',
            codigoPrincipal: '',
            descripcion: '',
            estado: '',
            idProducto: '',
            precioUnitario: '',
        });
        setDataAditional([]);
        setIsAdditional(false);
        setErrors({});
    };

    const updateView = () => {

    }

    return (
        <Fragment>
            <Grid container justify="center" className={classes.divButton}>
                <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                    Agregar Detalle
        </Button>
            </Grid>
            <Dialog
                fullWidth
                maxWidth="lg"
                open={open}
                onClose={handleClose}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="max-width-dialog-title">Nuevo detalle</DialogTitle>
                <DialogContent>
                    <form>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                >
                                    <Grid item xs={12} md={12}>
                                        <label>
                                            Los campos marcados con ({" "}
                                            <font color={palette.error.main}> *</font> ) son
                                                obligatorios:
                                        </label>
                                    </Grid>
                                    {msgError.show ? (
                                        <Alert
                                            tipo={msgError.type}
                                            mensaje={msgError.msg}
                                            mesajeSubrayado={null}
                                            titulo={null}
                                            mostrar={msgError.show}
                                            cerrarAlerta={closeAlert}
                                        />
                                    ) : null}


                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>
                                            Código Principal:{" "}
                                            <font color={palette.error.main}>*</font>
                                        </label>
                                    </Grid>

                                    <Grid item xs={12} md={8} className={classes.divInput}>
                                        <input
                                            type="text"
                                            placeholder="Ej. 0002"
                                            name="codigoPrincipal"
                                            value={foundProduct ? foundProduct.codigoPrincipal : ""}
                                            autoComplete="off"
                                            className={
                                                foundProduct
                                                    ? foundProduct.codigoPrincipal
                                                        ? "form-control  is-valid"
                                                        : "form-control "
                                                    : "form-control "
                                            }
                                            onChange={handleChange}
                                        />
                                        {errors.codigoPrincipal ? (
                                            <p className={classesApp.errorLetters}>
                                                {errors.codigoPrincipal}
                                            </p>
                                        ) : null}
                                    </Grid>

                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>
                                            Código Auxiliar:
                                        </label>
                                    </Grid>
                                    <Grid item xs={12} md={8} className={classes.divInput}>
                                        <input
                                            type="text"
                                            placeholder="Ej. 0002"
                                            name="codigoAuxiliar"
                                            value={foundProduct ? foundProduct.codigoAuxiliar : ""}
                                            autoComplete="off"
                                            className={
                                                foundProduct
                                                    ? foundProduct.codigoAuxiliar
                                                        ? "form-control  is-valid"
                                                        : "form-control "
                                                    : "form-control "
                                            }
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>
                                            Cantidad: <font color={palette.error.main}>*</font>
                                        </label>
                                    </Grid>
                                    <Grid item xs={12} md={8} className={classes.divInput}>
                                        <input
                                            className={styleInput(
                                                errors.cantidadProducto,
                                                product.cantidadProducto
                                            )}
                                            type="text"
                                            name="cantidadProducto"
                                            value={product.cantidadProducto}
                                            onChange={handleChange}
                                            autoComplete="off"
                                        />
                                        {errors.cantidadProducto ? (
                                            <p className={classesApp.errorLetters}>
                                                {errors.cantidadProducto}
                                            </p>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>
                                            Descripción: <font color={palette.error.main}>*</font>
                                        </label>
                                    </Grid>
                                    <Grid item xs={12} md={8} className={classes.divInput}>
                                        <input
                                            className={
                                                foundProduct
                                                    ? foundProduct.descripcion
                                                        ? "form-control  is-valid"
                                                        : "form-control "
                                                    : "form-control "
                                            }
                                            type="text"
                                            placeholder="Ej. Ordenador"
                                            name="descripcion"
                                            value={foundProduct ? foundProduct.descripcion : ""}
                                            onChange={handleChange}
                                            autoComplete="off"
                                        />
                                        {errors.descripcion ? (
                                            <p className={classesApp.errorLetters}>
                                                {errors.descripcion}
                                            </p>
                                        ) :
                                            null}
                                    </Grid>
                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>
                                            Precio Unitario: <font color={palette.error.main}>*</font>
                                        </label>
                                    </Grid>
                                    <Grid item xs={12} md={8} className={classes.divInput}>
                                        <input
                                            className={styleInput(
                                                errors.precioUnitario,
                                                foundProduct.precioUnitario
                                            )}
                                            type="text"
                                            placeholder="Ej. 12.00"
                                            name="precioUnitario"
                                            value={foundProduct.precioUnitario}
                                            onChange={handleChange}
                                            autoComplete="off"
                                        />
                                        {errors.precioUnitario ? (
                                            <p className={classesApp.errorLetters}>
                                                {errors.precioUnitario}
                                            </p>
                                        ) :
                                            null}
                                    </Grid>

                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>Descuento: </label>
                                    </Grid>
                                    <Grid
                                        fullWidth
                                        item
                                        xs={6}
                                        md={4}
                                        className={classes.divInput}
                                    >
                                        <input
                                            className={styleInput(
                                                errors.descuentoProducto,
                                                product.descuentoProducto
                                            )}
                                            type="text"
                                            placeholder="Ej. 1"
                                            name="descuentoProducto"
                                            value={product.descuentoProducto}
                                            onChange={handleChange}
                                            autoComplete="off"
                                        />
                                        {errors.descuentoProducto ? (
                                            <p className={classesApp.errorLetters}>
                                                {errors.descuentoProducto}
                                            </p>
                                        ) :
                                            null}
                                    </Grid>

                                    {<Grid xs={6} md={4}>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                aria-label="isPorcentaje"
                                                name="isDescuentoPorcentaje"
                                                value={product.isDescuentoPorcentaje}
                                                className={classes.radio}
                                                required
                                                onChange={handleChange}
                                            >
                                                <FormControlLabel
                                                    value={'false'}
                                                    control={
                                                        <Radio color="primary" className={classes.radioButton} />
                                                    }
                                                    label="Valor"
                                                    labelPlacement="start"
                                                    className={classes.radioLable}
                                                />
                                                <FormControlLabel
                                                    value={'true'}
                                                    control={
                                                        <Radio color="primary" className={classes.radioButton} />
                                                    }
                                                    label="Porcentaje"
                                                    labelPlacement="start"
                                                    className={classes.radioLable}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>}


                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>
                                            IVA: <font color={palette.error.main}>*</font>
                                        </label>
                                    </Grid>
                                    <Grid item xs={12} md={8} className={classes.divInput}>
                                        <FormControl className={classesGlobales.select}>
                                            <NativeSelect
                                                value={iva}
                                                onChange={(event) => setIva(event.target.value)}
                                                input={<BootstrapInput />}
                                            >
                                                <option aria-label="None" disabled>
                                                    --Seleccione--
                        </option>
                                                {valorIva.map((iva) => (
                                                    <option key={iva.CODIGO} value={iva.CODIGO}>
                                                        {iva.NOMBRE}
                                                    </option>
                                                ))}
                                            </NativeSelect>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>ICE:</label>
                                    </Grid>
                                    <Grid item xs={12} md={8} className={classes.divInput}>
                                        <FormControl className={classes.margin}>
                                            <NativeSelect
                                                value={ice}
                                                onChange={(event) => setIce(event.target.value)}
                                                input={<BootstrapInput />}
                                            >
                                                <option aria-label="None" value=''>
                                                    --Ninguno--
                        </option>
                                                {valorICE.map((ice) => (
                                                    <option key={ice.CODIGO} value={ice.CODIGO}>
                                                        {`${ice.CODIGO} - ${ice.NOMBRE}`}
                                                    </option>
                                                ))}
                                            </NativeSelect>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    md={12}
                                    justify="center"
                                    alignItems="center"
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isAditional}
                                                onChange={handleChangeCheck}
                                                name="isAditional"
                                                color="primary"
                                            />
                                        }
                                        label="Desea agregar información adicional al detalle?"
                                    />
                                </Grid>
                                {isAditional ? (
                                    <Grid
                                        container
                                        xs={12}
                                        md={12}
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <EditTable
                                            dataAditional={dataAditional}
                                            setDataAditional={setDataAditional}
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Divider />
                            </Grid>

                            <Grid item xs={12} md={12} className={classes.divButton}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleAddProduct}
                                    className={classesGlobales.button}
                                >
                                    Agregar
                </Button>

                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleClose}
                                    className={classesGlobales.button}
                                >
                                    Cancelar
                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>

        </Fragment>
    );
};

//export default AddDetailPhysical;
