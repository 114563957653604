import React, { Fragment, useState } from "react";
import {
  Grid,
  Button,
  Divider,
  Card,
  TextField,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
//componentes de la Vista
import AuthUser from "./authUser";
import Loader from "widgets/Loader";
//estilos
import "./login.css";
import { useStyles } from "./styles";
import ManualesModal from "./ManualesModal.js";
import "./login.css";
//import MsmWelcome from "./MsmWelcome";
import MsgPhones from "./MsgPhones";
import useLogin from "./useLogin";
//import { logo } from 'assets/icons/logo.png'
import RecoveryCredential from "./recoveryCredential";
import MsmWelcome from "./MsmWelcome";
import { domainSis } from "configurations/constant";

const Login = () => {
  const classes = useStyles();
  const logo = window.location.hostname.includes(domainSis) ? "../oYmSinFondo.png" : "../facturar12020.png";
  const {
    handleRedirectionRegister,
    handleAutentication,
    handleChange,
    error,
    errorInputs,
    user } = useLogin();

  const [openMsm, setOpenMsn] = useState(true);

  const handleCloseMsm = () => {
    setOpenMsn(false);
  };

  return (

    <Fragment>
      <Loader />
      {<MsmWelcome
        openMsm={openMsm}
        handleCloseMsm={handleCloseMsm}
      ></MsmWelcome>}

      <Grid>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          className={classes.root}
        >
          <Card className={classes.card}>
            <form onSubmit={handleAutentication}>
              <AuthUser />
              {errorInputs ? <p>Ingrese todos los campos</p> : null}
              <Grid
                container
                justify="center"
                align="center"
                direction="row"
                spacing={1}
              >
                <img
                  src={logo}
                  alt="logo"
                  className={classes.large}
                />
              </Grid>
              <br></br>
              <MsgPhones />
              <br></br>

              <Divider></Divider>
              <br />
              {error ? (
                <div className="alert alert-danger" role="alert">
                  <label> {error}</label>
                </div>
              ) : null}
              <Grid item md={12}>
                <TextField
                  type="text"
                  classes={{
                    root: classes.input, // class name, e.g. `classes-nesting-root-x`
                  }}
                  onChange={handleChange}
                  required
                  name="username"
                  autoComplete="off"
                  value={user.username}
                  label="Usuario"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  type="password"
                  classes={{
                    root: classes.input, // class name, e.g. `classes-nesting-root-x`
                  }}
                  //className={classes.inputLogin}
                  onChange={handleChange}
                  required
                  name="password"
                  value={user.password}
                  label="Contraseña"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} className={classes.link}>
                <RecoveryCredential />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonLogin}
                  type="submit"
                  fullWidth
                >
                  Ingresar
                </Button>
              </Grid>
            </form>

            <ManualesModal></ManualesModal>
            <Grid item md={12}>
              <Typography className={classes.questionLogin}>
                ¿Aún no está en Facturar?
              </Typography>
            </Grid>

            <Grid item md={12} className={classes.registro}>
              <Link
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleRedirectionRegister}
                underline="always"
                className={classes.link}
              >
                Regístrarse
              </Link>
            </Grid>
          </Card>
        </Grid>
      </Grid>


    </Fragment>
  );
};

export default Login;
