
import React, { Fragment, useState } from 'react';
import { Backdrop, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, makeStyles, NativeSelect, Radio, RadioGroup, TextField } from '@material-ui/core';
import AddShoppingCartOutlinedIcon from '@material-ui/icons/AddShoppingCartOutlined';
import { Alert, Autocomplete } from '@material-ui/lab';
import { msmSwalExito, msmSwalError, palette, styleInput, useStylesGlobal } from 'configurations/styles/theme';
import { valorIva } from 'views/facturacion/Forms/typeVoucher';
import { useDispatch, useSelector } from 'react-redux';
import { getRUCEmpresa } from 'redux/actions/userActions';
import { ConfirmAlert, iconsAlert } from 'utils/AlertsCustom';
import Swal from 'sweetalert2';
import CompanyLogo from 'views/administración/UserAccount/CompanyLogo';
import { clientPay } from 'configurations/axios';
import { selectedResourceAction } from 'redux/actions/resourcesActions';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(3),
        minWidth: 120,
    },
    radio: {
        display: "-webkit-box",
        marginTop: "0px",
    },
    radioLable: {
        color: palette.primary.main,
        display: "flex",
    },
    radioButton: {
        marginTop: "0px",
        paddingTop: "0px",
    },
    divForm: {
        marginBottom: "1%",
    },
    divInput: {
        marginBottom: "1%",
        paddingRight: "1%",
        paddingLeft: "0.5%",
        textAlign: "center",
    },
    divButton: {
        paddingLeft: "3%",
        paddingRight: "3%",
        textAlign: "center",
        display: "flex",
        justifyContent: "space-around",
        marginTop: "1%",
        marginBottom: "0.5%",
    },
    aligGrid: {
        textAlign: '-webkit-center'
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
}));

const AddPackage = ({ plan }) => {

    const classes = useStyles();
    const classesGlobales = useStylesGlobal();
    const tokenUser = useSelector(state => state.user.tk);
    const [open, setOpen] = useState(false);
    const [openVoucher, setOpenVoucher] = useState(false);
    const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
    const [openLoader, setOpenLoader] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const selectedResource = (resource) => dispatch(selectedResourceAction(resource));
    const [paymentVoucher, setPaymentVoucher] = useState({
        idEmpresa: getRUCEmpresa(tokenUser ? tokenUser : ''),
        //idEmpresa: '1722020483',
        idPaquete: plan.idPaquete,
        valorTotalPaquete: (plan.precio * valorIva[2].VALOR / 100) + plan.precio,
        iva: 0,
        voucher: '',
    })

    const handleClickOpen = () => {
        validateBuyPlan();

    };

    const validateBuyPlan = async () => {
        setOpenLoader(true);
        await clientPay.get(`/pago/validacion/compra/${tokenUser ? getRUCEmpresa(tokenUser) : '0'}`
        )
            .then(response => {
                if (response.status === 200) {
                    setOpenLoader(false);
                    setOpen(true);
                }
            })
            .catch(exception => {
                setOpenLoader(false);
                if (exception.response) {
                    msmSwalError(exception.response.data.apierror ? exception.response.data.apierror.mensaje ? exception.response.data.apierror.mensaje : 'Error, no puede adquirir un nuevo paquete' : 'Error, no puede adquirir un nuevo paquete')
                }
                else {
                    msmSwalError('Error en la red')
                }
            }
            )
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseVoucher = () => {
        setOpenVoucher(false);
        setPaymentVoucher({
            idEmpresa: getRUCEmpresa(tokenUser ? tokenUser : ''),
            //idEmpresa: '1722020483',
            idPaquete: plan.idPaquete,
            valorTotalPaquete: paymentVoucher.valorTotalPaquete,
            iva: 0,
            voucher: '',
        });
    };


    const upVoucher = () => {
        handleClose();
        setOpenVoucher(true)
    }
    const handleSubmitLogo = (logo) => {
        //setOpenLoader(true);
        setPaymentVoucher({ ...paymentVoucher, ['voucher']: logo });
    };

    function closeAlert() {
        setMsg({ show: false });
    }

    const handleShowAlert = (alert) => {
        enqueueSnackbar(`Unicamente puede subir archivos .png y .jpg`, {
            variant: "warning",
            anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            },
            autoHideDuration: 4000,
        });
    };

    const validateVoucher = () => {
        if (typeof paymentVoucher.voucher === 'undefined' || paymentVoucher.voucher === '') {
            return true;
        } else {
            return false;
        }
    }

    const saveVoucherImage = () => {
        if (validateVoucher()) {
            enqueueSnackbar(`Debe subir el comprobante de pago`, {
                variant: "warning",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
                autoHideDuration: 4000,
            });

        } else {
            saveVoucher();
        }

    }

    const saveVoucher = async () => {

        setOpenLoader(true);
        await clientPay
            .post('/pago/registro', paymentVoucher)
            .then((response) => {
                if (response.status >= 200 && response.status <= 300) {
                    //setMsg({ show: true, msg: "Pago registrado", type: "success" });
                    handleCloseVoucher();
                    //setOpenDiaglog(true);
                    if (typeof paymentVoucher.voucher !== null && paymentVoucher.voucher !== '') {
                        msmSwalExito(
                            "La transacción sera verificada en los próximos minutos, si tiene alguna inquietud por favor contáctese con su proveedor.",
                        ).then((response) => {

                        });
                    } else {
                        msmSwalExito(
                            "Transacción realizada",
                            `Recuerde subir su comprobante de pago para completar el proceso de contratación.`
                        ).then((response) => {

                        });
                    }

                }
                setOpenLoader(false);
            })
            .catch((exception) => {
                if (exception.response) {
                    handleCloseVoucher();
                    if (exception.response.status === 400) {
                        msmSwalError(
                            "Error",
                            `No se pudo registrar la solicitud. Contáctese con el administrador del Sistema.`,
                        )
                    } else if (exception.response.status === 404) {
                        msmSwalError(
                            "Transacción fallida",
                            exception.response.data.apierror.mensaje +
                            ". Contáctese con el administrador del Sistema.",
                            { show: true, }
                        )

                    } else {
                        setMsg({
                            show: true,
                            msg:
                                "Ocurrió un error interno. Contáctese con el administrador del Sistema.",
                            type: "error",
                        });
                    }
                } else {
                    setMsg({
                        show: true,
                        msg:
                            "Existen problemas en la red. Contáctese con el administrador del Sistema.",
                        type: "error",
                    });
                }
                setOpenLoader(false);
            });

    };

    const handleAddPlan = () => {


        Swal.fire({
            //title: 'Subir comprobante',
            text: "¿Desea subir el comprobante de pago en este momento?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí',
            cancelButtonText: 'No',
            allowEscapeKey: false,
            allowOutsideClick: false,

        }).then((result) => {
            if (result.value) {
                upVoucher();
            } else {
                handleClose();
                saveVoucher();
            }
        })
    }

    return (
        <Fragment>
            <Button variant="outlined" onClick={handleClickOpen} startIcon={<AddShoppingCartOutlinedIcon />}
                color="primary" >Adquirir
            </Button>

            <Dialog
                fullWidth
                maxWidth="md"
                open={open}
                onClose={handleClose}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="max-width-dialog-title">Adquirir Plan</DialogTitle>
                <DialogContent>
                    <form
                    >
                        <Grid container spacing={1}>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                            >
                                <Grid item xs={12} md={12}>
                                    <label>
                                        <strong>Resumen del Plan</strong>
                                    </label>
                                </Grid>

                                <Grid item xs={12} md={4} className={classes.divForm}>
                                    <label>
                                        Paquete:
                                    </label>
                                </Grid>
                                <Grid item xs={12} md={8} className={classes.divInput}>
                                    <input
                                        className="form-control  is-valid"
                                        type="text"
                                        disabled
                                        placeholder="Ej. Ordenador"
                                        name="descripcionProducto"
                                        value={plan.nombre}
                                        //onChange={handleChange}
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} className={classes.divForm}>
                                    <label>
                                        Precio:
                                    </label>
                                </Grid>
                                <Grid item xs={12} md={8} className={classes.divInput}>
                                    <input
                                        className="form-control  is-valid"
                                        type="text"
                                        disabled
                                        placeholder="Ej. 12.00"
                                        name="precioUnitario"
                                        value={`$. ${plan.precio.toFixed(2)}`}
                                        //onChange={handleChange}
                                        autoComplete="off"
                                    />

                                </Grid>




                                <Grid item xs={12} md={4} className={classes.divForm}>
                                    <label>
                                        {valorIva[2].NOMBRE}
                                    </label>
                                </Grid>

                                <Grid item xs={12} md={8} className={classes.divInput}>
                                    <input
                                        className="form-control  is-valid"
                                        type="text"
                                        disabled
                                        placeholder="Ej. 15.00"
                                        name="precioUnitario"
                                        value={`$. ${((valorIva[2].VALOR * plan.precio) / 100).toFixed(2)}`}
                                        autoComplete="off"
                                    />

                                </Grid>
                                <Grid item xs={12} md={4} className={classes.divForm}>
                                    <label>
                                        Total:
                                    </label>
                                </Grid>
                                <Grid item xs={12} md={8} className={classes.divInput}>
                                    <input
                                        className="form-control  is-valid"
                                        type="text"
                                        disabled
                                        placeholder="Ej. 15.00"
                                        name="precioUnitario"
                                        value={`$. ${paymentVoucher.valorTotalPaquete.toFixed(2)}`}
                                        autoComplete="off"
                                    />
                                </Grid>
                            </Grid>


                            <Grid item xs={12} md={12}>
                                <Divider />
                            </Grid>

                            <Grid item xs={12} md={12} className={classes.divButton}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleAddPlan}
                                    className={classesGlobales.button}
                                >
                                    Continuar
                                </Button>

                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleClose}
                                    className={classesGlobales.button}
                                >
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={openVoucher}
                onClose={handleClose}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="max-width-dialog">Subir comprobante</DialogTitle>
                <DialogContent>

                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item xs={12} md={12}>
                            <label>
                                Resumen del paquete a adquirir
                                    </label>
                        </Grid>
                        <Grid item xs={12} md={4} className={classes.divForm}>
                            <label>
                                Paquete:
                            </label>
                        </Grid>
                        <Grid item xs={12} md={8} className={classes.divInput}>
                            <input
                                className="form-control  is-valid"
                                type="text"
                                disabled
                                placeholder="Ej. Ordenador"
                                name="descripcionProducto"
                                value={plan.nombre}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} md={4} className={classes.divForm}>
                            <label>
                                Total:
                            </label>
                        </Grid>
                        <Grid item xs={12} md={8} className={classes.divInput}>
                            <input
                                className="form-control  is-valid"
                                type="text"
                                placeholder="Ej. 12.00"
                                name="precioUnitario"
                                value={`$. ${paymentVoucher.valorTotalPaquete.toFixed(2)}`}
                                autoComplete="off"
                            />

                        </Grid>

                        <Grid item sm={12} md={12} className={classes.aligGrid}>
                            <CompanyLogo
                                title={'Comprobante de pago'}
                                urlImage={paymentVoucher.voucher}
                                onSubmit={handleSubmitLogo}
                                onShowAlert={handleShowAlert}
                                textBtn="Subir comprobante"
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} md={12} className={classes.divButton}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={saveVoucherImage}
                                className={classesGlobales.button}
                            >
                                Continuar
                                </Button>

                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleCloseVoucher}
                                className={classesGlobales.button}
                            >
                                Cancelar
                                </Button>
                        </Grid>

                    </Grid>
                </DialogContent>
            </Dialog>
            <Backdrop className={classes.backdrop} open={openLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </Fragment>
    );
}

export default AddPackage;