import React, { Fragment, useEffect } from 'react';
import { Grid, makeStyles, Paper, ButtonBase, Typography, CircularProgress, Dialog, DialogTitle, DialogContent, Button, Backdrop, Avatar } from '@material-ui/core';
import { palette, msmSwalExito, msmSwalError } from 'configurations/styles/theme';
import clientBackend, { httpCore, clientBackendPDF } from 'configurations/axios'

import { getRUCEmpresa, getSub } from 'redux/actions/userActions';
import { useSelector, useDispatch } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import pdfIcon from 'assets/icons/pdf.png'
import bigDecimal from 'js-big-decimal';
//Notificacion errores https://iamhosseindhv.com/notistack/demos#variants
import { useSnackbar } from 'notistack';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import { ConfirmAlert, iconsAlert, ErrorsAlert } from 'utils/AlertsCustom';
import { selectedResourceAction } from "redux/actions/resourcesActions";
import { checkPreRequirements } from 'widgets/menu/preRedirect';
import { getNumberVoucherSuccess } from 'redux/actions/numberVouchersAction';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.primary,
        margin: theme.spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '1%',
        borderStyle: 'solid',
        boxShadow: "5px 3px 3px #aaaaaa",
        borderRadius: '8px',
        justifyContent: 'center',
        borderWidth: '1px'
    },
    formControl: {
        marginTop: theme.spacing(0),
        minWidth: 120,

    },
    divForm: {
        marginBottom: '1%',
        textAlign: 'justify',
    },

    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
    msgDialog: {
        padding: "2%"
    },
    btnReport: {
        borderRadius: "8px",
        borderStyle: "dashed",
        borderWidth: "1px",
        borderColor: palette.primary.main,
        color: palette.primary.main,
        marginTop: "1%",
        marginRight: "1%"
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    divButton: {
        paddingLeft: '3%',
        paddingRight: '3%'
    }
}));




const ElectronicVoucherOptions = ({ clearAllData, fechaEmision, factura, setFactura, detalles, pagos, additionalInfoInvoice, propina, idCliente, totals }) => {

    const classes = useStyles();
    const tokenUser = useSelector(state => state.user.tk);
    const [open, setOpen] = React.useState(false);
    const [msg, setMsg] = React.useState("");
    const [openLoader, setOpenLoader] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const selectedResource = (resource) => dispatch(selectedResourceAction(resource));

    const getPerchase = () => {
        if (validation()) {
            if (validateParametersPayment()) {
                let infoAdicional = [];
                if (idCliente.idAsignacionEmpresaCliente !== 0) {
                    infoAdicional.push({ nombre: 'CORREO', valor: idCliente.correo })
                    if (additionalInfoInvoice.length > 0) {
                        Array.prototype.push.apply(infoAdicional, additionalInfoInvoice);
                    }
                } else {
                    if (additionalInfoInvoice.length > 0) {
                        Array.prototype.push.apply(infoAdicional, additionalInfoInvoice);
                    }
                }
                return ({
                    detalles: detalles,
                    //fechaEmision:fechaEmision.toISOString().substring(0, 10),
                    fechaEmision: fechaEmision.toISOString(),
                    idAsigancionEmpresaUsuario: Number(getSub(tokenUser)),
                    idAsignacionEmpresaCliente: idCliente.idAsignacionEmpresaCliente,
                    infromacionAdicional: infoAdicional,
                    pagos: pagos,
                    rucEmpresa: getRUCEmpresa(tokenUser)

                });
            }
        } else {
            return null;
        }
    }

    //para generar la factura
    const generatePerchase = async () => {
        let perchase = getPerchase();
        if (perchase !== null) {
            setOpenLoader(true);
            await httpCore.post(`/generacion/comprobantes/liquidacion/compra`, perchase).then(
                response => {
                    if (response.status === 200) {
                    }
                    //para actualizar el contador de comprobantes
                    dispatch(getNumberVoucherSuccess(response.numeroComprobantesDisponibles));
                    clearAllData();
                    setOpenLoader(false);
                    selectedResource({
                        "id": -4,
                        "nameMenu": "Verificando pre-requisitos",
                        "titlePage": "Verificando pre-requisitos",
                        titleTab: "Inicio",
                        "menuPadre": ""
                    })
                    msmSwalExito(
                        "Comprobante generado",
                        `En breve será enviado al SRI para su autorización.`,
                        { show: true, text: "Ver Comprobantes Pendientes" }
                    ).then((response) => {
                        if (response.isDismissed) {
                            selectedResource({
                                "id": "PEN",
                                "nameMenu": "Pendientes",
                                "titlePage": "Pendientes",
                                titleTab: "Comp. Pendientes",
                                "menuPadre": "REP"
                            })
                        }
                    });
                    
                }
            ).catch(
                exception => {
                    if (exception.response) {

                        msmSwalError(exception.response.data.apierror ? exception.response.data.apierror.mensaje ? exception.response.data.apierror.mensaje : 'Error al generar el comprobante.' : 'Error al generar el comprobante.')
                    } else {

                        msmSwalError('Existen problemas en la red. Contáctese con el administrador del Sistema.')
                    }

                    setOpenLoader(false);
                }
            );
        }
    }

    const generateRide = async () => {
        let perchase = getPerchase();
        if (perchase !== null) {
            setOpenLoader(true);
            return await clientBackendPDF.post(`/core/generacion/comprobantes/ride/liquidacion/compra`, perchase).then(
                response => {
                    //Create a Blob from the PDF Stream
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);
                    setOpenLoader(false);
                    //Open the URL on new Window
                    window.open(fileURL);
                    msmSwalExito('RIDE generado')
                }
            ).catch(
                exception => {
                    if (exception.response) {

                        console.error('error', { exception });
                        msmSwalError(exception.response.data.apierror ? exception.response.data.apierror.mensaje ? exception.response.data.apierror.mensaje : 'Error al generar el comprobante.' : 'Error al generar el comprobante.')
                    } else {
                        msmSwalError('Existe problemas en la red. Contáctese con el administrador del Sistema.')
                        console.log({ exception });
                    }
                    setOpenLoader(false);
                }
            )
        }
    }

    const validation = () => {
        let result = true;
        const errors = {}
        if (!validationTip()) {
            errors.tip = 'La propina no puede ser mayor al 10% del valor del comprobante';
            result = false;
        }
        if (Object.entries(idCliente).length === 0) {
            errors.cliente = `Ingrese el proveedor`
            result = false;
        }
        if (idCliente.idAsignacionEmpresaCliente === 0 && (Number(validateMultiplePayment()) > 200)) {
            errors.consumidor = 'Cuando es consumidor final el total del comprobante no debe ser mayor a 200';
            result = false;
        }

        if (pagos.length === 1) {
            if (typeof pagos[0].plazo === 'undefined' || pagos[0].plazo.trim() === '') {

                errors.pagos = 'Ingrese el plazo en la forma de pago';
                result = false;
            }
        } else if (pagos.length === 0) {
            errors.pagos = 'Ingrese la(s) forma(s) de pago';
            result = false;
        }
        ErrorsAlert(errors)
        return result;
    }

    const validateMultiplePayment = () => {

        let sum = pagos.reduce(function (acc, pago) {
            return Number(acc) + Number(pago.total);
        }, 0);

        return sum;
    }

    const validationTip = () => {
        let recommendedTip = bigDecimal.multiply(totals.totalFactura, '0.10');
        return Number(propina) <= Number(recommendedTip);

    }

    const handleClose = () => {
        setOpen(false);
    };

    const clearForm = () => {
        ConfirmAlert(
            `¿Está seguro de limpiar los datos del formulario?`,
            "",
            iconsAlert.question,
            true,
            "Sí",
            "Cancelar",
            () => {
                clearAllData();
            },
            () => {

            }
        );
    }

    const validateParametersPayment = () => {
        if (pagos.length === 1) {
            if (typeof pagos[0].plazo === 'undefined' || pagos[0].plazo.trim() === '') {

                return false;
            } else {
                return true;
            }
        } else if (pagos.length === 0) {

            return false;
        } else {
            return true;
        }
    }


    const validatePrerequisities = (ride) => {
        const tk = tokenUser ? tokenUser : "";
        if (tk !== "") {
            setOpenLoader(true);
            checkPreRequirements(getSub(tk), getRUCEmpresa(tk), dispatch)
                .then((newErrorsCompany) => {
                    console.log(newErrorsCompany)
                    if (Array.isArray(newErrorsCompany)) {
                        if (newErrorsCompany.length === 0) {
                            if (ride) {
                                generateRide();
                            } else {
                                generatePerchase()
                            }
                        } else {
                            selectedResource({
                                "id": -4,
                                "nameMenu": "Verificando pre-requisitos",
                                "titlePage": "Verificando pre-requisitos",
                                titleTab: "Inicio",
                                "menuPadre": ""
                            })
                        }
                    }
                })
                .catch(() => {
                    //setShowLoader(false);
                    setOpenLoader(false);
                });
        }
    }

    return (
        <Fragment >

            <div className={classes.root} justifyContent='absolute' >
                <Grid item xs={12} md={4} align="center" className={classes.divButton} >
                    <Button onClick={() => validatePrerequisities(true)} variant="text" className={classes.btnReport} fullWidth>
                        <Avatar variant="square" src={pdfIcon} className={classes.small} />
                            Visualizar RIDE
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} align="center" className={classes.divButton}>
                    <Button onClick={() => validatePrerequisities(false)} variant="text" className={classes.btnReport} fullWidth>
                        <SaveIcon style={{ color: palette.info.main }} fontSize="large" />
                            Firmar y autorizar
                     </Button>
                </Grid>
                <Grid item xs={12} md={4} align="center">
                    <Button
                        onClick={clearForm}
                        variant="text"
                        className={classes.btnReport}
                        fullWidth
                    >
                        <DeleteSweepIcon style={{ color: palette.error.main, marginRight: "10px" }} fontSize="large" />
                        Limpiar Formulario
                    </Button>
                </Grid>

                <Dialog
                    fullWidth
                    maxWidth="sm"
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle >Advertencia</DialogTitle>
                    <DialogContent>

                        <Grid container spacing={0}>
                            <Grid item xs={12} md={12} className={classes.msgDialog} align="center">
                                {msg}
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Button variant="contained" color="primary" fullWidth
                                    onClick={handleClose}
                                >Cerrar</Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                <Backdrop className={classes.backdrop} open={openLoader} >
                    <CircularProgress color="primary" />
                </Backdrop>
            </div>



        </Fragment>

    );
}

export default ElectronicVoucherOptions;