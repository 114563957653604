import React from 'react'
import ReferralGuideData from './Forms/ReferralGuideData';
import useTitle from 'hooks/useTitle';
import { makeStyles, Paper } from '@material-ui/core';
import { useStylesGlobal } from 'configurations/styles/theme';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: '30px',
        flexGrow: 1,
        paddingLeft: '3%',
        paddingRight: '3%',
        marginBottom: "5%",
    },
}));

const ReferralGuide = () => {

    const classesView = useStyles();
    const classesGlobales = useStylesGlobal();
    useTitle({ title: 'Guía de Remisión' });

    return (
        <div className={classesView.root} >
            <Paper className={classesGlobales.paperHeader}>
                <h5 className={classesGlobales.titleHeader}>Emisión de Guía de Remisión</h5>
            </Paper>

            {/*<Addressee></Addressee>*/}
            <ReferralGuideData />
        </div>
    );
}

export default ReferralGuide;