import React, { Fragment, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Grid,
  Backdrop,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
//Notificacion errores https://iamhosseindhv.com/notistack/demos#variants
import { useSnackbar } from "notistack";
//componentes del sistema
import { palette, useStylesGlobal } from "configurations/styles/theme";
import { useSelector } from "react-redux";
import { getRUCEmpresa } from "redux/actions/userActions";
import clientBackend from "configurations/axios";
import Alert from "widgets/alert";
import useForm from "hooks/useFrom";
import validations from "../validations";
import { API_POST_SAVE_PRODUCTS } from "../apis";
import { useStylesApp } from "hooks/useStyles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(0),
    minWidth: 120,
  },
  radio: {
    display: "-webkit-box",
    marginTop: "0px",
  },
  radioLable: {
    color: palette.primary.main,
    display: "flex",
  },
  radioButton: {
    marginTop: "0px",
    paddingTop: "0px",
  },
  divForm: {
    marginBottom: "1%",
  },
  divInput: {
    marginBottom: "1%",
    paddingRight: "1%",
    paddingLeft: "0.5%",
  },
  divButton: {
    paddingLeft: "3%",
    paddingRight: "3%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 999,
    color: palette.primary.main,
  },
}));

const AddProductsBtn = ({ updateView }) => {
  const classes = useStyles();
  const classesApp = useStylesApp();
  const classesGlobal = useStylesGlobal();
  const [openLoader, setOpenLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const userToken = useSelector((state) => state.user.tk);
  const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
  const { enqueueSnackbar } = useSnackbar();
  const [products, setProducts] = useState({
    idEmpresa: userToken ? getRUCEmpresa(userToken) : "0",
    precioUnitario: null
  });

  const handleClose = () => {
    setOpen(false);
    setProducts({
      idEmpresa: userToken ? getRUCEmpresa(userToken) : "0",
    });
    getObject({
      idEmpresa: userToken ? getRUCEmpresa(userToken) : "0",
    });
  };

  const existError = () => {
    setOpenLoader(false);
  };

  const handleSave = async () => {
    console.log('error submit')
    setOpenLoader(true);
    await clientBackend
      .post(API_POST_SAVE_PRODUCTS, products)
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Producto/Servicio Agregado", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            autoHideDuration: 10000,
          });
          updateView();
          setOpenLoader(false);
          handleClose();
        }
      })
      .catch((exception) => {
        if (exception.response) {
          if (exception.response.status === 400) {
            setMsg({ show: true, msg: "No se pudo almacenar el producto." });
          } else if (exception.response.status === 404) {
            setMsg({
              show: true,
              msg:
                "No se encontró la empresa enviada para relacionarla con el producto.",
            });
          } else if (exception.response.status === 409) {
            setMsg({
              show: true,
              msg: "El Código principal ya está asignado a otro producto.",
            });
          } else {
            setMsg({
              show: true,
              msg:
                "Error al actualizar el producto. Contáctese con el administrador del Sistema.",
            });
          }
        } else {
          setMsg({
            show: true,
            msg:
              "Existen problemas en la red. Contáctese con el administrador del Sistema.",
          });
        }
        setOpenLoader(false);
      });
  };

  const { errors, handleSubmit, getObject } = useForm(
    handleSave,
    validations.addProduct,
    existError
  );

  function closeAlert() {
    setMsg({ show: false });
  }

  const handleClickOpen = () => {
    setOpen(true);
    setProducts({ idEmpresa: userToken ? getRUCEmpresa(userToken) : "0" });
    errors.codigoPrincipal = null;
    errors.codigoAuxiliar = null;
    errors.descripcion = null;
    errors.precioUnitario = null;
    setMsg({ show: false, msg: null, type: null });
  };

  const handleInvalidPrecie = (event) => {
    event.preventDefault();
    getObject(products);
    handleSubmit(event)
  }

  const handleChange = (event) => {
    let { isValid, value } = validations.changeProduct(
      event.target.name,
      event.target.value
    );
    if (isValid) {
      setProducts({
        ...products,
        [event.target.name]: value,
      });
      getObject({
        ...products,
        [event.target.name]: value,
      });
    }
  };


  return (
    <Fragment>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        <AddCircleIcon
          style={{ color: palette.primary.contrastText, paddingRight: "3%" }}
          fontSize="small"
        />{" "}
        Agregar Producto{" "}
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="max-width-dialog-title">
          Nuevo Producto / Servicio
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={12} md={12}>
                <label>
                  Los campos marcados con ({" "}
                  <font color={palette.error.main}> *</font> ) son obligatorios:
                </label>
              </Grid>
              {msgError.show ? (
                <Alert
                  tipo={msgError.type}
                  mensaje={msgError.msg}
                  mesajeSubrayado={null}
                  titulo={null}
                  mostrar={msgError.show}
                  cerrarAlerta={closeAlert}
                />
              ) : null}
              <Grid item xs={12} md={2} className={classes.divForm}>
                <label>
                  Código principal: <font color={palette.error.main}>*</font>
                </label>
              </Grid>
              <Grid item xs={12} md={4} className={classes.divInput}>
                <input
                  type="text"
                  className={
                    errors.codigoPrincipal
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Ej. 001"
                  name="codigoPrincipal"
                  value={products.codigoPrincipal}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {errors.codigoPrincipal ? (
                  <p className={classesApp.errorLetters}>
                    {errors.codigoPrincipal}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} md={2} className={classes.divForm}>
                <label>Código auxiliar:</label>
              </Grid>
              <Grid item xs={12} md={4} className={classes.divInput}>
                <input
                  type="text"
                  className={
                    errors.codigoAuxiliar
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Ej. 0001"
                  name="codigoAuxiliar"
                  value={products.codigoAuxiliar}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {errors.codigoAuxiliar ? (
                  <p className={classesApp.errorLetters}>
                    {errors.codigoAuxiliar}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} md={2} className={classes.divForm}>
                <label>
                  Descripción: <font color={palette.error.main}>*</font>
                </label>
              </Grid>
              <Grid item xs={12} md={4} className={classes.divInput}>
                <input
                  type="text"
                  className={
                    errors.descripcion
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Ej. zapato"
                  name="descripcion"
                  value={products.descripcion}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {errors.descripcion ? (
                  <p className={classesApp.errorLetters}>
                    {errors.descripcion}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} md={2} className={classes.divForm}>
                <label>
                  Precio Unitario: <font color={palette.error.main}>*</font>
                </label>
              </Grid>
              <Grid item xs={12} md={4} className={classes.divInput}>
                <input
                  type="number"
                  className={
                    errors.precioUnitario
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Ej. 10.01"
                  name="precioUnitario"
                  value={products.precioUnitario}
                  onChange={handleChange}
                  autoComplete="off"
                  onInvalid={handleInvalidPrecie}
                />
                {errors.precioUnitario ? (
                  <p className={classesApp.errorLetters}>
                    {errors.precioUnitario}
                  </p>
                ) : null}
              </Grid>
              <Grid container spacing={3} justify="center">
                <Grid item md={12} xs={12}>
                  <Divider></Divider>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classesGlobal.marginButton}
                >
                  Agregar
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  className={classesGlobal.marginButton}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Backdrop className={classes.backdrop} open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
};

export default AddProductsBtn;
