import React, { Fragment, useState } from 'react'
import { IconButton, Grid, Dialog, DialogTitle, DialogContent, Divider, Button, makeStyles, TextField, FormControl, NativeSelect, withStyles, InputBase } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useStylesGlobal, palette } from 'configurations/styles/theme';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { useStylesApp } from 'hooks/useStyles';
import InputMask from "react-input-mask";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import { useSnackbar } from 'notistack';
import bigDecimal from 'js-big-decimal';
import { typeInvoice } from 'views/facturacion/Forms/typeVoucher';
import { Autocomplete } from '@material-ui/lab';
import checkInputs from 'utils/checkInputs';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(3),
        minWidth: 120,
    },
    radio: {
        display: '-webkit-box',
        marginTop: '0px'
    },
    radioLable: {
        color: palette.primary.main,
        display: 'flex'
    },
    radioButton: {
        marginTop: '0px',
        paddingTop: '0px'
    },
    divForm: {
        marginBottom: '1%',
    },
    divInput: {
        marginBottom: '1%',
        paddingRight: '1%',
        paddingLeft: '0.5%'
    },
    divButton: {
        paddingLeft: '3%',
        paddingRight: '3%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '1%',
        marginBottom: '0.5%',
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
}));

const BootstrapInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(0),
        },
    },
    input: {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            borderRadius: 4,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}))(InputBase);

const EditTaxBtn = ({ data, impuestoRetencions, setImpuestoRetencions, taxDetail, setTaxDetail, viewDetalle, setViewDetalle }) => {

    const classesGlobales = useStylesGlobal();
    const classesApp = useStylesApp();
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [dataTax, setDataTax] = useState(data);
    const [minDate, setMinDate] = useState(new Date());
    const [minDate1, setMinDate1] = useState(minDate.setDate(minDate.getDate() - 30));
    const [empty, setEmty] = useState({
        impuesto: true,
        retencion: true,
        base: true,
        documento: true,
        numero: true,
        fecha: true,
    });

    const handleClose = () => {
        setOpen(false);
    };
    const handleTable = () => {
        setOpen(true);
    }

    const handleChange = event => {
        switch (event.target.name) {
            case 'baseImponible':
                event.preventDefault();
                const re = /^[0-9\b.]+$/;
                if (event.target.value === '' || re.test(event.target.value)) {
                    if (Number(event.target.value.trim()) >= 0 && checkInputs.countDigit(event.target.value) <= 14) {
                        if (validateDecimal(event.target.value)) {
                            let retainedValue = bigDecimal.multiply(event.target.value, bigDecimal.divide(dataTax.porcentaje ? dataTax.porcentaje : '0', '100'));
                            setDataTax({
                                ...dataTax,
                                ['baseImponible']: event.target.value,
                                ['valorRetenido']: bigDecimal.round(retainedValue, 2, bigDecimal.RoundingModes.HALF_UP)
                            })
                            if (typeof event.target.value !== 'undefined' && event.target.value.toString().trim() !== '' && event.target.value !== 0) {
                                setEmty({ ...empty, ['base']: true })
                            }
                        }
                    }
                }
                break;
            case 'numeroDocSustento':
                event.preventDefault();
                setDataTax({
                    ...dataTax,
                    ['numeroDocSustento']: event.target.value,
                })
                break;
            case 'codigoDocSustento':
                event.preventDefault();
                setDataTax({
                    ...dataTax,
                    ['numeroDocSustento']: event.target.value,
                })
                break;
            default:
                break;
        }



    }

    const validateDecimal = (numberValor) => {
        let splitList = numberValor.split('.');
        if (splitList.length > 2) {
            enqueueSnackbar(`El dato ingresado es erroneo utilice punto (.) para separar decimales`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                autoHideDuration: 4000,
            });
            return false;
        } else if (splitList.length > 1) {
            if (splitList[1].length > 2) {
                enqueueSnackbar(`Los datos numéricos debe tener máximo 2 decimales Ej, 12.43`, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    autoHideDuration: 4000,
                });
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }

    }

    const handleUpdate = () => {
        
            taxDetail.map(function updateProducts(tax, index, array) {
                if (compareObject(data, tax)) {
                    impuestoRetencions.splice(index, 1, {
                        baseImponible: dataTax.baseImponible,
                        codigoImpuesto: dataTax.codigoImpuesto,
                        codigoRetencion: dataTax.codigoRetencion,
                        porcentajeARetener: dataTax.porcentaje
                    });
                    taxDetail.splice(index, 1, dataTax);
                    //detalles.splice(index, 1, updateDetalle);
                    enqueueSnackbar(`Impuesto editado`, {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        autoHideDuration: 4000,
                    });
                }
            })

            if (viewDetalle === 1) {
                setViewDetalle(2)
            } else {
                setViewDetalle(1)
            }
            handleClose();
        
    }


    const compareObject = (data, tax) => {
        var aKeys = Object.keys(tax).sort();
        var bKeys = Object.keys(tax).sort();
        if (aKeys.length !== bKeys.length) {
            return false;
        }
        if (aKeys.join('') !== bKeys.join('')) {
            return false;
        }
        for (var i = 0; i < aKeys.length; i++) {
            if (data[aKeys[i]] !== tax[bKeys[i]]) {
                return false;
            }
        }
        return true;
    }

    const validateTax = () => {
        let state = true;
        /* if (typeof tax === 'undefined' || tax === '') {
             setEmty({ ...empty, ['impuesto']: false })
             return false;
         } else if (typeof dataTax.retention === 'undefined' || dataTax.retention === '') {
             setEmty({ ...empty, ['retencion']: false })
             return false;
         } else*/
        // if (typeof dataTax.baseImponible === 'undefined' || dataTax.baseImponible.toString().trim() === '' || dataTax.baseImponible === 0 || !validateDecimal(dataTax.baseImponible.toString())) {
        //     setEmty({ ...empty, ['base']: false })
        //     return false;
        // } else if (typeof dataTax.codigoDocSustento === 'undefined' || dataTax.codigoDocSustento.toString().trim() === '') {
        //     setEmty({ ...empty, ['documento']: false })
        //     return false;
        // } else if (typeof dataTax.codigoDocSustento !== 'undefined' && dataTax.codigoDocSustento.trim() !== '' && dataTax.codigoDocSustento !== '12') {
        //     if (typeof dataTax.numeroDocSustento === 'undefined' || dataTax.numeroDocSustento.trim() === '' || dataTax.numeroDocSustento.replace(/\D/g, '').length < 15) {
        //         setEmty({ ...empty, ['numero']: false })
        //         return false;
        //     } else {
        //         return true;
        //     }
        // } else if (typeof dataTax.fechaEmisionDocSustento === 'undefined' || dataTax.fechaEmisionDocSustento === '') {
        //     setEmty({ ...empty, ['fecha']: false })
        //     return false;
        // } else {
        //     return state;
        
    }

    return (
        <Fragment>
            <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => handleTable()} >
                <EditIcon style={{ color: palette.info.dark }} />
            </IconButton>
            <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="max-width-dialog-title">Editar impuesto</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} >
                        <Grid container direction="row" justify="flex-start" alignItems="center" >
                            <Grid item xs={12}>
                                <label>Los campos marcados con (  <font color={palette.error.main}> *</font> ) son obligatorios:</label>
                            </Grid>


                            <Grid item xs={12} md={4} className={classes.divForm}>
                                <label>Base Imponible: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid fullWidth item xs={12} md={8} >
                                <input className={dataTax.baseImponible !== 0 && dataTax.baseImponible.trim() !== '' ? "form-control  is-valid" : 'form-control'}
                                    type="text"
                                    placeholder="Ej. 123"
                                    name="baseImponible"
                                    value={dataTax.baseImponible}
                                    onChange={event => handleChange(event)}
                                    autoComplete="off"
                                />
                                {empty.base === true ?
                                    null
                                    :
                                    <p className={classesApp.errorLetters}>Ingrese la base imponible</p>
                                }
                            </Grid>

                            <Grid item xs={12} md={4} className={classes.divForm}>
                                <label>Valor Retenido: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid fullWidth item xs={12} md={8} className={classes.divForm}>
                                <input className={dataTax.valorRetenido ? "form-control  is-valid" : 'form-control'}
                                    type="text"
                                    placeholder="Ej. 123"
                                    disabled
                                    name="valor"
                                    value={dataTax.valorRetenido}
                                    //onChange={handleChange}
                                    autoComplete="off"
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} className={classes.divButton}>
                            <Button variant="contained" color="primary" onClick={handleUpdate} className={classesGlobales.button} >
                                Actualizar
                            </Button>

                            <Button variant="outlined" color="secondary" onClick={handleClose} className={classesGlobales.button}>
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}

export default EditTaxBtn;