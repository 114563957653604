import { Backdrop, CircularProgress, IconButton, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { msmSwalError, msmSwalExito, palette } from 'configurations/styles/theme';
import { clientPay } from 'configurations/axios';
import { TramOutlined } from '@material-ui/icons';
import Swal from 'sweetalert2';

const useStyles = makeStyles(theme => ({
    IconButton: {
        marginTop: "0%",
        marginBottom: "0%",
        padding: "0px",
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
}))

const AcceptPaymentBtn = ({ data, reload }) => {
    const classes = useStyles();
    const [openLoader, setOpenLoader] = useState(false);

    

    //cuando el usuario haga submit (click)
    const submitAcceptPayment = () => {
        //Validar el input clave de acceso

        Swal.fire({
            title: 'Aprobar paquete',
            text: "¿Está seguro de aprobar el paquete contratado?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                cancelPayment();
            }
        })

    }

    const cancelPayment = async () => {
        setOpenLoader(true);
        await clientPay.post(`/pago/revision`,
            {
                idAsignacionPaqueteEmpresa: data.idAsignacionPaqueteEmpresa,
                observacion: '',
                revision: true,
            })
            .then(response => {
                if (response.status === 200) {
                    setOpenLoader(false)
                    msmSwalExito('Proceso Exitoso', 'El paquete se ha aprobado con éxito!');
                    reload();

                }
            })
            .catch(exception => {
                if (exception.response) {
                    msmSwalError(exception.response.data.apierror ? exception.response.data.apierror.mensaje ? exception.response.data.apierror.mensaje : 'Error, comprobante no anulado' : 'Error, comprobante no anulado')
                }
                else {
                    msmSwalError('Error en la red')
                }
                setOpenLoader(false)
            }
            )
    }

    return (
        <div>

            <IconButton color="primary" aria-label="upload picture" component="span" onClick={submitAcceptPayment}
                className={classes.IconButton}
            >
                <CheckCircleOutlineIcon style={{ color: palette.primary.main }} className={classes.small} />
            </IconButton>
            <Backdrop className={classes.backdrop} open={openLoader}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </div>
    );
}

export default AcceptPaymentBtn;