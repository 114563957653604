import { domainSis } from 'configurations/constant';
import React from 'react';

//estilos
import '../assets/css/base.css'

const Footer = () => {
    const date = new Date();
    return (
        <>{window.location.hostname.includes(domainSis) ?

            <footer >
                Derechos Reservados  O&M-ASOCIADOS S.A.S. &copy; {date.getFullYear()}
            </footer>
            :
            <footer >
                Derechos Reservados  BIGDATA C.A. &copy; {date.getFullYear()}
            </footer>
        }
        </>
    );
}

export default Footer;