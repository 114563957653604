import React, { Fragment, useState, useEffect } from 'react';
import { Button, Grid, Dialog, DialogTitle, DialogContent, makeStyles, withStyles, InputBase, FormControl, NativeSelect, Divider } from '@material-ui/core';
import { palette, useStylesGlobal } from 'configurations/styles/theme';
import { useStylesApp } from 'hooks/useStyles';
import { formasPago, unidadTiempo } from 'views/facturacion/Forms/typeVoucher';
import EditTable from 'widgets/Tables/EditTable';
import bigDecimal from 'js-big-decimal';
//Notificacion errores https://iamhosseindhv.com/notistack/demos#variants
import { useSnackbar } from 'notistack';
import uuid from 'react-uuid';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        margin: theme.spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '1%',
        borderStyle: 'solid',
        borderBottomWidth: '5px',
        borderRadius: '4px',
    },
    formControl: {
        marginTop: theme.spacing(0),
        minWidth: 120,

    },
    radio: {
        display: '-webkit-box',
        marginTop: '0px'
    },
    radioLable: {
        color: palette.primary.main,
        display: 'flex'
    },
    radioButton: {
        marginTop: '0px',
        paddingTop: '0px'
    },
    divForm: {
        marginBottom: '1%',
        textAlign: 'justify',
    },
    divInput: {
        marginBottom: '1%',
        paddingRight: '1%',
        paddingLeft: '0.5%',
        textAlign: 'justify',

    },
    divSelect: {
        width: '100%',
    },
    divButton: {
        paddingLeft: '3%',
        paddingRight: '3%',
        marginBottom: '1%'
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
    haedClient: {
        backgroundColor: palette.primary.light,
        width: '10%',
        marginBottom: '2%',
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '1%',
        borderStyle: 'solid',
        borderBottomWidth: '5px',
        borderRadius: '4px',
    }
}));

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(0),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);


const MultiplePayments = ({ pagos, setPagos, viewPago, setViewPago, totals }) => {

    const classes = useStyles();
    const classesApp = useStylesApp();
    const classesGlobal = useStylesGlobal();
    const [openLoader, setOpenLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [pago, setPago] = useState({
        id: uuid(),
        formaPago: "",
        plazo: "",
        total: "",
        unidadTiempo: ""
    });

    const [empty, setEmty] = useState({
        pago: true,
        plazo: true,
        total: true,
        tiempo: true
    })

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        cleanPago();
        setOpen(false);
    };

    const hadleChangePago = event => {
        setPago(
            {
                ...pago,
                [event.target.name]: event.target.value
            }
        )

    }

    const hadleAddPago = () => {
        if (validationInputs()) {
            if (validateDecimal(pago.total.toString())) {
                if (Number(bigDecimal.add(validateMultiplePayment(), pago.total)) <= Number(bigDecimal.add(totals.totalFactura, totals.totalPropina))) {

                    if (pagos.length > 0) {
                        setPagos([...pagos, pago]);
                    } else {
                        setPagos([pago]);
                    }
                    if (viewPago === 1) {
                        setViewPago(2)
                    } else {
                        setViewPago(1)
                    }
                    handleClose();
                    enqueueSnackbar(`Forma de pago agregado`, {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        autoHideDuration: 4000,
                    });

                } else {
                    //alert(Number(bigDecimal.add(validateMultiplePayment(), pago.total)))
                    enqueueSnackbar(`El valor es: ${Number(bigDecimal.add(validateMultiplePayment(), pago.total))}, y excede al valor total`, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        autoHideDuration: 4000,
                    });
                }
            }
        }

    }

    const handleChange = event => {
        event.preventDefault();
        const re = /^[0-9\b]{1,14}$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setPago({
                ...pago,
                [event.target.name]: event.target.value
            })
        }
    }

    const handleChangeTotal = event => {
        event.preventDefault();
        const re = /^[0-9\b.]{1,14}$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            if (Number(event.target.value.trim()) >= 0) {
                setPago({
                    ...pago,
                    [event.target.name]: event.target.value
                })
            }
        }
    }

    const validateDecimal = (numberValor) => {
        let splitList = numberValor.split('.');
        if (splitList.length > 2) {
            enqueueSnackbar(`El dato ingresado es erroneo utilice punto (.) para separar decimales`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                autoHideDuration: 4000,
            });
            return false;
        } else if (splitList.length > 1) {
            if (splitList[1].length > 2) {
                enqueueSnackbar(`El dato ingresado debe tener solo 2 decimales Ej, 12.43`, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    autoHideDuration: 4000,
                });
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }

    }

    const validateMultiplePayment = () => {

        let sum = pagos.reduce(function (acc, pago) {
            return Number(acc) + Number(pago.total);
        }, 0);
        return sum;


    }

    const cleanPago = () => {
        setPago({
            id: uuid(),
            formaPago: "",
            plazo: "",
            total: "",
            unidadTiempo: ""

        });
        setEmty({
            pago: true,
            plazo: true,
            total: true,
            tiempo: true
        })
    }

    useEffect(() => {

    }, [pago])

    const validationInputs = () => {
        if (typeof pago === 'undefined' || pago.formaPago.trim() === '') {
            setEmty({ ...empty, ['pago']: false })
            return false;
        } else if (typeof pago === 'undefined' || pago.unidadTiempo.trim() === '') {
            setEmty({ ...empty, ['tiempo']: false })
            return false;
        } else if (typeof pago === 'undefined' || pago.plazo.trim() === '') {
            setEmty({ ...empty, ['plazo']: false })
            return false;
        } else if (typeof pago === 'undefined' || pago.total === '') {
            setEmty({ ...empty, ['total']: false })
            return false;
        } else {
            return true;
        }
    }


    const summation = () => {
        let sumTotal = bigDecimal.round(bigDecimal.subtract(bigDecimal.add(totals.totalFactura, totals.totalPropina), validateMultiplePayment()), 2, bigDecimal.RoundingModes.HALF_UP);
        return (sumTotal);
    }


    return (
        <Fragment>
            <Grid container justify='center' className={classes.divButton}>
                <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                    Agregar Pago
                </Button>
            </Grid>
            <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="max-width-dialog-title">Nuevo pago</DialogTitle>
                <DialogContent>
                    <form onSubmit={event => { setOpenLoader(true); }}>
                        <Grid container >

                            <Grid item xs={12} md={12}>
                                <label>Los campos marcados con (  <font color={palette.error.main}> *</font> ) son obligatorios:</label>
                            </Grid>
                            <Grid item xs={12} md={12} className={classes.divForm}>
                                <label>Pago faltante: <font color={palette.error.main}> {`${summation()}`}</font></label>
                            </Grid>
                            <Grid item xs={12} md={4} className={classes.divForm}>
                                <label>Forma: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid item xs={12} md={8} className={classes.divInput}>
                                <FormControl className={classes.divSelect} >
                                    <NativeSelect
                                        name='formaPago'
                                        value={pago.formaPago}
                                        onChange={event => setPago({ ...pago, [event.target.name]: event.target.value })}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" >--Seleccione--</option>
                                        {
                                            formasPago.map(pago => (
                                                <option
                                                    key={pago.CODIGO}
                                                    value={pago.CODIGO}>
                                                    {pago.CODIGO} - {pago.NOMBRE}
                                                </option>
                                            ))
                                        }

                                    </NativeSelect>
                                </FormControl>

                                {empty.pago === true ?
                                    null
                                    :
                                    <p className={classesApp.errorLetters}>Seleccione la forma de pago</p>
                                }
                            </Grid>

                            <Grid item xs={12} md={4} className={classes.divForm}>
                                <label>Tiempo: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid item xs={12} md={8} className={classes.divInput}>
                                <FormControl className={classes.divSelect}>
                                    <NativeSelect
                                        name='unidadTiempo'
                                        value={pago.unidadTiempo}
                                        onChange={event => setPago({ ...pago, [event.target.name]: event.target.value })}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" >--Seleccione--</option>
                                        {
                                            unidadTiempo.map(unidad => (
                                                <option
                                                    key={unidad.CODIGO}
                                                    value={unidad.NOMBRE}>
                                                    {unidad.NOMBRE}
                                                </option>
                                            ))
                                        }

                                    </NativeSelect>
                                </FormControl>
                                {empty.tiempo === true ?
                                    null
                                    :
                                    <p className={classesApp.errorLetters}>Seleccione la unidad de tiempo</p>
                                }
                            </Grid>

                            <Grid item xs={12} md={4} className={classes.divForm}>
                                <label>Plazo: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid item xs={12} md={8} className={classes.divInput}>
                                <input type="text"
                                    placeholder="Ej. 3"
                                    name="plazo"
                                    value={pago.plazo}
                                    onChange={handleChange}
                                    autoComplete="off"
                                    className={pago ? pago.plazo ? 'form-control is-valid' : 'form-control' : 'form-control'} />

                                {empty.plazo === true ?
                                    null
                                    :
                                    <p className={classesApp.errorLetters}>Ingrse el número de plazos</p>
                                }

                            </Grid>

                            <Grid item xs={12} md={4} className={classes.divForm}>
                                <label>Total: <font color={palette.error.main}>*</font></label>
                            </Grid>
                            <Grid item xs={12} md={8} className={classes.divInput}>
                                <input type="text"
                                    placeholder="Ej. 300"
                                    name="total"
                                    value={pago.total}
                                    onChange={handleChangeTotal}
                                    autoComplete="off"
                                    className={pago ? pago.total ? 'form-control is-valid' : 'form-control' : 'form-control'} />

                                {empty.total === true ?
                                    null
                                    :
                                    <p className={classesApp.errorLetters}>Ingrse el valor del pago</p>
                                }

                            </Grid>



                            <Grid container spacing={3} justify='center' >
                                <Grid item md={12} xs={12} >
                                    <Divider />
                                </Grid>
                                <Button  variant="contained" color="primary" onClick={hadleAddPago} className={classesGlobal.marginButton}>
                                    Agregar
                                </Button>
                                <Button  variant="outlined" color="secondary" onClick={handleClose} className={classesGlobal.marginButton}>
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>


                    </form>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}

export default MultiplePayments;