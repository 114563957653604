import checkInputs from "utils/checkInputs";
import checkDocument from "utils/checkDocument";

export const validations = {
  onChange: function checkOnChange(nameInput, value, radioOption) {
    const response = { value: value, error: null, keyWordError: null };
    switch (nameInput) {
      case "identificacionDestinatario":
        if (radioOption === "C") {
          if (!checkInputs.isNumeric(value)) {
            response.keyWordError = "isNotNumeric";
            response.error = "Solo puede ingresar números.";
          } else if (
            !checkDocument.validarCedula(value) &&
            !checkDocument.validarRucPersonaNatural(value) &&
            !checkDocument.validarRucSociedadPrivada(value) &&
            !checkDocument.validarRucSociedadPublica(value)
          ) {
            response.error = "Cédula o RUC inválido.";
          }
        } else if (!checkInputs.isAlphanumeric(value)) {
          response.keyWordError = "isNotNumeric";
          response.error = "No ingrese caracteres especiales.";
        } else if (value.length > 300) {
          response.error = "No ingrese más de 300 caracteres.";
          response.keyWordError = "isLong";
        }

        return response;
      case "razonSocialDestinatario":
        if (checkInputs.countLenth(value) > 300) {
          response.error = "No ingrese más de 300 caracteres.";
        }
        return response;
      case "dirDestinatario":
        if (checkInputs.countLenth(value) > 300) {
          response.error = "No ingrese más de 300 caracteres.";
        }
        return response;
      case "motivoTraslado":
        if (checkInputs.countLenth(value) > 300) {
          response.error = "No ingrese más de 300 caracteres.";
        }
        return response;
      case "numAutDocSustento":
        if (!checkInputs.isNumeric(value)) {
          response.keyWordError = "isNotNumeric";
          response.error = "Solo puede ingresar números.";
        } else if (checkInputs.countLenth(value) > 49) {
          response.keyWordError = "isLong";
          response.error = "Solo puede ingresar 10 o 49 .";
        }
        return response;
      case "codEstabDestino":

        let data = value
        if (typeof value !== "string") {
          data = data.toString()
        }
        data = data.replace('_', '')
        if (checkInputs.notNull(data)) {
          if (!checkInputs.isNumeric(data)) {
            response.error = "Solo ingrese números.";
          } else if (checkInputs.countLenth(data) !== 3) {
            response.error = "El Código debe contener 3 dígitos";
          }
        }
        return response;
      case "numDocSustento":

        if (checkInputs.notNull(value)) {
          if (!checkDocument.validarNumDocumentoSRI(value))
            response.error = "El número de documento debe tener el formato  XXX-XXX-XXXXXXXXX";
        }
        return response;
      default:
        return response;
    }
  },
  onSubmit: function checkOnSubmit(data) {
    const errors = {};
    if (data.pasaporteCheck !== 'P') {

      if (
        !checkDocument.validarCedula(data.identificacionDestinatario) &&
        !checkDocument.validarRucPersonaNatural(
          data.identificacionDestinatario
        ) &&
        !checkDocument.validarRucSociedadPrivada(
          data.identificacionDestinatario
        ) &&
        !checkDocument.validarRucSociedadPublica(data.identificacionDestinatario)
      ) {
        errors.identificacionDestinatario = "Cédula o RUC inválido.";
      }
    } else if (data.identificacionDestinatario.trim() === '') {
      errors.identificacionDestinatario = "Cédula / RUC / Pasaporte es necesario.";
    }
    if (!checkInputs.notNull(data.razonSocialDestinatario)) {
      errors.razonSocialDestinatario = "La razón social es requerida.";
    } else if (checkInputs.countLenth(data.razonSocialDestinatario) > 300) {
      errors.error = "No ingrese más de 300 caracteres.";
    }
    if (!checkInputs.notNull(data.dirDestinatario)) {
      errors.dirDestinatario = "La dirección es requerida.";
    } else if (checkInputs.countLenth(data.dirDestinatarioo) > 300) {
      errors.error = "No ingrese más de 300 caracteres.";
    }
    if (!checkInputs.notNull(data.motivoTraslado)) {
      errors.motivoTraslado = "El motivo  es requerido.";
    } else if (checkInputs.countLenth(data.dirDestinatario) > 300) {
      errors.error = "No ingrese más de 300 caracteres.";
    }
    if (checkInputs.notNull(data.codEstabDestino)) {
      if (!checkInputs.isNumeric(data.codEstabDestino)) {
        errors.error = "Solo ingrese números.";
      } else if (checkInputs.countLenth(data.codEstabDestino) !== 3) {
        errors.error = "El Código debe contener 3 dígitos";
      }
    }

    if (checkInputs.notNull(data.numDocSustento)) {
      if (!checkDocument.validarNumDocumentoSRI(data.numDocSustento))
        errors.numDocSustento = "El número de documento debe tener el formato  XXX-XXX-XXXXXXXXX";
    }
    /*if (!checkInputs.notNull(data.codDocSustento)) {
      errors.codDocSustento = "El tipo de documento es requerido.";
    }
    
    if (!checkInputs.notNull(data.numAutDocSustento)) {
      errors.numAutDocSustento = "El número autorización Doc. es requerido.";
    } else if (checkInputs.countLenth(data.numAutDocSustento) > 300) {
      errors.error = "No ingrese más de 300 caracteres.";
    }*/

    return errors;
  },
};

export const validationsDispatcher = {
  onChange: function checkOnChange(nameInput, value, radioOption) {
    const response = { value: value, error: null, keyWordError: null };

    switch (nameInput) {
      case "rucTransportista":
        if (checkInputs.countLenth(value) > 13) {
          response.keyWordError = "isLong";
          response.error = "No ingrese más de 13 caracteres.";
        } else if (!checkInputs.isNumeric(value)) {
          response.keyWordError = "isNotNumeric";
          response.error = "Solo puede ingresar números.";
        } else if (
          !checkDocument.validarCedula(value) &&
          !checkDocument.validarRucPersonaNatural(value) &&
          !checkDocument.validarRucSociedadPrivada(value) &&
          !checkDocument.validarRucSociedadPublica(value)
        ) {
          response.error = "Cédula o RUC inválido.";
        }

        return response;
      case "dirPartida":
        if (checkInputs.countLenth(value) > 40) {
          response.error = "No ingrese más de 40 caracteres.";
          response.keyWordError = "isLong";
        }
        return response;

      case "razonSocialTransportista":
        if (checkInputs.countLenth(value) > 300) {
          response.error = "No ingrese más de 300 caracteres.";
        }
        return response;
      case "rise":
        if (checkInputs.countLenth(value) > 10) {
          response.error = "No ingrese más de 40 caracteres.";
          response.keyWordError = "isLong";
        } else if (!checkInputs.isNumeric(value)) {
          response.keyWordError = "isNotNumeric";
          response.error = "Solo puede ingresar números.";
        }
        return response;

      default:
        return response;
    }
  },
  onSubmit: function checkOnSubmit(data) {
    const errors = {};

    if (!checkInputs.notNull(data.rucTransportista)) {
      errors.rucTransportista = "El RUC/CI/Cédula es requerida.";
    } else if (data.checkInputs === 'C') {
      if (
        !checkDocument.validarCedula(data.rucTransportista) &&
        !checkDocument.validarRucPersonaNatural(data.rucTransportista) &&
        !checkDocument.validarRucSociedadPrivada(data.rucTransportista) &&
        !checkDocument.validarRucSociedadPublica(data.rucTransportista)
      ) {
        errors.rucTransportista = "Cédula o RUC inválido.";
      }
    } else if (checkInputs.countLenth(data.rucTransportista) > 300) {
      errors.error = "No ingrese más de 300 caracteres.";
    }

    if (!checkInputs.notNull(data.razonSocialTransportista)) {
      errors.razonSocialTransportista = "La razón social es requerida.";
    } else if (checkInputs.countLenth(data.razonSocialTransportista) > 300) {
      errors.error = "No ingrese más de 300 caracteres.";
    }
    if (!checkInputs.notNull(data.dirPartida)) {
      errors.dirPartida = "La dirección es requerida.";
    } else if (checkInputs.countLenth(data.dirPartida) > 40) {
      errors.error = "No ingrese más de 40 caracteres.";
    }
    if (!checkInputs.notNull(data.placa)) {
      errors.placa = "La placa es requerida.";
    }
    if (checkInputs.countLenth(data.rise) > 300) {
      errors.error = "No ingrese más de 300 caracteres.";
    }
    return errors;
  },
};

export const validationsDetailDispatcher = {
  onChange: function checkOnChange(nameInput, value) {
    const response = { value: value, error: null, keyWordError: null };

    switch (nameInput) {
      case "codigoInterno":
        if (checkInputs.countLenth(value) > 25) {
          response.error = "No ingrese más de 25 caracteres.";
          response.keyWordError = "isLong";
        } else if (!checkInputs.isAlphanumeric(value)) {
          response.keyWordError = "isNotNumeric";
          response.error = "No puede ingresar caracteres especiales.";
        }
        return response;
      case "cantidad":
        if (!checkInputs.isDecimal(value)) {
          response.keyWordError = "isNotNumeric";
          response.error = "Solo puede ingresar números.";
        }
        return response;

      case "descripcion":
        if (checkInputs.countLenth(value) > 300) {
          response.error = "No ingrese más de 300 caracteres.";
        }
        return response;

      default:
        return response;
    }
  },
  onSubmit: function checkOnSubmit(data) {
    const errors = {};
    if (!checkInputs.notNull(data.codigoInterno)) {
      errors.codigoInterno = "La codigo es requerido.";
    } else if (checkInputs.countLenth(data.codigoInterno) > 300) {
      errors.error = "No ingrese más de 300 caracteres.";
    }
    if (!checkInputs.notNull(data.cantidad) || data.cantidad.toString() === '' || Number(data.cantidad) <= 0) {
      errors.cantidad = "La cantidad es requerida.";
    } else if (checkInputs.countLenth(data.cantidad) > 300) {
      errors.error = "No ingrese más de 300 caracteres.";
    }
    if (!checkInputs.notNull(data.descripcion)) {
      errors.descripcion = "La descripción es requerida.";
    } else if (checkInputs.countLenth(data.descripcion) > 300) {
      errors.error = "No ingrese más de 300 caracteres.";
    }

    return errors;
  },
};
