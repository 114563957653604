import React, { useState, useRef } from "react";
import {
  ListItemIcon,
  IconButton,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import BusinessIcon from "@material-ui/icons/Business";
import TuneIcon from "@material-ui/icons/Tune";
import PublishIcon from "@material-ui/icons/Publish";
import SecurityIcon from '@material-ui/icons/Security';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { useSelector, useDispatch } from "react-redux";

import { palette, colorHeader1 } from "configurations/styles/theme";
import { getRUCEmpresa } from "redux/actions/userActions";
import UploadCertificateDialog from "widgets/UploadCertificateDialog";

import { selectedResourceAction } from "redux/actions/resourcesActions";
import DialogConfirmExit from "../dialogs/ConfirmExit";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import usePermissons from "hooks/usePermissons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "contents",
  },
  paper: {
    marginRight: theme.spacing(2),
    backgroundColor: palette.primary.light,
    color: palette.primary.contrastText,
  },
}));

export default function MenuListComposition() {
  const classes = useStyles(colorHeader1);
  const [open, setOpen] = useState(false);
  const [openDialogUploadCertificate, setOpenUpCert] = useState(false);
  const [openDialogConfirmExit, setOpenDialogConfirmExit] = useState(false);
  const anchorRef = useRef(null);
  const tokenUser = useSelector((state) => state.user.tk);
  const dispatch = useDispatch();
  const { getConfigPermissons } = usePermissons(useSelector, dispatch);
  let configPermissons = getConfigPermissons() || [];
  configPermissons = configPermissons.filter(item => item.id === "IEM" || item.id === "CCO" || item.id === "SCE");

  const selectedResource = (resource) =>
    dispatch(selectedResourceAction(resource));

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
    setOpenUpCert(false);
  };

  const handleClickUserAccount = (prem) => () => {
    selectedResource({
      id: prem.id,
      nameMenu: prem.nameMenu,
      titlePage: prem.nameMenu,
      titleTab: prem.titleTab,
      menuPadre: null,
    });
    setOpen(false);
  };

  const handleClickUploadCertificate = () => {
    setOpenUpCert(true);
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleCloseDialogCertificate = () => {
    setOpenUpCert(false);
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const renderSettingsOptions = (pr, index) => {
    switch (pr.id) {
      case "IEM":

        return (
          <MenuItem onClick={handleClickUserAccount(pr)} key={index}>
            <ListItemIcon>
              <TuneIcon
                style={{ color: palette.primary.contrastText }}
              />
            </ListItemIcon>
            <ListItemText primary={pr.nameMenu} />
          </MenuItem>
        );
      case "CCO":

        return (
          <MenuItem onClick={handleClickUserAccount(pr)} key={index}>
            <ListItemIcon>
              <SecurityIcon
                style={{ color: palette.primary.contrastText }}
              />
            </ListItemIcon>
            <ListItemText primary={pr.nameMenu} />
          </MenuItem>
        );
      case "SCE":

        return (
          <MenuItem onClick={handleClickUploadCertificate} key={index}>
            <ListItemIcon>
              <PublishIcon
                style={{ color: palette.primary.contrastText }}
              />
            </ListItemIcon>
            <ListItemText primary={pr.nameMenu} />
          </MenuItem>
        );
      default:
        return null;
    }
  }

  return (
    <div className={classes.root}>
      <Tooltip title={`Información del Usuario`}>
        <IconButton
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <PersonOutlineOutlinedIcon style={{ color: palette.primary.menuHeader }} />
        </IconButton>
      </Tooltip>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <BusinessIcon
                        style={{ color: palette.primary.contrastText }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={tokenUser ? getRUCEmpresa(tokenUser) : ""}
                    />
                  </MenuItem>
                  {
                    configPermissons.map((pr, index) => renderSettingsOptions(pr, index))
                  }

                  <MenuItem onClick={() => setOpenDialogConfirmExit(true)}>
                    <ListItemIcon>
                      <ExitToAppIcon style={{ color: palette.primary.contrastText }} />
                    </ListItemIcon>
                    <ListItemText primary="Salir" style={{ color: palette.primary.contrastText }} />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <UploadCertificateDialog
        idempresa={tokenUser ? getRUCEmpresa(tokenUser) : ""}
        openDialog={openDialogUploadCertificate}
        onClose={handleCloseDialogCertificate}
      />
      <DialogConfirmExit
        openDialog={openDialogConfirmExit}
        closeDialog={setOpenDialogConfirmExit}
      />
    </div>
  );
}
