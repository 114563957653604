import React, { Fragment, useState } from 'react'
import EditIcon from '@material-ui/icons/Edit';
import { IconButton, Dialog, DialogTitle, DialogContent, Grid, NativeSelect, FormControl, CircularProgress, makeStyles, withStyles, InputBase, Button, Backdrop, Divider, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { palette, useStylesGlobal, styleInput } from 'configurations/styles/theme';
import { useStylesApp } from 'hooks/useStyles';
import EditTable from 'widgets/Tables/EditTable';
import bigDecimal from 'js-big-decimal';
import { useSnackbar } from 'notistack';
import { numDecimales, numDecimalesDescuento } from 'configurations/constant';
import { validationVoucher, calculateDiscount } from '../../invoices/validations';
import checkInputs from 'utils/checkInputs';
import { valorICE, valorIva } from 'views/facturacion/Forms/typeVoucher';



const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(3),
        minWidth: 120,
    },
    radio: {
        display: '-webkit-box',
        marginTop: '0px'
    },
    radioLable: {
        color: palette.primary.main,
        display: 'flex'
    },
    radioButton: {
        marginTop: '0px',
        paddingTop: '0px'
    },
    divForm: {
        marginBottom: '1%',
    },
    divInput: {
        marginBottom: '1%',
        paddingRight: '1%',
        paddingLeft: '0.5%'
    },
    divButton: {
        paddingLeft: '3%',
        paddingRight: '3%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '1%',
        marginBottom: '0.5%',
    },
    backdrop: {
        zIndex: theme.zIndex.modal + 999,
        color: palette.primary.main,
    },
}));
const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(0),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);
export function EditDetailPhysicalBtn({ data, productsDetalle, setProductsDetalle, setViewDetalle, viewDetalle, detalles }) {

    const classes = useStyles();
    const classesGlobales = useStylesGlobal();
    const classesApp = useStylesApp();
    const [open, setOpen] = useState(false);
    const [openLoader, setOpenLoader] = useState(false);
    const [msgError, setMsg] = useState({ show: false, msg: null, type: null });
    const [dataAditional, setDataAditional] = useState(data.informacionAdicional);
    const [iva, setIva] = useState(data.impuestoIva.length > 0 ? data.impuestoIva[0].CODIGO : '');
    const [ice, setIce] = useState(data.impuestoIce.length > 0 ? data.impuestoIce[0].CODIGO : '');
    const [product, setProduct] = useState(data);
    const [empty, setEmty] = useState(true);
    const [emptyPrecio, setEmtyPrecio] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const [errors, setErrors] = useState({});

    function closeAlert() { setMsg({ show: false }); }

    const handleTable = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeDescuento = event => {
        event.preventDefault();
        const re = /^[0-9\b.]{1,14}$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            if (Number(event.target.value.trim()) >= 0) {
                setProduct({
                    ...product,
                    [event.target.name]: event.target.value
                });
                if (event.target.name === 'precioUnitario') {
                    setEmtyPrecio(true);
                }
            }
        }
    }
    const validateProductData = (data) => {
        let newErros = validationVoucher.onSubmit(
            {
                codigoPrincipal: data.codigoPrincipal,
                cantidadProducto: data.cantidadProducto,
                precioUnitario: data.precioUnitario,
                descripcion: data.descripcion,
            }
        );
        if (Object.entries(newErros).length > 0) {
            setErrors(newErros)
            return false;
        } else {
            return true;
        }
    }


    const addProduct = () => {
        let discount = calculateDiscount({
            isDescuentoPorcentaje: product.isDescuentoPorcentaje,
            cantidadProducto: product.cantidadProducto,
            precioUnitario: product.precioUnitario,
            descuentoProducto: product.descuentoProducto,
        })
        let totProducto = Number(bigDecimal.multiply(product.cantidadProducto.toString(), product.precioUnitario.toString()) - Number(discount));

        let iceProducto = valorICE.reduce(function (iceProducto, vIce) {
            if (vIce.CODIGO === ice) {
                return Number(iceProducto) + ((Number(totProducto) * Number(vIce.VALOR)) / 100);
            } else {
                return Number(iceProducto);
            }
        }, 0);
        return {
            ...product,
            descuentoProducto: product.descuentoProducto.toString().trim() !== '' ? product.descuentoProducto : 0,
            impuestoIce: valorICE.filter(vice => vice.CODIGO === ice),
            impuestoIva: valorIva.filter(aiva => aiva.CODIGO === iva),
            informacionAdicional: dataAditional,
            total: Number(bigDecimal.subtract(bigDecimal.multiply(product.precioUnitario, product.cantidadProducto),
                discount)),
            totalIceProducto: Number(bigDecimal.round(iceProducto, checkInputs.countDecimals(iceProducto.toString()), bigDecimal.RoundingModes.HALF_UP))
        };

    }

    const handleUpdate = () => {
        const updateProduct = addProduct();
        if (validateProductData({
            codigoPrincipal: product.codigoPrincipal,
            cantidadProducto: product.cantidadProducto,
            precioUnitario: product.precioUnitario,
            descripcion: product.descripcion,
        })) {
            if (Number(product.cantidadProducto) > 0) {
                if (updateProduct.precioUnitario.toString() !== '' && Number(updateProduct.precioUnitario) >= 0) {
                    if (validateDecimal(updateProduct.precioUnitario.toString().trim())) {
                        if (validateDiscount()) {
                            console.log('entro a agregar')
                            const updateDetalle = {
                                cantidad: updateProduct.cantidadProducto,
                                codigoPorcentajeICE: ice ? ice : '',
                                codigoPorcentajeIVA: iva ? iva : '',
                                descuento: updateProduct.descuentoProducto,
                                codigoPrincipal: updateProduct.codigoPrincipal,
                                codigoAuxiliar: updateProduct.codigoAuxiliar,
                                descripcion: updateProduct.descripcion,
                                idProducto: updateProduct.idProducto,
                                precioProducto: updateProduct.precioUnitario,
                                informacionAdicional: updateProduct.informacionAdicional,
                                isDescuentoPorcentaje: updateProduct.isDescuentoPorcentaje,
                            }
                            productsDetalle.map(function updateProducts(prod, index, array) {

                                if (compareObject(data, prod)) {
                                    productsDetalle.splice(index, 1, updateProduct);
                                    detalles.splice(index, 1, updateDetalle);

                                    enqueueSnackbar(`Detalle editado`, {
                                        variant: 'success',
                                        anchorOrigin: {
                                            vertical: 'top',
                                            horizontal: 'right',
                                        },
                                        autoHideDuration: 4000,
                                    });
                                }
                            })


                            if (viewDetalle === 1) {
                                setViewDetalle(2)
                            } else if (viewDetalle === 2) {
                                setViewDetalle(1)
                            }
                            clearData();
                            setOpen(false);
                        }
                    }
                } else {
                    setEmtyPrecio(false);
                }
            }
        } else {
            setEmty(false);
        }

    }

    const validateDiscount = () => {
        if (product.descuentoProducto.toString().trim() !== '' && Number(product.descuentoProducto) > 0) {
            if (validateDecimal(product.descuentoProducto.toString().trim())) {
                let valorDiscount = calculateDiscount({
                    isDescuentoPorcentaje: product.isDescuentoPorcentaje,
                    cantidadProducto: product.cantidadProducto,
                    precioUnitario: product.precioUnitario,
                    descuentoProducto: product.descuentoProducto,
                });

                valorDiscount = bigDecimal.round(valorDiscount, 2, bigDecimal.RoundingModes.HALF_UP)
                let totalPrice = Number(product.precioUnitario) * Number(product.cantidadProducto)
                totalPrice = bigDecimal.round(totalPrice, 2, bigDecimal.RoundingModes.HALF_UP)
                totalPrice = bigDecimal.round(totalPrice, 2, bigDecimal.RoundingModes.HALF_UP)
                if (Number(valorDiscount) <= Number(totalPrice)) {
                    return true;
                } else {
                    enqueueSnackbar(`El descuento debe ser menor a ${totalPrice}`, {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        autoHideDuration: 4000,
                    });
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return true;
        }
    }


    const validateDecimal = (numberValor) => {
        let splitList = numberValor.split('.');
        if (splitList.length > 2) {
            enqueueSnackbar(`El dato ingresado es erroneo utilice punto (.) para separar decimales`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                autoHideDuration: 4000,
            });
            return false;
        } else if (splitList.length > 1) {
            if (splitList[1].length > numDecimales) {
                enqueueSnackbar(`Los datos numéricos debe tener máximo 2 decimales Ej, 12.43`, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    autoHideDuration: 4000,
                });
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }

    }

    const compareObject = (data, prod) => {
        var aKeys = Object.keys(data).sort();
        var bKeys = Object.keys(prod).sort();
        if (aKeys.length !== bKeys.length) {
            return false;
        }
        if (aKeys.join('') !== bKeys.join('')) {
            return false;
        }
        for (var i = 0; i < aKeys.length; i++) {
            if (data[aKeys[i]] !== prod[bKeys[i]]) {
                return false;
            }
        }
        return true;

    }

    const clearData = () => {
        setEmty(true);
        setEmtyPrecio(true);
    }


    const handleChange = (event) => {
        event.preventDefault();
        const { error, value, keyWordError } = validationVoucher.onChange(
            event.target.name,
            event.target.value
        );
        switch (event.target.name) {
            case 'codigoPrincipal':
            case 'codigoAuxiliar':
                if (checkInputs.countDecimals(event.target.value.toString().trim()) <= 25) {
                    setProduct({
                        ...product,
                        [event.target.name]: event.target.value,
                    });

                }
                setErrors({ ...errors, [event.target.name]: error });
                break;

            case 'descripcion':
                if (checkInputs.countDecimals(event.target.value.toString().trim()) <= 100) {
                    setProduct({
                        ...product,
                        [event.target.name]: event.target.value,
                    });

                }
                setErrors({ ...errors, [event.target.name]: error });
                break;

            case 'cantidadProducto':
                if (keyWordError !== "isNotNumeric" && keyWordError !== "isLong" && checkInputs.countDecimals(event.target.value.toString().trim()) <= numDecimales) {
                    setProduct({
                        ...product,
                        [event.target.name]: event.target.value,
                    });

                }
                setErrors({ ...errors, [event.target.name]: error });
                break;
            case 'precioUnitario':
                if (keyWordError !== "isNotDecimal" && keyWordError !== "isLong" && checkInputs.countDecimals(event.target.value.toString().trim()) <= numDecimales) {
                    setProduct({
                        ...product,
                        [event.target.name]: event.target.value,
                    });
                }
                setErrors({ ...errors, [event.target.name]: error });
                break;
            case 'descuentoProducto':
                if (keyWordError !== "isNotDecimal" && keyWordError !== "isLong" && checkInputs.countDecimals(event.target.value.toString().trim()) <= numDecimalesDescuento) {
                    setProduct({
                        ...product,
                        [event.target.name]: event.target.value,
                    });
                }
                setErrors({ ...errors, [event.target.name]: error });
                break;
            case 'isDescuentoPorcentaje':
                setProduct({
                    ...product,
                    [event.target.name]: event.target.value,
                });

                break;
            default:
                break;
        }


    };

    return (
        <Fragment>
            <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => handleTable()} >
                <EditIcon style={{ color: palette.info.dark }} />
            </IconButton>
            <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose} >
                <DialogTitle id="max-width-dialog-title">Editar detalle</DialogTitle>
                <DialogContent>
                    <form >
                        <Grid container spacing={1} >
                            <Grid item xs={12} md={6}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" >
                                    <Grid item xs={12} md={12}>
                                        <label>Los campos marcados con (  <font color={palette.error.main}> *</font> )son obligatorios:</label>
                                    </Grid>
                                    {msgError.show ?
                                        <Alert
                                            tipo={msgError.type}
                                            mensaje={msgError.msg}
                                            mesajeSubrayado={null}
                                            titulo={null}
                                            mostrar={msgError.show}
                                            cerrarAlerta={closeAlert}
                                        /> : null
                                    }
                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>Codigo Principal: <font color={palette.error.main}>*</font></label>
                                    </Grid>

                                    <Grid item xs={12} md={8} className={classes.divInput} >
                                        <input type="text"
                                            placeholder="Ej. 0002"

                                            name="codigoPrincipal"
                                            value={product ? product.codigoPrincipal : ''}
                                            autoComplete="off"
                                            className='form-control is-valid'
                                            onChange={handleChange}
                                        />

                                    </Grid>

                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>Codigo Auxiliar: <font color={palette.error.main}>*</font></label>
                                    </Grid>
                                    <Grid item xs={12} md={8} className={classes.divInput}>
                                        <input type="text"
                                            placeholder="Ej. 0002"

                                            name="codigoAuxiliar"
                                            value={product.codigoAuxiliar ? product.codigoAuxiliar : ''}
                                            autoComplete="off"
                                            onChange={handleChange}
                                            className='form-control is-valid' />

                                    </Grid>
                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>Cantidad: <font color={palette.error.main}>*</font></label>
                                    </Grid>
                                    <Grid item xs={12} md={8} className={classes.divInput}>
                                        <input className={styleInput(
                                            errors.cantidadProducto,
                                            product.cantidadProducto
                                        )}
                                            type="text"
                                            name="cantidadProducto"
                                            value={product.cantidadProducto}
                                            onChange={handleChange}
                                            autoComplete="off"
                                        />
                                        {errors.cantidadProducto ? (
                                            <p className={classesApp.errorLetters}>
                                                {errors.cantidadProducto}
                                            </p>
                                        ) :
                                            null}

                                    </Grid>
                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>Descripción: <font color={palette.error.main}>*</font></label>
                                    </Grid>
                                    <Grid item xs={12} md={8} className={classes.divInput}>
                                        <input className='form-control is-valid'
                                            type="text"

                                            placeholder="Ej. Ordenador"
                                            name="descripcion"
                                            value={product.descripcion ? product.descripcion : ''}
                                            onChange={handleChange}
                                            autoComplete="off"
                                        />

                                    </Grid>
                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>Precio Unitario: <font color={palette.error.main}>*</font></label>
                                    </Grid>
                                    <Grid item xs={12} md={8} className={classes.divInput}>
                                        <input className={styleInput(
                                            errors.precioUnitario,
                                            product.precioUnitario
                                        )}
                                            type="text"
                                            placeholder="Ej. 12"
                                            name="precioUnitario"
                                            value={product.precioUnitario}
                                            onChange={handleChange}
                                            autoComplete="off"
                                        />
                                        {errors.precioUnitario ? (
                                            <p className={classesApp.errorLetters}>
                                                {errors.precioUnitario}
                                            </p>
                                        ) :
                                            null}

                                    </Grid>


                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>Descuento:</label>
                                    </Grid>
                                    <Grid item xs={6} md={4} className={classes.divInput}>
                                        <input className={styleInput(
                                            errors.descuentoProducto,
                                            product.descuentoProducto
                                        )}
                                            type="text"
                                            placeholder="Ej. 0"
                                            name="descuentoProducto"
                                            value={product.descuentoProducto}
                                            onChange={handleChange}
                                            autoComplete="off"
                                        />
                                        {errors.descuentoProducto ? (
                                            <p className={classesApp.errorLetters}>
                                                {errors.descuentoProducto}
                                            </p>
                                        ) :
                                            null}
                                    </Grid>
                                    {<Grid xs={6} md={4}>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                aria-label="isPorcentaje"
                                                name="isDescuentoPorcentaje"
                                                value={product.isDescuentoPorcentaje}
                                                className={classes.radio}
                                                required
                                                onChange={handleChange}
                                            >
                                                <FormControlLabel
                                                    value={'false'}
                                                    control={
                                                        <Radio color="primary" className={classes.radioButton} />
                                                    }
                                                    label="Valor"
                                                    labelPlacement="start"
                                                    className={classes.radioLable}
                                                />
                                                <FormControlLabel
                                                    value={'true'}
                                                    control={
                                                        <Radio color="primary" className={classes.radioButton} />
                                                    }
                                                    label="Porcentaje"
                                                    labelPlacement="start"
                                                    className={classes.radioLable}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>}

                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>IVA: <font color={palette.error.main}>*</font></label>
                                    </Grid>
                                    <Grid item xs={12} md={8} className={classes.divInput}>
                                        <FormControl className={classesGlobales.select} >
                                            <NativeSelect
                                                value={iva}
                                                onChange={event => setIva(event.target.value)}
                                                input={<BootstrapInput />}
                                            >
                                                <option aria-label="None" value="" disabled >--Seleccione--</option>
                                                {
                                                    valorIva.map(iva => (
                                                        <option
                                                            key={iva.CODIGO}
                                                            value={iva.CODIGO}>
                                                            {iva.NOMBRE}
                                                        </option>
                                                    ))
                                                }

                                            </NativeSelect>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={4} className={classes.divForm}>
                                        <label>ICE:</label>
                                    </Grid>
                                    <Grid item xs={12} md={8} className={classes.divInput}>
                                        <FormControl className={classes.margin} >
                                            <NativeSelect
                                                value={ice}
                                                onChange={event => setIce(event.target.value)}
                                                input={<BootstrapInput />}
                                            //placeholder='Seleccione'
                                            >
                                                <option aria-label="None" value='' >--Ninguno--</option>
                                                {
                                                    valorICE.map(ice => (
                                                        <option
                                                            key={ice.CODIGO}
                                                            value={ice.CODIGO}>
                                                            {`${ice.CODIGO} - ${ice.NOMBRE}`}
                                                        </option>
                                                    ))
                                                }
                                            </NativeSelect>
                                        </FormControl>
                                    </Grid>


                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container xs={12} md={12} justify='center' alignItems="center">
                                    <EditTable
                                        key={product.idProducto}
                                        dataAditional={dataAditional}
                                        setDataAditional={setDataAditional} />
                                </Grid>
                            </Grid>



                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} md={12} className={classes.divButton}>
                            <Button variant="contained" color="primary" onClick={handleUpdate} className={classesGlobales.button} >
                                Actualizar
                            </Button>

                            <Button variant="outlined" color="secondary" onClick={handleClose} className={classesGlobales.button}>
                                Cancelar
                             </Button>
                        </Grid>

                    </form>
                </DialogContent>
            </Dialog>
            <Backdrop className={classes.backdrop} open={openLoader} >
                <CircularProgress color="inherit" />
            </Backdrop>


        </Fragment>
    )
};

